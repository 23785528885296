.cart-notice {
  padding: 20px;
  margin: 10px auto;
  background-color: $clr-dy-grey-1;

  p {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }
}

.cart-empty, .checkout-success {
  border: 1px solid $clr-dy-grey-3;
  padding: 20px;
  margin: 20px 0;

  p {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; padding: 0 20px; }
  }
}

/** Custom styling for Jumbo Cart Notice, to align with the rest of the cart page
 */

.dyson-ae, .dyson-il, .dyson-sa, .dyson-my, .dyson-th, .dyson-hu {
  .cart-notice {
    margin: 15px auto;
  }
}

.dyson-sa .cart-notice-wrapper,
.dyson-il .cart-notice-wrapper,
.dyson-th .cart-notice-wrapper,
.dyson-ro .cart-notice-wrapper {
  padding: 0 20px;

  @include respond-min($bp-tablet-min) {
    padding: 0 50px;
  }

  @include respond-min($bp-tablet-wide-min) {
    padding: 0 20px;
  }
}
