// ------- DYSON-MX SPECIFIC -------

.dyson-mx {
  .am-checkout {

    .payment-method .payment-method-title input[type="radio"] {
      display: none;
    }

    .fieldset {
      .field {
        &[name="shippingAddress.postcode"] {
          width: 100%;
          margin-right: 4%;

          .input-text {
            border-color: $clr-dy-grey-2;
          }

          &._error {
            .input-text {
              border-color: $clr-dy-red-1;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.prefix"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.company"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.0"] {
          label {
            letter-spacing: -0.3px;
          }

          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
            font-size: 15px;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.1"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.2"] {
          width: 48%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.city"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="shippingAddress.region"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.telephone"] {

          label.label {
            &:after {
              content: '*';
              display: inline-block;
              margin: 0 0 0 3px;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 100%;

            .control {
              width: 48%;
            }
          }
        }

        &[name="shippingAddress.region_id"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            display: block;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.country_id"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            display: block;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.newsletter_signup"] {
          @include respond-min($bp-tablet-wide-min) {
            padding-bottom: 0;
          }
        }

        &[name="shippingAddress.custom_attributes.dont_activate_guarantee"] {
          label.label {
            display: inline-block;
          }
        }

        //  PAYPAL PLUS RELATED - BILLING ADDRESS STYLES //


        &[name="billingAddressqbo_paypalplusmx.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.lastname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.company"] {
          width: 48%;
        }

        &[name="billingAddressqbo_paypalplusmx.street.0"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.street.1"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.street.2"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.city"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.postcode"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.country_id"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressqbo_paypalplusmx.telephone"] {

          label.label {
            &:after {
              content: '*';
              display: inline-block;
              margin: 0 0 0 3px;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }
      }
    }

    .control.complete:after {
      height: 36px;
      width: 36px;
      top: 15px;
      right: 15px;
    }
  }

  &.checkout-onepage-success {
    .column.main {
      > img[src^="https://www.paypalobjects.com"] {
        display: none;
      }
    }
  }

}
