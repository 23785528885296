.contact-us  {
    border-bottom: 1px solid #dadada;
    color: #000;

    h2 {
        margin-bottom: 40px;
        color: #000;
        font-size: 28px;

        @include respond-min($bp-tablet-wide-min) {
            font-size: 32px;
            margin-bottom: 66px;
        }
    }

    .icon,
    .icon svg {
        width: 60px;
        height: 60px;
    }

    .icon {
        float: left;
        margin-right: 30px;
        display: block;
    }

    p {
        font-family: $f-fallback;
    }
}

.contact-us__call,
.contact-us__email,
.contact-us__whatsapp{
    margin-bottom: 40px;

    @include respond-min(890px) {
        margin-bottom: 80px;
    }

    p {
        font-size: 14px;

        @include respond-min($bp-tablet-wide-min) {
            font-size: 16px;
        }
    }

    .icon-text {
        font-size: 16px;

        @include respond-min($bp-tablet-wide-min) {
            font-size: 18px;
        }
    }
}

.contact-us__call {
    @include respond-min(890px) {
        width: 50%;
    }

    @include respond-min($bp-desktop-min) {
        width: 64%;

        &.with-whatsapp {
            width: 33%;
        }

    }




    p {
        margin-bottom: 36px;

        &:last-child {
            margin: 0;
        }
    }

    .icon {
        position: relative;
        top: -3px;
    }

    span {
        font-weight: 700;
        color: #000;
    }
}


.contact-us__email {
    @include respond-min(890px) {
        width: 50%;
    }

    @include respond-min($bp-desktop-min) {
        width: 36%;

        &.with-whatsapp {
            width: 33%;
        }

    }

    a {
        font-weight: 700;
        color: #000;
        text-decoration: underline;
    }

    .contact-us__inner {
        border-top: 1px solid #dadada;
        padding-top: 40px;
        min-height: 100%;

        @include respond-min(890px) {
            border: none;
            border-left: 1px solid #dadada;
            padding-top: 0;
            padding-left: 64px;
        }
    }
}



.contact-us__whatsapp {
    @include respond-min(890px) {
        width: 50%;
    }

    @include respond-min($bp-desktop-min) {
        width: 33%;
    }

    a {
        font-weight: 700;
        color: #000;
    }

    .contact-us__inner {
        border-top: 1px solid #dadada;
        padding-top: 40px;
        min-height: 100%;

        @include respond-min(890px) {
            border: none;
            border-left: 1px solid #dadada;
            padding-top: 0;
            padding-left: 64px;
        }
    }
}


.contact-us__address {
    margin-bottom: 80px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    p {
        margin-bottom: 0;
    }

    &--title, p {
        font-size: 16px;
        @include respond-min($bp-tablet-wide-min) {
            font-size: 18px;
        }
    }
}



