$fs-search-h3: (g:20px, gt:28px);

.search-results {

  .search-results__input {
    @include fs-static(32);
    font-family: $f-futura;
    font-weight: 300;
    background: transparent;
    border: 0;
    height: auto;
    padding: 0;
    color: $clr-dy-grey-7;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    @include respond-min($bp-tablet-wide-min) {
      @include fs-static(40);
    }

    &::placeholder {
      color: $clr-dy-grey-4;
    }

    &:hover, &:focus, &:active {
      box-shadow: none;
    }

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      display: block;
      background-color: $clr-dy-grey-2;
    }
  }
}

.search-results__form {
  width: 100%;
  position: relative;
  padding-right: 60px;
  margin-top: 60px;

  @include respond-min($bp-tablet-wide-min) {
    margin-top: 0;
  }

  .icon--clear{
    fill: $clr-dy-grey-7;
    height: 0.75rem;
    width: 0.75rem;

    @include respond-min($bp-tablet-wide-min) {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .icon--search {
    fill: $clr-dy-grey-7;
    height: 2rem;
    width: 2rem;

    @include respond-min($bp-tablet-min) {
      height: 3rem;
      width: 3rem;
    }
  }
}

.search-results__underline {
  display: block;
  border-bottom: 1px solid $clr-dy-grey-2;
  padding-bottom: 0.5rem;
  position: relative;
  width: 100%;

  @include respond-min($bp-tablet-wide-min) {
    width: 78%;
  }
}

.search-results__submit {
  position: absolute;
  top: 0.75rem;
  right: -60px;
  background: transparent;
  border: 0;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;

  &[disabled] {
    opacity: 0.25;
  }

  &:hover, &:focus, &:active {
    border: 0;
    background: transparent;
  }
}

/*
  1. Reset button styles
  todo: Stop setting styles on <button>
*/
.search-results__clear {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 1.4rem;

  // @include respond-min($bp-tablet-min) {
  //   right: 4rem;
  //   top: 1.25rem;
  // }
  //
  // @include respond-min($bp-tablet-wide-min) {
  //   right: 1.5rem;
  //   top: 2rem;
  // }

  border: none; /* 1 */
  background: none; /* 1 */
  padding: 0; /* 1 */

  &:hover,
  &:focus {
    background: none; /* 1 */
    border: none; /* 1 */
  }

  [data-input-state="input"] & {
    opacity: 1;
  }

  &:hover {
    .icon--clear {
      fill: $clr-dy-grey-7;
    }
  }
}

.search-results__indicator {
  margin-bottom: 1em;
  p {
    @include fs-static(14);
    color: $clr-dy-grey-7;
    font-family: $f-fallback;
  }
}

.search-results__results-list {
  border-top: 2px solid $clr-dy-grey-2;
  margin-top: $v-small;
  margin-left: (-$l-gutter-small);
  margin-right: (-$l-gutter-small);
  list-style: none;
  padding: 0;

  @include respond-min($bp-tablet-min) {
    margin-left: (-$l-gutter);
    margin-right: (-$l-gutter);
  }

  @include respond-min($bp-tablet-wide-min) {
    margin-left: auto;
    margin-right: auto;
  }
}

.search-results__result {
  border-bottom: 1px solid $clr-dy-grey-2;
  padding-left: $l-gutter-small;
  padding-right: $l-gutter-small;
  padding-top: $v-minute;
  padding-bottom: $v-minute;

  @include respond-min($bp-tablet-min) {
    padding-left: $l-gutter;
    padding-right: $l-gutter;
    padding-top: $v-tiny;
    padding-bottom: $v-tiny;
  }

  @include respond-min($bp-tablet-wide-min) {
    padding-left: 0;
    padding-right: 0;
    max-width: 75%;
  }

  h3 {
    @include fs-responsive($fs-search-h3);
    color: $clr-dy-grey-7;
    font-weight: 400;
    font-family: $f-futura;
    margin-bottom: 0.5em;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }
  }
}

.search-results__link {

}

.search-results__link__link-icon {
  fill: $clr-dy-grey-7;
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
}

.search-results__link__link-text {
  @include fs-static(16);
  color: $clr-dy-grey-7;
  font-family: $f-futura;
  font-weight: 500;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}
