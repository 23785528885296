.stat {
  display: block;

  &--dark {
    color: $clr-white;
  }
}

.stat__data {
  display: block;
  color: $clr-black;
  margin-bottom: 0.4em;

  .stat--dark & {
    color: $clr-white;
  }
}

.stat__value {
  display: inline-block;
  @include fs-responsive((g:56,gtw:72));
  line-height: $lh-tight;
  letter-spacing: -0.05em;
  font-family: $f-foundrygridnik;
  font-weight: 500;
}

.stat__measure {
  display: inline-block;
  @include fs-responsive((g:15px,gd:18px));
  // margin-left: 3px;
}

.stat__info {
  display: block;
}
