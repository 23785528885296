.icon-info {
    display: inline-block;
    text-align: center;
    background: none;
    border: none;
    max-width: 100%;

    &:hover,
    &:focus {
        background: none;
        border: none;
    }

    &:focus {
        @include focus();
    }

    &--inline {
        text-align: left;
        display: flex;
        align-items: center;
    }
}

.icon-info__url {
    display: block;
    @include link-reset;
    font-family: $f-futura;

}

.icon-info__icon {
    @include vspace($v-minuscule,$v-minuscule,$v-minuscule,$dir:bottom);
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;
    flex-shrink: 0;
    width: 64px;
    min-height: 64px;

    .icon-info--small & {
        @include vspace($v-minuscule,$dir:bottom);
        width: 60px;
        min-height: 60px;
    }

    .icon-info--inline & {
        width: 40px;
        min-height: 40px;
        margin: 0 12px 0 0;
        @include vspace(0,$dir:bottom);

        @include respond-min($bp-mobile-wide-min) {
            width: 40px;
            min-height: 40px;
        }
    }

    .image {
        width: 100%;
    }
}

.icon-info__icon--full-width {
    @include respond-min($bp-mobile-min) {
        width: auto !important;
    }
}

.icon-info__body {
    width: 100%;
}

.icon-info__title {
    color: $clr-dy-grey-7!important;
    font-weight: 400;
    display: block;
    margin: 0 0 0.5em 0;
    font-family: $f-futura;
    @include fs-responsive((g:20px, gt:22px, gtw:25px));


    .icon-info--small & {
        margin: 0 0 0.5em 0;
        @include fs-responsive((g:13px, gt:16px, gtw:18px));
    }

    .icon-info--inline & {
        margin: 0;
        @include fs-responsive((g:18px, gt:21px, gtw:24px));
    }

    .icon-info--dark & {
        color: $clr-dy-grey-7;
    }
}

.icon-info__text {
    display: block;
    @include fs-responsive((g:13px, gt:16px, gtw:18px));

    .icon-info--small & {
        margin: 0 0 0.5em 0;
        @include fs-responsive((g:13px, gt:16px, gtw:18px));
    }
}

.icon-info-set__title {
    @include fs-responsive((g:28px, gt:32px, gd:40px));
}

.row--large, .row--medium, .row--small {
    &.rtb-icons-row{
        padding: 16px 0;
        @include respond-min(768px) {
            padding: 20px 0;
        }
        @include respond-min(1024px) {
            padding: 24px 0;
        }
    }
}

.row--small {
    &.rtb-icons-row{
        .icon-info__text {
            display: none;
        }
    }
}

ul.rtb-modal-list {
    .icon-info {
        background: $clr-white;
        width: 100%;
        border: 1px solid $clr-dy-grey-25;
        flex-direction: column;
        padding: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        @include respond-min(768px) {
            padding: 12px;
        }
        @include respond-min(1024px) {
            padding: 16px;
        }

        &:hover,
        &:focus {
            background: $clr-white;
            border: 1px solid $clr-dy-grey-7;
        }

        &:active {
            border: 3px solid $clr-dy-grey-7;
            background: none;
            margin: -2px;
        }

        &--inline {
            flex-direction: column;
            padding: 8px;
            @include respond-min(768px) {
                padding: 12px;
            }
            @include respond-min(1024px) {
                padding: 16px;
            }
        }

    }

    .icon-info__title {
        font-weight: 500;
        text-decoration: underline;
        margin: 0;

        .icon-info--inline &,  .icon-info & {
            @include fs-responsive((g:16px, gtw:18px));
            line-height: 24px;
            @include respond-min(1024px) {
                line-height: 28px;
            }
        }
    }

    .icon-info--inline, .icon-info {
        .icon-info__title {
            font-size: 16px;
            line-height: 24px;
            @include respond-min(1024px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
        .icon-info__icon {
            width: 24px;
            height: 24px;
            min-height: 24px;
            margin: 0 0 12px;
            padding-bottom: 0;

            @include respond-min(1024px) {
                width: 28px;
                height: 28px;
                min-height: 28px;
                margin-bottom: 8px;
            }
        }
    }
    .icon-info__text {
        color: $clr-dy-grey-7;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        @include respond-min(1024px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.rtb-icons-header {
    color: $clr-dy-grey-7;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    @include respond-max(767px) {
        &.featured-enabled {
            margin-bottom: 24px;
        }
    }
    @include respond-min(768px) {
        padding-bottom: 12px;
        font-size: 22px;
        line-height: 28px;
    }
    @include respond-min(1024px) {
        padding-bottom: 16px;
        font-size: 24px;
        line-height: 32px;
    }
}

.rtb-title-tile {
    display: flex;
    align-items: center;
    height: 100%;
}

.rtb-overlay {
    background-color: $clr-white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100000;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    transform: translateZ(1px);
    transition: all .3s ease-out;

    &.overlay-is-open {
        display: flex;
        flex-direction: column-reverse;
        background-color: rgba(0,0,0,0.6);
        height: 100%;

        .rtb-overlay-container  {
            animation-name: slideup;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
        }
    }

    .rtb-overlay-container {
        background-color: $clr-white;

        .icon-info-set-section {
            margin-bottom: 0;
            &:before{
                content: none;
            }
        }
    }

    .overlay__content--fullscreen {
        margin: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        height: auto !important;
    }

}
.overlay-is-open {
    overflow: hidden;
    .rtb-overlay {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.rtb-overlay-container {
    .icon-info-set-section {
        color: $clr-dy-grey-7;
        padding-bottom: 16px;

        @include respond-min(768px) {
            padding-bottom: 20px;
        }

        @include respond-min(1024px) {
            padding-bottom: 24px;
        }

        .rtb-overlay-header {
            padding: 16px 0;
            margin: 0;
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 9;

            @include respond-min(768px) {
                padding: 20px 0;
            }

            @include respond-min(1024px) {
                padding: 24px 0;
            }

            .rtb-overlay-header-wrapper {
                position: relative;
                h2 {
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 24px;
                    margin: 0;
                    padding: 0;

                    @include respond-min(1024px) {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    img {
                        height: 26px;
                        width: 26px;
                        @include respond-min(1024px) {
                            height: 30px;
                            width: 30px;
                        }
                    }
                    span {
                        padding-left: 7px;
                    }
                }
                .rtb-overlay-close-button {
                    position: absolute;
                    right: -42px;
                    top: -5px;
                    outline: 0;
                    border: 0;
                    background: 0;
                    padding: 10px;
                    color: $clr-dy-grey-7;
                    line-height: 16px;
                    text-align: center;

                    .rtl & {
                        right: unset;
                        left: -42px;
                    }

                    @include respond-max(767px) {
                        right: -17px;
                        .rtl & {
                            right: unset;
                            left: -17px;
                        }
                    }

                    @include respond-min(1024px) {
                        right: -5px;
                        .rtl & {
                            right: unset;
                            left: -5px;
                        }
                    }

                    &.action-close {
                        &:before {
                            content: url('../images/icons/modal-close.svg');
                            -webkit-font-smoothing: antialiased;
                            font-size: 16px;
                            line-height: 16px;
                            font-family: "icons-blank-theme";
                            margin: 0;
                            display: inline-block;
                            font-weight: normal;
                            overflow: hidden;
                            vertical-align: middle;
                            text-align: center;
                            width: 16px;
                            height: 16px;
                        }

                        &:focus {
                            @include focus();
                        }
                        span {
                            border: 0;
                            clip: rect(0, 0, 0, 0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                        }
                    }
                }
            }
        }
        .rtb-overlay-content{
            font-size: 14px;
            line-height: 20px;
            @include respond-min(1024px) {
                font-size: 16px;
                line-height: 24px;
            }
            p:first-child {
                margin-top: 0;
            }
        }

    }
}


.tile-wrap-count-4 {
    li.tile-no-1, li.tile-no-2 {
        @include respond-max(767px) {
            margin-bottom: 8px;
        }
    }
}

.featured-enabled {
    .title-tile {
        @include respond-max(767px) {
            padding-bottom: 16px;
            .rtb-icons-header {
                padding-bottom: 8px;
            }
        }
    }
}

@include respond-max(767px) {
    .icon-info-set__list {
        ul.rtb-modal-list {
            margin-left: 0;

            li {
                padding-left: 0;
                .featured-tile {
                    width: 100%;
                    left: 0;
                }
            }
            &.tile-wrap-count-4 {
                li {
                    &.tile-no-2,&.tile-no-4 {
                        padding-left: 10px;
                        .rtl & {
                            padding-left: 0;
                            padding-right: 10px;
                        }
                    }
                }
            }
            &.tile-wrap-count-3 {
                li {
                    &.tile-no-3 {
                        padding-left: 10px;
                        .rtl & {
                            padding-left: 0;
                            padding-right: 10px;
                        }
                    }
                }
            }
            &.tile-wrap-count-2 {
                li {
                    &.tile-no-2 {
                        padding-left: 10px;
                        .rtl & {
                            padding-left: 0;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.tile-wrap-count-3 {
    li.title-tile,li.tile-no-1 {
        @include respond-max(767px) {
            margin-bottom: 8px;
        }
    }
    li.tile-no-1 {
        @include respond-max(767px) {
            width: 100%;
        }
    }
}

.tile-wrap-count-2 {
    li.title-tile {
        @include respond-max(767px) {
            margin-bottom: 8px;
        }
    }
}

.tile-wrap-count-1 {
    li.title-tile {
        @include respond-max(767px) {
            margin-bottom: 8px;
        }
    }
    li.tile-no-1 {
        @include respond-max(767px) {
            width: 100%;
        }
    }
}

.icon-info-set__list {
    ul.featured-enabled {
        @include respond-min(768px) {
            padding-top: 28px;
        }
    }
}

.featured-tile {
    position: absolute;
    top: -28px;
    text-align: center;
    width: calc(100% - 30px);
    left: 30px;
    right: 0;
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
    background-color: $clr-dy-grey-7;
    color: $clr-white;
    border-radius: 2px 2px 0 0;

    @include respond-max(1023px) {
        width: calc(100% - 20px);
        left: 20px;
        font-size: 12px;
        line-height: 24px;
        top: -24px;
    }

}

.icon-info-set {
    &.theme-light {
        background-color: $clr-dy-grey-05;

        .rtb-modal-list .icon-info {
            &.tile-selected {
                border: 3px solid $clr-dy-grey-7;
            }
        }
    }
    &.theme-dark {
        background-color: $clr-dy-grey-7;
        .rtb-icons-header {
            color: $clr-white !important;
        }
        .rtb-modal-list .icon-info__title,.rtb-modal-list .icon-info__text {
            color: $clr-white !important;
        }
        .featured-tile {
            background-color: white;
            color: $clr-dy-grey-7;
        }
        .rtb-modal-list .icon-info {
            border: 1px solid #919191;
            background-color: $clr-dy-grey-7;
            &:hover {
                border-color: $clr-white;
            }
            &.tile-selected {
                border: 3px solid $clr-white;
            }
        }
    }
}

@-webkit-keyframes digi-fade-in-modal {
0 {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    opacity: 0
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
}
}

@keyframes digi-fade-in-modal {
0 {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    opacity: 0
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
}
}

@-webkit-keyframes digi-slide-up-modal {
0 {
    margin-top: 100vh;
    height: 100%
}
to {
    margin-top: 0;
    height: 100%
}
}


@keyframes digi-slide-up-modal {
0 {
    margin-top: 100vh;
    height: 100%
}
to {
    margin-top: 0;
    height: 100%
}
}

.rtb-overlay {
    margin-top: 100%;
}

.overlay-is-open .rtb-overlay {
    overflow-x: hidden;
    overflow-y: auto;
    animation-duration: .25s,.5s;
    animation-timing-function: ease-in-out,ease-in-out;
    animation-delay: 0,0;
    animation-iteration-count: 1,1;
    animation-direction: normal,normal;
    animation-fill-mode: both,both;
    animation-play-state: running,running;
    animation-name: digi-fade-in-modal,digi-slide-up-modal;
}
