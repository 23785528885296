// ----------
// SECONDARY NAV

.secondary-nav {
  background-color: $clr-dy-grey-7;
  color: $clr-white;
  @include clearfix;
  position: relative;

  .layout {
    display: flex;
    min-height: 3.5rem;

    @include respond-min($bp-tablet-min) {
      min-height: 4rem;
    }
  }

  .icon--chevron {
    fill: $clr-white;
    width: 16px;
    height: 16px;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 140px;
    left: inherit;
    margin-left: inherit;
    margin-top: -8px;

    @include respond-min($bp-mobile-min) {
      right: 37%;
      margin-right: 4%;
    }

    @include respond-min($bp-mobile-wide-min) {
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }

    @include respond-min($bp-tablet-min) {
      left: 50%;
      right: inherit;
    }
  }

  .bcStatic {
    a {
      @include button;
      @include button--fluid;
      @include button--secondary;
      padding: 17px 12px;

      @include respond-min(0) {
        @include fs-static(16);
      }

      @include respond-min(768px) {
        padding: 21px 18px;
      }

      img {
        height: .5625rem;
        position: relative;
        top: -2px;
        width: auto; /* fixes issue with IE11, no bad affect for other browsers */

        @include respond-min($bp-tablet-min) {
          height: .75rem;
        }
      }
    }
  }
}

.secondary-nav__inner {
  width: 100%;
}

.secondary-nav__title {
  @include fs-responsive((g:14px, gt:16px, gtw:18px));
  line-height: 1.5;
  font-weight: 400;
  color: $clr-white;
  padding: 5px 0;
  display: inline-flex;
  flex-wrap: wrap;
}

.price-wrapper {
  margin-left: 5px;

  .dyson-my .price-final_price & {
    margin-left: 0;
  }
}

.secondary-nav__dropdown {
  text-decoration: none;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  width: 75%;
  text-align: left;
  padding-right: 15%;


  @include respond-min($bp-tablet-min) {
    padding-right: 35%;
    //min-height: 65px;
  }

  //@include respond-min($bp-tablet-wide-min) {
  //  min-height: 74px;
  //}

  &:hover,
  &:focus {
    background: none;
    border: none;
  }

  .icon {
    transition: 0.2s ease-in-out;
  }

  &[aria-expanded="true"] {
    .icon--chevron {
      transform: rotateX(180deg);
    }
  }
}

.secondary-nav__text {
  flex: 1;
  display: flex;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 8px 0;

  &__divide {
    text-align: center;
    margin: 0 5px;
  }
}

.secondary-nav__text__total {
  font-weight: 500;
}

.secondary-nav__add {
  width: 120px;
  //justify-content: flex-start;
  flex-direction: row;
  //align-items: stretch;
  display: flex;
  margin-right: -25px;
  //position: relative;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: auto;
  height: auto;

  a.button.button--fluid.product__variant__add-to-cart--animated-smaller.button--tertiary.button--cart.secondary-nav__button.button--add-to-cart--two-line {
    padding: 11px;
  }

  @include respond-min($bp-tablet-min) {
    margin-right: 0;
    width: 180px;
  }

  @include respond-min($bp-tablet-wide-min) {
    width: 240px;
  }

  .secondary-nav__button {
    @include fs-responsive((g:14px, gt:16px, gtw:18px));
    padding: 0;
    //height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 0;
    }
  }

  form & {
    height: 100%;
  }
}

.secondary-nav__content {
  position: absolute;
  background-color: $clr-dy-grey-8;
  width: 100%;
  @include z-index(modal-bottom);
  max-height: 0;
  overflow: hidden;
  top: 56px;
  right: 0;
  left: 0;
  transition: max-height 0.2s ease-in-out;

  @include respond-min($bp-tablet-min) {
    top: 64px;
  }

  &.open {
    max-height: calc(100vh - 56px);
    overflow-y: auto;
  }
}

.secondary-nav__list {
  font-family: $f-futura;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.secondary-nav__links {
  @include list-reset;
  @include fs-static(16);
  font-family: $f-futura;
  font-weight: 300;
  padding-top: 0.5rem;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }


  li {
    @include list-item-reset;
  }
}

.secondary-nav__link {
  @include link-reset;
  line-height: 1.5;
  padding: 0.5rem 0;
  display: inline-block;
  width: 100%;
  color: $clr-white;

  &:hover, &:focus, &:active, &.active, &:visited {
    color: $clr-white;
  }
}


.secondary-nav__list__title {
  @include fs-static(16);
  margin: 0;
  line-height: 1.5;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $clr-dy-grey-5;
    margin-top: 1rem;

    @include respond-min($bp-tablet-min) {
      width: 50%;
    }
  }
}

.secondary-nav__breadcrumb {
  border-top: 1px solid $clr-dy-grey-6;
}


// SECONDARY NAV BREADCRUMB

.secondary-nav__breadcrumb {
  .clr-dy-grey-6-fill {
    background-color: inherit;
  }
  .layout{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.breadcrumb-list {
  @include list-reset;
}

.breadcrumb-list__item {
  @include list-item-reset;
  display: inline-flex;

  .icon {
    fill: $clr-dy-grey-5;
    margin: 0 .5rem;
    width: .75rem;
    height: 1.125rem;
  }

  &:last-of-type {
    .icon {
      display: none;
    }
  }
}

.breadcrumb-list__link {
  @include link-reset;
  font-family: $f-futura;
  @include fs-responsive((g:12px, gtw:14px));

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

}
