/*
  PAGE
  Overall page structure (header, main, footer, etc.)
*/

.page {
  max-width: 100%;
}

.page-header {

}

.page-main {
  // flex: 1;
}

.page-footer {
}


//styles for iframe temporary fix

.dyson-kr.cms-get-the-most-out-of-your-machine,
.dyson-nz.categorypath-support-get-the-most-out-of-your-machine-support {

  @include respond-min($bp-mobile-min) {
    main#maincontent {
      width: 1000px;
    }
  }

  @include respond-min($bp-tablet-min) {
    main#maincontent {
      width: 1500px;
    }
  }

  @include respond-min($bp-tablet-wide-min) {
    main#maincontent {
      width: auto;
    }
  }

}