.contact {
  background-color: $clr-dy-grey-9;
  color: $clr-white;
}

.contact__title {
  @include fs-responsive((g:28px, gt:36px, gtw:40px));
}

.contact__methods {
  padding-top: $v-minuscule;

  @include respond-min($bp-tablet-min) {
    padding-top: $v-minute;
      .dynamic__footer__contact & {
        padding-top: 0;
      }
  }
}

.contact__methods__type {
  padding-top: $v-minute;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
    .dynamic__footer__contact & {
        height: 100%;
      }
  @include respond-min($bp-tablet-min) {
    padding-top: 0;
    margin-bottom: 1.5em;
  }
}

.contact__methods__type--boldchat {
  max-width: 200px;

  /*
    IE10 and IE11 hack,
    this CSS should only apply to them.
  */
  @media all and (-ms-high-contrast:none) {
    .bcStatic {
      width: 200px;
    }
  }

  a {
    display: block;
  }

  @include respond-min($bp-tablet-wide-min) {
    img {
      max-width: 100%;
      margin-top: 0.25em;
    }
  }
}

.contact__methods__link {
  text-decoration: none;
  width: 40px;
  height: 40px;

  @include respond-min($bp-tablet-min) {
    min-width: 52px;
    min-height: 52px;
  }

  &:active, &:active, &:focus{
    text-decoration: none;
  }

  .icon {
    fill: $clr-white;
    width: 40px;
    height: 40px;

    @include respond-min($bp-tablet-min) {
      min-width: 52px;
      min-height: 52px;
    }
  }

  .icon--black {
    fill: $clr-black;
  }

  .icon--phone {
    @include respond-min($bp-desktop-min) {
      position: relative;
      top: -3px;
    }
  }
}

.contact__methods__text {
  margin-left: 1.625rem;
  @include fs-responsive((g:$fs-body, gtw:$fs-body-larger));
}

.contact__methods__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.talk-to-dyson__icon {
  fill: #fff;
  width: 3.375rem;
  height: 3.375rem;
}

.talk-to-dyson__chat_icon {
  height: 3rem;
}

.talk-to-dyson__text {
  margin-left: 1.625rem;
}
