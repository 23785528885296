.product-nav {

}

.product-nav__content {
  @extend .vspace-small-bottom;
}

.product-nav__title {

}

.product-nav__copy {

}

.product-nav__slider {
  overflow-x: hidden;
}

.product-nav__slider__reviews {
  overflow-x: hidden;
}
