.rating-set {
}

.rating-set__title {
  // @include fs-responsive((g:28,gtw:32));
  @include fs-responsive((g:36,gtw:40));
}

.rating-set__content {
  @include vspace($v-small,$dir:top,$negative:true);
}

.rating-set__item {
  @include vspace($v-small,$dir:top);
}

.rating-set__item--rating {

}

.rating-set__item--quote {
  padding-top: 0;

  @include respond-min($bp-phablet-wide-min) {
    @include vspace($v-small,$dir:top);
  }
}

.rating-set__action {
  @include vspace($v-tiny,$dir:top);
}
