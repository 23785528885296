.contents {

  .contents__body {
    @include vspace($v-tiny,$v-tiny,$v-small,$dir:top,$negative:true);
  }

  .contents__main {
    min-height: 20%;
    display: flex;
    align-items: flex-end;
  }

  .contents__list {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .contents__item {
    border-bottom: 1px solid $clr-dy-grey-2;
    @include vspace($v-tiny,$v-tiny,$v-small,$dir:top);
    @include vspace($v-minuscule,$v-minuscule,$v-tiny,$dir:bottom);
    @include fs-responsive((g:$fs-small, gtw:14px));
    width: 100%;
    height: auto;
    // min-height: 254px;

    @include respond-min($bp-tablet-min) {
      width: 50%;
    }

    @include respond-min($bp-desktop-min) {
      width: 100%;
    }

    .contents__main & {
      padding-bottom: $v-tiny;
    }
  }

  .contents__item__url, .contents__item__text {
    @include link-reverse;
    display: block;
    font-family: $f-fallback;
    color: $clr-dy-grey-7;
  }

  .contents__list .contents__item__text {
    width: 100px;
  }

  img {
    position: relative;
    vertical-align: middle;
    text-align: center;
    min-height: 120px; // bigger images will break
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 108px;

    .contents__main & {
      min-height: 0;
      display: block;
    }
  }

  strong {
    display: block;
    padding-top: $v-minuscule;

    .contents__main & {
      padding-top: $v-tiny;
    }

    // safe for 3 lines text max
    // min-height: 70px;
    // @include respond-min($bp-tablet-min) { min-height: 80px; }

    //  &:before {
    //    content: "[o]";
    //    margin-right: 5px;
    //    display: inline-block;
    //  }
  }
}

.contents--product {
  .contents__title {
    @include fs-responsive((g:28,gtw:32px));
  }
}
