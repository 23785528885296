/*
  SUB NAV
*/

.sub-nav {
  @include z-index(modal-mid);
  background-color: $clr-black;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  @include respond-min($bp-tablet-wide-min) {
    position: absolute;
    right: 0;
    left: 0;
    top: 64px;
    background-color: $clr-dy-grey-9;
  }

  &.open {
    max-height: 100vh;
    overflow-y: auto;
  }
}

.sub-nav__inner {
  > .g-wrap {
    width: 100%;
    margin-left: 0;

    @include respond-min($bp-desktop-min) {
      margin-left: -25px;
    }
  }

  @include respond-min($bp-tablet-wide-min) {
    padding: 2em 0;
  }
}

.sub-nav__item {
  padding-left: 0;

  @include respond-min($bp-tablet-min) {
    &:nth-child(odd) {
      padding-right: 10px;
    }
  }

  @include respond-min($bp-tablet-wide-min) {
    padding-bottom: 1em;
    padding-left: 30px;
    padding-right: 0;

    &:nth-child(odd) {
      padding-right: 0;
    }
  }

  .image {
    display: none;

    @include respond-min($bp-tablet-wide-min) {
      display: inherit;
    }
  }
}

.sub-nav__link {
  @include link-reset;
  color: $clr-white;
  font-family: $f-futura;
  font-weight: 300;
  display: inline-block;
  width: 100%;
  @include fs-static(16);

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }


  &:hover, &:focus, &:active {
    color: $clr-white;
  }
}

// specificity!!
a.sub-nav__link {
  color: $clr-white;
  &:hover, &:focus, &:active {
    color: $clr-white;
  }
}


.sub-nav__link--category {
  padding: 1.5rem 0;
  border-bottom: 1px solid $clr-dy-grey-675;

  @include respond-min($bp-tablet-wide-min) {
    padding: 1rem 0;
    @include fs-static(16);
  }
}

.sub-nav__link--subcategory {
  padding-top: 1rem;
}

.sub-nav__link--content-only {
  @include respond-min($bp-tablet-wide-min) {
    @include fs-static(14px);
  }
}

.sub-nav__sub-links {
  @include list-reset;
  max-height: 0;
  display: none;

  @include respond-min($bp-tablet-wide-min) {
    padding-bottom: 1.5rem;
    max-height: inherit;
    display: inherit;
  }

  li {
    @include list-item-reset;
  }
}

/*
  MOBILE: If the second level category is not a link then show it's children links instead
*/
.sub-nav__item-inactive-category {
  .sub-nav__link--category {
    display: none;

    @include respond-min($bp-tablet-wide-min) {
      display: block;
    }
  }

  .sub-nav__sub-links {
    max-height: inherit;
    display: inherit;
  }
}
