/*
  ICONS
*/

// FLAG ICONS

.flag-icon {
  background-image: url('../images/icons/sprite-flags.png');
  background-repeat: no-repeat;
  width: 30px; height: 20px;

  &--ae {
    background-position: center -20px;
  }

  &--il {
    background-position: center -40px;
  }

  &--gb {
    background-position: center -60px;
  }

  &--kr {
    background-position: center -80px;
  }

  &--in {
    background-position: center -100px;
  }

  &--mx {
    background-position: center -120px;
  }

  &--nz {
    background-position: center -140px;
  }

  &--gv {
    background-position: center -160px;
  }

  &--tr {
    background-position: center -180px;
  }

  &--pl {
    background-position: center -200px;
  }

  &--sa {
    background-position: center -225px;
  }

  &--sg {
    background-position: center -245px;
  }

  &--my {
    background-position: center -285px;
  }

  &--th {
    background-position: center -305px;
  }

  &--hu {
    background-position: center -345px;
  }

  &--cz {
    background-position: center -369px;
  }
  &--ro {
    background-position: center -389px;
  }
  &--vn {
    background-position: center -409px;
  }
    &--id {
        background-image: url('../images/icons/flag-id.jpg');
        background-position: center;
    }
}
