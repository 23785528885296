body.rtl {
  .notify-me__container__close {
    float: left;
    text-align: left;
  }

  .notify-me__container__headerSub__content {
    input {
      @include respond-min($bp-desktop-min) {
        margin-left: inherit;
        margin-right: 58px;
      }
    }
  }

  .notify-me__container__headerSub__content {
    .button__headerSub--notify-me {
      @include respond-min($bp-desktop-min) {
        margin-left: inherit;
        margin-right: 10px;
      }
    }

    div.mage-error,
    div.field-error {
      left: inherit;
      right: -1px;

      @include respond-min($bp-desktop-min) {
        left: inherit;
        right: 40%;
      }
    }
  }

  &.catalog-product-view,
  &.catalog-category-view {
    [data-ui-id="message-success"] {
      > div {
        padding-left: 0;
        padding-right: 70px;

        @include respond-min($bp-tablet-min) {
          padding-left: 0;
          padding-right: 100px;
        }
      }
    }
  }

  &.catalog-product-view [data-ui-id="message-success"]:before,
  &.catalog-category-view [data-ui-id="message-success"]:before {
    left: 0;
    right: 36px;

    @include respond-min($bp-tablet-min){
      left: 0;
      right: 66px;
    }
  }

  &.catalog-product-view [data-ui-id="message-success"]:after,
  &.catalog-category-view [data-ui-id="message-success"]:after {
    left: 0;
    right: 52px;

    @include respond-min($bp-tablet-min) {
      left: 0;
      right: 82px;
    }
  }
  .notify-me__form__error{
   border-color: #ed8380;
  }
}
