.spec-set {

}

.spec-set__title {
  @include fs-responsive((g:28,gtw:32px));
}

.spec-set__list {
  @include vspace($v-minute,$v-minuscule,$v-tiny,$dir:top,$negative:true);
}

.spec-set__image-set {
  @include vspace($v-tiny,$v-small,$v-medium,$dir:top);
}

.spec-set__image-set__image {
  @include vspace($v-minute,0,top);
  @include vspace($v-small,$v-medium,$v-medium,$dir:bottom);
}

.spec-set__image-set__list {
  @include vspace($v-minute,$v-minuscule,$v-tiny,$dir:top,$negative:true);
}

.spec-set__item {
  @include vspace($v-minute,$v-minuscule,$v-tiny,$dir:top);
  border-bottom: 1px solid $clr-dy-grey-3;
  padding-bottom: $v-minute;
  min-height: 100%;
}
