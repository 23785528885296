// ------- AMASTY SPC: DYSON-RO SPECIFIC -------

.dyson-ro {
    // Start of checkout shipping address fields sizing changes
    .am-checkout .fieldset .field {
        @include respond-min($bp-tablet-wide-min) {
            width: 48%;
        }

        &[name="shippingAddress.street.0"] {
            @include respond-min($bp-tablet-wide-min) {
                width: 100%;

                .control {
                    width: 100%;
                }
            }
        }

        &[name="shippingAddress.telephone"] {
            @include respond-min($bp-tablet-wide-min) {
                margin-right: 4%;

                .control {
                    width: 100%;
                }
            }
        }

        &[name="shippingAddress.region_id"] {
            @include respond-min($bp-tablet-wide-min) {
                width: 48%!important;
                //margin-right: 25%;

                & input {
                    width: 100%;
                    max-width: 1440px;
                }
            }
        }

        &[name="shippingAddress.country_id"],
        &[name="shippingAddress.city"] {
            @include respond-min($bp-tablet-wide-min) {
                margin-right: 4%;
            }
        }

        &[name="shippingAddress.city"],
        &[name="shippingAddress.postcode"],
        &[name="shippingAddress.country_id"] {
            @include respond-min($bp-tablet-wide-min) {
                width: 48%!important;

                & input {
                    width: 100%;
                    max-width: 1440px;
                }
            }
        }

        &[name="shippingAddress.custom_attributes.marketing_opt_in"] {
            margin-top: 12%;
            width: 100%;

            @include respond-min($bp-tablet-min) {
                margin-top: 0;
            }
        }
    }
    // End of checkout shipping address fields sizing changes


    // Start of checkout billing address fields sizing changes
    .am-checkout .checkout-billing-address {
        .fieldset.address {
            field[name="billingAddressadyen_cc.street.0"] {
                @include respond-min($bp-tablet-wide-min) {
                    width: 100%;

                    .control {
                        width: 100%;
                    }
                }
            }

            .field[name="billingAddressadyen_cc.firstname"],
            .field[name="billingAddressadyen_cc.telephone"],
            .field[name="billingAddressadyen_cc.postcode"] {
                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                    margin-right: 4%;

                    & input {
                        width: 100%;
                        max-width: 600px;
                    }
                }
            }

            .field[name="billingAddressadyen_cc.city"] {
                @include respond-min($bp-tablet-wide-min) {
                    margin-right: 4%;
                    width: 48%;

                    & input {
                        width: 100%;
                        max-width: 600px;
                    }
                }
            }

            .field[name="billingAddressadyen_cc.country_id"] {
                @include respond-min($bp-tablet-wide-min) {
                    margin-right: 4%;
                    width: 48%;

                    & select {
                        width: 100%;
                        max-width: 1440px!important;
                    }
                }
            }

            .field[name="billingAddressadyen_cc.lastname"],
            .field[name="billingAddressadyen_cc.postcode"] {
                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                    margin-right: 0;

                    & input {
                        width: 100%;
                        max-width: 600px;
                    }
                }
            }

            .field[name="billingAddressadyen_cc.region_id"] {
                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;

                    & select {
                        width: 100%;
                        max-width: 1440px;
                    }
                }
            }

            .field[name="billingAddressadyen_cc.street.0"] {
                @include respond-min($bp-tablet-wide-min) {
                    width: 100%;

                    & input {
                        width: 100%;
                        max-width: 1440px;
                    }
                }
            }
        }
    }
    // End of checkout billing address fields sizing changes


    // Stop T&Cs being shoved off-screen by RO's custom company details fields
    .checkout-agreements {
        display: inline-block;
        margin-top: 6%;

        // Remove extra asterisk in T&Cs checkbox (NB content: none removes the arrow and makes the checkbox unclickable)
        .field.required .label:after,
        .field._required .label:after {
            content: '' !important;
        }
    }

    // Styling for T&Cs (set in admin > stores > settings > T&Cs
    // needed as RO disables the T&Cs modal in app/design and uses two links (GDPR and privacy policy)
    .am-checkout .payment-method .checkout-agreements-block .action-show {
        text-decoration: none !important;
    }

    .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .checkout-agreement .action-show {
        text-decoration: none !important;
    }

    .terms-link {
        text-decoration: underline;
        cursor: pointer;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    // Styling for company fields
    .checkout-agreements-block .admin__field-label {
        color: $clr-dy-grey-7;
    }

    // Sort out sizing of the custom company details fields
    .payment-method-content .field input[name="company"],
    .payment-method-content .field input[name="trade_register_no"],
    .payment-method-content .field input[name="cui_tax_id"] {
        max-width: 1000px;
    }

    div[name="before-place-order.company"] {
        margin-right: 4%;
    }

    // Align company details radio buttons horizontally
    .admin__field-ro-company-details {
        display: flex;
        margin-bottom: 5%;
        margin-top: 5%;

        @include respond-min($bp-tablet-wide-min) {
            margin-bottom: 0;
            margin-top: 2%;
        }
    }

    .admin__field-ro-company-details .admin__field.admin__field-option {
        margin-right: 4%;
    }
}
