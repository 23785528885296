/*
  BUTTONS
*/
.button {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.15);
        width: 10%;
        height: 25%;
        border-radius: 50%;
        z-index: 1;
        top: 35%;
        left: 45%;
        transform: scale(0, 0);
    }

    &:hover::after {
        // content: attr(data-content);
        content: '';
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover::after span {
        display: none;
    }

    &:hover::before {
        transform: scale(12, 12);
        transition: transform 0.5s, 0.5s border-radius 0.5s;
    }

    &:hover {
        transition: transform 0, 0 border-radius 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
    }
}

button {
    // max-width: 400px;
}


.button--small {
    width: 50%;
    display: block !important;
    margin: auto;
}


@mixin button {
    @include link-reset;
    @extend .button--primary;
    @include fs-responsive((g:16px, gtw:18px));
    -webkit-appearance: none;
    border: 0;
    padding: 16px 12px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    max-width: 100%;
    text-align: center;
    border-radius: 0;

    &:hover, &:focus {
        background: $clr-dy-grey-7-hover;
        border: 0;
    }

    &.active {
        animation: clicked .25s ease-in;
    }

    @include respond-min($bp-tablet-min) {
        padding: 20px 23px;

        .dyson-pl & {
            padding: 20px 16px;
        }

        .dyson-cz & {
            //padding: 10% 10%;
        }
    }

    @include respond-min($bp-tablet-wide-min) {
        .dyson-sa & {
            padding: 20px 16px;
        }
    }

    &--primary {
        background: $clr-dy-grey-7;
        color: $clr-white;

        &:hover, &:focus, &:active, &:visited {
            background: $clr-dy-grey-7-hover;
        }

    }

    &--tertiary:hover,
    &--tertiary:focus,
    &--tertiary:active,
    &--tertiary:visited {
        background: $clr-dy-green-0-hover;
    }

    &--tertiary:hover,
    &--tertiary:focus,
    &--tertiary:active,
    &--tertiary:visited {
        background: $clr-dy-green-0-hover;
    }

    &--secondary:hover,
    &--secondary:focus,
    &--secondary:active,
    &--secondary:visited {
        background: $clr-dy-grey-2-hover;
    }


    &--light {
        background: $clr-white;
        color: $clr-dy-grey-7;

        span {
            color: $clr-dy-grey-7;
        }

        &:hover, &:focus, &:active {
            background: $clr-dy-grey-2-hover;
            color: $clr-dy-grey-7;
        }

        &:visited {
            background: $clr-white;
        }
    }

    &--disabled {
        opacity: 0.75;
        cursor: default;
        pointer-events: none;

        &:hover, &:focus, &:active {
            background: inherit;
        }
    }
}

@mixin button--wider {
    padding: 16px 30px;
    @include respond-min($bp-tablet-min) {
        padding: 20px 60px;
    }
}

@mixin button--large {
    @include fs-responsive((g:16px, gt:18px, gtw:21px));
}

@mixin button--fluid {
    width: 100%;
    max-width: none;
}

@mixin button--tertiary {
    background: $clr-dy-green-0;
    color: $clr-black;
    // background-image: url(../images/ppp-icon-basket-2x.png);

    &:hover, &:focus, &:active {
        background: $clr-dy-green-0-hover;
        color: $clr-black;
    }
}

@mixin button--secondary {
    background: $clr-dy-grey-2;
    color: $clr-dy-grey-7;

    &:hover, &:focus, &:active {
        background: $clr-dy-grey-2-hover;
        color: $clr-black;
    }
}

@mixin button--text {
    background: $clr-white;
    color: $clr-dy-grey-7;

    &:hover, &:focus, &:active {
        background: $clr-white;
        color: $clr-dy-grey-7;
    }
}

@mixin button-video {
    position: relative;
    background: none;
    border: 0;
    padding: 0;
}

a.button,
.button {
    @include button;

    &---large {
        @include button--large;
    }

    &--fluid {
        @include button--fluid;
    }

    &--wider {
        @include button--wider;
    }

    &--secondary {
        @include button--secondary;
        // fix for sandbox soft bundling
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--tertiary {
        @include button--tertiary;
        // fix for sandbox soft bundling
        .dyson-gv &,
        .dyson-decgv & {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }
}

// has animation
.button--cart {

}

.button--cart__basket-icon {
    width: 24px;
    position: relative;
    left: -4px;
    top: 4px;

    .dyson-pl & {
        left: 0px;
        top: 3px;
    }

    .dyson-nz & {
        width: 22px;
    }

    .dyson-mx & {
        top: 14px;
    }
}

.button--cart__basket-icon--smaller {
    width: 18px;
    position: relative;
    top: -2px;
    left: -4px;

    @include respond-min($bp-tablet-min) {
        width: 20px;
    }

    @include respond-min($bp-desktop-min) {
        width: 24px;
    }

    .dyson-mx & {
        margin-bottom: 0px;
    }
}

.button--cart__basket-icon--card,
.button--cart__basket-icon--smaller {

    .slider__item & {
        display: none;

        @include respond-min($bp-tablet-wide-min) {
            display: inline-block;
            position: relative;
            width: 20px;
            top: 3px;
            left: -2px;

            .dyson-mx & {
                width: 28px;
                left: 16px;
                top: 0px;
            }

            .dyson-pl & {
                top: -3px;
                left: 0px;
            }

            .dyson-nz & {
                top: -2px;
                left: 2px;
            }
        }
    }
}

.button--learn-more--larger {
    .dyson-mx & {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 72px;

        @include respond-min($bp-tablet-wide-min) {
            padding: 10px 18px;
        }
    }
}

.button--pre-order--larger {
    .dyson-mx & {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 72px;

        @include respond-min($bp-tablet-wide-min) {
            padding: 10px 18px !important;
        }
    }
}

.dyson-tr .button--add-to-cart--preorder {
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.button--pre-order--larger {
    .dyson-tr & {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 72px;

        @include respond-min($bp-tablet-wide-min) {
            padding: 20px 18px;
        }
    }
}

.dyson-mx .button--add-to-cart--two-line {
    line-height: 1.2;
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.product__variant__add-to-cart--animated {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.15);
        width: 10%;
        height: 45%;
        border-radius: 50%;
        z-index: 1;
        top: 35%;
        left: 45%;
        transform: scale(0, 0);
    }

    &:hover::after {
        // content: attr(data-content);
        content: '';
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover::after span {
        display: none;
    }

    &:hover::before {
        transform: scale(12, 12);
        transition: transform 0.5s, 0.5s border-radius 0.5s;
    }

    &:hover {
        transition: transform 0, 0 border-radius 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
    }
}

.product__variant__add-to-cart--animated-smaller {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.15);
        width: 10%;
        height: 25%;
        border-radius: 50%;
        z-index: 1;
        top: 35%;
        left: 45%;
        transform: scale(0, 0);
    }

    &:hover::after {
        // content: attr(data-content);
        content: "";
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover::after span {
        display: none;
    }

    &:hover::before {
        transform: scale(12, 12);
        transition: transform 0.5s, 0.5s border-radius 0.5s;
    }

    &:hover {
        transition: transform 0, 0 border-radius 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
    }
}

.dyson-tr .button--add-to-cart--two-line {
    padding: 11px 18px;
    line-height: 0.8;
}




