.soft-options-container {
  padding: 30px 0;

  .fieldset {
    outline: none;
  }

  .soft-option__item__card {
    margin-bottom: 40px;
  }
}

.soft-bundling__title {
  margin-top: 0;
}

.soft-option__content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond-min($bp-tablet-min) {
    padding: 15px;
    flex-grow: 1;
  }

  @include respond-min($bp-desktop-min) {
    padding: 20px;
  }

  span.price {
    color: $clr-dy-blue-1;
    font-weight: 500;
    font-size: 20px;
  }
}


.soft-option__content__description {
  .firefox &,
  .ie & {
    margin-left: 15px;
  }
}

.soft-options-container .card__action__item {
  width: 100%;
}

.soft-option__item__card.soft-option__item__card--active .card__action__item .field.choice .label .button.button--fluid  {

  &:after {
    content: "";
    display: block;
    width: 7px;
    height: 16px;
    border: solid #79b928;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 45%;
    left: 24%;
    -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
    transform: translateY(-50%) rotate(45deg) scale(1);

    @include respond-min($bp-desktop-min) {
      width: 8px;
      height: 18px;
      left: 27%;
      .dyson-hu & {
        @include respond-min($bp-desktop-wide-min) {
          left: 29%;
        }
      }

      .dyson-cz & {
        @include respond-min($bp-desktop-min) {
          left: 29%;
        }
      }
    }

    // Translation for KR spans wider so tick needs moving to accommodate
    .dyson-kr & {
      left: calc(50% - 7.5rem);
      @include respond-min($bp-tablet-wide-min) {
        left: calc(50% - 8.3rem);
      }
    }

    // Translation for HU spans wider so tick needs moving to accommodate
    .dyson-hu & {
      @include respond-min($bp-desktop-min) {
        left: 29%;
      }
    }
  }
}

.soft-option__item__card--disabled {
  pointer-events: none;
}

.soft-option__item__card__inner {
  border: 2px solid $clr-dy-grey-2;
  display: flex;
  flex-direction: column;
  position: relative;

  .soft-option__item__card--active & {
    border-color: $clr-dy-green-2;
  }

  > span {
    color: #fff;
    background-color: #000;
    padding: 6px;
    width: auto;
    position: absolute;
    top: -12px;
    @include z-index(content-mid);
    left: 20px;
    font-weight: 600;
    text-align: center;
  }

  .card__action .card__action__item .field.choice label.label span.button.button--fluid {

    &:after {
      transition: none;
    }

    &:hover {
      transition: none;
    }
  }
}

.soft-bundling__selections {
  border: 1px solid $clr-dy-grey-2;
  border-bottom: 0;
  background: $clr-dy-grey-05;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .soft-bundling__title {
    margin-bottom: 8px;
  }

  .soft-bundling__guarantee {
    margin-top: 0;
    font-size: 14px;
  }

  .soft-bundling__selected {
    border-top: 1px solid $clr-dy-grey-2;
    border-bottom: 1px solid $clr-dy-grey-2;
    padding: 10px 15px;
    font-weight: 400;
    color: $clr-dy-blue-1;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: none;
      border: none;
      padding: 0;
    }
  }

  .soft-bundling__price {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $clr-dy-grey-2;
    padding-top: 10px;
    flex-wrap: wrap;

    span {
      float: right;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.soft-bundling__selected__button {
  svg.icon.icon--chevron {
    fill: $clr-dy-blue-1;
    width: 15px;
    height: 15px;
  }
}


span[aria-expanded="true"] svg.icon.icon--chevron {
  transform: rotateX(180deg);
}


.soft-bundling__selection--top {
  display: flex;
  padding: 15px;

  img {
    width: 105px !important;
    height: 100% !important;
  }

  .product-image-container {
    width: auto !important;
  }

  .product-image-container .product-image-wrapper {
    img.product-image-photo {
      max-width: 5em;
    }
  }
}

.soft-bundling__selection--top__text {
  margin-left: 5px;
  max-width: 100%;
  width: 100%;
}

.soft-bundling__selected__product-summary {
  background: #f8f8f8;
}

.soft-bundling__selected__product-summary ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.soft-bundling__selected__product-summary__item {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $clr-dy-grey-2;
  padding-left: 10px;
}

.soft-bundling__selected__product-summary--options {
}

.soft-bundling__selected__product-summary--image {

   img {
      display: block;
      max-width: 7em;
      max-height: 5em;
      width: auto;
      height: auto;

     @include respond-min ($bp-tablet-wide-min) {
       max-width: 4em;
     }
    }
}

.soft-bundling__selected__product-summary--copy {
  //margin-left: auto;
  padding: 15px;
  flex-grow: 1;
  width: 50%;

  .title, .remove, .price {
    font-size: 12px;
  }

  .title {
    margin-bottom: 6px;
  }

  .remove {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .price {
    margin-top: 0;
    font-weight: 500;
    color: $clr-dy-blue-1;
  }

  .remove-and-price {
    display: flex;
    justify-content: space-between;
  }

}

.soft-bundling__total-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
}

.soft-bundling__total-wrapper__text {
  font-weight: 600;

  + div {
    font-weight: 600;
    color: $clr-dy-blue-1;
    font-size: 20px;
    text-align: right;
  }
}


.soft-bundling__warning {
  position: fixed;
  width: 100%;
  background-color: $clr-dy-grey-675;
  padding: 30px;
  bottom: 0;
  @include z-index(heaven);
  left: 0;
  color: $clr-white;
  font-size: 20px;

  .layout {
    display: flex;
    justify-content: space-between;
  }
}

.soft-bundling__warning__action-close {
  background: none;
  box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  padding: 0;
  text-shadow: none;
  font-weight: 400;
  font-size: 0;
  display: inline-block;
  text-decoration: none;
  //position: absolute;
  //right: 0;
  //top: 0;
  @include z-index(modal-mid);

  &:before {
    -webkit-font-smoothing: antialiased;
    content: "";
    background-image: url(../images/icons/modal-close-wht.svg);
    /* background-size: 18px auto; */
    width: 20px;
    height: 20px;
    background-size: contain;
    display: block;
  }

  &:active, &:hover, &:focus {
    background: none;
    border: none;
    color: #555;
  }

}

.modal-popup.soft-bundling__optional-warning {

  button.soft-bundling__optional-warning--button {
    background: none;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    margin-right: 29px;

    &:hover {
      text-decoration: none;
    }
  }

  .modal-content {
    padding: 0;
  }

  .modal-inner-wrap {
    width: 100%;

    @include respond-min($bp-tablet-min) {
      width: 50%;
    }

    @include respond-min($bp-desktop-min) {
      width: 27%;
    }
  }

}

.soft-bundling__optional-warning--text {
  padding: 20px;
}

.soft-bundling__optional-warning--buttons {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid $clr-dy-grey-3;

  .continue-to-basket {
    background-color: $clr-black;
    color: $clr-white;
    padding: 10px 20px;
    cursor: pointer;

    @include respond-min($bp-tablet-min) {
      padding: 10px 30px;
    }
  }
}

//Checkout for bundles

.soft-bundling__cart-item {
  margin-bottom: 20px;
  padding: 20px;

  .leap-cart__img {
    border-right: none;

    @include respond-min($bp-tablet-wide-min) {
      border-right: 1px solid #ebebeb;
    }
  }

}

.soft-bundling__checkout-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid $clr-dy-grey-2;
}

.soft-bundling__checkout--list {
  border-bottom: 1px solid $clr-dy-grey-2;
  display: block;
  padding: 5px 0;

  @include respond-min($bp-tablet-min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 0 0;
    border-bottom: 1px solid $clr-dy-grey-2;

    img {
      width: 10%;
      display: inline-block;
    }

    span.price {
      margin-top: 0;
    }
  }
}

.soft-bundling__checkout--list__title {
  display: flex;
  align-items: center;

  span {
    color: $clr-dy-grey-6;

    &.dyson-product-debossing-label {
      font-weight: $font-weight__semibold;
    }
  }

  img {
    display: inline-block;
    max-width: 5em;
    width: auto;
    height: auto;
  }
}

.soft-bundling__checkout--list__price {
  text-align: right;
}

.soft-bundling__checkout--list__price__is {
  font-weight: 600;
  color: $clr-dy-blue-1;

  span.price {
    color: $clr-dy-blue-1;
  }
}


.soft-bundling__checkout--list__price__was {
  text-decoration: line-through;
  color: $clr-dy-grey-5;
}


.soft-bundling__qty-input {
  padding: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0px;
  @include respond-max($bp-phablet-wide-min) {
    flex-wrap: wrap;
  }
  .ie & {
    width: 100%;
  }
  .update-cart__buttons {
    @include respond-max($bp-phablet-wide-min) {
      width: auto;
    }
  }
  > div.mage-error {
    display: block;
    position: absolute;
    top: 17%;
    width: 50%;
    left: -4px;
    right: auto;
    bottom: auto;

      @include respond-min($bp-tablet-min) {
        top: 30%;
        width: 55%;
        left: 15%;
      }
      @include respond-max($bp-phablet-wide-min) {
          order: 5;
          width: 100%;
          position: relative;
      }

      @include respond-min($bp-tablet-wide-min) {
        top: auto;
        width: auto;
        right: 25%;
        left: auto;
        bottom: 44%;
      }
    }

  input {
    padding: 5px;
    height: 40px;
    width: 40px;
    text-align: center;

    .firefox & {
      padding: 5px;
      height: 50px;
      width: 60px;
    }
  }

  p {
    margin-right: 5px;
    color: $clr-dy-grey-7;
  }
}

.soft-bundling__qty-input .basket__items__below__btn.action.update {
  margin-left: 5px;
  font-family: $f-futura;
  font-weight: normal;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

}


.soft-bundling__checkout-name {
  padding-bottom: 30px;
}

.soft-bundling__checkout-name,
.soft-bundling__checkout-included,
.soft-bundling__checkout--items-selected {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid $clr-dy-grey-2;
}

.soft-bundling__checkout--items-selected {
  color: $clr-dy-blue-1;
  font-weight: 500;
  display:flex;
  justify-content: space-between;
  flex-direction: row;

  p {
    margin-right: 20px;
  }

  button {
    background: none;
    border: 0;
  }
}

.soft-bundling__selected {
  button {
    display: inline;
  }
}


.soft-bundling__checkout--items-selected__button {
  .icon.icon--chevron {
    width: 20px;
    height: 20px;
    fill: $clr-dy-blue-1;
    transform: rotateX(180deg);
  }
}

//need to change this class...
.faqs-collapsible {
  .icon.icon--chevron {
    width: 20px;
    height: 20px;
    fill: $clr-dy-blue-1;
  }
}

.faqs-collapsible [aria-expanded=true]:after {
  transform: rotate(180deg) !important;
}

.faqs-collapsible [data-role="title"]:after {
  //content: '';
  //background-image: url(../images/icons/chevron.svg);
  //background-position: 0px -3967px;
  //display: block;
  //float: right;
  //width: 24px;
  //height: 29px;

  display: none;
}


.soft-bundling__checkout--items-selected__text {
  display: flex;

}

.soft-bundling__checkout-included {
  font-weight: 500;
}

.soft-bundling__checkout--sub-total {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 20px;
  position: relative;
}

.soft-bundling__checkout--sub-total--price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  span.price {
    color: #0a6acb;
    font-weight: 500;
    font-size: 20px;

    @include respond-min($bp-tablet-min){
      font-size: 25px;
    }
  }
}

.soft-bundling__checkout-remove {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.soft-bundling__checkout--sub-total__inner {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .cart.item.message.error {
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
}

.soft-bundling__checkout--sub-total__subtotal {
  color: $clr-dy-blue-1;
  font-weight: 500;
  font-size: 20px;
  margin-right: 10%;

  @include respond-min($bp-tablet-min){
    font-size: 25px;
    margin-right: 20%;
  }
}

.soft-option__card__pricing {

  .card__pricing__price .price.price--sale,
  .card__pricing__price .price.price--sale .price-container.tax.weee .price-wrapper .price {
    color: $clr-dy-grey-5;
    text-decoration: line-through;
  }

  .card__pricing__price .price.price--sale .price-container.tax.weee .price-wrapper .price {
    font-size: 15px;
    font-weight: 400;
  }

  .card__pricing__sold-out {
    position: relative;
    top: 68%;
    font-weight: 600;
  }

}

.soft-bundling__checkout__edit-selections-wrapper {
  //margin-top: 10px;
}

.soft-bundling__checkout__edit-selections-wrapper__text {
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.soft-bundling-collapsible {
  padding-left: 0;

  .icon.icon--chevron {
    width: 20px;
    height: 20px;
    fill: $clr-dy-blue-1;
  }

}

.price.price--sale ~ div {
  color: $clr-dy-blue-1;
  font-weight: 500;
  font-size: 25px;
}

//Debossing modal

.modal-popup.modal-slide.dyson-product-debossing-modal .modal-inner-wrap {
  background-color: #fff;
  max-width: 90%;
  margin: 80px auto;
  height: auto;
}

.modal-popup.dyson-product-debossing-modal .modal-header {
  padding: 0;
}

.modal-popup.dyson-product-debossing-modal .modal-header .action-close{
  padding: 16px;

  @include respond-min($bp-tablet-wide-min) {
    padding: 8px;
  }

  &:before {
    font-size: 50px;
    line-height: 50px;
    color: $clr-black;
  }

  &:hover {
    &:before {
      color: $clr-black-75;
    }
  }


}

.modal-popup.dyson-product-debossing-modal .modal-content {
  padding: 0;
  //
  //@include respond-min($bp-tablet-min) {
  //  padding: 0 0 64px 0;
  //}
}

.modal-popup.dyson-product-debossing-modal .debossing-error {
  height: 1rem;
  margin: 8px 0;
  color: $clr-dy-red-1;
}

.modal-popup.dyson-product-debossing-modal .modal-content .soft-bundling__debossing--text__title {
  margin: 16px 20px 8px 20px;
}

.modal-popup.dyson-product-debossing-modal .modal-content .soft-bundling__debossing--text__subtitle {
  margin: 0 20px 8px 20px;
}

.modal-popup.dyson-product-debossing-modal .modal-content button {
  width: 100%;

  @include respond-min($bp-tablet-wide-min) {
    width: 80%;
  }
}


.modal-popup.dyson-product-debossing-modal .modal-inner-wrap {
  text-align: center;
  width: 100%;

  @include respond-min($bp-tablet-min) {
    width: 75%;
  }

  @include respond-min($bp-tablet-min) {
    width: 40%;
  }
}


.dyson-product-debossing-modal__container {
  height: 100%;
  max-height: 800px;
}

.modal-popup.dyson-product-debossing-modal .modal-footer {
  display:none;
}

.soft-bundling__debossing--input-container {
  text-align: center;
  width: 90%;
  position: relative;
  margin: 0 auto;

  @include respond-min($bp-tablet-min) {
    width: 80%;
  }

  @include respond-min($bp-tablet-wide-min) {
    width: 50%;
  }

  input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

}

.soft-bundling__debossing--text p {
  margin-bottom: 20px;
}

.soft-bundling__debossing--input-placeholder {
  position: absolute;
  top: 28px;
  color: lightgrey;
  z-index: -1;
  width: 100%;

  ::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  ::-moz-selection {
    color: none;
    background: none;
  }

}

.input-text.soft-bundling__debossing--input {
  letter-spacing: 20px;
  font-size: 27px;
  text-transform: uppercase;
  margin-top: 8px;
  text-align: center;
  padding: 20px;
  padding-right: 0;
  font-family: $f-futura;
  height: 60px;
  line-height: 60px;
  background: transparent;

  @include respond-min($bp-tablet-min) {
    font-size: 40px;
  }

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

}

.soft-bundling__debossing__submit {
  margin: 32px 0;

  @include respond-min($bp-tablet-wide-min) {
    margin: 32px 0 64px 0;
  }
}

.soft-bundling__debossing--input div.mage-error {
  //order: 0;
}

.debossing-summary-value {
  font-size: 12px;
}
