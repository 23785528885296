// ------- DYSON-GV SPECIFIC -------

.dyson-decgv,
.dyson-gv {
  .am-checkout {

    .control {
      width: 100% !important;
    }

    .field[name="shippingAddress.street.0"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        display:inline-block;
      }
    }

    .field[name="shippingAddress.street.1"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        display: inline-block;
        margin-left: 4%;
      }
    }

    .field[name="shippingAddress.city"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin-right: 0;
      }
    }

    .field[name="shippingAddress.postcode"] {
      @include respond-min($bp-tablet-min) {
        margin-left: 4%;
        width: 48%;
      }
    }

    .field[name="shippingAddress.telephone"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        clear: both;
      }
    }


  }
}
