body.rtl {
  .icon-info--inline {
    .icon-info__icon {
      margin: 0 0 0 12px;
    }

    .icon-info__body {
      text-align: right;
    }
  }
}
