.catalogsearch-result-index {
  .slider__item {
    padding-left: $grid-gutter;
    padding-bottom: $grid-gutter;
    display: inline-block;

    @include respond-min($bp-tablet-wide-min) {
      padding-left: $grid-gutter-large;
      padding-bottom: $grid-gutter-large;
    }
  }
}