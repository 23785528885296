// ------- DYSON-MY SPECIFIC -------

.dyson-my {
  .am-checkout {

    // Adjusts payment error message so it doesn't block order summary:
    div[data-role="checkout-messages"] .message.message-error.error {
      @include z-index(heaven);
      position: absolute;
      bottom: 90%;
      left: 8%;
      width: 84%;
      padding: 15px;
      margin-top: 0;

      @include respond-min($bp-tablet-min) {
        bottom: 90%;
        width: 82%;
        left: 9%;
      }

      @include respond-min($bp-tablet-wide-min) {
        bottom: 86%;
        width: 65%;
        left: 6.5%;
        padding: 20px;
      }

      @include respond-min($bp-desktop-wide-min) {
        bottom: 86%;
        width: 62%;
        left: 8%;
      }
    }

    .am-opc-wrapper {

      // Shipping address styling:
      .fieldset {
        .field {
          &[name="shippingAddress.region"],
          &[name="shippingAddress.postcode"] {
            width: 100%!important;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%!important;
            }
          }

          &[name="shippingAddress.telephone"] {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;

              .control {
                width: 100%;
              }
            }
          }

          &[name="shippingAddress.street.0"],
          &[name="shippingAddress.city"],
          &[name="shippingAddress.company"] {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
              margin-right: 4%;

              .control {
                width: 100%;
              }
            }
          }

          &[name="shippingAddress.street.1"] {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;

              .control {
                width: 100%;
              }
            }
          }
        }
      }

      // Billing address styling:

      .payment-method.dfe_ipay88 {
        max-width: 100em;
      }

      .payment-method-billing-address {
        div.field {

          &[name="billingAddressdfe_ipay88.firstname"],
          &[name="billingAddressdfe_ipay88.company"],
          &[name="billingAddressdfe_ipay88.street.0"],
          &[name="billingAddressdfe_ipay88.city"],
          &[name="billingAddressdfe_ipay88.postcode"] {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
              margin-right: 4%;
            }
          }

          &[name="billingAddressdfe_ipay88.lastname"],
          &[name="billingAddressdfe_ipay88.street.1"],
          &[name="billingAddressdfe_ipay88.region"],
          &[name="billingAddressdfe_ipay88.telephone"] {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
            }
          }
        }
      }

      // Hides checkboxes on iPay88 payment method images
      .payment-method .df-option label:before,
      .payment-method .df-option label:after {
        display: none;
        height: 0;
      }

      .payment-method-content .primary {
        max-width: 100em!important;
        margin: 0!important;
      }

      .action-update {
        margin: 0 0 4% 0;
        padding: 17px 60px!important;

        @include respond-min($bp-tablet-wide-min) {
          margin: 0 1% 0 0;
        }

        @include respond-min($bp-desktop-wide-min) {
          margin: 0;
        }
      }

      .action-cancel {
        padding: 17px 62px!important;
      }
    }
  }
}

