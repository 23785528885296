body.rtl {
  .button--cart__basket-icon {
    left: 0;
    right: -1%;
  }

  .button--cart__basket-icon--smaller {
    left: 0;
    right: -4%;
  }
}
