body.stuck {
    // padding-top: 64px;
}

:-moz-any(#maincontent) browser{
    margin-right:-14px!important;
    overflow-y:scroll;
    margin-bottom:-14px!important;
    overflow-x:scroll;
}

.product__stickynav {
    background-color: $clr-white;
    height: 60px;
    border-bottom: 2px solid $clr-dy-grey-2;
    z-index: 3;
    position: relative;
    color: $clr-dy-grey-5;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    transition: transform 0.5s cubic-bezier(.68,.03,.26,1.02);

    @include respond-min($bp-tablet-min) {
        overflow-x: visible;
        overflow-y: visible;
        height: 64px;
    }
}

.product__stickynav__title {
    font-weight: 500;
    color: $clr-dy-grey-9;
    // margin-right: 10px;
}

.secondary-nav {
    &.stuck {
        position: fixed;
        width: 100%;
        @include z-index(heaven);
        top: 0;
    }
}


.product__stickynav {
    &.stuck {
        position: fixed;
        width: 100%;
        z-index: 3;
        top: 60px;
    }
}

.header.stuck {
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
}




.scroll {
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

::-webkit-scrollbar, ::-moz-scrollbar {
    height: 0px;
    width: 0px;
    -webkit-appearance: none;
}

.product__stickynav__links {
    color: $clr-dy-grey-5;
    height: auto;
    display: inline-block;
    // text-align: center;
    position: relative;
    z-index: 2;
    margin: 20px 0 0 0;
    padding: 0 0 20px 0;

    a:link {
        padding: 20px 0px;
        margin: 0px 20px;
    }

    &:hover {
        &:after {
            content: '';
            display: block;
            width: 80%;
            height: 2px;
            background: $clr-dy-grey-5;
            position: absolute;
            left: 14px;
            bottom: -2px;
        }
    }

    .active {
        font-weight: bold;

        &:after {
            content: '';
            display: block;
            width: 80%;
            height: 2px;
            background: $clr-dy-grey-5;
            position: absolute;
            left: 14px;
            bottom: -2px;
        }
    }
}


// .product__stickynav__links:hover,
// .product__stickynav__links:visited,
// .product__stickynav__links:active,
// .product__stickynav__links:focus, {
//   &:after {
//     content: '';
//     display: block;
//     width: 80%;
//     height: 1px;
//     background: $clr-dy-grey-5;
//     position: absolute;
//     left: 14px;
//     bottom: -1px;
//   }
// }

.product__stickynav__links--buy {
    color: $clr-dy-green-2;
    background: none;
    border: none;
    margin: 0 20px 5px 20px;

    &:hover, &:active, &:visited, &:focus {
        background: none;
        border: none;
        color: $clr-dy-green-2-hover;
    }
}
