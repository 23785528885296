body.rtl {
  .icon-link {
    .roundal--small {
      @include respond-min($bp-tablet-min) {
        margin-right: 0;
        margin-left: 10px;
      }

      .roundal__icon .icon {
        left: 0;
        right: 6px;

        @include respond-min($bp-tablet-min) {
          left: 0;
          right: 10px;
        }
      }
    }
  }
}
