/*
  VERTICAL SPACING
*/

// SPACING
// If you want to just use px variables directly
$v-massive: 250px;
$v-huge: 130px;
$v-large: 100px;
$v-medium: 70px;
$v-medium-smaller: 50px;
$v-small: 45px;
$v-tiny: 30px;
$v-minuscule: 20px;
$v-minute: 10px;

// HELPERS
// vspace allows you to specify top/bottom or both in padding, margin or negative margin across a consistent set of breakpoints
@mixin vspace($sml,$med:$sml,$lrg:$med,$dir:both,$type:padding,$negative:false) {
  $pf: ''; // value prefix: default of blank

  @if $negative {
    $type: margin; // if negative mode, it has to be a margin
    $pf: '-'; // provide the negative prefix
  }

  @if $dir == top or $dir == both { // if top or both
    #{$type}-top: #{$pf+$sml};
    @include respond-min($bp-tablet-min) { #{$type}-top: #{$pf+$med}; }
    @include respond-min($bp-tablet-wide-min) { #{$type}-top: #{$pf+$lrg}; }
  }

  @if $dir == bottom or $dir == both { // if bottom or both
    #{$type}-bottom: #{$pf+$sml};
    @include respond-min($bp-tablet-min) { #{$type}-bottom: #{$pf+$med}; }
    @include respond-min($bp-tablet-wide-min) { #{$type}-bottom: #{$pf+$lrg}; }
  }
}

// CLASSES
.vspace-massive { @include vspace($v-medium,$v-huge,$v-massive); }
.vspace-huge { @include vspace($v-small,$v-large,$v-huge); }
.vspace-large { @include vspace($v-medium,$v-large,$v-large); }
.vspace-medium { @include vspace($v-small,$v-medium); }
.vspace-medium-smaller { @include vspace($v-small,$v-small,$v-medium-smaller); }
.vspace-small { @include vspace($v-tiny,$v-small); }
.vspace-tiny { @include vspace($v-tiny); }
.vspace-minuscule { @include vspace($v-minuscule); }
.vspace-minute { @include vspace($v-minute); }
.vspace-zero { @include vspace(0); }

.vspace-massive-top { @include vspace($v-medium,$v-huge,$v-massive,$dir:top); }
.vspace-huge-top { @include vspace($v-small,$v-large,$v-huge,$dir:top); }
.vspace-large-top { @include vspace($v-medium,$v-large,$v-large,$dir:top); }
.vspace-medium-top { @include vspace($v-small,$v-medium,$dir:top); }
.vspace-medium-smaller-top { @include vspace($v-small,$v-small,$v-medium-smaller,$dir:top); }
.vspace-small-top { @include vspace($v-tiny,$v-small,$dir:top); }
.vspace-tiny-top { @include vspace($v-tiny,$dir:top); }
.vspace-minuscule-top { @include vspace($v-minuscule,$dir:top); }
.vspace-minute-top { @include vspace($v-minute,$dir:top); }
.vspace-zero-top { @include vspace(0,$dir:top); }

.vspace-massive-bottom { @include vspace($v-medium,$v-huge,$v-massive,$dir:bottom); }
.vspace-huge-bottom { @include vspace($v-small,$v-large,$v-huge,$dir:bottom); }
.vspace-large-bottom { @include vspace($v-medium,$v-large,$v-large,$dir:bottom); }
.vspace-medium-bottom { @include vspace($v-small,$v-medium,$dir:bottom); }
.vspace-medium-smaller-bottom { @include vspace($v-small,$v-small,$v-medium-smaller,$dir:bottom); }
.vspace-small-bottom { @include vspace($v-tiny,$v-small,$dir:bottom); }
.vspace-tiny-bottom { @include vspace($v-tiny,$dir:bottom); }
.vspace-minuscule-bottom { @include vspace($v-minuscule,$dir:bottom); }
.vspace-minute-bottom { @include vspace($v-minute,$dir:bottom); }
.vspace-zero-bottom { @include vspace(0,$dir:bottom); }
