$card-padding: 20px;
$card-padding-large: 30px;
$card-padding-v: 16px;
$card-padding-h: 12px;

.card {
  min-width: 300px;
  // Classic px fallback.
  max-width: 322px;
  // Full viewport width minus sum of left and right page padding,
  // minus tiny offset to reveal the next slide.
  max-width: calc(100vw - 50px - 10px);

  // @640px Slick shows 2.15 slides.
  @include respond-min($bp-phablet-wide-min) {
    min-width: 200px;
    max-width: 400px;
    max-width: calc((100vw)/2 - 50px - 10px);
  }
  @include respond-min($bp-tablet-wide-min) {
    max-width: 400px;
  }
  .price-wrapper{
     margin: 100px 0 0 0 ;
  }

  .button {
     display: inline-flex;
     justify-content: space-evenly;
     align-items: center;
  }
}

.card__group-title {
  border-bottom: 1px solid $clr-dy-grey-3;
  padding-bottom: 1em;
  margin-bottom: 2.5em;

  .card--hide-title & {
    visibility: hidden;
  }
}

.card__inner {
  border: 1px solid $clr-dy-grey-3;
  background: $clr-dy-grey-05;
  position: relative;
}

.card__header {
    padding: $card-padding-h $card-padding-v;
}

.card__title {
  @include fs-responsive((g:20,gtw:24));
  font-weight: 400;
}

.card__stars_summary {
    padding: 0 $card-padding-v $card-padding-h $card-padding-v;

  .product-reviews-summary {
    margin: 0;
  }
}

.card__pricing, .card__in_home_installation {
    padding: $card-padding-h $card-padding-v;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

  strong {
    font-weight: 500;
  }
}

.card__pricing__sold-out {
    font-style: normal;
    display: block;
    @include fs-responsive($fs-body-small-bp);
    width: 100%;

  .hero__content__preorder-container & {
    margin-top: 70px;

    @include respond-min($bp-tablet-min) {
      margin-top: 50px;
    }
  }
}

.card__pricing__price {
  align-self: flex-end;
  width: 100%;
}

.card__perks {
    @include list-reset;
    border: 1px solid $clr-dy-grey-3;
    border-left: 0;
    border-right: 0;
    padding: $card-padding-h $card-padding-v;

  li {
    @include list-item-reset;
    @include fs-responsive((g:14,gtw:16));
    margin: 0.5em 0;

    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }
}
//added by sejal for tradeup card tools and accessories
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.hidden {
  display: none;
}
.slider {
  .slider__control {
    top: 350px;
  }
}
.slider__item {
  .card__tools.trade-up-item-tools {
    .trade-up-item-tools__chevron {
        width: 15px;
        height: 15px;
    }
    .empty__tools {
        border-bottom: 1px solid #dadada;
        padding: 24px; // addition of padding top + bottom | 12px+12px
        @include respond-max($bp-tablet-wide-min) {
          padding: 32px;
        }
    }
  }
  .trade-up-item-tools__accordion-heading:hover {
    background: #f7f7f7;
  }
  .mage-accordion-disabled.active {
    .trade-up-item-tools__drop-down {
      border-bottom: 0;
    }
  }
  .trade-up-item-tools__drop-down {
    border-bottom: 1px solid #dadada;
    padding: 12px 22px;
    cursor: pointer;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    @include respond-max($bp-tablet-wide-min) {
      padding: 12px 20px;
    }
   }
   .tool__item.active{
      .see-accordion {
        display: none;
      }
      .hide-accordion{
        display: inline-block;
          &:after {
              border-top: 2px solid;
              border-left: 2px solid;
              border-bottom: 0;
              border-right: 0;
              margin-bottom: -3px;
          }
      }
      .trade-up-item-tools__chevron {
          transform: translateY(-50%) rotateX(180deg);
          margin-bottom: -15px;
      }
   }
  .trade-up-item-tools__drop-down-text {
    font-family: $f-futura,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin: 0 1rem 0 0;
    @include respond-max($bp-tablet-wide-min) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .trade-up-item-tools__drop-down-label {
    font-size: 16px;
    line-height: 24px;
    font-family: $f-futura;
    font-weight: 500;
    color: #333;
    text-align: right;
    text-decoration: underline;
    margin: 0 10px 0 auto;
    max-width: 50%;
    min-width: 25%;
    @include respond-max($bp-tablet-wide-min) {
      font-size: 14px;
      line-height: 20px;
    }
    &.js-drop-down-label {
        &:after {
            content: '';
            width: 8px;
            height:8px;
            display: inline-block;
            border: 2px solid;
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-top: 0;
            border-left: 0;
            vertical-align: inherit;
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
  }
  .trade-up-item-tools__accordion-content {
      padding: 0 24px;
      background: #f7f7f7;
      border-bottom: 1px solid #dadada;
      @include respond-max($bp-tablet-wide-min) {
        padding: 0 20px;
      }
      ul {
          padding-left: 0;
          margin: 0;
      }
  }
  .trade-up-item-tools__single {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      overflow: hidden;
      padding: 12px 0;
      margin: 0;
    &.complimentary_title_block {
      border-top: 1px solid #dadada;
      padding: 0;
      margin: 13px -25px 0px;
      font-size: 1.6rem;
      overflow: initial;
      .complimentary_title {
        font-family: $f-futura,sans-serif;
        font-weight: 700;
        font-size: 12px;
        background: #e1e1e1;
        padding: 6px 18px;
        margin-top: -14px;
        color: #333;
      }
    }
  }
  .trade-up-item-tools__block {
      width: 80%;
      margin-right: 1rem;
  }
  .trade-up-item-tools__block--web-name {
      font-family: $f-futura;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 500;
      color: #333;
      @include respond-max($bp-tablet-wide-min) {
        font-size: 14px;
        line-height: 20px;
      }
  }
  .trade-up-item-tools__block-description {
      font-family: $f-futura;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #555;
      margin: 0;
      @include respond-max($bp-tablet-wide-min) {
        font-size: 14px;
        line-height: 20px;
      }
  }
  .trade-up-item-tools__image {
      position: relative;
      border: 1px solid #ebebeb;
        max-width: 52px;
        max-height: 52px;
        min-width: 52px;
        min-height: 52px;
        @include respond-max($bp-phablet-wide-min) {
            max-width: 44px;
            max-height: 44px;
            min-width: 44px;
            min-height: 44px;
        }
  }
}
.rtl {
  .slider__item {
    .trade-up-item-tools__drop-down-text {
      margin-right: 0;
    }
    .trade-up-item-tools__drop-down-label {
      margin: 0 auto 0 10px;
      text-align: left;
    }
    .trade-up-item-tools__block {
        margin-right: 0;
        margin-left: 1rem;
    }
    .trade-up-item-tools__accordion-content ul {
        padding-right: 0;
    }
  }
}
//end
.card__action {
  display: flex;
}

.card__action__item {
  width: 50%;

// Fix to make padding on both trade up card buttons match when longer text added to the span
  .dyson-sa &, .dyson-hu &, .dyson-cz &, {
    .button.button--large.button--fluid.product__variant__add-to-cart--animated-smaller.button--learn-more--larger {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
    }
  }
}

.dyson-cz .card__action__item .button.button--large.button--fluid.product__variant__add-to-cart--animated-smaller.button--learn-more--larger {
  padding: 10% 8%;
}

.card__action__items {
  display: flex;
}

.card__action__items__item {
  width: 50%;
}

.card__footer {
    padding: $card-padding-h $card-padding-v;
}

.card__compare {
  border-bottom: 1px solid $clr-dy-grey-3;
  padding-bottom: 1.4em;
  margin-bottom: 1em;
  text-align: right;
}

.card__pricing .card__action__item__product-amasty-note {
    position: absolute;
    top: 28%;
    font-weight: bold;
    font-size: 14px;

    .hgroup & {
      font-weight: bold;
      font-size: 14px;
    }
}
.card-tradeup--button-notify-me {
  .card__action__item {
    width: 100%;
  }
  .secondary-nav__add {
    width: auto;
    margin-right: unset;
  }
}

.slider .button, .slider form {
  height:100%;
}

//afterpay installment
.dyson-nz {
  afterpay-placement {
      font-family: "Futura Latin", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      margin-block-start: 0px;
      margin-block-end: 0px;
      line-height: 10px;
      --logo-badge-width: 87.7px;
  }
  //tradeupcard
  .card .after-pay-installment-msg {
    padding: 0 12px 12px;
  }
  //pdp
  .hgroup .after-pay-installment-msg {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #555;
  }
}
//product Variants
.card__variants {
    padding: 12px 16px 4px;
}
.soft-product-options,.checkout-cart-configure {
    .card__variants {
        padding-left: 0;
        min-height: 122px;
    }
    .card__pricing__sold-out {
        min-height: 19px;
    }
    .var-prods {
        display: flex;
        flex-wrap: wrap;
    }
}
