//Styling for badges on variant pages, and product cards


.hgroup__image .image > span,
.card .card__inner > span {
  padding: 5px 10px 5px 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}


.card .card__inner > span {
  position: absolute;
  z-index: 1;
  margin-left: 15px;
  margin-top: -20px;
}

// .catalog-product-view .slider {
//   overflow-x: visible;
// }
