body.rtl {
  .review__title--img {
    @include respond-min($bp-tablet-min) {
      text-align: left;
      margin-right: inherit;
      margin-left: 10%;
    }
  }

  #product-submit-review {
    .required {
      label.label {
        margin-left: 0;
        margin-right: 9px;
      }
    }

    .review-min-char {
      margin-left: inherit;
      margin-right: auto;
    }

    .review-field-age,
    .review-field-nickname {
      @include respond-min($bp-tablet-min) {
        margin-right: inherit ;
        margin-left: 25px;
      }
    }

    .contact--border {
      @include respond-min($bp-desktop-min) {
        border-right: 0;
      }
    }
  }
}
