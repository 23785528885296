.tag {
  display: inline-block;
  text-transform: uppercase;
  border: 1px solid $clr-dy-grey-4;
  border-radius: 5px;
  @include fs-static($fs-small);
  padding: 6px;
  @include link-reset;
  line-height: 1;

  &:hover,
  &:focus,
  &:active {
    border-color: $clr-dy-grey-7;
  }
}
