.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    direction: ltr;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    direction: ltr;
    height: 100%;
    min-height: 1px;

    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev,
.slick-next {
    &.slick-disabled {
        display: none !important;
    }
}

/* ----------------------- */
.slider {
  position: relative;
  // overflow-x: hidden;

  .slick-list {
    overflow: visible;
  }
}

.slider__list {
  margin-left: -#{$grid-gutter};

  @include respond-min($bp-tablet-wide-min) {
    margin-left: -#{$grid-gutter-large};
  }
}

.slider__item {
  padding-left: $grid-gutter;

  @include respond-min($bp-tablet-wide-min) {
    padding-left: $grid-gutter-large;
  }
}

.product-nav__slider__reviews {
  .slider__item:not(.slick-active) {
    height: 0px;
    overflow: hidden;
  }
}

.slider__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: $clr-white;
  border: none;
  background: $clr-dy-grey-7;
  border-radius: 0;
  padding: 0;
  padding-top: 59px;
  vertical-align: middle;
  width: 59px;
  height: 0;
  overflow: hidden;

  .product__slideshow & {
    padding-top: 54px;
    width: 54px;

    &:after {
      margin-top: -8px;

      @include respond-min($bp-tablet-wide-min) {
        margin-top: -5px;
      }
    }

    @include respond-min($bp-tablet-wide-min) {
      padding-top: 56px;
      width: 56px;
    }

    &:hover,
    &:focus,
    &:active {
      border: 0;
      outline: 0;
    }
  }

  &.slick-disabled {
    display: none !important; // because slick thinks otherwise
  }

  &:hover, &focus {
    background-color: $clr-dy-grey-6;
    border: none;
  }

  &:after {
    @include fs-static(58);
    content: '';
    display: block;
    position: absolute;
    height: 30px;
    width: 30px;
    color: $clr-white;
    margin-top: -5px; // bit of optical alignment
    top: -50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.slider__control--prev {
  left: -14px;

  &:after {
    content: '‹';
  }
}

.slider__control--next {
  right: -47px;

  &:after {
    content: '›';
  }
}

//styles for when cards are using slider--grid instead of demo-slider
.slider--grid {
  .slider__list {
    @include respond-min($bp-phablet-min) {
      display: flex;
      align-content: space-between;
      flex-flow: row wrap;
    }
  }


  .slider__item {
    width: 100%;

    @include respond-min($bp-phablet-wide-min) { //520px
      width: 50%;
    }

    @include respond-min($bp-tablet-wide-min) { //1024px
      width: 33.33%;
    }
  }

  .card {
    padding-bottom: 40px;
  }

  .card__group-title {
    display: none;
  }
}
