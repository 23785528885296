// z-index helpers
$z-index: (
  hell:           -9999,
  to-back:        -100,
  backwards:      -10,
  base-back:      -2,
  base-behind:    -1,
  base:           0,
  content-lower:  10,
  content-mid:    20,
  content-top:    30,
  chrome-lower:   100,
  chrome-mid:     200,
  chrome-top:     300,
  modal-bottom:   1000,
  modal-mid:      2000,
  modal-top:      3000,
  heaven:         9999
);

@mixin z-index($level) {
  @if map-has-key($z-index, $level) {
    z-index: map-get($z-index, $level);
  }
  @else {
    @warn "Unknown '#{$level}' in map $lh.";
  }
}
