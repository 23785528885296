/**
 * all steps
 */
.homedemo-index-index,
.homedemo-step2-index,
.homedemo-step3-index,
.homedemo-step4-index {
  form {
    .input_and_link {
      height: 5rem;
      width: 100%;
      margin-bottom: 1em;
      position: relative;

      .pincode-available  {
        display: none;
        position: absolute;
        right: 10px;
        bottom: 29px;
        width: 40px;
      }

      .pincode-unavailable {
        display: none;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 40px;
      }

      .icon.icon--clear {
        width: 30px;
        z-index: 1;
        position: absolute;
        right: 10px;
        top: -40px;
        // background-color: $clr-dy-grey-0;
      }
    }

    .input_and_btn {
      button {
        background-color: $clr-dy-green-0;
      }
      @include respond-min($bp-tablet-min) {
      display: flex;
      // width: 80%;
        button {
          width: 40%;
          margin-left: 10px;
          height: 63px;
          max-height: 63px;
        }
      }
    }

    h2, p:last-child {
    }

    a, a:visited, .alink:visited, .alink {
      text-decoration: underline;
    }
    a:hover, .alink:hover {
      text-decoration: none;
    }

    p {
      margin-bottom: 30px;
    }

    .input--loading {
      background-image: url(../images/loading.gif);
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: 90% center;
      background-position: 95% center;
      background-position-y: center;
    }

    .input-label-overlay {
      font-family: $f-fallback;
      position: absolute;
      top: 21px;
      left: 16px;
    }
    .input-label-overlay + input[type="text"] {
      padding-left: 48px;
    }
  }

  button:focus {
    //border: 1px solid transparent;
  }

  .button--fluid {
    margin-bottom: 1.3em;
  }
}

//STEP 1 //

.dyson-in.homedemo-index-index.page-layout-1column {
  .g-col.g-col-1-2.gt-col-1-4.g-icon-margin-top {
    margin-top: 20px;

    @include respond-min($bp-tablet-min) {
      margin-top: 0;
    }
  }
}


.demo_step1 .hgroup__content--in-home-demo {
  margin-bottom: 20px;
}
.dyson-in .button--in-home-demo--book,
.dyson-in .button--in-home-demo--call,
.dyson-in .button--in-home-demo--whatsapp,
.dyson-in .button--in-home-demo--call-back {
  font-family: $f-futura;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}


.dyson-in .hgroup__content--in-home-demo__cta-wrap {

  @include respond-min($bp-tablet-min) {
    display: flex;
    justify-content: space-between;
  }

  button:hover {
    color: $clr-white !important;
  }

}

.dyson-in .hero--in-home-demo--content-2 {
  background-color: $clr-dy-yellow-0;
  text-align: center;
}

.dyson-in.homedemo-index-index.page-layout-1column._has-modal {
  .modals-overlay {
    z-index: 0 !important;
  }

  button:focus, button:active {
    color: $clr-white;
  }
}

.demo_step1 {

  .hero--in-home-demo--content-1 {
    @include respond-min($bp-tablet-wide-min) {
      padding-top: 50px;
    }
  }

  .store-selector__main--demo {
    .layout {
      padding-left: 25px;
      padding-right: 25px;
    }
    .demo_step1 .store-selector__main--demo
    .store-selector-tabs__tab
    [data-role="collapsible"]
    [aria-expanded=true] a {
      border-right: none;
      border-left: none;
      background-color: white;
    }

    .store-selector-tabs__tab {
      margin: 0 auto;
      [data-role="collapsible"] {
        border: 1px solid #ebebeb;
        border-bottom: none;
        background: #fbfbfb;
        padding: 0;

        &[aria-expanded=true] {
          margin-bottom: -1px;
          background: white;

          a {
            border-bottom: none;
            color: #000;
            background: white;

            &:hover,
            &:active,
            &:focus {
              color: #000;
              background: white;
              border-bottom: none;
            }
          }
        }
      }
      a {
        color: #000;
        background-color: #fbfbfb;

        &:hover,
        &:active,
        &:focus {
          color: #000;
        }
      }
    }
    .store-selector-tabs__content {
      border-top: 1px solid #ebebeb;
      padding: 0;
    }

    .store-selector-collapsible__content {
      padding-top: 0;
    }
  }

  #tab-label-2 {
    border-right: none;
    border-left: none;
  }

  .contact__methods__link .icon {
    fill: $clr-black;
  }

  .contact__methods__type {
    width: 300px;
    margin: 0 auto;
    @media screen and (min-width: 1024px) {
      width: 330px;
    }
  }

  .contact__methods__text {
    //margin-left: 10px;
  }

  .title .row--border-top {
    background-color: $clr-dy-grey-05;
  }

  .layout__steps__content, .layout__content {
    font-size: 1rem;
  }

  .layout {
    h2 {
      font-size: 30px;
    }

    .layout__steps {
      margin-bottom: 3em;
    }

    .layout__steps__title {
      font-size: 35px;
    }

    .layout__image-main {
      margin-bottom: 1.5em;
    }

    .layout__image-small {
      margin-bottom: 1.5em;
    }
  }

  .icon-info__body {
    p {
      margin-top: 0;
    }
  }

  .icon-info__body__title {
      font-size: 1.5em;
  }

  .store-selector__main--demo .layout {
    @include respond-min($bp-tablet-min) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  .button--in-home-demo-call,
  .button--in-home-demo-whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-min($bp-tablet-min) {
      margin-left: 20px;
    }

    &:link, &:active, &:visited {
      background-color: #b2b3b4;
      color: #000;
    }

    &:hover {
      background-color: #999;
      color: #000;
    }
  }
}

//MODAL
.modal-register {
  .store-selector-tabs {
    display: inline-block;
    margin: 0 auto;
    @include respond-min($bp-tablet-min) {
      width: 100%;
    }
  }

  .field.form__item.form__item--entered {
    @include respond-min($bp-tablet-min) {
      width: 70%;
    }
  }

  .input--submit.button.button--fluid {
    @include respond-min($bp-tablet-min) {
      width: 29%;
      margin-left: 5px;
      height: 65px;
      max-height: 65px;
    }
  }

  div.mage-error, div.field-error {
    position: absolute;
    left: 0;
    top: 70px;
  }

  h2 {
    width: 100%;
    @include respond-min($bp-tablet-min) {
      text-align: left;
      width: 100%;
    }
  }

  .form__email, .form__mobile {
    width: 100%;
    margin-bottom: 3rem;
    @include respond-min($bp-tablet-min) {
      width: 70%;
    }
  }

  .form__email-label [aria-invalid="true"] {
    margin-top: -20px;
  }

  .input-plus-btn {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .store-selector__main {
    text-align: center;
  }

  .store-selector-tabs__tab {
    margin: 0 auto;
    padding: 0;
  }

  .store-selector-tabs__tab a {
    width: 100%;
    text-decoration: none;
    background-color: $clr-dy-grey-05;
    color: $clr-dy-grey-5;
    padding: 20px 30px;
    border: none !important;
  }

  .store-selector-tabs__tab [data-role="collapsible"] {
    padding: 0;
    border: 1px solid $clr-dy-grey-2;
  }

  .store-selector-tabs__tab a:hover,
  .store-selector-tabs__tab a:active,
  .store-selector-tabs__tab a:focus {
    color: $clr-dy-grey-5;
    border-bottom: none;
    text-decoration: none;
    background-color: $clr-white;
  }

  .store-selector-tabs__tab [data-role="collapsible"][aria-expanded=true] {
      border-bottom-color: white;
  }

  .store-selector-tabs__tab [data-role="collapsible"][aria-expanded=true] a {
    color: $clr-dy-grey-5;
    background-color: $clr-white;
  }


  .store-selector-tabs__content {
    display: flex;
    flex-wrap: wrap;
  }

}

.homedemo-index-index
  .modal-popup._show
  .modal-inner-wrap {
  width: 100%;
  @include respond-min($bp-tablet-min) {
    width: 60%;
  }
  @include respond-min($bp-desktop-min) {
    width: 40%;
  }

  .no-demo-available {
    color: $clr-dy-red-1;
  }
}


/**
 * STEP TWO
 */
.demo_step2 {
  /*
    Collapsibles
    =============================================
  */
  input {
    text-transform: capitalize;
    border: 1px solid $clr-dy-grey-2 !important;
  }

  input.mage-error {
    border: 1px solid $clr-dy-red-1 !important;
  }

  input#email_address {
    text-transform: none;
  }

  .demo__accordion__header {
    padding-right: 20px;
  }

  .demo__accordion {
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }

  .demo__accordion__header {
    &:before {
      content: '';
      background-image: url('../images/icons/sprite.png');
      background-position: 0 -3961px;
      pointer-events: none;
      opacity: 0.2;
      display: block;
      float: right;
      width: 24px;
      height: 29px;
    }

    &[aria-expanded=true] {
      &:before{
        transform: rotate(0.5turn);
        pointer-events: auto;
        opacity: 1;
      }
    }

    .demo__accordion__header--green {
      color: $clr-dy-green-0-hover;
      .text-left__icons--number {
        display:none;
      }
      .text-left__icons--green-tick {
        display: inline-block;
      }
    }
  }

  .demo-step2-accordion__products__product {
    img.text-left__icons--select-tick {
      display: none;
    }
  }
  .demo-step2-accordion__products__product.selected {
    img.text-left__icons--select-tick {
      display: inline-block;
      width: 30px;
      border-bottom: none;
    }
  }

  .demo-step2-accordion__products__product {
    img {
      border: 1px solid $clr-dy-grey-2;
    }
    div {
      border-left: 1px solid $clr-dy-grey-2;
      border-right: 1px solid $clr-dy-grey-2;
      background-color: #fbfbfb;
    }

    .text-center.category-names {
      @include respond-min($bp-phablet-min) {
        font-size: 18px;
      }
      // @include respond-min($bp-tablet-min){
      //   font-size: 19px;
      // }
      @include respond-min($bp-tablet-wide-min){
        font-size: 20px;
      }
      @include respond-min($bp-desktop-min){
        font-size: 25px;
      }
    }
    &.selected {
      img {
        border-width: 2px;
        border-color: $clr-dy-grey-7;
        border-bottom: 1px solid $clr-dy-grey-2;;
      }
      div {
        border-left: 2px solid $clr-dy-grey-7;
        border-right: 2px solid $clr-dy-grey-7;
      }
      button {
        background-color: $clr-dy-grey-7;
      }

    }
  }

  .in_home_demo_availability_date {
    display: inline-block;
    width: 19%;
    margin: 0 10px 10px 0;
    padding: 1em;
    background: $clr-dy-grey-0;
    border: 2px solid $clr-dy-grey-2;

    @include respond-min($bp-tablet-wide-min) {
      padding: 0.5em;
    }

    @include respond-min($bp-desktop-min) {
      width: 10%;
    }

    &.active {
      border-color: $clr-dy-grey-6;
    }

    span {
      display: inline-block;
      width: 100%;
      text-align: center;
      cursor: pointer;
      color: $clr-dy-grey-6;

      &:first-child,
      &:last-child {
        @include fs-responsive((g:12px, gtw:14px));
      }

      &:nth-child(2n) {
        @include fs-responsive((g:24px, gtw:32px));
        line-height: 1;
        position: relative;
        top: 2px;
      }
    }
  }

  .in_home_demo_availability_time {
    @include fs-responsive((g:18px, gtw:21px));
    display: inline-block;
    width: 30%;
    margin-right: 10px;
    margin-bottom: 10px;
    background: $clr-dy-grey-0;
    border: 2px solid $clr-dy-grey-2;
    text-align: center;
    color: $clr-dy-grey-6;
    padding: 1em 0;
    cursor: pointer;

    @include respond-min($bp-tablet-wide-min) {
      padding: 1.5em;
    }

    &.active{
      border-color: $clr-dy-grey-6;
    }

    span.time-slot {
      font-size: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      p {
        text-align: center;
        font-size: 16px;
        width: 100%;
      }
      p:last-child {
        color: $clr-dy-green-2;
      }
    }

    .time-slot-am-pm {
      font-size: 14px;
      margin: 0 5px;
    }
  }

  .text-left__icons--number,
  .text-left__icons--green-tick {
    width: 40px;
  }

  .text-left__icons--green-tick {
      display: none;
  }

  [data-demo-accordion-availabilities-validation],
  [data-demo-accordion-category-validation] {
   display: none;
  }
}

/**
 * STEP THREE
 */
.demo_step3 {
  form {
    display: inline-block;
  }
  .demo_step3__booking_details {
    border: 1px solid $clr-dy-grey-2;
    padding: 20px;
  }
  a:visited, .alink:visited, a, .alink {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

/**
* STEP Four
*/
 .demo_step4 {
   margin: 3em 0;

   a:visited, .alink:visited, a, .alink {
    text-decoration: underline;
   }
   a:hover {
    text-decoration: none;
   }
   .button--wider {
     background-color: $clr-dy-green-0;
     width: 100%;
     margin: 0 auto;

     @include respond-min($bp-tablet-min) {
       width: 30%;
       float: right;
     }
   }
   .hgroup__title {
     color: $clr-dy-green-2;
   }
   .text-left__icons--green-tick {
     width: 40px;
     height: 40px;
     margin-right: 10px;
   }

   h2 {
     margin-bottom: 1em;
     margin-top: 0;
   }

   p {
     font-size: 16px;
   }

   .step4__green-header {
     display: flex;
   }
   .step4__content {
     border: 1px solid $clr-dy-grey-2;
     margin: 0 -4em 2em -4em;
     padding: 3em 4em;
     // @include respond-min($bp-tablet-min) {
     //
     // }
   }
}

