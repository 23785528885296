/*
  GLOBAL
  html, body and global styles
*/

html {
  -webkit-text-size-adjust: 100%; // Prevents iOS orientation font resize bug
  font-size: 100%;
  overflow-x: hidden;
}

body {
  // overflow-x: hidden !important;
  // overflow-y: auto;
  min-height: 100vh;
  min-width: 325px;
  word-wrap: break-word; // break long words over two lines on smaller screens
  word-break: keep-all; // for space-based languages (e.g. Korean)
  font-family: $f-futura;
  line-height: $lh-base;
  @include fs-responsive($fs-body-bp);
  color: $clr-dy-grey-6;
  text-rendering: optimizeLegibility; // aestheticallyloyal.com/public/optimize-legibility/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

}

* {
  box-sizing: border-box;
}


//this is temporary whilst we have the promo code on, the promo code class is only on India
// .promo-code {
//   display: none;
//   .checkout-cart-index & {
//     display: inline-block;
//     color: #06c;
//     border: 1px solid #06c;
//     padding: 1.85em;
//     margin-top: 1.5em;
//     width: 100%;
//   }
// }
