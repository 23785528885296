.icon-link {
  @include link-reverse;
  @include fs-responsive((g:24px, gt:28px));
  color: $clr-dy-grey-7;
  font-weight: 300;

  &--large {
    // @include fs-responsive((g:24px, gtw:28px));
  }

  &:hover, &:focus, &:active, &:visited {
    color: $clr-dy-grey-7;
  }

  &--dark {
    color: $clr-white;

    &:hover, &:focus, &:active, &:visited {
      color: $clr-white;
    }
  }

  .hgroup--dark & {
    color: $clr-white;

    &:visited {
      color: $clr-white;
    }
  }

  .roundal--small {
    display: inline-block;
    position: relative;
    top: 4px;

    @include respond-min($bp-tablet-min) {
      top: 0;
      margin-bottom: -10px;
      margin-right: 10px;
    }

    .roundal__icon .icon {
      top: -6px;
      left: 6px;

      @include respond-min($bp-tablet-min) {
        top: 0;
        left: 10px;
      }
    }

    .hgroup--dark & {
      background-color: $clr-white;
      .roundal__icon .icon {
        fill: $clr-black;
      }

    }
  }
}
