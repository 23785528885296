body.rtl {
  .basket__items__quantity {
    .label {
      margin-right: inherit;
      margin-left: 12px;
    }
  }

  .basket__items__price {
    text-align: left;
    padding-right: inherit;
    padding-left: 0.5em;

    .label {
      margin-right: inherit;
      margin-left: 5px;
    }
  }

  .action-delete, .action-delete span {
    text-align: left;
  }

  .action-delete {
    padding-right: inherit;
    padding-left: 0.5em;
  }

  .update-cart__buttons {
    @include respond-min($bp-tablet-min) {
      float: left;
      text-align: left;
    }

    button {
      &:first-child {
        margin-right: inherit;
        margin-left: 20px;
      }

      @include respond-min($bp-tablet-min) {
        margin-right: inherit;
        margin-left: 20px;
        padding-left: 0;
        padding-right: 15px;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .basket__discount__form {
    .actions-toolbar {
      margin-left: inherit;
      margin-right: 20px;

      @include respond-min($bp-tablet-min) {
        margin-right: 0;
      }
    }
  }

  .basket__summary {
    .table-wrapper {
      .totals {
        th {
          text-align: right;
        }

        td {
          text-align: left;
        }
      }
    }
  }

  .basket__checkout {
    @include respond-min($bp-tablet-min) {
      margin-left: inherit;
      margin-right: 20px;
    }
  }

  .basket__checkout__payment {
    margin-left: inherit;
    margin-right: 20px;

    @include respond-min($bp-tablet-min) {
      margin-right: 0;
    }
  }


  .soft-bundling__qty-input .basket__items__below__btn.action.update {
    margin-left: 0;
  }

}

.dyson-sa.rtl {
  .basket__checkout__payment:after {
    background-image: url(../images/brand/payment-options-cart-SA-r.jpg);
    background-repeat: no-repeat;
    width: 272px;
    height: 195px;
    background-size: contain;
    display: block;
    content: '';
    margin: 0 auto 20px auto;
  }
}
