.hero--product-variant--legacy {
  //padding-bottom: 1%;

  div.row.row--full-height.row--small {
    padding-bottom: 0;
  }

  h1 {
    margin: 0;
  }

  @include respond-min($bp-tablet-min) {
    padding-bottom: 0;
  }

  .hero__media {
    padding-bottom: 300px;

    @include respond-min($bp-mobile-wide-min) {
      padding-bottom: 275px;
    }
    @include respond-min($bp-tablet-min) {
      padding-bottom: 40px;
    }
    @include respond-min($bp-tablet-wide-min) {
      padding-bottom: 0;
    }
  }

  .hgroup__main-image {
    @include respond-min($bp-tablet-min) {
      float: right;
      width: 35%;
    }
    @include respond-min($bp-desktop-min) {
      //PPP layout, centralised image:
      //position: absolute;
      //left: 31%;
      //top: -71px;
      width: 33%;
      order: 2;
    }
  }

  // assume product logos
  .hgroup__image {
    margin: 0;

    img {
      max-width: 50%;
      margin-top: 20px;
    }
  }

  a.button {
    margin: 8px 0 8px 0;

    &.button--primary {
        color: $clr-white;
        text-decoration: none;

        &:hover, &:focus, &:active {
          text-decoration: none;
          color: $clr-white;
        }

        &:visited {
          color: $clr-white;
        }

      @include respond-min($bp-tablet-min) {
        margin: 0 0 16px 0;
      }
      @include respond-min($bp-tablet-wide-min) {
        margin: 0 16px 0 0;
      }
    }

    &.button--secondary {
        color: $clr-dy-grey-7;
        background-color: $clr-dy-grey-2;
        text-decoration: none;

        &:hover, &:focus, &:active {
          text-decoration: none;
          color: $clr-dy-grey-7;
        }

        &:visited {
          color: $clr-dy-grey-7;
        }
      }
    }

  .product__claim-section {
    width: 100%;

    @include respond-min($bp-tablet-min) {
      display: flex;
    }
    @include respond-min($bp-tablet-wide-min) {
      display: flex;
      float: none;
      position: relative;
    }

    .layout {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.legacy__hero__content {
  display: flex;
  flex-direction: column;
}

.legacy__hero__content--bottomer {
  bottom: 0;

  @include respond-min($bp-tablet-min) {
    position: relative;
    top: 70px;
  }
}

.legacy__product__availability-messaging {
  display: flex;
  margin: 16px 0 16px 0;
  font-family: $f-futura;
  color: $clr-dy-red-1;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include fs-responsive((g:16px, gtw:18px));
  @include respond-min($bp-tablet-min) {
    margin: 20px 0 20px 0;
  }
  @include respond-min($bp-tablet-wide-min) {
    margin: 24px 0 24px 0;
  }
}

.legacy__product__availability-messaging__alert-icon {
  width: 1.25rem;
  vertical-align: text-bottom;
  margin-right: 8px;
}

.legacy__button__container {
  margin-bottom: 2px;

  @include respond-min($bp-tablet-min) {
    display: flex;
    flex-direction: column;
  }
  @include respond-min($bp-tablet-wide-min) {
    display: flex;
    flex-direction: row;
  }
}

#legacy__promos {
  svg {
    top: -6px;
    left: 6px;

    @include respond-min($bp-tablet-min) {
      top: 0px;
      left: 10px;
    }
    @include respond-min($bp-tablet-wide-min) {
      top: -1px;
      left: 11px;
    }
    @include respond-min($bp-desktop-wide-min) {
      top: -2px;
      left: 8px;
    }
  }

  .promos__title {
    margin: 0;

    @include respond-min($bp-tablet-min) {
      margin: 0;
    }
    @include respond-min($bp-tablet-wide-min) {
      margin: 0;
    }
  }

  .promos__list {
    margin-top: 8px;

    @include respond-min($bp-tablet-min) {
      margin-top: 0;
    }
  }

  .promos__item {
    padding-top: 8px;
    padding-bottom: 8px;

    @include respond-min($bp-tablet-min) {
      padding-top: 20px;
    }
    @include respond-min($bp-tablet-wide-min) {
      padding-top: 24px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 8px 0 8px 0;

      @include respond-min($bp-tablet-min) {
        margin: 12px 0 12px 0;
      }
      @include respond-min($bp-tablet-wide-min) {
        margin: 16px 0 16px 0;
      }
    }

    p {
      margin: 0;
    }
  }

  .vspace-minute-top {
    padding-top: 8px;

    @include respond-min($bp-tablet-min) {
      margin-top: 10px;
    }
  }

  .icon-link {
    font-family: $f-futura;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

  }

  .hgroup__action--text {
    margin-left: 0.25rem;
    vertical-align: text-top;

    @include respond-min($bp-tablet-min) {
      margin-left: 0;
    }
    @include fs-responsive((g:18px, gtw: 24px, gd:30px));
  }

}