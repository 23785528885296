/*
 * COUNTRIES - edge cases on a site by site basis
 *
 * Use me with care. I'm for adhoc tweaks, not features or permenant solutions
 * Group by country and nest inside .dyson-* (e.g. dyson-in) class
 * Comment each exclusion!
 */

 // INDIA
 .dyson-in {
   .promos--product-promo {
     .promos__item {
       .image__cta--roundal {
         // display: none;
       }
     }
   }

   .update-cart__buttons-IN {
     padding: 18px 0 10px 0;
     width: 100%;
     text-align: center;
     float: none;

     @include respond-min($bp-tablet-min) {
       float: right;
       padding: 14px 1em 14px 0;
       width: auto;
       text-align: left;
     }
   }

   #pincode_sign_up_trigger {
     cursor: pointer;
   }

   .pincode_fieldset_title {
     border-bottom: 1px solid $clr-dy-grey-2;
     padding-bottom: 25px;
   }

   .checkout__row {
     [data-index="pincode_button"] {
       @include button;
       margin-top: 28px;
     }
     [data-index="pincode_button_billing"] {
       @include button;
       margin-top: 28px;
     }
     #checkout-step-shipping {
       .input--loading {
         background-image: url(../images/loading.gif);
         background-size: 25px 25px;
         background-repeat: no-repeat;
         background-position: 90% center;
         background-position: 95% center;
         background-position-y: center;
       }
     }
     #shipping-new-address-form,
     .checkout-billing-address fieldset {
       .field {
         @include respond-min($bp-tablet-min) {

           //&:nth-child(1) {
           //  width: 48%;
           //  margin-right: 4%;
           //}
           //
           //&:nth-child(2) {
           //  width: 48%;
           //}
           //
           //&:nth-child(3) {
           //  width: 48%;
           //  margin-right: 4%;
           //}
           //
           //&.street {
           //  width: 100%;
           //
           //  .field {
           //    margin-bottom: 0;
           //
           //    &:last-child {
           //      margin-top: 8px;
           //    }
           //  }
           //
           //}

           &:nth-child(5) {
             width: 100%;
             margin-right: 0;
           }

           &:nth-child(6) {
             width: 100%;
           }

           &:nth-child(7) {
             width: 100%;
           }

           //&:nth-child(8) {
           //  width: 48%;
           //  margin-right: 4%;
           //}
           //
           //&:nth-child(9) {
           //  width: 48%;
           //}

           &:nth-child(10) {
             width: 48%;
             margin-right: 0;
           }

           //&:nth-child(11) {
           //  width: 48%;
           //}

           &:nth-child(12) {
             width: 48%;
             margin-right: 4%;
           }

           &:nth-child(13) {
             width: 48%;
           }
         }
       }
     }
   }
}
