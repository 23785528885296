// Remove padding on .layout for mobile
.store-selector__main {
  @include respond-min($bp-tablet-wide-min) {
    padding-top: 15px;
  }

  .layout {
    @include respond-max($bp-tablet-wide-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/*
  Tabs
  =============================================
*/
.store-selector-tabs {
  display: none;

  @include respond-min($bp-tablet-wide-min) {
    display: block;
  }
}

.store-selector-tabs__tab {
  margin: 0;

  [data-role="collapsible"] {
    margin: 0;
    padding: 0 20px 0 0;

    &[aria-expanded=true] {
      a {
        border-bottom: 1px solid #000;
        color: #000;

        &:hover,
        &:active,
        &:focus {
          color: #000;
        }
      }
    }
  }

  a {
    color: #dadada;
    font-family: $f-futura;
    font-weight: 300;
    display: inline-block;
    width: 100%;
    padding: 1em 0;
    @include fs-responsive((g:20px, gpw:22px, gtw:24px));

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    &:hover,
    &:active,
    &:focus {
      color: #014c8c;
    }
  }
}

.store-selector-tabs__content {
  padding-top: 32px;

  ul {
    margin: 0 0 0 -10px;
  }

  li {
    padding: 0 0 0 0;
    margin: 0 0 13px 0;
  }

  a {
    @include fs-responsive((g:14px, gtw:16px));
    font-family: $f-futura;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    padding: 1em;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    @include respond-min($bp-tablet-wide-min) {
      padding: 1em 0 1em 0.625em;
    }
  }
}

/*
  Collapsibles
  =============================================
*/
.store-selector-collapsible {
  @include respond-min($bp-tablet-wide-min) {
    display: none;
  }
  /*
    1. Reset button styles
    todo: Stop setting styles on <button>
  */
  [data-role="title"] {
    color: #000;
    font-family: $f-futura;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    padding: 1.25em 25px;
    border: none; /* 1 */
    background: none; /* 1 */
    border-bottom: 1px solid #ECECEC;
    text-align: left;
    @include fs-responsive((g:20px, gpw:22px, gtw:24px));

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    @include respond-min($bp-tablet-min) {
      padding-left: 50px;
      padding-right: 50px;
    }

    &:hover,
    &:focus {
      background: none; /* 1 */
      border: none; /* 1 */
      border-bottom: 1px solid #ECECEC;
      color: #000;
    }

    &:after {
      content: '';
      background-image: url('../images/icons/sprite.png');
      background-position: 0px -3967px;
      display: block;
      float: right;
      width: 24px;
      height: 29px;
    }
  }

  [aria-expanded=true] {
    &:after {
      transform: rotate(180deg);
    }
  }

  li {
    padding: 0;
    margin: 0;
  }

  a {
    @include fs-responsive((g:14px, gtw:16px));
    display: block;
    font-family: $f-futura;
    font-weight: 400;
    padding: 20px 42px;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }
  }
}

.store-selector-collapsible__content {
  padding-top: 20px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

/*
  Country Flags (applies to both collapsible and tag widgets)
  =============================================
*/
.country-flag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  background-image: url('../images/icons/sprite.png');
  width: 24px;
  height: 24px;

  &--be {
    background-position: 0 -56px;
  }

  &--cy {
    background-position: 0 -320px;
  }

  &--dk {
    background-position: 0 -392px;
  }

  &--de {
    background-position: 0 -368px;
  }

  &--es {
    background-position: 0 -416px;
  }

  &--bg {
    background-position: 0 -80px;
  }

  &--fr {
    background-position: 0 -488px;
  }

  &--hr {
    background-position: 0 -656px;
  }

  &--ie {
    background-position: 0 -728px;
  }

  &--it {
    background-position: 0 -800px;
  }

  &--lv {
    background-position: 0 -32px;
  }

  &--lu {
    background-position: 0 -920px;
  }

  &--hu {
    background-position: 0 -680px;
  }

  &--nl {
    background-position: 0 -1040px;
  }

  &--no {
    background-position: 0 -1064px;
  }

  &--pl {
    background-position: 0 -1208px;
  }

  &--pt {
    background-position: 0 -1256px;
  }

  &--ro {
    background-position: 0 -1304px;
  }

  &--ch {
    background-position: 0 -152px;
  }

  &--si {
    background-position: 0 -1424px;
  }

  &--sk {
    background-position: 0 -1448px;
  }

  &--fi {
    background-position: 0 -464px;
  }

  &--se {
    background-position: 0 -1376px;
  }

  &--tr {
    background-position: 0 -1568px;
  }

  &--gb {
    background-position: 0 -512px;
  }

  &--at {
    background-position: 0 -944px;
  }

  &--cz {
    background-position: 0 -344px;
  }

  &--gr {
    background-position: 0 -560px;
  }

  &--ru {
    background-position: 0 -1328px;
  }

  &--ua {
    background-position: 0 -1616px;
  }

  &--au {
    background-position: 0 -1232px;
  }

  &--hk {
    background-position: 0 -608px;
  }

  &--in {
    background-position: 0 -776px;
  }

  &--id {
    background-position: 0 -704px;
  }

  &--my {
    background-position: 0 -1016px;
  }

  &--nz {
    background-position: 0 -1088px;
  }

  &--ph {
    background-position: 0 -1184px;
  }

  &--sg {
    background-position: 0 -1400px;
  }

  &--kr {
    background-position: 0 -848px;
  }

  &--th {
    background-position: 0 -1496px;
  }

  &--cn {
    background-position: 0 -248px;
  }

  &--jp {
    background-position: 0 -824px;
  }

  &--hk {
    background-position: 0 -608px;
  }

  &--ca {
    background-position: 0 -128px;
  }

  &--cr {
    background-position: 0 -296px;
  }

  &--sv {
    background-position: 0 -1472px;
  }

  &--gt {
    background-position: 0 -584px;
  }

  &--hn {
    background-position: 0 -632px;
  }

  &--mx {
    background-position: 0 -992px;
  }

  &--us {
    background-position: 0 -1640px;
  }

  &--br {
    background-position: 0 -104px;
  }

  &--cl {
    background-position: 0 -224px;
  }

  &--co {
    background-position: 0 -272px;
  }

  &--pa {
    background-position: 0 -1112px;
  }

  &--pe {
    background-position: 0 -1160px;
  }

  &--il {
    background-position: 0 -752px;
  }

  &--lb {
    background-position: 0 -872px;
  }

  &--qa {
    background-position: 0 -1280px;
  }

  &--sa {
    background-position: 0 -1352px;
  }

  &--tr {
    background-position: 0 -1568px;
  }

  &--ae {
    background-position: 0 -1136px;
  }

  &--ma {
    background-position: 0 -968px;
  }

  &--za {
    background-position: 0 -1832px;
  }
}
