$fs-price-bp: (g:20px, gt:25px, gtw:30px);
$fs-price-large-bp: (g:28px, gt:32px, gtw:38px);

.price {
  margin: 1em 0;

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }

  &--large {
    margin: 2em 0;
  }
}

.price__was,
.price__is,
.price__save {
  display: block;
}
div.price-final_price {
  .price__was {
      display: inline-block;
      text-decoration-line: line-through;
      .price-final_price {
          display: inline;
      }
  }
}
.dyson-mx  {
    .price__was,
    .price__save {
        font-size: initial;
    }
}
.price__is {
  @include fs-responsive($fs-price-bp);

  .price--large & {
    @include fs-responsive($fs-price-large-bp);
  }

  .price--sale & {
    color: $clr-dy-blue-1;
  }
}

.price__save {
  font-style: normal;

  .price--sale & {
    color: $clr-dy-blue-1;
  }
}

.price-mrp {
  color: $clr-dy-grey-4;
}

.product__price-matched {
  font-family: $f-futura;
  @include fs-static($fs-body);

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}

.product__price-matched,
.price--promotion-enabled {
  color: $clr-dy-blue-1;
}

.price--promotion-enabled .price{
  font-weight: 500;
  line-height: 14px;
}

.dyson-ro .hero__content.hero__content--bottomer--g.hero__content--66pc--gt {
  width: 75%;
}
