// ------ Checkout & Form Overrides ------

.am-checkout {
    position: relative;
    padding-bottom: 4em;

    .loading-mask .loader img {
        transform: scale(-0.6);
        margin-top: -75px;
        margin-left: 0;
    }

    .authentication-wrapper,
    .opc-estimated-wrapper {
        display: none;
    }

    .checkout-messages {
        padding-top: 4em;
        margin-bottom: -4em;

        @include respond-min($bp-tablet-wide-min) {
            padding-top: inherit;
            margin-bottom: inherit;
        }
    }

    .button--primary {
        &:hover,
        &:focus {
            color: $clr-white;
        }
    }

    .button--tertiary {
        &:hover,
        &:focus {
            color: $clr-black;
        }
    }
    .zero-checkout-message {//ROW-14297
        .payment-method-title-zero-checkout {
            &.field {
                margin-bottom: 0;
            }
        }
    }
}


// ------ Checkout Header ------

.am-checkout__header {
    display: none;
    margin:24px 0;

    @include respond-min($bp-tablet-wide-min) {
        display: inherit;
    }

    h1 {
        @include fs-static(28);
    }
}


// ------ Checkout Main ------

.am-opc-main {
    padding-top: 60px;

    @include respond-min($bp-tablet-wide-min) {
        padding-top: 0;
    }
}

.checkout-block {
    li {
        list-style: none;
    }

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }

    select {
        color: $clr-dy-grey-7;
    }
}

.checkout-block__header {
    border: 1px solid $clr-dy-grey-2;
    display: flex;
    align-items: center;
    //ROW7065 max 1024
    @include respond-max($bp-tablet-wide-min) {//max 1024
        border: 0;
        //border-bottom: 1px solid $clr-dy-grey-2;
        .checkout-block--payment & {
            border-top: 1px solid $clr-dy-grey-2;
        }
    }
    @include respond-min($bp-phablet-wide-min) {//min 640
        padding: 16px 1em;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640
        padding: 16px 0;
    }
    @include respond-min($bp-tablet-wide-min) {//1024
        padding: 2em;
    }
    .checkout-block--complete & {
        cursor: pointer;
        border-bottom: 0;

        &:after {
            content: '';
            display: block;
            background-image: url('../images/icons/chevron-down.png');
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: auto;

            // @include respond-min($bp-tablet-wide-min) {
            //     margin-right: 2.5em;
            // }
        }
    }
}

.checkout-block__header__title {
    @include fs-responsive((g:24px, gtw:28px));
    font-weight: 400;
    color: $clr-dy-grey-5;
    margin: 0;

    .checkout-block--active & {
        color: $clr-dy-grey-7;
    }

    .checkout-block--complete & {
        color: $clr-dy-green-2;
    }
}
//hide payment method title when zero subtotal checkout
.zero-payment .checkout-block__content__subheading {
    display: none !important;
}
.zero-checkout-message {
    display: flex;
    padding: 16px;
    border: 1px solid #0D8722;
    margin-left: -16px;
    margin-right: -16px;
    .indicator::after {
        display: block;
        border-width: 0 2px 2px 0;
        @include respond-min($bp-phablet-wide-min) {//min 640
            left: 5px;
            width: 3px;
            height: 6px;
            top: 6.5px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640
            left: 5px;
            width: 3px;
            height: 6px;
            top: 6.5px;
        }
        @include respond-min($bp-tablet-wide-min) {//1024
            left: 8px;
            width: 3px;
            height: 6px;
            top: 9.5px;
        }
    }
    .payment-method-title-zero-checkout {
        padding-bottom: 0 !important;
        .label{
            box-shadow: none !important;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 14px !important;
            line-height: 20px;
            font-family: $f-futura;
            @include respond-min($bp-tablet-wide-min) {//1024
                padding-top: 3px;
            }
        }
    }
}

.checkout-block__header__indicator, .indicator {
    @include fs-responsive((g:24px, gtw:28px));
    display: inline-block;
    border-radius: 50%;
    border: 2px solid $clr-dy-grey-25;
    color: #555;
    height: 38px;
    width: 38px;
    line-height: 38px;
    text-align: center;
    margin-right: 0.5em;
    position: relative;
    font-weight: 300;

    @include respond-min($bp-tablet-wide-min) {
        height: 43px;
        width: 43px;
        line-height: 43px;
    }

    span {
        display: block;
        line-height: 36px;

        @include respond-min($bp-tablet-wide-min) {
            line-height: 40px;
        }

        .checkout-block--complete & {
            display: none;
        }
    }

    .checkout-block--active & {
        color: #000;
    }

    .checkout-block--complete & {
        border-color: $clr-dy-green-2;
        color: $clr-dy-green-2;
    }

    .zero-checkout-message & {
        border-color: #0D8722;
        color: #0D8722;
        @include respond-min($bp-phablet-wide-min) {//min 640
            width: 20px;
            height: 20px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640
            width: 19px;
            height: 19px;
        }
        @include respond-min($bp-tablet-wide-min) {//1024
            width: 26px;
            height: 26px;
        }
    }

    &:after {
        content: "";
        display: none;
        width: 6px;
        height: 12px;
        border: solid $clr-dy-green-2;
        border-width: 0 3px 3px 0;
        position: absolute;
        top: 48%;
        left: 13px;
        transform: translateY(-50%) rotate(45deg) scale(1);

        @include respond-min($bp-tablet-wide-min) {
            left: 15px;
        }

        .checkout-block--complete & {
            display: block;
        }
    }
}

.checkout-block__content {
    max-height: 0;
    overflow: hidden;
    border: 1px solid $clr-dy-grey-2;
    transition: 0.2s ease max-height;
    border-top: 0;
    border-bottom: 0;
    //ROW7065 max 1024
    @include respond-max($bp-tablet-wide-min) {//max 1024
        border: 0;
    }
    .checkout-block--active & {
        transition: 0.5s ease max-height;
        max-height: 3000px;
    }
}

.checkout-block--payment.checkout-block--active {
    .checkout-block__content {
        border-bottom: 1px solid $clr-dy-grey-2;
    }
}

.checkout-block__content__inner {
    opacity: 0;
    transition: 0.5s ease opacity 0.5s;
    //ROW7065
    @include respond-max($bp-phablet-wide-min) {//max 640
        padding: 16px 0;
    }
    @include respond-min($bp-phablet-wide-min) {//min 640
        padding: 16px 20px; //16px layout + 20 = 36px
    }
    @include respond-min($bp-tablet-wide-min) {//min 1024
        padding: 16px 2em;
    }
    .checkout-block--active & {
        opacity: 1;
    }
}

.checkout-block__content__subheading {
    @include fs-responsive((g:20px, gt:22px, gd:24px));
    font-weight: 400;
    padding-bottom: 0.5em;
}


// ------ Checkout Summary ------

.am-opc-summary {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: $clr-white;
    padding-right: 20px;
    overflow: hidden;
    background-color: $clr-dy-grey-05;
    border-bottom: 1px solid $clr-dy-grey-2;//ROW7065
    @include respond-min($bp-tablet-wide-min) {
        position: inherit;
        background-color: $clr-white;
        padding-right: 0;
        border: 0;//ROW7065
    }

    &.am-opc-summary--open {
        bottom: 0;
        overflow-y: scroll;
    }

    .shipping-details {
        display: flex;
        align-items: baseline;

        .shipping-details__info {
            flex: 1;
        }

        .value {
            @include fs-responsive((g:16px, gtw:18px));
            color: $clr-dy-grey-7;
            font-weight: 500;
        }

        .shipping-details__icon {
            img {
                width: 28px;
                height: 28px;
                margin-right: 0.5em;

                @include respond-min($bp-tablet-wide-min) {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .shipping-details__info__price {
            @include fs-responsive((g:16px, gtw:18px));
            font-weight: 500;
            float: right;
            color: $clr-dy-green-2;
        }

        .shipping-details__info__details {
            padding-right: 1em;
        }

        .checkout-sp & {
            display: none;
        }
    }

    .shipping-details-sortedpro {
        display: inline-block;
        width: 100%;

        .shipping-details-sortedpro__top {
            display: inline-flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1em;
        }

        .shipping-details__info {
            flex: 1;
        }

        .value {
            @include fs-responsive((g:16px, gtw:18px));
            color: $clr-dy-grey-7;
            font-weight: 500;
        }

        .shipping-details__icon {
            img {
                width: 28px;
                height: 28px;
                margin-right: 0.5em;

                @include respond-min($bp-tablet-wide-min) {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .shipping-details__info__price {
            @include fs-responsive((g:16px, gtw:18px));
            font-weight: 500;
            float: right;
            color: $clr-dy-green-2;
        }

        .shipping-details__info__details {
            color: $clr-dy-grey-7;
            padding-right: 1em;
            padding-bottom: 1em;
        }
    }
}

.am-opc-summary--stick {
    position: fixed;
    @include z-index(chrome-mid);

    @include respond-min($bp-tablet-wide-min) {
        position: relative;
    }
}

.checkout-block__summary__header {
    padding: 1.5em 0 1.5em 3em;
    position: relative;
    border-bottom: 1px solid $clr-dy-grey-2;

    @include respond-min($bp-tablet-wide-min) {
        display: none;
    }

    &:before {
        content: '';
        display: block;
        background-image: url('../images/icons/basket-icon.png');
        width: 32px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        left: 0;
        top: 50%;
        margin-top: -16px;
        position: absolute;

        @include respond-min($bp-tablet-wide-min) {
            display: none;
        }
    }

    &:after {
        content: '';
        display: block;
        background-image: url('../images/icons/chevron-down.png');
        width: 18px;
        height: 18px;
        background-size: contain;
        background-repeat: no-repeat;
        right: 1em;
        top: 50%;
        margin-top: -9px;
        position: absolute;

        .am-opc-summary--open & {
            transform: rotate(180deg);
        }

        @include respond-min($bp-tablet-wide-min) {
            display: none;
        }
    }

    @include respond-min($bp-tablet-wide-min) {
        padding: 0;
    }

}

.checkout-block__summary__title {
    display: none;
}

.checkout-block__summary__items {
    padding: 0;
    display: none;

    .am-opc-summary--open & {
        display: inherit;
    }

    @include respond-min($bp-tablet-wide-min) {
        padding: 1.5em 0;
        border-top: 1px solid $clr-dy-grey-2;
        display: inherit;
    }
}

.checkout-block__summary__items__title {
    @include fs-responsive((g:14px, gtw:20px));
    color: $clr-dy-grey-7;
    display: inline-block;

    .am-opc-summary--open .checkout-block__summary__items & {
        display: none;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 16px;
        position: relative;
        margin: 0 2px 0 6px;
        top: 4px;
        background-color: $clr-dy-grey-2;
        display: inline-block;

        @include respond-min($bp-tablet-wide-min) {
            display: none;
        }
    }
}

.checkout-block__summary__items__count {
    @include fs-responsive((g:14px, gtw:16px));
    color: $clr-dy-grey-7;
    display: inline-block;

    .am-opc-summary--open .checkout-block__summary__items & {
        display: none;
    }

    @include respond-min($bp-tablet-wide-min) {
        line-height: 28px;
        font-weight: 400;
        padding-top: 3px;
    }

    @include respond-min($bp-desktop-min) {
        float:right;
    }

}

.checkout-block__summary__items__list {
    margin: 2em 0 0;
}

.checkout-block__summary__items__list__product {
    color: $clr-dy-grey-7;
    background: $clr-dy-grey-05;
    border: 1px solid $clr-dy-grey-2;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 1.5em 0;
    margin-bottom: 1.5em;
    position: relative;
    width: 100%;


    .product-image-container {
        width: 25%;
        padding: 0.25em;
    }

    .product-item-details {
        flex: 1;
        padding: 0 1em;

        .product-item-name {
            @include fs-static(20);
            color: $clr-dy-grey-7;
            font-weight: 400;
            word-wrap: break-word;
            padding-bottom: 1em;
        }

        .subtotal {
            display: none;
        }

        .product-item-inner-info {
            border-top: 1px solid $clr-dy-grey-2;
            padding-top: 1em;
            margin-top: 1em;
            color: $clr-dy-grey-7;
            font-family: $f-futura;

            &--price {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }

            &--left {
                float:left;
                margin: 10px 0;
                font-family: $f-futura;
                color: $clr-dy-grey-7;
                @include fs-responsive((g:14px, gtw:16px));
            }

            &--delivery {
                border-top: 0;
                margin-top: 0.25em;
                padding-top: 0;
                border-bottom: 1px solid $clr-dy-grey-2;
                padding-bottom: 1em;
                margin-bottom: 0.5em;
            }

            &--subtotal {
                @include fs-responsive((g:14px, gtw:16px));
                border-top: 0;
                padding-top: 0;
                margin-top: 0.5em;
            }

            .product-item-quantity__value {

                @include respond-min($bp-desktop-min) {
                    float: right;
                }
            }

        }

        .cart-price {
            width: 100%;
            display: block;
            text-align: right;
        }

        .product.options {
            float: left;
            margin-top: 8px;

            strong.subtitle {
                font-weight: 500;
            }

            span.toggle {
                text-decoration: underline;
                cursor: pointer;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            div.content {
                dt.label {
                    background-color: inherit;
                    display: none;
                }
            }
        }
    }
}

.checkout-block__summary__totals {
    display: none;

    .shipping-details {
        display:flex;
        justify-content: space-between;

        > div {
            display: inline-flex;
        }
    }

    .am-opc-summary--open & {
        display: inherit;
        padding-bottom: 2em;
    }

    span.label {
        @include fs-responsive((g:14px, gt:16px));
        color: $clr-dy-grey-7;
    }

    span.value, span.not-calculated, span.price--shipping {
        font-family: $f-futura;
        color: $clr-dy-grey-7;

    }

    span.value {
        margin-right: 5px;
    }

    @include respond-min($bp-tablet-wide-min) {
        display: inherit;
    }

    th, td {
        padding: 0;
        border: 0;
    }

    .totals {
        &.shipping,
        &.sub {
            display: none;
        }

        &.grand {
            display: block;
            width: 100%;
            font-family: $f-futura;

            @include respond-min($bp-tablet-wide-min) {
                padding-bottom: 2em;
            }

            th, td {
                display: inline-block;

                &.mark {
                    @include fs-responsive((g:14px, gtw:18px));
                    color: $clr-dy-grey-7;
                    font-weight: 500;
                    font-family: $f-futura;

                    .am-opc-summary--open & {
                        @include fs-responsive((g:16px, gtw:20px));
                        line-height: 34px;
                    }

                    @include respond-min($bp-tablet-wide-min) {
                        font-weight: 400;
                        line-height: 39px;
                    }

                }

                &.amount {
                    @include fs-responsive((g:14px, gtw:28px));
                    color: $clr-dy-grey-7;
                    font-weight: 500;
                    font-family: $f-futura;

                    .am-opc-summary--open & {
                        @include fs-responsive((g:24px, gtw:20px));
                        float: right;
                    }

                    @include respond-min($bp-desktop-min) {
                        float: right;
                    }
                }
            }
        }

        &.discount {
            display: block;
            width: 100%;
            margin-bottom: 1em;
            th, td {
                color: $clr-dy-grey-7;
                &.mark {
                    display: inline-block;
                    .discount {
                        margin-left: 0.25em;
                    }
                }
                &.amount {
                    float: right;
                }
                .title {
                    font-weight: 400;
                }
            }
        }

        &.discount--no-code {
            display: flex;
            width: 100%;
            margin-bottom: 1em;
            justify-content: space-between;

            .title {
                width: 50%;
                display: inline-block;
            }
        }

        .discount__text {
            display: flex;
            justify-content: space-between;

            @include respond-min($bp-tablet-wide-min) {
                display:block;
            }

            @include respond-min($bp-desktop-min) {
                display: flex;
            }
            .price {
                margin-top: 0;
            }
        }
    }

    .totals-tax-details {
        width: 100%;
        display: block;
        padding-bottom: 0.25em;

        th, td {
            display: inline-block;

            &.mark {
                color: $clr-dy-grey-7;
                font-weight: 400;
            }

            &.amount {
                float: right;
                color: $clr-dy-grey-7;
            }
        }
    }

    .button {
        .am-opc-summary--open & {
            margin-top: 2em;
        }
    }
}


.am-checkout {
    .label {
        display: block;
        @include fs-responsive((g:14px, gtw:16px));
        font-family: $f-futura;
        color: $clr-dy-grey-7;
        margin-bottom: 8px;
        //min-height: 26px; //Commented for ROW7065

    }

    .fieldset {
        padding-bottom: 0;
        #paypal_billing_agreement_ba_agreement_id {
            margin-bottom: 24px;
            @include respond-max($bp-tablet-wide-min) {//max 1024
                margin-bottom: 28px;
            }
        }
        .field {
            color: $clr-dy-grey-7;
            width: 100%;
            float: left;
            margin-bottom: 24px;//ROW7065
            @include respond-max($bp-tablet-wide-min) {//max 1024
                margin-bottom: 28px;//ROW7065
            }

            &.required,
            &._required {
                .label:after {
                    content: '*';
                    margin: 0 0 0 3px;
                    font-size: 1em;
                    color: $clr-dy-grey-7;
                }
            }

            &.field--other {
                display: none;

                &.field--other--show {
                    display: block;
                }

                .field-error {
                    display: none;

                    &.field-error--show {
                        display: block;
                    }
                }
            }

            .control {
                label.input-label-overlay {
                    position: absolute;
                    left: 13px;
                    font-size: 16px;
                    top: 17px;
                    @include respond-max($bp-tablet-wide-min) {//desktop max 1024
                        top: 14px;
                    }
                    & + .input-text {
                        padding-left: 48px;
                    }
                }
            }

            &.street {
                margin-bottom: 0;//ROW7065
                // legend.label {
                //     display: none;
                // }
            }

            // ------- Individual Checkout Fields -------

            &[name="shippingAddress.firstname"],
            &[name="billingAddresscheckmo.firstname"],
            &[name="billingAddresscashondelivery.firstname"],
            &[name="billingAddresspayusubvention.firstname"],
            &[name="billingAddresspayu.firstname"],
            &[name="billingAddressworldpay_cc.firstname"] {
                width: 100%;

                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                    margin-right: 4%;
                }
            }

            &[name="shippingAddress.lastname"],
            &[name="billingAddresscheckmo.lastname"],
            &[name="billingAddresscashondelivery.lastname"],
            &[name="billingAddresspayusubvention.lastname"],
            &[name="billingAddresspayu.lastname"],
            &[name="billingAddressworldpay_cc.lastname"] {
                width: 100%;

                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                }
            }

            &[name="shippingAddress.telephone"],
            &[name="billingAddresscheckmo.telephone"],
            &[name="billingAddresscashondelivery.telephone"] {
                width: 100%;
                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                }
            }

            &[name="shippingAddress.street.0"] {
                .control {
                    width: 100%;

                    @include respond-min($bp-tablet-wide-min) {
                        width: 48%;
                    }
                }
            }

            &[name="shippingAddress.street.1"] {
                .control {
                    width: 100%;

                    @include respond-min($bp-tablet-wide-min) {
                        width: 48%;
                    }
                }
            }

            &[name="shippingAddress.city"] {
                width: 100%;

                @include respond-min($bp-tablet-wide-min) {
                    width: 23%;
                    margin-right: 2%;
                }
            }

            &[name="shippingAddress.postcode"] {
                width: 100%;

                @include respond-min($bp-tablet-wide-min) {
                    width: 23%;
                }
            }

            // SortedPro Fields
            &[name="shippingAddress.other_firstname"] {
                width: 100%;

                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                    margin-right: 4%;
                }
            }

            &[name="shippingAddress.other_lastname"],
            &[name="shippingAddress.other_title"] {
                width: 100%;

                @include respond-min($bp-tablet-wide-min) {
                    width: 48%;
                }
            }
            div.control {
                div.mage-error,
                div.field-error {
                    @include fs-responsive((g:14px, gtw:16px));
                    margin-top: 8px;
                    font-weight: 300;
                    font-size: 14px !important;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }
            &.field--checkboxes {
                padding: 0 0 24px;
                margin: 0;
                @include respond-max($bp-tablet-wide-min) {
                    padding-bottom: 28px;
                }
                &[name='shippingAddress.custom_attributes.activate_guarantee'] {
                    @include respond-max($bp-phablet-wide-min) {//max 640
                        padding: 0 0 28px 0;
                    }
                }
                .form__item--checkbox {
                    margin-bottom: 0;
                }

                input[type="checkbox"] {
                    position: absolute;
                    height: 2rem;
                    width: 2rem;
                    z-index: 1;
                    left: 0;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                }

                input[type="checkbox"],
                input[type="radio"] {
                    display: inline-block;
                    font-family: $f-futura;
                    margin-right: 0.5rem;


                    &:last-child{
                        margin-right: 0;
                    }

                    &:checked ~ label {
                        &:after{
                            opacity: 1;
                        }
                    }
                }

                label {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    //height: 32px;
                    margin-right: 1rem;
                    padding-left: 48px;
                    position: relative;
                    font-weight: normal;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    font-family: $f-futura;
                    color: $clr-dy-grey-7;



                    &:before,
                    &:after {
                        content: '';
                        height: 32px;
                        width: 32px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all .15s ease-in;
                        box-sizing: border-box;
                    }

                    &:before{
                        background-color: $clr-white;
                    }

                    &:after{
                        background-image: url('../images/icons/sprite.png');
                        background-repeat: no-repeat;
                        background-position: 0 -1856px;
                        opacity: 0;
                    }
                }

                &.required,
                &._required {
                    .label:after {
                        content: '';
                        margin: 0;
                    }
                }

                &.field--delivery-to-someone-else {
                    padding-top: 0;
                    padding-bottom: 3em;
                }
            }
        }
    }

    #customer-email-fieldset {
        .field {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
                width: 48%;
            }
        }
        .field-tooltip {
            display: none;
        }
    }

    div.mage-error[generated]:before,
    div.field-error:before {
        margin-right: 4px;
    }

    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"] {
        &:disabled {
            background: #f3f3f3;
            border-color: #e1e1e1;
        }
    }
    // TODO - Copied from checkout.scss - sort out!
    .note,
    .field-tooltip-content,
    .field-tooltip.toggle {
        display: none!important;
    }
}

.rtl { // Row 5534
    .am-checkout {
        .fieldset {
            .field .control {
                label.input-label-overlay {
                    left: auto;
                    right: 13px;
                    & + .input-text {
                        padding-left: 0;
                        padding-right: 48px;
                    }
                    span {
                        unicode-bidi: plaintext;
                    }
                }
            }
        }
    }
}

.checkout-block__progress-button {
    display: inline-block;
    width: 100%;
    float: left;

    .button {
        width: 100%;
        margin-top: 32px;//ROW7065
        margin-bottom: 32px;//ROW7065
        @include respond-min($bp-tablet-wide-min) {
            float: right;
            width: 48%;
        }
    }

    &--tabs {
        border-top: 1px solid $clr-dy-grey-2;
        margin-top: 2em;
    }
}

.checkout-block--payment {
    legend.legend,
    .step-title {
        display: none;
    }
}

// ------ Checkout Payment Tabs ------

.am-checkout {

    .payment-methods {
        .loader {
            img,p {
                display: none;
            }
        }
    }

    .checkout-block__payment-tabs, .payment-tabs__header, .checkout-block__tabs {
        width: 100%;
    }
    .checkout-block__tabs {
        .dyson-in &,
        .dyson-kr & {
            display: none;
        }
    }


    .checkout-block__payment-tabs__header, .checkout-block__tabs__header {
        display: flex;
        flex-wrap: wrap;
        @include respond-min($bp-phablet-wide-min) {//min 640
            padding-bottom: 25px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640
            padding-bottom: 19px;
        }
        @include respond-min($bp-tablet-wide-min) {//1024
            padding-bottom: 2em;
            .dyson-nz & {
                @include respond-max($bp-tablet-wide-min) { //max 1024px;
                    padding-bottom: 24px;
                }
                @include respond-max($bp-phablet-wide-min) {
                    padding-bottom: 16px;
                }
            }
        }

        .payment-method-title  {
            width: 100%;
            padding: 0;
            margin: 0 0 8px 0;
            @include respond-min($bp-tablet-min) {
                width: 49%;
                margin: 0 .5% 1% .5%;
            }

            label {
                @include fs-responsive((g:18px, gtw:20px));
                font-family: $f-futura;
                cursor: pointer;
                color: $clr-dy-grey-7;
                background: $clr-dy-grey-05;
                box-shadow: inset 0 0 0 1px $clr-dy-grey-2;
                width: 100%;
                padding: 1.4em 1em;
                margin-bottom: 0;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                flex-direction: column;

                img {
                    margin: 0.5em 0;
                }

                .action.action-help {
                    @include fs-responsive((g:14px, gtw:16px));
                    text-decoration: underline;
                    margin: 0.5em 0;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }

            &--active {
                label {
                    box-shadow: inset 0 0 0 4px $clr-dy-grey-6;
                }

            }

            input[type="radio"] {
                display: none;
            }
        }
    }
    .tabs__tab--cashondelivery {
        .checkout-billing-address .billing-address-details {
            display: none;
        }
    }
    .payment-method {
        display: none;

        .payment-method-title {
            display: none;
        }

        &._active {
            display: inherit;
        }

        .actions-toolbar {
            display: flex;
            width: 100%;
            padding: 32px 0 2em 0;

            .primary {
                width: 100%;

                .action.checkout {
                    @include button--fluid;
                    @include button--tertiary;
                    @include fs-static(18);
                    font-weight: 500;
                    padding: 1em 0;

                    @include respond-min($bp-tablet-wide-min) {
                        width: 48%;
                    }
                }

                .action-primary {
                    @include button--fluid;
                    @include fs-static(18);
                    font-weight: 500;
                    padding: 1em 0;
                    width: 48%;
                    float: left;
                }

                .action-update {
                    @include button;
                    @include button--fluid;
                    @include fs-static(18);
                    font-weight: 500;
                    width: 48%;
                    float: left;
                }

                .action-cancel {
                    @include button--fluid;
                    @include button--secondary;
                    @include fs-static(18);
                    font-weight: 500;
                    padding: 1em 0;
                    width: 48%;
                    float: left;
                    margin-left: 4%;
                }
            }
        }

        // TODO - TOO GENERAL REMOVE FROM TEMPLATES?
        h3 {
            @include fs-static(20);
            color: $clr-dy-grey-7;
            display: inline-block;
            font-weight: 400;
            padding-top: 1em;
        }

        p {
            margin: 0;
        }

        .field-select-billing {
            display: none;
        }

        .checkout-agreement {
            display: flex;
        }

        .choice,
        .checkout-agreement {
            padding: 1em 0;

            input[type="checkbox"] {
                position: absolute;
                height: 2rem;
                width: 2rem;
                z-index: 1;
                left: 0;
                margin: 0;
                padding: 0;
                opacity: 0;
            }

            input[type="checkbox"],
            input[type="radio"] {
                display: inline-block;
                font-family: $f-futura;
                margin-right: 0.5rem;


                &:last-child{
                    margin-right: 0;
                }

                &:checked ~ label {
                    &:after{
                        opacity: 1;
                    }
                }
            }

            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 32px;
                margin-right: 1rem;
                padding-left: 48px;
                position: relative;
                font-weight: normal;
                -moz-user-select: none;
                -webkit-user-select: none;
                font-family: $f-futura;
                color: $clr-dy-grey-7;


                &[for="billing-address-same-as-shipping-cashondelivery"] {
                    display: none;
                }

                &:before,
                &:after {
                    content: '';
                    height: 32px;
                    width: 32px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: all .15s ease-in;
                    box-sizing: border-box;
                }

                &:before {
                    border: 2px solid $clr-dy-grey-2;
                    background-color: $clr-white;
                }

                &:after {
                    background-image: url('../images/icons/sprite.png');
                    background-repeat: no-repeat;
                    background-position: 0 -1856px;
                    opacity: 0;
                }
            }
        }

        .billing-address-details {
            color: $clr-dy-grey-7;
            width: 100%;

            button {
                @include button;
                @include button--secondary;
                @include fs-static(18);
                font-weight: 500;
                padding: 1em 0;
                width: 48%;
                margin-top: 2em;
            }
        }

        .checkout-agreements-block {
            clear: left;

            .action-show {
                background: transparent;
                border: 0;
                color: $clr-dy-grey-7;
                padding: 0;
                text-align: left;
                &:after {
                    content: '*';
                    font-size: 1em;
                    color: #333333;
                }
                span{
                    text-decoration: underline;
                }
            }
        }

        .payment-method-billing-address {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
                .actions-toolbar {
                    width: 48%;
                    float: right;
                    text-align: right;
                }
            }
        }

    }

}

// ------ Checkout Payment Icons -------

.checkout-payment {
    border-top: 1px solid $clr-dy-grey-2;
    margin-top: 5em;
    margin-bottom: 2em;
    padding: 2em 0;
    @include respond-max($bp-tablet-wide-min) {//max 1024
        border-top: 0;
        margin-top: auto;
    }
}

//ROW2154
.checkout-index-index .am-checkout {
    input[type="text"]:not(.adyen-checkout__input),
    input[type="email"],
    input[type="search"],
    input[type="tel"],
    input[type="url"],
    input[type="number"],
    input[type="password"],
    select[multiple],
    textarea,
    select,
    .form__input {
        //ROW7065
        height: 48px;
        @include respond-min($bp-tablet-wide-min) {//desktop min 1024
            height: 56px;
        }//ends
        border: 1px solid $clr-field-border;
        &:focus {
            border: 2px solid $clr-field-focus-border;
        }
        &.mage-error{
            border-color: $clr-mage-error;
            &:focus{
                border: 2px solid $clr-mage-error;
            }
        }
    }

    .field._error input[type="text"], .field._error input[type="tel"], .field._error input[type="number"], .field._error select {
        border-color: $clr-mage-error;
        &:focus{
            border: 2px solid $clr-mage-error;
        }
    }

    input[type="checkbox"]{
        & + label:before {
            border: 1px solid $clr-field-border;
        }
        &:focus + label:before {
            border: 2px solid $clr-field-focus-border;
        }
    }
}
//ROW7065
.checkout-index-index .am-checkout {
    .opc-wrapper.layout-2-columns.am-opc-wrapper.g-wrap {
        @include respond-max(1024) {
            margin-left: 0;
        }
    }
    .checkout-column.opc.g-col.am-opc-main {
        @include respond-max(1024) {
            padding-left: 0;
        }
    }
    //max 1024
    @include respond-max($bp-tablet-wide-min) {
        .layout {
            padding-left: 16px;
            padding-right: 16px;
        }
        select {
            padding: 0 16px;
            background-position: 0 -1952px; // as we changed height
            background-position-x: right;
        }
        .checkout-payment.g-wrap {
            margin-left: 0;
            .g-col {
                padding-left: 0;
            }
        }
    }
    //max 1024 ends
}
//ROW7065 ends
.control__note {
    margin:8px 0;
    color: #555;
    font-size: 0.75rem;
    //max 1024
    @include respond-max($bp-tablet-wide-min) {
        margin:8px 0 4px 0;
    }
}

//ROW-2711
.am-checkout {
    .payment-method {
        .checkout-agreements-block {
            .action-show:after {
                content: '*';
                display: inline-block;
            }
        }
    }
}

.checkout-agreement.required {
    display: block !important;
}

.checkout-agreement.field.choice.required label.label:after {
    content: '';
}
//ROW-2711 END

//ROW-8017
.checkout-nav__section {
    @include respond-max($bp-phablet-wide-min) {
        display: flex;
        padding-left: 8px;
    }
    @include respond-max($bp-tablet-wide-min) {//max 640 mobile
        padding-left: 8px;
        display:block;
    }
}
.checkout-block__summary__items__title{
    @include respond-max($bp-tablet-wide-min) {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 0;
        color: #333;
        &:after {
            display: none !important;
        }
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        width: 100%;
    }
}
.checkout-block__summary__items__count {
    @include respond-max($bp-tablet-wide-min) {
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.57143;
        color: #555;
        padding-left: 20px;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-left: 0;
    }
}
.checkout-nav__heading-price {
    @include respond-max($bp-tablet-wide-min) {
        font-size: .875rem;
        line-height: 1.57143;
        margin-bottom: 0.5rem;
        color: #555;
        line-height: 1;
        font-weight: 400;
        display: inline-block;
    }
}
.checkout-block_summary_nav__separator {
    @include respond-max($bp-tablet-wide-min) {
        width: 1px;
        height: 1rem;
        position: relative;
        margin: 0 2.5px;
        top: 0.125rem;
        background-color: #555;
        display: inline-block;
    }
}
.checkout-block__summary__header {
    @include respond-max($bp-tablet-wide-min) {
        padding: 16px 0 0.75rem 1.5em;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding: 0.75rem 0 0.75rem 1.80em;
    }
    &:before {
        display: none !important;
    }

}
.am-opc-summary {
    @include respond-max($bp-tablet-wide-min) {
        padding-left: 35px;
        padding-right: 35px;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-left: 20px;
        padding-right: 20px;
    }

}
svg.icon.icon--basket-checkout-nav {
    font-size: 14px;
    height: 24px;
    width: 24px;
    left: 0;
    top: 56%;
    margin-top: -16px;
    position: absolute;
    @include respond-max($bp-phablet-wide-min) {
        top: 43%;
    }

    @include respond-min($bp-tablet-wide-min) {
        display: none;
    }
}
.am-opc-main {
    @include respond-max($bp-tablet-wide-min) {
        padding-top: 57px;
    }
    @include respond-max($bp-phablet-wide-min) {
        padding-top: 74px;
    }
}
//END ROW-8017

//ROW7357 Sprint43
.checkout-payment{
    .payment-icons-title {
        @include respond-min($bp-phablet-wide-min) {//min 640 tab
            margin-bottom: 12px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            margin-bottom: 12px;
        }
        @include respond-min($bp-tablet-wide-min) {//1024 desktop
            margin-bottom: 16px;
        }
    }
    .checkout-payment__image {
        display: flex;
        flex-wrap: wrap;
        .payment-icon-item {
            margin: 0 40px 12px 0;
            img {
                width: 47px;
                height: 32px;
            }
        }
    }
}

//ROW2149 Sprint 50
.accordion-payment-layout{ //body class
    .am-checkout {
        & > .layout {
            padding-right: 0;
            padding-left: 0;
        }
        .am-opc-summary {
            @include respond-max($bp-tablet-wide-min) { //max 1024
                padding-left: 45px;
                padding-right: 45px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                padding-left: 21px;
                padding-right: 21px;
            }
        }
        .checkout-payment.g-wrap {
            @include respond-max($bp-tablet-wide-min) {
                margin-left: 45px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                margin-left: 21px;
            }
        }
        .checkout-block__summary__header:after {
            @include respond-max($bp-tablet-wide-min) {
                right: 0;
            }
        }
        .am-opc-wrapper.g-wrap {
            @include respond-max($bp-tablet-wide-min) {//1024 desktop
                margin-left: 0;
            }
        }
        .am-opc-main {
            @include respond-max($bp-tablet-wide-min) {//1024 desktop
                padding-left: 0;
            }
        }
        .checkout-block__header {
            @include respond-max($bp-tablet-wide-min) {//1024 desktop
                padding: 16px 45px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                padding: 16px 21px;
            }
        }
        .payment-method-selection-title {
            margin-bottom: 16px;
            padding: 0;
            font-weight: normal !important;
        }
        .payment-group-wrapper {
            padding-bottom: 16px;
        }
        .payment-group-title {
            margin-bottom: 8px;
            margin-top: 16px;
            font-weight: 500;
            color: $clr-dy-grey-7;
            line-height: 28px;
            @include fs-responsive((g:16px, gt:16px, gd:18px));
        }
        .payment-group-empty-title {
            visibility: hidden;
            height: 16px;
        }
        .checkout-block__content__inner {
            @include respond-min($bp-phablet-wide-min) {//min 640 tablet
                padding: 10px 45px 24px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                padding: 16px 21px 16px;
            }
            @include respond-min($bp-tablet-wide-min) {//1024 desktop
                padding: 16px 32px 32px; //bottom=32+16 payment-group-wrapper = 48
            }
        }
        .accordion-payment-methods{ //ROW2149 list.html
            .checkout-block__payment-tabs__header {
                padding-bottom: 0;
                .accordion-heading {
                    cursor: pointer;
                    &.cursor-effect {
                        cursor: default;
                        pointer-events: none;
                        .payment-method-name,
                        .payment-method-description {
                            cursor: default;
                        }
                    }
                    &[data-code="free"] {
                        margin: 0 auto !important;
                    }
                    @include respond-min($bp-phablet-wide-min) {//min 640 tablet
                        margin: 0 -45px;
                    }
                    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                        margin: 0 -32px;
                    }
                    @include respond-min($bp-tablet-wide-min) {//1024 desktop
                        margin: 0 -32px;
                    }
                    &.active {
                        .payment-method-title{
                            background: transparent;
                            label {
                                box-shadow: none;
                                background: transparent;
                            }
                            &:after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .payment-method .actions-toolbar {
                    padding-bottom: 48px;
                    @include respond-max($bp-tablet-wide-min) {
                        padding-bottom: 40px;
                    }
                    @include respond-max($bp-phablet-wide-min) {
                        padding-bottom: 32px;
                    }
                }
                .payment-tabs__header .accordion-heading.active + .accordion-content{
                    border-top: 1px solid $clr-dy-grey-2;
                    padding: 0;
                    @include respond-min($bp-phablet-wide-min) {//min 640 tab
                        padding-top: 28px;
                    }
                    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                        padding-top: 28px;
                    }
                    @include respond-min($bp-tablet-wide-min) {//1024 desktop
                        padding-top: 24px;
                    }
                    &:last-child {
                        .actions-toolbar {
                            padding-bottom: 0;
                        }
                    }
                }
                .accordion-heading {
                    &.active .payment-method-title {
                        border-bottom: none;
                    }
                    .payment-method-title {
                        width: 100%;
                        margin: 0;
                        background: $clr-dy-grey-05;
                        box-shadow: none;
                        border-top: 1px solid $clr-dy-grey-2;
                        border-bottom: 1px solid $clr-dy-grey-2;
                        display: flex;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-align-items: center;
                        @include respond-min($bp-phablet-wide-min) {//min 640 tablet
                            padding: 20px 45px;
                        }
                        @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                            padding: 20px 32px;
                        }
                        @include respond-min($bp-tablet-wide-min) {//1024 desktop
                            padding: 20px 32px;
                        }

                        &:after {
                            content: '';
                            display: block;
                            background-image: url('../images/icons/chevron-down.png');
                            width: 20px;
                            height: 20px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin-left: auto;
                        }
                        label{
                            display: block;
                            box-shadow: none;
                            width: 90%;
                            padding: 0;
                        }
                        .payment-method-logo {
                            width: 48px;
                            height: 32px;
                            @include respond-min($bp-phablet-wide-min) {//min 640 tablet
                                margin-right: 20px;
                            }
                            @include respond-max($bp-tablet-min) { // max 768 tablet
                                width: 32px;
                                height: 20px;
                            }
                            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                                margin-right: 16px;
                            }
                            @include respond-min($bp-tablet-wide-min) {//1024 desktop
                                margin-right: 24px;
                            }
                        }
                        .payment-method-name {
                            display: block;
                            text-align: left;
                            @include fs-responsive((g:14px, gt:16px));
                            line-height: 22px;
                            font-weight: 500;
                        }
                        .payment-method-description {
                            @include fs-responsive((g:14px, gt:16px));
                            line-height: 24px;
                            color: #555;
                            text-align: left;
                            display: block;
                        }
                    }
                }
                .no-accordion {
                    .accordion-heading {
                        .payment-method-title {
                            &:after {
                                content: '';
                                background-image: none;
                            }
                        }
                    }
                }
            }
        }
        .zero-checkout-message {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
.rtl{
    .checkout-block__header:after{
        margin-right: auto;
        margin-left: 0;
    }
    &.accordion-payment-layout{ //body class
        .am-checkout {
            .accordion-payment-methods .checkout-block__payment-tabs__header{
                .payment-method-title::after {
                    margin-left: 0;
                    margin-right: auto;
                }
                .accordion-heading {
                    .payment-method-title {
                        display: flex;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-align-items: center;
                        .payment-method-name,
                        .payment-method-description {
                            text-align: right;
                        }
                        .payment-method-logo{
                            margin-right: 0;
                            @include respond-min($bp-phablet-wide-min) {//min 640 tablet
                                margin-left: 20px;
                            }
                            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                                margin-left: 16px;
                            }
                            @include respond-min($bp-tablet-wide-min) {//1024 desktop
                                margin-left: 24px;
                            }
                        }
                    }
                }
                .adyen-checkout__field,
                .billing-address-same-as-shipping-block label,
                .checkout-agreement label {
                    margin-right: 0;
                }
            }
        }
    }
}
// end ROW2149 sprint 50
.am-checkout {
    .field.custom-country-label {
        width: 100% !important;
    }
    .field.custom-country-label .label{
        margin-bottom: 0px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
        font-family: $f-futura;
        @include respond-min($bp-tablet-wide-min) {
            font-size: 16px;
        }
        @include respond-max($bp-tablet-wide-min) {
            font-size: 14px;
        }
        span {
            @include respond-min($bp-tablet-wide-min) {
                font-size: 16px !important;
            }
            @include respond-max($bp-tablet-wide-min) {
                font-size: 14px !important;
            }
        }
    }
    .payment-method .actions-toolbar {
        padding: 0 0 2em 0;
    }
}
@include respond-max($bp-tablet-wide-min) {//max 640 mobile
    .payment-method-content {
        .fieldset {
            .field {
                input,
                select {
                    max-width: 100%;
                }
            }
        }
    }
}

//row-11158 sprint 53
.am-checkout {
    div.items.payment-methods {
        div.payment-method {
            .actions-toolbar {
                padding-top: 0px;
            }
            .checkout-agreement {
                padding: 0;
                .label {
                    margin-bottom: 0px;
                }
            }
            .checkout-agreement,
            .billing-address-details {
                @include respond-min($bp-phablet-wide-min) {//min 640 tab
                    margin-bottom: 28px !important;
                }
                @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                    margin-bottom: 28px !important;
                }
                @include respond-min($bp-tablet-wide-min) {//1024 desktop
                    margin-bottom: 24px !important;
                }
            }
            .checkout-billing-address {
                .billing-address-same-as-shipping-block {
                    padding: 0;
                    color: $clr-black;
                    font-family: $f-futura;
                    font-weight: 300;
                    letter-spacing: 0;
                    @include respond-min($bp-phablet-wide-min) {//min 640 tab
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 28px;
                    }
                    @include respond-max($bp-phablet-wide-min) { //max 640 mobile
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 28px;
                    }
                    @include respond-min($bp-tablet-wide-min) { //1024 desktop
                        font-size: 18px;
                        line-height: 28px;
                        margin-bottom: 24px;
                    }
                }
                .actions-toolbar {
                    @include respond-min($bp-phablet-wide-min) {
                        padding: 14px 0 42px !important;
                    }
                    @include respond-max($bp-phablet-wide-min) {
                        padding: 4px 0 32px !important;
                    }
                    @include respond-min($bp-tablet-wide-min) {
                        padding: 24px 0 48px 0 !important;
                    }
                    .primary {
                        width: 100%;
                        .action-update {
                            border: 1px solid #333;
                            padding: 1em 0;
                            @include respond-max($bp-phablet-wide-min) {
                                width: 100%;
                                margin-bottom: 16px;
                            }
                        }
                        .action-cancel {
                            @include respond-max($bp-phablet-wide-min) {
                                width: 100%;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .field {
        .label {
            font-weight: 500;
            @include respond-min($bp-phablet-wide-min) {//min 640 tab
                line-height: 20px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                line-height: 20px;
            }
            @include respond-min($bp-tablet-wide-min) {//1024 desktop
                line-height: 24px;
            }
        }
    }
    .control__note {
        color: #555555;
        display: block;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
    }
}

//Row 10692 Klarna payment sprint53
.klarna-block {
    font-family:  $f-futura; //ROW-6520-font-family-updates-across-site
    margin-top: 16px;
    margin-bottom: 32px;
    @include respond-max($bp-tablet-wide-min) {
        margin-top: 20px;
    }
    @include respond-max($bp-phablet-wide-min) {
        margin-top: 12px;
    }
    .klarna-logo {
        width: auto;
        height: 64px;
        margin-bottom: 24px;
        @include respond-max($bp-tablet-wide-min) {
            margin-bottom: 20px;
        }
        @include respond-max($bp-phablet-wide-min) {
            margin-bottom: 16px;
        }
    }
    h6 {
        color: $clr-dy-grey-7;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 8px;
        margin-top: 0 !important;
    }
    .pay-30-days {
        color: $clr-dy-grey-66;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
    }
    .klarna-text-box {
        border: 1px solid $clr-dy-grey-2;
        background-color: $clr-dy-grey-05;
        margin:  24px 0;
        padding: 24px;
        color: $clr-dy-grey-66;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        @include respond-max($bp-tablet-wide-min) {
            margin:  20px 0;
        }
        @include respond-max($bp-phablet-wide-min) {
            margin:  16px 0;
        }
        ul li {
            list-style: disc;
        }
    }
    .agreement-text {
        color: $clr-dy-grey-66;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        padding-bottom: 24px;
        @include respond-max($bp-tablet-wide-min) {
            padding-bottom: 20px;
        }
        @include respond-max($bp-phablet-wide-min) {
            padding-bottom: 16px;
        }
        a {
            text-decoration: underline;
        }
    }
}

// ROW 7342 apple CTA hide for all browser except safari in apple device

.apple-button {
    .payment-tabs__header {
        #applepay {
            display: none ;
        }
    }
}
.apay-logo {
    padding: 16px 0 40px 0;
    @include respond-max($bp-tablet-wide-min){
        padding-top: 12px;
    }
    @include respond-max($bp-phablet-wide-min){
        padding: 4px 0 32px 0;
    }
    .apple-pay-mark {
        width: auto;
        height: 40px;
    }
    h6 {
        margin-top: 0;
        margin-bottom: 8px;
        padding-top: 24px;
        color: $clr-dy-grey-66;
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        @include respond-max($bp-tablet-wide-min){
            padding-top: 20px;
        }
        @include respond-max($bp-phablet-wide-min){
            padding-top: 16px;
        }
    }
    .body-small {
        color: $clr-dy-grey-66;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
    }
}
#adyen-alternative-payment-container-applepay {
    position: relative;
    width: 48%;
    margin-top: 32px;
    @include respond-max($bp-tablet-wide-min){
        width: 100% !important;
    }
    .adyen-checkout__applepay__button {
        width: 100% !important;
        height: 64px !important;
        max-width: none;
        background: $clr-black;
        color: $clr-white;
    }
    &:hover {
        background: $clr-black;
    }
}
.apay-button-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    cursor: pointer;
    span {
        color: $clr-white;
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
    }
    .apple-pay-white {
        width: auto;
        height: 19px;
    }
}

// end ROW-7342

.checkout-index-index .checkout-payment .checkout-payment__image {
    list-style-type: none;
    padding: 0;
}

// ROW-4699 start
.am-checkout{
    .checkout-block__content__inner {
        padding-top: 0px;
    }
    .payment-option-title {
        color: $clr-dy-grey-7;
        font-family: $f-futura;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 24px;
        text-decoration: underline;
        cursor: pointer;
        #block-discount-heading {
            border: none;
            padding: 0;
            background-color: $color-gray-darken0;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .form-discount .label {
        color: $clr-dy-grey-7;
        font-family: $f-futura;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        @include respond-max($bp-tablet-wide-min) {
            font-size: 14px;
        }
    }
    .discount-code:not(._active){
        .payment-option-title:not(.coupon-applied) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
        .payment-option-title:not(.coupon-not-applied) {
            margin-top: 24px;
        }
        @include respond-max($bp-tablet-wide-min) {
            .payment-option-title:not(.coupon-applied) {
                margin-top: 0px;
                margin-bottom: 40px;
            }
            .payment-option-title:not(.coupon-not-applied) {
                margin-top: 0px;
            }
        }
        @include respond-max($bp-phablet-wide-min) {
            .payment-option-title:not(.coupon-applied) {
                margin-top: 0px;
                margin-bottom: 32px;
            }
            .payment-option-title:not(.coupon-not-applied) {
                margin-top: 0px;
            }
        }
    }
    .discount-code._active{
        .payment-option-title:not(.coupon-applied) {
            margin-top: 24px;
            margin-bottom: 32px;
        }
        .payment-option-title:not(.coupon-not-applied) {
            margin-top: 24px;
        }
        @include respond-max($bp-tablet-wide-min) {
            .payment-option-title:not(.coupon-applied) {
                margin-top: 0px;
                margin-bottom: 28px;
            }
        }
        @include respond-max($bp-tablet-wide-min) {
            .payment-option-title:not(.coupon-applied) {
                margin-top: 0px;
            }
        }
        @include respond-max($bp-tablet-wide-min) {
            .payment-option-title:not(.coupon-not-applied) {
                margin-top: 0px;
            }
        }
        margin-bottom: 48px;
        @include respond-max($bp-tablet-wide-min) {
            margin-bottom: 40px;
        }
        @include respond-max($bp-phablet-wide-min) {
            margin-bottom: 32px;
        }
        .messages .error {
            margin: 8px 0 0 0;
            padding: 0;
            background: none;
            color: $clr-dy-red-1;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20px;
            >div:before {
                display: inline-block;
                content: "!";
                border-radius: 50%;
                border: 2px solid $clr-dy-red-1;
                width: 15px;
                height: 15px;
                line-height: 15px;
                text-align: center;
                color: $clr-dy-red-1;
                font-weight: bold;
                font-size: 14px;
                margin-right: 8px;
            }
        }
        .messages .success {
            margin: 8px 0 0 0;
            padding: 0;
            background: none;
            color: $clr-shade-off-green;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20px;
            border: none;
            display: flex;
        }
    }
    .payment-option-wrap{
        display: flex;
        .actions-toolbar{
            line-height: 14px;
        }
        .actions-toolbar .action-apply{
            color: $clr-white;
            font-family: $f-futura;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            background-color: $clr-black;
            border: $clr-black;
            cursor: pointer;
            height: 62px;
            width: 100%;
            min-width: 120px;
            @include respond-max($bp-phablet-wide-min) { //max 640 mobile
                min-width: 100px;
            }
        }
        .actions-toolbar .action-cancel{
            border: none;
            padding: 0;
            background-color: transparent;
            text-decoration: underline;
            cursor: pointer;
            height: 20px;
            color: $clr-dy-grey-66;
            font-family: $f-futura;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 14px;
        }
        .control #discount-code{
            box-sizing: border-box;
            height: 62px;
            border: 1px solid $clr-field-border;
            background-color: $clr-white;
            color: $color-gray20;
            &:focus{
                border: 1px solid $clr-field-border;
            }
        }
        .payment-option-inner {
            width: 40%;
            @include respond-max($bp-tablet-wide-min) {
                width: 100%;
            }
        }
    }
    #discount-form {
        display: block;
        input[type="text"]{
            &:focus{
                border: 1px solid $clr-field-border;
            }
            &.mage-error{
                border: 1px solid $clr-mage-error;
            }
        }
    }
    .coupon-applied-mssg {
        color: $clr-shade-off-green;
        font-family: $f-futura;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        margin-top: 8px;
        margin-bottom: 8px;
        display: inline-flex;
        > span {
            margin: 0px 3px 0px 0px;
        }
    }
    span.coupoun-rounded-icon {
        display: inline-flex;
        border-radius: 50%;
        border: 1px solid $clr-shade-off-green;
        height: 18px;
        width: 18px;
        text-align: center;
        position: relative;
        border-color: $clr-shade-off-green;
        color: $clr-shade-off-green;
        margin: 0px 8px 0px 0px;
    }
    span.coupoun-rounded-icon::after {
        content: "";
        width: 4px;
        height: 8px;
        border: solid $clr-shade-off-green;
        border-width: 0 1px 1px 0;
        position: absolute;
        -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
        transform: translateY(-50%) rotate(45deg) scale(1);
        top: 7px;
        left: 6px;
    }
    #discount-code-error{
        margin: 8px 0 0 0;
        padding: 0;
        background: none;
        color: $clr-dy-red-1;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
    }
}
.accordion-payment-layout .am-checkout .checkout-block__content__inner {
    padding-top: 0px !important;
}
.checkout-block--details h2.checkout-block__content__subheading {
    @include respond-min($bp-tablet-wide-min) { //1024 desktop
        padding-top: 16px;
    }
}
.rtl {
    .am-checkout{
        .discount-code._active{
            .messages .success {
                >div:before {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
            .messages .error {
                >div:before {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
        }
        span.coupoun-rounded-icon {
            margin: 0px 0px 0px 8px;
        }
    }
    .coupon-applied-mssg {
        > span {
            margin: 0px 0px 0px 3px;
        }
    }
}
// ROW-4699 end

.checkout-index-index {
    .modals-overlay {
        background-color: $clr-modal-overlay;
    }
}

// ROW-13705-Grabpay Payment methods start
.checkout-index-index {
    .payment-method-banner-image {
        width: 48%;
        margin: 24px 0 48px 0;
        @include respond-max($bp-tablet-wide-min) {
            margin: 12px 0 40px 0;
        }
        @include respond-max($bp-tablet-min) {
            width: 100%;
            margin: 4px 0 32px 0;
        }
    }
    &.click-collect-store {
        .payment-method-banner-image {
            margin-top: 0;
        }
    }
}
// ROW-13705-End
