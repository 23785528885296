.icon-info-set {

  &.icon-info-set--cart > .row {
    background: $clr-dy-grey-05;
    border-bottom: 1px solid $clr-dy-grey-2;
    padding: 1em 0;
    max-height: 108px;
    .slider__cart__icons {
      visibility: hidden;
      .icon-info__title {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}

.icon-info-set__title {

  .icon-info-set--offset-title & {
    @extend .h-large-margin;
    margin-top: 0 !important;
  }
}

.icon-info-set__list {
  //margin-top: -$v-tiny;
}

.icon-info-set__item {
  //padding-top: $v-tiny;
  text-align: center;
  // @include vspace($v-tiny,$v-small,$v-small,$dir:top);
}

.icon-info-set__copy {
  text-align: center;
}
