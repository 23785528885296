body.rtl {
  .message.cookie {
    .actions {
      right: inherit;
      left: 20px;

      @include respond-min($bp-desktop-min) {
        left: 0;
      }
    }

    .content {
      padding-right: 1.9em;
      padding-left: (($content-padding_px / $font-size__base) * 1em) + ($action-padding_em * 2) + (($action-size_px / $font-size__base) * 1em); // Ensure there's enough padding for the close action
    }
  }
}
