$hero-breakpoints: (g:0, gt:$bp-tablet-min, gp:$bp-phablet-min, gtw:$bp-tablet-wide-min, gd:$bp-desktop-min); // mimic grid breakpoints

.hero {
  >button {
    @include button-video;
  }
  position: relative;


  &--gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,e5ebee+35,d7dee3+45,c8ccd1+62,f7fbff+77,f5f7f9+100 */
    background: linear-gradient(to bottom, #f6f8f9 0%,#e5ebee 35%,#d7dee3 45%,#c8ccd1 62%,#f7fbff 77%,#f5f7f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &--dark {
    background: $clr-black;
  }

  // hacky fix to the impossible problem of clearing absolutes
  &--product-group {
    overflow: hidden;
    @include respond-min($bp-tablet-min) { padding-bottom: 20%; }
    @include respond-min($bp-tablet-wide-min) { padding-bottom: 0; }
    @include respond-min($bp-desktop-min) { padding-bottom: 11%; }
    @include respond-min($bp-desktop-wide-min) { padding-bottom: 15%; }
    @include respond-min($bp-desktop-super-wide-min) { padding-bottom: 20%; }

  }
}

.hero__url {
  @include link-reset;
  display: block;
}

.hero__button {
  width: 100%;

  .notify & {
    @include respond-min($bp-tablet-wide-min) {
      width: 50%;
    }
  }
}

.hero__media {
  text-align: left;

  &--stretch {
    width: 100%;
  }
}

.hero--hide {
  &--gt {
    display: none;
    @include respond-min($bp-tablet-min) { display: inherit; }
  }
}

@each $bp-code, $bp in $hero-breakpoints {
  .hero__media--left--#{$bp-code} { @include respond-min($bp) { text-align: left; } }
  .hero__media--center--#{$bp-code} { @include respond-min($bp) { text-align: center; } }
  .hero__media--right--#{$bp-code} { @include respond-min($bp) { text-align: right; } }

  .hero__media--absolute--#{$bp-code} { @include respond-min($bp) { position: absolute; z-index: -1; top: 0;} }
  .hero__media--static--#{$bp-code} { @include respond-min($bp) { position: static; } }

  .hero__media--stretch--#{$bp-code} { @include respond-min($bp) { width: 100%; } }
  .hero__media--auto--#{$bp-code} { @include respond-min($bp) { width: auto; } }
}

.hero__body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero__body__inner {
  position: relative;
  height: 100%;
}

.hero--product-variant__inner--perfect {

  .hgroup__image {
    margin-bottom: 0;
  }

  @include respond-min($bp-desktop-min) {
    display:flex;
  }
}

.hero__content {
  @include fs-responsive((g:16, gtw:20));
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  // max-width: 100%;
  @include respond-min($bp-mobile-wide-min) {
    max-width: 100%;
  }

  // special block quote versions of hero styles
  blockquote {
    text-align: inherit;

    .hgroup__prefix {
      @include respond-min($bp-tablet-wide-min) {
        @include fs-static(24);
        margin-bottom: 0;
      }
    }
    cite {
      @include respond-min($bp-tablet-wide-min) {
        margin-top: 0;
      }
      p {
        @include respond-min($bp-tablet-wide-min) {
          @include fs-static(20);
          margin-top: 0;
        }
      }
    }
  }
}

@each $bp-code, $bp in $hero-breakpoints {
  .hero__body--inline--#{$bp-code} {
    @include respond-min($bp) {
      position: static;

      .hero__content {
        position: static;
      }
    }
  }

  .hero__body--absolute--#{$bp-code} {
    @include respond-min($bp) {
      position: absolute;

      .hero__content {
        position: absolute;
      }
    }
  }
  .hero__body--relative--#{$bp-code} {
    @include respond-min($bp) {
      position: relative;

      .hero__content {
        position: absolute;
      }
    }
  }

  .hero__body--padding-boost--#{$bp-code} {
    @include respond-min($bp) {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
  }
}

// Responsive content helpers
@each $bp-code, $bp in $hero-breakpoints {

  // CONTENT
  // vertical
  .hero__content--top--#{$bp-code} { @include respond-min($bp) { top: 0; transform: translateY(0); } }
  .hero__content--topper--#{$bp-code} { @include respond-min($bp) { top: 5%; bottom: auto; transform: translateY(0);} }
  .hero__content--middleup--#{$bp-code} { @include respond-min($bp) { top: 30%; bottom: auto; transform: translateY(-50%); } }
  .hero__content--middle--#{$bp-code} { @include respond-min($bp) { top: 50%; bottom: auto; transform: translateY(-50%); } }
  .hero__content--middledown--#{$bp-code} { @include respond-min($bp) { top: 70%; bottom: auto; transform: translateY(-50%); } }
  .hero__content--bottomer--#{$bp-code} { @include respond-min($bp) { top: auto; bottom: 5%; transform: translateY(0); } }
  .hero__content--bottom--#{$bp-code} { @include respond-min($bp) { top: auto; bottom: 0; transform: translateY(0); } }

  // horizontal
  .hero__content--left--#{$bp-code} { @include respond-min($bp) { left: 0; right: auto; } }
  .hero__content--lefter--#{$bp-code} { @include respond-min($bp) { left: 10%; right: auto; } }
  .hero__content--center--#{$bp-code} { @include respond-min($bp) { left: 0; right: 0; margin: 0 auto; } }
  .hero__content--righter--#{$bp-code} { @include respond-min($bp) { left: auto; right: 10%; } }
  .hero__content--right--#{$bp-code} { @include respond-min($bp) { left: auto; right: 0; } }

  // widths px
  .hero__content--full--#{$bp-code} { @include respond-min($bp) { width: 100%; } }
  .hero__content--1024--#{$bp-code} { @include respond-min($bp) { width: 1024px; } }
  .hero__content--860--#{$bp-code} { @include respond-min($bp) { width: 860px; } }
  .hero__content--820--#{$bp-code} { @include respond-min($bp) { width: 820px; } }
  .hero__content--660--#{$bp-code} { @include respond-min($bp) { width: 660px; } }
  .hero__content--620--#{$bp-code} { @include respond-min($bp) { width: 620px; } }
  .hero__content--560--#{$bp-code} { @include respond-min($bp) { width: 560px; } }
  .hero__content--520--#{$bp-code} { @include respond-min($bp) { width: 520px; } }
  .hero__content--460--#{$bp-code} { @include respond-min($bp) { width: 460px; } }
  .hero__content--420--#{$bp-code} { @include respond-min($bp) { width: 420px; } }
  .hero__content--360--#{$bp-code} { @include respond-min($bp) { width: 360px; } }
  .hero__content--320--#{$bp-code} { @include respond-min($bp) { width: 320px; } }
  .hero__content--290--#{$bp-code} { @include respond-min($bp) { width: 290px; } }
  .hero__content--260--#{$bp-code} { @include respond-min($bp) { width: 260px; } }

  // widths percent
  .hero__content--25pc--#{$bp-code} { @include respond-min($bp) { width: 25%; } }
  .hero__content--33pc--#{$bp-code} { @include respond-min($bp) { width: 33.33%; } }
  .hero__content--40pc--#{$bp-code} { @include respond-min($bp) { width: 40%; } }
  .hero__content--50pc--#{$bp-code} { @include respond-min($bp) { width: 50%; } }
  .hero__content--66pc--#{$bp-code} { @include respond-min($bp) { width: 100%; } }
  .hero__content--75pc--#{$bp-code} { @include respond-min($bp) { width: 75%; } }
  .hero__content--83pc--#{$bp-code} { @include respond-min($bp) { width: 83%; } }

  // alignment
  .hero__content--text-left--#{$bp-code} { @include respond-min($bp) { text-align: left; } }
  .hero__content--text-center--#{$bp-code} { @include respond-min($bp) { text-align: center; } }
  .hero__content--text-right--#{$bp-code} { @include respond-min($bp) { text-align: right; } }

  .dyson-nz.catalog-product-view {
     .hgroup {
      .hero__content--66pc--#{$bp-code} { @include respond-min($bp) { width: 100%; } }
      a.button.button--large.button--cart.card__addtocart.product__variant__add-to-cart--animated-smaller{
        width: 75%;
      }
    }
  }

}


// special heroes
.hero--product-variant--perfect {
    background: #f2f2f2; /* Old browsers */
    background: -moz-linear-gradient(top, #f2f2f2 30%, #ffffff 33%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f2f2f2 30%,#ffffff 33%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f2f2f2 30%,#ffffff 33%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

  .hgroup__main-image {
    @include respond-min($bp-tablet-min) {
      float: right;
      width: 35%;
    }

    @include respond-min($bp-desktop-min) {
      // position: absolute;
      // left: 31%;
      // top: -71px;
      width: 33%;
      order: 2;
    }
  }
  // assume product logos
  .hgroup__image {
    img {
      max-width: 50%;
      margin-top: 20px;
    }
  }

  .product__action {
    .button {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

// special heroes
.hero--product-variant {
  // assume product logos
  .hgroup__image {
    img {
      max-width: 70%;
      margin-top: 20px;

      // mobile hero hack to emulate proper leap, kicks content below absolutely positioned image
      margin-bottom: 100%;

      // tweaks on phablet
      @include respond-min($bp-phablet-min) {
        margin-bottom: 130%;
        max-width: 70%;
      }

      // tweaks on phablet-wide
      @include respond-min($bp-phablet-wide-min) {
        margin-bottom: 155%;
      }

      // reset on tablet
      @include respond-min($bp-tablet-min) {
        max-width: 50%;
        margin-bottom: 0;
      }
    }
  }

  .product__action {
    .button {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.hero--product-variant {

  @include respond-min($bp-tablet-min) {
    padding-bottom: 10%;
  }
  @include respond-min($bp-tablet-wide-min) {
    padding-bottom: 5%;
  }
  @include respond-min($bp-desktop-min) {
    padding-bottom: 7%;
  }
  @include respond-min($bp-desktop-wide-min) {
    padding-bottom: 12%;

    // Product-specific spacing fix
    .product-dyson-v8-animal-plus & {
      padding-bottom: 8%;
    }
  }
}

.hero--product-group {
  .product__logo {
    img {
      max-width: none;

      .dyson-kr &,
      .dyson-gv &,
      .dyson-decgv &,
      .dyson-in &,
      .dyson-mx &,
      .dyson-nz &,
      .dyson-tr & {
        max-width: 100%;
      }
    }
  }
}

.hero--product-group-airblade-tap {
  @include respond-min($bp-tablet-min) {
    padding-bottom: 2%;
  }
}

.hero--product-group-airblade-generic {
  @include respond-min($bp-tablet-min) {
    padding-bottom: 5%;
  }
}

.product__basket.product__basket__preorder-container {
  position: relative;
  padding-bottom: 30px;

  @include respond-min($bp-tablet-wide-min) {
    padding-bottom: 0;
  }
}
.product__basket.product__basket__preorder-container .card__action__item__product-amasty-note {
  font-weight: bold;
  font-size: 14px;
}

.hero__pricing__sold-out {
  font-style: normal;
  @include fs-responsive($fs-body-small-bp);
  position: relative;
  width: 100%;

  .hero__content__preorder-container & {
    margin-top: 70px;

    @include respond-min($bp-tablet-min) {
      margin-top: 50px;
    }
  }
}

//promo code sign up hero fixes - needed so promo code signup rows render correctly when child templates are used in Dyson_Notify in app/design
.hero__body--relative--gd--promo{
  z-index: 0;
}
