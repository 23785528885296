body.rtl {

  .promo-signup__body-text,
  .actions.form__item.promo-signup__button,
  .promo-notify__details,
  .promo_notify__button,
  .promo-notify__privacy-policy {
    text-align: right;
  }

  .mage-error {
    text-align: right;
    width: 100%;
  }

  .form__label--inner {
    margin: 0 .5rem 0 0;
    position: absolute;
    right: 0;
    text-align: right;
    width: 119px;

    &[for="first_name"]{
      width: 62px;
    }

    &[for="last_name"]{
      width: 65px;
    }

    &[for="phone_number"]{
      width: 90px;
    }
  }


  .form__item--checkbox {
    input[type="checkbox"]{
      left: inherit;
      right: 0;
    }

    .form__label{
      margin-right: 0;
      margin-left: 1rem;
      padding-left: 1rem;
      padding-right: 48px;
      text-align: right;

      &:before,
      &:after {
        left: inherit;
        right: 0;
      }
    }
  }

  input[type="radio"] {
    margin-right: inherit;
    margin-left: 0.5em;
  }

  .form__item--recommend--radio,
  .form__item--registered--radio {
    label {
      margin-right: inherit;
      margin-left: 25px;
    }
  }

  select {
    background-position-x: 8px;
  }

  div.mage-error,
  div.field-error {
    &:before {
      margin-right: inherit;
      margin-left: 8px;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin-right: inherit;
    margin-left: 0.5em;
  }
}

.dyson-ae.rtl {
  .hero__body--relative--gd--promo .form__label.form__label--inner.label {
    position: absolute;
    right: 0;
    text-align: right;
    width: 119px;
    background-color: red;
  }
}

.dyson-il.rtl {
  .form__label--inner {
    margin: 0 .5rem 0 0;
    position: absolute;
    right: 0;
    text-align: right;
    width: 119px;

    &[for="first_name"]{
      width: 100px;
    }

    &[for="last_name"]{
      width: 100px;
    }

    &[for="phone_number"]{
      width: 100px;
    }
  }
}
