$fs-hgroup-title: (g:28px, gt:32px);
$fs-hgroup-title-lead: (g:32px, gt:44px);

.hgroup {

  &--dark {
    color: $clr-white;
  }
}
.hgroup--reduce {
  p {
    @include fs-static(16);
    font-family: $f-fallback;
  }
  li {
    @include fs-static(16);
    font-family: $f-fallback;
  }
}

.hgroup__prefix {
  @include fs-static(18);
  color: $clr-dy-grey-6;
  font-family: $f-futura;
  margin-bottom: 0;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  .hgroup--dark & {
    color: $clr-white;

    &:visited {
      color: $clr-white;
    }
  }

  &--lead {
    @include fs-responsive((g:20, gt:24));
  }

  .hgroup--lead & {
    @include fs-responsive((g:20, gt:24));
  }
}

.hgroup__image {
  margin: 1em 0;
  text-align: inherit;
}

.hgroup__title--pink h3 {
  color: #c4398d;
}

.hgroup__title {
  @include fs-responsive($fs-hgroup-title);
  margin: 0.4em 0;
  font-family: $f-futura;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-wide-min) {
    margin: 1em 0 0.4em 0;
  }

  .hgroup--dark & {
    color: $clr-white;
  }

  .hgroup--lead & {
    @include fs-responsive($fs-hgroup-title-lead);
    line-height: $lh-condensed;
  }
}

.hgroup__prefix + .hgroup__title {
  margin-top: 0.25em;
}

.hgroup__subtitle {
  margin: 0.4em 0;
}

.hgroup__content {
  margin-top: 0.8em;
  color: $clr-dy-grey-7;
  font-family: $f-fallback;

  .hgroup--dark & {
    color: $clr-white;
  }

  // probably not needed
  // &--lead {
  //   @include fs-responsive($fs-lead-bp);
  // }
}

.hgroup__action {
  width: 100%;

  &--sml { max-width: 220px; }
  &--med { max-width: 320px; }
  &--lrg { max-width: 420px; }

  a {
    .hgroup--dark & {
      color: $clr-white;
      &:visited {
        color: $clr-white;
      }
    }
  }
}
