/*
  MEDIA
  Videos and iframes
*/

video { // html5 element
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
  max-width: 100%;
}

// Fluid media (youtube, google maps, etc.)
// http://webdesignerwall.com/tutorials/css-elastic-videos
.fluid-media {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; // 16:9

  &--4by3 { padding-top: 75%; } // 4:3

  iframe,
  object,
  embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
