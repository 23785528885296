$fs-testimonial-cite-name-bp: $fs-h3-bp;

.testimonial {

  &--dark {
    color: $clr-white;
  }
}

.testimonial__image {
  text-align: center;
  margin-bottom: $v-minuscule;
  img {
    height: 5rem;
  }
}

.testimonial__blockquote {
  margin: 0;
}

.testimonial__blockquote__quote {
//  color: $clr-black;
  font-family: $f-futura;
  font-weight: 300;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  .testimonial--dark & {
    color: $clr-white;
  }

  .testimonial--small & {
    @include fs-responsive($fs-h4-bp);
  }
}

.testimonial__cite {
  @include vspace($v-minuscule,$v-tiny,$dir:top);
  max-width: $l-width-girdle;
  margin: 0 auto;

  &:before {
    content: "";
    display: block;
    border-top: 1px solid $clr-white;
    width: 130px;
    margin: 0 auto;
    @include vspace($v-minuscule,$v-tiny,$dir:top);

    .testimonial--no-separator & {
      display: none;
    }
  }
}

.testimonial__cite__title,
.testimonial__cite__text,
.testimonial__cite__url {
  @include vspace($v-minute,$dir:top);

  &:first-child { @include vspace(0,$dir:top); }
}

.testimonial__cite__title {
  display: block;
  font-weight: normal;
  color: $clr-black;
  @include fs-responsive($fs-testimonial-cite-name-bp);

  .testimonial--dark & {
    color: $clr-white;
  }

  .testimonial--small & {
    @include fs-responsive($fs-h6-bp);
  }
}

.testimonial__cite__text {
  display: block;
  font-style: normal;

  .testimonial--dark & {
    color: $clr-dy-grey-5;
  }
}

.testimonial__cite__url {
  display: block;

  .testimonial--small & {
    @include fs-static($fs-small);
  }

  a {
//    color: $clr-black;

    .testimonial--dark & {
      color: inherit;
    }
  }
}
