// ------- AMASTY SPC: DYSON-HU SPECIFIC -------

.dyson-hu {

  //t's and c's
  .terms-link {
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .basket__checkout__payment {
    width: 250px;
    margin: 0 auto;

    @include respond-min($bp-tablet-min) {

    }

    @include respond-min($bp-tablet-wide-min) {

    }
  }

  .basket__checkout button {
    margin-top: 30px;

    @include respond-min($bp-tablet-min) {
      margin-top: 0;
    }
  }

  .checkout-methods-items {
    flex-direction: column;
  }

  //checkout agreement
  .checkout-agreement {
    margin-bottom: 30px;
  }

  .hu-checkout-caveat {
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .am-checkout .checkout-block__payment-tabs__header label[for="bigfishpaymentgateway_pmgw_otparuhitel"],
  .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .payment-method-title .label[for="bigfishpaymentgateway_pmgw_otparuhitel"] {
    display:inline-block;

    &:after {
      content: '*';
    }
  }

  .am-checkout .payment-method .checkout-agreements-block .action-show {
    text-decoration: none !important;
  }

  .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .checkout-agreement .action-show {
    text-decoration: none !important;
  }

  // SPC shipping address fields styling tweaks:
  .am-checkout {

    // Sorts out 'Address Line 1'/'Cím 1'
    fieldset.field.street.admin__control-fields.required {
      &:nth-child(3) {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          clear: left;
          margin-right:4%;
        }
      }
    }

    .fieldset .field {
      &[name="shippingAddress.city"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          margin-left: 0;
          margin-right: 0;
        }
      }

      // Sorts out 'Address Line 1'/'Cím 1'
      &[name="shippingAddress.street.0"] {
        @include respond-min($bp-tablet-wide-min) {
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.street.0"] .control {
        @include respond-min($bp-tablet-wide-min) {
          width: 100%;
        }
      }

      &[name="shippingAddress.telephone"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          margin-right: 0;
        }
      }

      &[name="shippingAddress.telephone"] .control {
        @include respond-min($bp-tablet-wide-min) {
          width: 100%;
        }
      }

      &[name="shippingAddress.postcode"],
      &[name="shippingAddress.company"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.custom_attributes.tax_number"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
        }
      }
    }


    // SPC billing address fields styling tweaks

    .billing-address-form {

      // Sorts out 'Address Line 1'/'Cím 1'
      fieldset.field.street.admin__control-fields.required {
        @include respond-min($bp-tablet-min) {
          margin-right: 4%;
        }
      }

      .fieldset .field {
        &[name="billingAddress.custom_attributes.tax_number"] {
          @include respond-min($bp-tablet-min) {
            width: 48%;
          }
        }
      }

      // This styles the field labelled 'Tax number'
      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.fax"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.fax"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.fax"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;

          & input {
            width: 100%;
          }
        }
      }

      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.firstname"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.firstname"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.firstname"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.postcode"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.postcode"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.postcode"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.company"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.company"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.company"] {
        @include respond-min($bp-tablet-min) {
          width: 48%;
          margin-right: 4%;
        }
      }

      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.lastname"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.lastname"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.lastname"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.city"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.city"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.city"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.telephone"],
      [name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.telephone"],
      [name="billingAddressbigfishpaymentgateway_pmgw_paypal.telephone"] {
        @include respond-min($bp-tablet-min) {
          width: 48%;
        }
      }


      // Sorts out 'Address Line 1'/'Cím 1'
      &[name="shippingAddress.street.0"] {
        @include respond-min($bp-tablet-wide-min) {
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.street.0"] .control {
        @include respond-min($bp-tablet-wide-min) {
          width: 100%;
        }
      }

      .checkout-billing-address {
        fieldset.fieldset[data-bind="visible: !isAddressDetailsVisible()"] .actions-toolbar {
          @include respond-min($bp-tablet-min) {
            width: 100%;
          }
        }

        fieldset.fieldset[data-bind="visible: !isAddressDetailsVisible()"] .actions-toolbar .action-cancel {
          @include respond-min($bp-tablet-min) {
            float: none;
            margin-left: 0;
          }
        }
      }

    }
  }
}