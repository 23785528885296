body.rtl {
  header {
    &.page-header {
      left: inherit;
      right: 0;
    }
  }

  .header__logo {
    right: -10px;
    left: 0;

    @include respond-min($bp-tablet-min) {
      right: 0;
      margin-left: $v-minuscule;
      margin-right: 0;
    }
  }

  .header__search {
    margin-right: auto;
    margin-left: $v-minuscule;

    @include respond-min($bp-tablet-min){
      margin-right: auto;
      margin-left: $v-minuscule;
    }

    @include respond-min($bp-desktop-min) {
      margin-right: auto;
      margin-left: $v-tiny;
    }

    .icon {
      @include respond-min($bp-tablet-min) {
        float: left;
      }
    }
  }

  .header__toggle-nav {
    right: -25px;
  }

  .header__hamburger {
    &:before,
    &:after {
      right: 0;
    }
  }

  .header__nav {
    text-align: right;
  }

  .header__nav-item {
    > a {
      .icon--chevron {
        float: left;
      }
    }
  }

  .header__contact {
    .header__contact__link {
      .header__contact__link__text {
        margin-left: inherit;
        margin-right: 0.75em;
      }

      .header__contact__link__details {
        margin-left: inherit;
        margin-right: 0.75em;
      }
    }
  }

}
