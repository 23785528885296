// ------- TWO PAGE CHECKOUT: DYSON-RO SPECIFIC -------

.dyson-ro {

  // SHIPPING ADDRESS
  // Styling tweaks for RO 2PC shipping address fields in 2 columns
  .checkout__row .opc-wrapper .opc #shipping-new-address-form {

    // Hide unneeded Street Address label
    .field.street {
      legend {
        display: none;
      }

      @include respond-min($bp-tablet-min) {
        width: 48%;

        div[name="shippingAddress.street.0"] {
            margin-right: 4%;
            width: 100%;
        }
      }
    }

    div.field[name="shippingAddress.telephone"],
    div.field[name="shippingAddress.postcode"],
    div.field[name="shippingAddress.region_id"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
    }

    div.field[name="shippingAddress.city"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;

      }
    }

      div.field[name="shippingAddress.custom_attributes.tax_number"] {
          width: 100%;
      }
  }

    // BILLING ADDRESS
    .payment-method-billing-address .billing-address-form {
        // Hide unneeded Street Address label
        fieldset.field.street {
            legend {
                display: none;
            }
        }
    }

    .checkout__row .opc-wrapper .opc .fieldset {
        .field[name="billingAddressadyen_cc.firstname"],
        .field[name="billingAddressadyen_cc.lastname"],
        .field[name="billingAddressadyen_cc.street.0"],
        .field[name="billingAddressadyen_cc.telephone"],
        .field[name="billingAddressadyen_cc.city"],
        .field[name="billingAddressadyen_cc.region_id"],
        .field[name="billingAddressadyen_cc.country_id"],
        .field[name="billingAddressadyen_cc.postcode"] {
            @include respond-min($bp-tablet-min) {
                width: 48%;

                input {
                    width: 100%;
                }
            }
        }
    }

  // Billing address buttons
  .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .payment-method .actions-toolbar {
    margin-bottom: 2%;
  }
}
