// ------- DYSON-SG SPECIFIC -------

.dyson-sg {
  .am-checkout {

    .fieldset {
      .field {
        &[name="shippingAddress.company"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            .control {
              width: 48%;

              label {
                margin-right: 10px;
              }
            }
          }
        }

        &[name="shippingAddress.street.0"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }

          label.label {
            display: none;
          }
        }

        &[name="shippingAddress.street.1"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }

          label.label {
            display: none;
          }
        }

        &[name="shippingAddress.city"] {
          width: 100%;

          .control {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
            }
          }
        }

        &[name="shippingAddress.postcode"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.country_id"] {
          display: inherit;
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.telephone"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.newsletter_signup"] {
          width: 100%;
          margin-top: 50px;

          @include respond-min($bp-tablet-wide-min) {
            margin-top: 0;
          }
        }

        &[name="shippingAddress.custom_attributes.dont_activate_guarantee"] {
          width: 100%;
          margin-top: 50px;

          @include respond-min($bp-tablet-wide-min) {
            margin-top: 0;
          }

          .form__title {
            @include fs-responsive((g:16px, gt:18px, gtw:20px));
            font-weight: 400;
            color: $clr-dy-grey-7;
            padding-top: 25px;
          }

          .custom_checkout_field--divider_line_top {
            border-top: 1px solid $clr-divider-line;
          }

          .label {
            color: $clr-dy-grey-7;
          }
        }

        // Worldpay Specifc - TODO - Should this be in the general styles?

        &[name="billingAddressadyen_cc.firstname"], &[name="billingAddressadyen_hpp.firstname"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.lastname"],  &[name="billingAddressadyen_hpp.lastname"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.company"],  &[name="billingAddressadyen_hpp.company"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.telephone"], &[name="billingAddressadyen_hpp.telephone"]  {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.country_id"], &[name="billingAddressadyen_hpp.country_id"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.street.0"], &[name="billingAddressadyen_hpp.street.0"] {
          width: 100%;

          label.label {
            display: none;
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.street.1"], &[name="billingAddressadyen_hpp.street.1"] {
          width: 100%;

          label.label {
            display: none;
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.city"], &[name="billingAddressadyen_hpp.city"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.postcode"], &[name="billingAddressadyen_hpp.postcode"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }


        &.field--checkboxes {
          padding: 1em 0;

          .form__item {
            margin-bottom: 0;

            label.label {
              margin-bottom: 0;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.newsletter_signup"] {
          margin: 5em 0;

          @include respond-min($bp-mobile-wide-min) {
            margin-top: inherit;
            margin-bottom: inherit;
          }
        }

        &.street {
          legend.label {
            display: inherit;
          }
        }
      }

      & label[for="direct_cc_ECMC-SSL"],
      & label[for="direct_cc_VISA-SSL"],
      & label[for="direct_cc_MAESTRO-SSL"]{
        margin-right: 20px;
      }
    }

    .payment-method-cc-form {
      .ccard .type .select,
      .ccard .name .input-text {
        width: 100%;
      }

      .field-tooltip.toggle {
        display: none;
      }
    }
  }

    .payment-method-content .field input, .payment-method-content .field select {
        max-width: 100% !important;
    }
    

    .checkout__row .opc-wrapper #shipping-new-address-form .field.street .field:last-child,
    .checkout__row .opc-wrapper .checkout-billing-address fieldset .field.street .field:last-child {
        margin-top: 0;
    }

    .field[name="billingAddressadyen_cc.street.0"] label.label:after, .field[name="billingAddressadyen_cc.street.1"] label.label:after, {
        display: none !important;
    }

    .checkout-agreement.field.choice.required label.label:after {
        content: '';
        margin: 0 0 0 0;
    }


}
