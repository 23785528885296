body.rtl {
  .secondary-nav {
    .icon--chevron {
      right: inherit;
      left: 140px;

      @include respond-min($bp-mobile-min) {
        left: 180px;
      }

      @include respond-min($bp-tablet-min) {
        right: 50%;
        left: inherit;
      }
    }
  }

  .secondary-nav__add {
    margin-right: 0;
    margin-left: -25px;

    @include respond-min($bp-tablet-min) {
      margin-left: 0;
    }
  }

  .secondary-nav__dropdown {
    padding-right: 0;
    padding-left: 15%;

    @include respond-min($bp-tablet-min) {
      padding-left: 35%;
    }
  }
}
