.dyson-id, .dyson-ph {
    #shipping-new-address-form, .checkout-billing-address fieldset.fieldset {
        display: flex;
        flex-wrap: wrap;
        .field[name*=".city"] {
            order: 5;
            margin-left: 0;
        }
        .field[name*=".custom_attributes.district"] {
            margin-left: 0;
        }
        .field[name*=".custom_attributes.province"],.field[name*=".custom_attributes.ward"] {
            margin-left: 0%;
            @include respond-min($bp-tablet-min) {
                margin-left: 4%;
            }
        }
    }
	.am-checkout .fieldset .field {
		@include respond-min($bp-tablet-min) {
			width: 48%;
		}

		&[name="shippingAddress.lastname"], &[name="shippingAddress.city"], &[name="shippingAddress.country_id"] {
			@include respond-min($bp-tablet-min) {
				margin-left: 0%;
			}

			@include respond-min($bp-tablet-wide-min) {
				margin-left: 0;
			}

		}

		&[name="shippingAddress.street.0"] {
			@include respond-min($bp-tablet-min) {
				width: 100%;
				margin-left: 9%;
			}
		}

		&[name="shippingAddress.country_id"], &[name="billingAddressonepay_domestic.country_id"] {
			display: none;
		}

		&[name="shippingAddress.custom_attributes.ward"], &[name="shippingAddress.custom_attributes.province"] {
			@include respond-min($bp-tablet-min) {
				clear:both;
			}
		}

		&[name="shippingAddress.telephone"] .control, &[name="shippingAddress.street.0"] .control {
			@include respond-min($bp-tablet-wide-min) {
				width: 100%;
			}
		}

		&[name="shippingAddress.city"], &[name="shippingAddress.country_id"] {
			@include respond-min($bp-tablet-wide-min) {
				margin-left: 4%;
				margin-right: 0;
			}
		}

		&[name*="firstname"] {
			@include respond-min($bp-tablet-min) {
				margin-right: 4%;
			}
		}

		&[name*=".street.0"] {
			@include respond-min($bp-tablet-min) {
				width: 100%;
				margin-left: 9%;
			}
		}

		&[name*=".city"], &[name*=".country_id"]  {
			@include respond-min($bp-tablet-min) {
				margin-left: 4%;
			}
		}
	}

    .am-checkout {
        .billing-address-form {
            .fieldset {
                &.address {
                    .field {
                        .control {
                            width: 100%;
                        }

                        &[name*=".country_id"] {
                            display: none;
                        }
                    }
                }
            }
        }
        .fieldset {
            div[data-role="activate-guarantee"] {
                order: 6;
                .field.field--checkboxes {
                    width: 100%;
                }
            }
            &._error {
                select {
                    &:focus {
                        border: 1px solid #dd4157;
                    }
                }
            }
        }
        .billing-address-form {
            .fieldset {
                &.address {
                    .field {
                        .control {
                            width: 100%;
                        }
                        &[name*=".country_id"] {
                            display: none;
                        }
                    }
                }
            }
        }
        div.mage-error:before,
        div.field-error:before {
            margin-right: 4px;
        }
        .checkout-block__payment-tabs__header, .checkout-block__tabs__header {
            .payment-tabs__header {
                display: flex;
                flex-wrap: wrap;
            }
        }
        .payment-method-title {
            label {
                white-space: break-spaces;
            }
        }
        .payment-method {
            .actions-toolbar {
                .primary {
                    .action-update {
                        padding: 1em 0;
                        border: 1px;
                    }
                }
            }
        }
    }

	.form-login {
		@include respond-min($bp-tablet-min) {
			width: 48%;
		}

		@include respond-min($bp-tablet-wide-min) {
			width: 100%;
		}
	}

	.field.field--checkboxes.newsletter-signup-component, .billing-address-same-as-shipping-block.field.choice, .checkout-agreement.field.choice.required {
		width: 100%;
	}

	.payment-method-billing-address {
		margin-top: 40px;
	}

	//overriding OnePay's styles
	.banks-list .payment-description {
		display: block;
		margin: 10px 0;
	}

	.dl-dropdown-district, .field-district {
		@include respond-min($bp-tablet-min) {
			margin-left: 4%
		}
	}
    .am-checkout .fieldset .field.required.checkout-agreement .label:after {
        content: "";
        margin: 0;
    }
    // Stop checkbox text overlapping at mobile
    .billing-address-same-as-shipping-block {
        margin-bottom: 3em;

        @include respond-min($bp-tablet-min) {
            margin-bottom: 1.5em;
        }
    }

    div.newsletter-signup-component {
        margin-bottom: 2.5em;

        @include respond-min($bp-tablet-min) {
            margin-bottom: 1.5em;
        }
    }

	.checkout-payment__image {
		&:after {
			content: '';
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			background-image: url('../images/brand/accepted-payment-vn.png');
			width: 244px;
			height: 165px;
		}
	}
    .cart-container {
        .cart-summary {
            .basket__row {
                .basket__summary {
                    width: 100%;
                    @include respond-min($bp-tablet-min) {
                        width: calc(100% - 320px);
                    }
                  .table-wrapper .totals {
                      @include respond-min($bp-tablet-wide-min) { //(min-width: 1024px)
                          width: 85%;
                      }
                  }
                }
                .basket__checkout {
                    margin-left: auto;
                    text-align: right;
                }
                .basket__summary ~ .basket__checkout {
                    margin-left: 0;
                    text-align: left;
                    padding-left: 0;
                    @include respond-min($bp-tablet-min) {
                      padding-left: 20px;
                    }
                }
            }
        }
    }
  .checkout-block__summary__items__list__product {
      .product-item-details {
          .product-item-inner-info {
             .product-item-quantity__value {
               &.free-gift-text {
                 color: #0066CC;
                 font-weight: bold;
                 text-transform: uppercase;
               }
             }
          }
      }
  }
  .desktop-show-pdp {
    .hgroup__image {
      .double-line-motif {
        img {
          padding-top: 0 !important;
          height: auto;
        }
      }
    }
  }
}

