.title {
  .title__icon__links > :first-child > a > img {
    float: right;
  }

  .layout + .title__icon__links {
    margin-top: 0.5em;
  }

  .title__content {
    @include fs-static(16);

    @include respond-min($bp-tablet-wide-min) {
      @include fs-static(20);
      margin-top: 0;
    }
  }
}

.title--borders {
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
}

.title__icon__links {
  display: flex;
  flex-wrap: wrap;
}
