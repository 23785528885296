body.rtl {
  .product__slideshow__container {
    h4 {
      padding-left: 0;
      padding-right: 20px;
    }
  }

  .product__slideshow__container__gallery {
    .slick-dots {
      padding-right: 0;
    }
  }
}
