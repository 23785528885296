.tiles {

}

.tiles__item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $clr-dy-grey-6;

  @include respond-min($bp-phablet-wide-min) {
    border-top: 0;
  }

  // min-height helper
  .tiles--mh620 & {

    @include respond-min($bp-phablet-min) {
      // min-height: 400px;
    }

    @include respond-min($bp-desktop-min) {
      min-height: 620px;
    }
  }

  &.tiles__item--override-mh {
    min-height: 0; // override individual items
  }

  .hero {
    width: 100%;
  }
}
