// ------- DYSON-IN SPECIFIC -------

.dyson-in {
  .am-checkout {

    .pincode_fieldset_title {
      @include fs-responsive((g:18px, gt:20px));
      font-weight: 400;
      padding-bottom: 1em;
    }

    .fieldset {
      .field {
        &[name="shippingAddress.postcode"] {
          width: 100%;

          label.label {
            &:after {
              content: '*';
              display: inline-block;
              margin: 0 0 0 3px;
            }
            @include respond-min($bp-tablet-wide-min) {
              width: 650px;
            }
          }

          .input-text {
            border-color: $clr-dy-grey-2;
          }

          &._error {
            .input-text {
              border-color: $clr-dy-red-1;
            }
          }

          @include respond-min($bp-tablet-wide-min) { //(min-width: 1024px)
            width: 48%;
          }
        }

        [data-index="pincode_button"]{
          @include button;
          @include button--fluid;
          padding: 16px 12px;
          margin: 12px 0 24px;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            padding: 20px 18px 19px 18px;
            margin-top: 33px;
          }
        }

        &[name="shippingAddress.street.0"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.1"],  &[name="billingAddresspayu.street.1"]{
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-top: -35px !important;
            .label.label{
              display: inline-block!important;
            }

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.city"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.region"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.telephone"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.gst_number"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="shippingAddress.custom_attributes.activate_guarantee"] {
          @include respond-min($bp-tablet-wide-min) {
            padding: 1em 0;
          }
        }

        &[name="shippingAddress.custom_attributes.activate_installation"] {
          @include respond-min($bp-tablet-wide-min) {
            padding: 1em 0;
          }
        }

        &[name="shippingAddress.custom_attributes.newsletter_signup"] {
          padding: 3em 0;

          @include respond-min($bp-tablet-wide-min) {
            padding: 1.5em 0;
          }
        }

        /* TODO - PAYU RELATED (payusubvention & payu modules, one enabled) - MOVE THIS TO MAIN SPC STYLES? */
        &[name="billingAddresspayusubvention.street.0"],
        &[name="billingAddresspayu.street.0"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddresspayusubvention.street.1"],
        &[name="billingAddresspayu.street.1"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddresspayusubvention.region"],
        &[name="billingAddresspayu.region"] {
          display: block;
        }

        &[name="billingAddresspayusubvention.street.2"],
        &[name="billingAddresspayu.street.2"] {
          width: 100%;

          .control {
            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
            }
          }
        }

        &[name="billingAddresspayusubvention.telephone"],
        &[name="billingAddresspayu.telephone"] {
          width: 100%;
          margin-right: 4%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddresspayusubvention.gst_number"],
        &[name="billingAddresspayu.gst_number"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddresspayusubvention.company"],
        &[name="billingAddresspayu.company"] {
          width: 100%;

          .control {
            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
            }
          }
        }

        &.field--checkboxes {
          padding-bottom: 0;
        }

        &[name="billingAddresspayusubvention.postcode"],
        &[name="billingAddresspayu.postcode"] {
          .control.complete {
            &:after {
              display: none;
            }
          }
        }

        &.street {
          legend.label {
            display: none;
          }
        }

        &.in-error {
          input.input-text {
            border: 1px solid $clr-dy-grey-2;
          }

          .field-error {
            display: none;
          }
        }
      }
      [data-index="pincode_button_billing"]{
        @include button;
        @include button--fluid;
        padding: 16px 12px;
        margin: 12px 0 24px;

        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          padding: 20px 18px 19px 18px;
          margin-top: 52px;
        }
      }
    }

    .control.complete:after {
      height: 36px;
      width: 36px;
      top: 15px;
      right: 15px;
    }

    .input--loading {
      background-image: url(../images/loading.gif);
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: 90% center;
      background-position: 95% center;
      background-position-y: center;
    }
  }
}
