.stars {
  @include clearfix;
  display: flex;
}

.stars__image {
  flex-shrink: 0;
  max-width: 110px;
  display: flex;
  align-items: center;

  .stars--small & {
    max-width: 85px;
  }

  @include respond-min($bp-tablet-wide-min) {
    max-width: none;
  }
}

.stars__text {
  font-style: normal;

  .stars--small & {
    @include fs-responsive((g:14,gtw:16));
    display: inline-block;
    min-width: 135px;
    margin-left: 30px;

    @include respond-min($bp-tablet-wide-min) {
      min-width: 300px;
    }
  }
}
