/*
  BASKET
*/

// DEFAULT PAGE WRAPPER CLASS

.checkout-cart-index {
    .summary.title,
    .block.shipping,
    .multicheckout {
        display: none;
    }
    .message {
        padding: 16px;
        margin-top: 16px;
        margin-bottom: -16px;
        font-size: 14px;
    }
    .cart-container {
        padding: 30px 0;
    }

    .page-main {
        padding-bottom: 30px;

        @include respond-min($bp-tablet-min) {
            padding-bottom: 60px;
        }
    }
}

.cart-wrap {
    padding: 30px 0;

    @include respond-min($bp-tablet-min) {
        padding: 60px 0;
    }
}


// BASKET EMPTY

.cart-empty {
    @include fs-responsive((g:20px, gt:24px, gtw:28px));
    color: $clr-dy-grey-7;
    background: $clr-dy-grey-0;
    font-weight: 300;
    padding: 20px;
}


// BASKET ITEMS ROW

.basket__items {


    table.items{
        margin-bottom: 0;
        display: block;

        tr {
            display: inline-block;

            @include respond-min($bp-tablet-min) {
                display: table-row;
            }
        }

        th,td {
            display: inline-block;

            @include respond-min($bp-tablet-min) {
                display: table-cell;
            }
        }

        @include respond-min($bp-tablet-min) {
            display: table;
        }
    }

    .item{
        background: $clr-dy-grey-05;
        border: 1px solid $clr-dy-grey-2;
    }

    .item-info {
        border-bottom: 1px solid $clr-dy-grey-2;
    }

    .col {
        &.item {
            width: 40%;
            text-align: center;
            vertical-align: middle;
            border: 0;
            float: left;

            @include respond-min($bp-tablet-min) {
                width: 15%;
                border-right: 1px solid $clr-dy-grey-2;
                float: none;
            }
        }

        &.name {
            width: 60%;
            padding: 20px 20px 10px 20px;
            float: right;

            @include respond-min($bp-tablet-min) {
                width: 35%;
                padding: 30px 0 30px 30px;
                float: none;
            }
        }

        &.qty {
            width: 60%;
            padding: 10px 20px;
            float: right;

            @include respond-min($bp-tablet-min) {
                width: 35%;
                padding: 30px 0;
                float: none;
            }
        }

        &.subtotal {
            width: 60%;
            padding: 10px 20px;
            float: right;

            @include respond-min($bp-tablet-min) {
                width: 25%;
                padding: 30px 30px 30px 0;
                float: none;
                text-align: right;
            }
        }
    }
}

.secure-checkout__button {
    width: 100% !important;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-right: -25px;

    @include respond-min($bp-tablet-min) {
        margin-right: 0;
        width: 180px !important;
        display: inline-block;
    }

    @include respond-min($bp-tablet-wide-min) {
        width: 240px !important;
    }

    span {
        margin: 0 auto;
    }
}

.basket__items__photo {
    max-height: 200px;
}

.basket__items__name {
    @include fs-responsive((g:20px, gt:24px, gtw:28px));
    color: $clr-dy-grey-7;

    a {
        text-decoration: none;
    }
}

.subtotal.basket__items__price {
    @include respond-min(439px) {
        margin-top: 8px;
    }

    @include respond-min(480px) {
        margin-top: 0;
    }
}

.basket__items__quantity {
    color: $clr-dy-grey-7;
    font-family: $f-fallback;

    .label {
        @include fs-responsive((g:13px, gt:16px));
        display: inline-block;
        vertical-align: top;
        margin-right: 12px;

        .dyson-cz & {
            margin: 0 0 10px 10px;

            @include respond-min($bp-phablet-min) {
                margin-top: 10px;
            }
        }

        @include respond-min(439px) {
            margin-top: 8px;
        }
    }

    .control {
        display: inline-block;
        vertical-align: middle;
        text-align: center;

        input{
            border-color: $clr-dy-grey-2;
            text-align: center;
            padding: 0;
            width: 36px; height: 36px;
        }
    }
}

.basket__items__price {
    color: $clr-dy-grey-7;
    font-family: $f-fallback;
    text-align: right;
    padding-right: 0.5em;

    .label {
        display: inline-block;
        vertical-align: middle;
        @include fs-responsive((g:13px, gt:16px));
        margin-right: 5px;
    }

    .price {
        @include fs-responsive((g:13px, gt:15px));
        display: inline-block;
        vertical-align: middle;
    }

    .msrp_promotion__label {
        color: $clr-dy-blue-1;
        margin-right: 0;
    }
    .price-including-tax {
        .cart-price {
            .price {
                word-break: break-word;
            }
        }
    }
}
.msrp_promotion__msrp_highlighted {
    color: $clr-dy-blue-1;
}
.basket__items__actions {
    text-align: right;
    padding: 10px 20px;
    float: right;

    @include respond-min($bp-tablet-min) {
        padding: 30px;
        float: none;
    }

    .action {
        font-family: $f-fallback;
        color: $clr-dy-grey-5;
        text-decoration: none;
        @include fs-responsive((g:14px, gt:16px));
    }
}


// UPDATE CART ROW

.basket__items__below__btn.action.update {
    text-decoration: underline;

    &:hover, &:focus {
        text-decoration: none;
    }
}


.update-cart {
    padding: 0 0 20px 0;
    border-bottom: 1px solid $clr-dy-grey-2;
    margin-bottom: 20px;

    .button {
        width: 100%;

        @include respond-min($bp-phablet-min) {
            width: auto;
        }
    }
}

.update-cart__buttons {
    padding: 10px 0;
    width: 100%;
    text-align: left;
    float: none;

    @include respond-min($bp-tablet-min) {
        padding: 14px 1em 14px 0;
        width: auto;
        text-align: left;
    }

    @include respond-min($bp-tablet-min) {
        padding: 14px 0;
    }

    button {
        @include fs-responsive((g:12px, gt:14px, gtw:16px));
        @include button-reset;
        font-family: $f-fallback;
        padding: 0;

        &:first-child {
            margin-right: 20px;
        }

        @include respond-min($bp-tablet-min) {
            margin-right: 20px;
        }

        &:last-of-type {
            margin: 0;
        }
    }
}


// BASKET ROW

.basket__row {
    @include respond-min($bp-tablet-min) {
        display: flex;
        justify-content: space-between;
    }
}

.button.button--tertiary.product__variant__add-to-cart--animated.action.primary.checkout {
    margin-bottom: 20px;

    @include respond-min($bp-tablet-min) {
        width: 300px;
    }
}

.basket__checkout {

}


// BASKET SUMMARY

.basket__summary ~ .basket__checkout {
    @include respond-min($bp-tablet-min) {
        display: flex;
        flex-direction: column;
        justify-content: normal;

        .dyson-sg &, .dyson-th &, .dyson-my &, .dyson-in &, .dyson-nz & {
            justify-content: flex-start;
        }
    }
}

.basket__summary{
    width: 100%;
    flex-grow: 1;

    .table-caption {
        display: none;
    }

    .table-wrapper {
        font-family: $f-fallback;
        background: $clr-dy-grey-05;
        border: 1px solid $clr-dy-grey-2;
        color: $clr-dy-grey-7;
        margin-bottom: 30px;

        @include respond-min($bp-tablet-min) {
            margin-bottom: 0;
        }

        .totals {
            width: 100%;
            margin-bottom: 0;

            @include respond-min($bp-tablet-wide-min) {
                width: 65%;
            }

            th {
                width: 50%;
                border: none;
            }

            td {
                width: 50%;
                text-align: right;
                border: none;
            }

            &.sub {
                th,td {
                    padding: 20px 20px 6px 20px;

                    @include respond-min($bp-tablet-min) {
                        padding: 30px 30px 10px 30px;
                    }
                }
            }

            &.shipping {
                th,td {
                    padding: 6px 20px;

                    @include respond-min($bp-tablet-min) {
                        padding: 10px 30px;
                    }
                }
            }

            &.grand {
                th,td {
                    padding: 6px 20px 20px 20px;
                    font-family: $f-futura;
                    vertical-align: bottom;

                    .dyson-th.primary & {
                        font-family: $f-futura-thai;
                    }

                    .dyson-hk.primary & {
                        font-family: $f-futura-hant;
                    }

                    .dyson-ae.rtl &, .dyson-sa.rtl & {
                        font-family: $f-futura-arabic;
                    }

                    .dyson-il.rtl & {
                        font-family: $f-futura-hebrew;
                    }

                    .dyson-kr & {
                        font-family: $f-noto-sans;
                    }

                    @include respond-min($bp-tablet-min) {
                        padding: 10px 30px 30px 30px;
                    }

                    strong {
                        font-weight: 500;
                    }
                }

                th {
                    @include fs-responsive((g:21px, gt:24px));
                }

                td {
                    @include fs-responsive((g:24px, gt:28px));
                    &.amount {
                        .price {
                            word-break: break-word;
                        }
                    }
                }
            }

            .mark {
                @include fs-responsive((g:13px, gt:15px));
                margin-right: 12px;
                font-weight: normal;
            }
        }

        .totals-tax-summary {
            th,td {
                padding: 6px 20px;

                @include respond-min($bp-tablet-min) {
                    padding: 10px 30px;
                }
            }
        }

        .totals-tax-details {
            th,td {
                padding: 6px 20px;

                @include respond-min($bp-tablet-min) {
                    padding: 10px 30px;
                }
            }
        }

        th, td {
            padding: 10px;
        }
    }
}

.totals.shipping .price{
    color: #0066CC;
    font-weight: bold;
    text-transform: uppercase;
}

.msrp_promotion {
    color: $clr-dy-blue-1;
    width: 100%;
    text-align: left;
    padding: 10px 10px;

    @include respond-min($bp-tablet-min) {
        padding: 20px;
    }
}


// BASKET CHECKOUT

.basket__checkout {
    width: 100%;

    @include respond-min($bp-tablet-min) {
        width: 320px;
        margin-left: 20px;
    }

    button {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        order: 1;
        flex: 1;
    }
}

.checkout-methods-items {
    display: flex;
    .checkout-methods-payment {
        margin-bottom: 30px;
    }

    @include respond-min($bp-tablet-min) {
        display: block;
    }
}

.dyson-sa {
    .basket__checkout__payment:after {
        background-image: url(../images/brand/payment-options-cart-SA-l.jpg);
        background-repeat: no-repeat;
        width: 272px;
        height: 195px;
        background-size: contain;
        display: block;
        content: '';
        margin: 0 auto 20px auto;
    }
}


//For India
.dyson-in .basket__section-totals {
    margin-top: 20px;
    @include respond-min($bp-tablet-min) {
        margin-top: 0;
    }
}

.dyson-au .action.continue.button.button--secondary.action.apply.primary {
    width: 100%;
}

.basket__checkout__payment--cart {
    margin: 0 auto;

    //this is correct for Sandbox/MX/SG
    &:after {
        background-image: url(../images/brand/accepted-payment-sand-mobile.png);
        background-repeat: no-repeat;
        width: 274px;
        height: 50px;
        background-size: contain;
        display: block;
        content: '';
        margin: 0 auto 20px auto;

        @include respond-min($bp-tablet-min) {
            background-image: url(../images/brand/accepted-payment.png);
            height: 120px;

            .dyson-mx & {
                height: 95px;
            }
        }

        .dyson-au & {
            background-image: url(../images/brand/accepted-payment-au.png);
            height: 115px;

            @include respond-min($bp-phablet-min) {
                background-image: url(../images/brand/accepted-payment-au-phablet.png);
                height: 39px;
                width: 372px;
            }
            @include respond-min($bp-tablet-min) {
                background-image: url(../images/brand/accepted-payment-au.png);
                height: 115px;
                width: 268px;
            }
        }

        .dyson-tr & {
            background-image: url(../images/brand/accepted-payment-tr.png);
        }

        .dyson-il & {
            background-image: url(../images/brand/accepted-payment-il.png);
            height: 100px;
        }

        .dyson-th & {
            background-image: url(../images/brand/accepted-payment-th.png);
            margin: 20px 0;
        }

        .dyson-in & {
            background-image: url(../images/brand/accepted-payment-in.png);
            height: 209px;
        }

        .dyson-my & {
            background-image: url(../images/brand/accepted-payment-my.png);
            height: 239px;
        }

        .dyson-cz & {
            background-image: url(../images/brand/accepted-payment-cz.png);
            height: 152px;
            width: 157px;
        }

        .dyson-ae & {
            background-image: url(../images/brand/payment-options-AE.jpg);
            height: 115px;
            @include respond-min($bp-tablet-min){
                height: 120px;
            }
        }

        .dyson-hu & {
            background-image: url(../images/brand/accepted-payment-hu-mobile.png);

            @include respond-min($bp-phablet-min) {
                width: 480px;
            }

            @include respond-min($bp-phablet-wide-min) {
                width: 700px;
                margin-left: 100px;
            }

            @include respond-min($bp-tablet-min) {
                background-image: url(../images/brand/accepted-payment-hu.png);
                margin: 0 auto;
                width: 100%;
            }
        }

        .dyson-nz & {
            background-image: none;
        }

        .dyson-kr & {
            background-image: none;
        }

        .dyson-sa.rtl & {
            // Display payment logos in a line at mobile breakpoints
            background-image: url(../images/brand/payment-options-SA-r.jpg);
            height: 115px;
            width: 280px;

            @include respond-min($bp-mobile-wide-min) {
                width: 430px;
                height: 200px;
            }

            @include respond-min($bp-phablet-min) {
                width: 450px;
                height: 120px;
            }

            @include respond-min($bp-phablet-wide-min) {
                width: 540px;
                height:150px;
            }

            @include respond-min($bp-tablet-min){
                background-image: url(../images/brand/payment-options-cart-SA-r.jpg);
                height: 120px;
                width: 100%;
            }
        }

        .dyson-sa & {
            // Display payment logos in a block at higher breakpoints
            background-image: url(../images/brand/payment-options-SA-l.jpg);
            height: 115px;
            width: 280px;

            @include respond-min($bp-mobile-wide-min) {
                width: 430px;
                height: 200px;
            }

            @include respond-min($bp-phablet-min) {
                width: 450px;
                height: 120px;
            }

            @include respond-min($bp-phablet-wide-min) {
                width: 540px;
                height:150px;
            }

            @include respond-min($bp-tablet-min){
                background-image: url(../images/brand/payment-options-cart-SA-l.jpg);
                height: 120px;
                width: 100%;
            }
        }

        .dyson-sg & {
            background-image: url(../images/brand/accepted-payment-sg-mobile.png);
            height: 152px;
            width: 297px;
            @include respond-min($bp-tablet-min) {
                background-image: url(../images/brand/accepted-payment-sg.png);
                margin: 0 auto;
                width: 289px;
            }
        }

        .dyson-ro & {
            background-image: url(../images/brand/accepted-payment-ro.png);
            width: 245px;
            margin: 20px auto;
        }

        .dyson-vn & {
            background-image: url(../images/brand/accepted-payment-vn.png);
            height: 209px;
        }

        .dyson-pl & {
          background-image: unset;
          height: auto;
        }
    }

    //the payment icons need to be left aligned for TR/TH
    .dyson-tr &, .dyson-th & {
        margin: 0;
    }

    .minicart-items {
        display: block;
    }
}

.dyson-ro .cart.main.actions .action.continue.button.button--secondary.action.apply.primary {
    width: 100%;
}

.dyson-sa .basket__checkout__payment {
    margin-left: 0 !important;

    @include respond-min($bp-tablet-min) {
        width: auto;
    }
}


// BASKET DISCOUNT

.basket__discount {
    padding-bottom: 30px;

    @include respond-min($bp-tablet-min){
        width: 100%;
        flex-grow: 1;
    }

    @include respond-min($bp-tablet-wide-min) {
        flex-grow: 0;
        width: 50%;
    }

    .dyson-th & {

        @include respond-min($bp-tablet-min) {
            padding-bottom: 15px;
            padding-top: 10px;
        }
        @include respond-min($bp-tablet-wide-min) {
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }

    .dyson-my & {
        margin-top: 20px;
    }
}

.basket__discount ~ .basket__checkout {

    @include respond-min($bp-tablet-min) {
        width: 274px;
    }
}

.basket__discount__form {
    .coupon {
        display: flex;
    }

    .field {
        flex: 1;
        vertical-align: middle;

        @include respond-min($bp-tablet-min) {
            width: 270px;
            display: inline-block;
        }

        input {
            height: 3.4rem;
            @include fs-responsive((g:14px, gtw:16px));

            @include respond-min($bp-tablet-min) {
                height: 3.9rem;
            }

            @include respond-min($bp-tablet-wide-min) {
                height: 4.1rem;
            }
        }
    }

    .actions-toolbar {
        margin-left: 10px;
        .button{
            width: 100%;

            .rtl & {
                margin-right: 10px;
            }
        }

        @include respond-min($bp-tablet-min) {
            width: auto;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.action-delete, .action-delete span {
    display: block;
    text-align: right;
    width: 100%;
    color: #333333;
    text-decoration: underline;
}

.action-delete {
    padding-right: 0.5em;
}

.action-delete span {
    @include fs-static(16);
    font-family: $f-fallback;
    color: $clr-dy-grey-7;

    &:hover, &:focus {
        text-decoration:none;
    }
}

.totals-tax {
    display: none;
}

.dyson-in {
    tr.totals th.mark span.value {
        display: none;
    }
}
.delivery-proposition {
    padding-bottom: 20px;
    padding-top: 24px;
    @include respond-max($bp-tablet-wide-min) { //max 1024px;
      padding-top: 20px;
    }
}
.delivery-proposition__title {
    color: $clr-dy-grey-5;
    margin: 0;
    padding-bottom: 20px;
}
.delivery-proposition-item {
    display: flex;
    padding: 30px 0;
    border-top: 1px solid $clr-dy-grey-2;

    &:last-child {
        border-bottom: 1px solid $clr-dy-grey-2;
    }
}

$delivery-proposition-image-max-width: 40px;

.delivery-proposition-item__image {
    width: $delivery-proposition-image-max-width;
}
.delivery-proposition-item__image img {
    max-width: 100%;
}
.delivery-proposition-item__text-group {
    width: calc(100% - #{$delivery-proposition-image-max-width});
    padding-left: 30px;
    padding-right: 20px;

    @include respond-min($bp-tablet-wide-min) {
        padding-right: 200px;
    }
}
.delivery-proposition-item__title {
    font-size: 1.4rem;
    margin-bottom: 8px;
}
.delivery-proposition-item__text {
    font-size: 1rem;
}

.delivery-proposition__smallprint {
    text-align: center;
    font-size: 1rem;
    padding-top: 30px;
}

.checkout-cart-index {
    .free-gift-text {
        color: $clr-dy-blue-1;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;
        margin-left: auto;
        padding-top: 0;
        text-transform: uppercase;
        @include respond-min($bp-tablet-min) {
            justify-content: end;
        }
        @include respond-min($bp-tablet-wide-min) {
            font-size: 16px;
            line-height: 24px;
            justify-content: end;
        }
    }
}
