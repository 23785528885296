
//TODO ask Jay what this is for...
.dyson-review-post .page {
  background-color: #000;
}

//this is styling for the review list page eg. /products/cord-free/dyson-v8/reviews
.slider__list__reviews, .slider__list__reviews__content {
  visibility: hidden;
}

//styling for reviews listings page

  .product-review-container {
    min-height: 300px;
  }

  div.tab__image {

    div.image {
      max-width: inherit;
    }
 }

 .field.form__item.form__item--inline.form__item--checkbox.form__item--entered {
   display:inline;
 }

  .review__title {

      img {
        max-width: 30%;
      }

    @include respond-min($bp-tablet-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .review__title--img {

    text-align: center;

    @include respond-min($bp-tablet-min) {
      text-align: right;
      margin-right: 10%;
    }
  }


//this is styling for the submit a review form with the id of #product-submit-review

#product-submit-review {

  width: 90%;
  margin: 0 auto;

  @include respond-min($bp-tablet-min) {
    width: 80%;
  }

  @include respond-min($bp-desktop-min) {
    width: 75%;
  }

  .review-form {
    max-width: 100%;
  }

  label {
    font-family: $f-fallback;
  }

  .required {
    position: relative;

    label.label {
      position: absolute;
      background: $clr-white;
      margin-left: 9px;
      padding: 0 5px;
      line-height: normal;
      top: -6px;
      font-size: 14px;
      width: auto;
      z-index: 1;
    }
  }

  p {
    font-family: $font-family__sans-serif;
  }

  fieldset.fieldset.review-fieldset.review-write {
    border: none;
    padding: 0px !important;
    min-width: 100%;

    input {
      margin-bottom: 5px;
    }
  }

  .review-form {
    margin: 0 auto;
  }

  .review-control-vote:before,
  .review-control-vote label:before {
    font-size: 50px;
  }

  .review-control-vote:before {
    color: #c7c7c7;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #c7c7c7;
  }

  .review-control-vote label:before {
    color: $clr-dy-yellow-0;
    -webkit-text-fill-color: $clr-dy-yellow-0; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $clr-dy-yellow-0;
  }

  .review-form .action.submit.primary {
    background-color: $clr-black-90;
    border: none;
    color: $clr-white;
    font-weight: bold;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    font-family: $f-fallback;

    @include respond-min($bp-tablet-min) {
      width: 50%;
    }
  }

  .review-form .action.submit.primary:hover {
    background-color: $clr-black-70;
  }

  .review-guidelines {
    background-color: $clr-dy-yellow-0;
    padding: 30px;
    margin-top: 40px;

    li {
      font-size: 14px;
    }
  }

  span.review-terms-trigger {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .review-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label.label.label--review {
      position: relative;
      top: 10px;
    }

  }

  input::placeholder {
    font-size: 14px;
  }

  input::-webkit-input-placeholder {
    font-size: 14px;
  }

  input::-moz-placeholder {
    font-size: 14px;
  }

  input:-ms-input-placeholder {
    font-size: 14px;
  }

  input:-moz-placeholder {
    font-size: 14px;
  }

  textarea::placeholder {
    font-size: 14px;
  }

  textarea::-webkit-input-placeholder {
    font-size: 14px;
  }

  textarea::-moz-placeholder {
    font-size: 14px;
  }

  textarea:-ms-input-placeholder {
    font-size: 14px;
  }

  textarea:-ms-input-placeholder {
    font-size: 14px;
  }

  div.control {
    select {
      margin-bottom: 5px;
    }
  }

  .review-min-char {
    font-size: 12px;
    font-family: $font-family__sans-serif;
    letter-spacing: 0.5px;
    margin-left: auto;
  }

  .review-cta {
    margin: 3em 0 3em 0;
  }

  .review-about-you {
    width: 100%;

    @include respond-min($bp-tablet-min) {
      display: flex;
    }
  }

  .review-field-age,
  .review-field-nickname {

    @include respond-min($bp-tablet-min) {
      width: 50%;
      margin-right: 25px;
    }
  }

  .review-field-email,
  .review-field-gender {
    @include respond-min($bp-tablet-min) {
      width: 50%;
    }
  }

  div.control input {
    width: 100%;

    @include respond-min($bp-tablet-min) {
      // min-width: 340px;
    }
  }

//TODO ask James about how to style that modal
  // .modal-inner-wrap {
  //   width: 100%;
  //
  //   // @include respond-min($bp-tablet-min) {
  //   //   width: 80%;
  //   // }
  // }

  .contact--border {
    @include respond-min($bp-desktop-min) {
      border-right: 1px solid $clr-dy-grey-3;
    }
  }
}

.reviews-actions {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.reviews-form-link {
  text-decoration: underline;
  margin-top: 15px;

  &:hover {
    text-decoration: none;
  }

  .card__inner & {
    margin-top: 0;
  }
}

.review-item__radio {

}

.review-item__radio--recommend {
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  span {
    margin-right: 5px;
  }
}

.review-item__radio--registered {
  font-size: 12px;
}

.review-details
  .review-details-label {
    font-size: 14px;
    color: $clr-dy-grey-5;
  }

ul.items.pages__items {
  list-style-type: none;

    li {
      display: inline-block;
      padding: 5px;
    }
}

.pagination {
  background: $clr-dy-grey-2;
  padding: 5px;
  text-align: center;
  border-radius: 2px;
}

.dyson-review-product .modal-popup.modal-small .modal-inner-wrap {
  @include respond-min(755px) {
    width: 60%;
    height: auto;
    padding: 20px;
  }
}

//styling for the Terms modal
.review-terms .review-tcs {
  p {
    font-family: $font-family__sans-serif;
  }
}


//HU styling:
.review-form-privacy-link a, .review-form-privacy-link a:link, .review-form-privacy-link a:active, .review-form-privacy-link a:visited {
  text-decoration: underline;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: none;
  }
}