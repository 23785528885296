/*
  COLOURS
*/

// Primary colors (nicer alternatives to basic html colours)
$clr-black: #000;
$clr-white: #fff;

// BASE colours
$clr-grey-base: #808080;

// Blacks
$clr-black-90: lighten($clr-black, 10%);
$clr-black-85: lighten($clr-black, 15%);
$clr-black-80: lighten($clr-black, 20%);
$clr-black-75: lighten($clr-black, 25%);
$clr-black-70: lighten($clr-black, 30%);
$clr-black-65: lighten($clr-black, 35%);
$clr-black-60: lighten($clr-black, 40%);
$clr-black-55: lighten($clr-black, 45%);
$clr-black-50: lighten($clr-black, 50%);
$clr-black-45: lighten($clr-black, 55%);
$clr-black-35: lighten($clr-black, 65%);
$clr-black-25: lighten($clr-black, 75%);
$clr-black-15: lighten($clr-black, 85%);
$clr-black-5: lighten($clr-black, 95%);

// Brand Greys
$clr-dy-grey-05:  #fbfbfb;
$clr-dy-grey-07:  #fdfdfd;
$clr-dy-grey-0:   #f3f3f3;
$clr-dy-grey-1:   #f0f0f0;
$clr-dy-grey-2:   #ebebeb;
$clr-dy-grey-25:  #dadada;
$clr-dy-grey-2-hover: darken($clr-dy-grey-2, 4%);
$clr-dy-grey-3:   #d5d5d5;
$clr-dy-grey-4:   #b2b3b4;
$clr-dy-grey-5:   #999999;
$clr-dy-grey-6:   #666666;
$clr-dy-grey-65:  #6f6f6f;
$clr-dy-grey-66:  #555555;
$clr-dy-grey-675: #424242;
$clr-dy-grey-7:   #333333;
$clr-dy-grey-7-hover: darken($clr-dy-grey-7, 4%);
$clr-dy-grey-8:   #2d2d2d;
$clr-dy-grey-9:   #242424;
$clr-divider-line: #ececec;
$clr-dy-grey-10:   #222222;
$clr-dy-grey-11:   #dcdcdc;
$clr-dy-grey-12:   #f7f7f7;
$clr-dy-grey-13:   #C4398D;
$clr-dy-grey-14:   #f4f4f4;
$clr-dy-grey-15:   #0D0D0D;

// Brand colours
$clr-dy-green-0:  #79b928;
$clr-dy-green-0-hover: darken($clr-dy-green-0, 2%);
$clr-dy-green-1:  #69a239;
$clr-dy-green-2:  #537D1C;
$clr-dy-green-2-hover: lighten($clr-dy-green-2, 7%);
$clr-dy-blue-0:   #66afe9;
$clr-dy-blue-1:   #0a6acb;
$clr-dy-yellow-0: #ffcc01;

// Other colours
$clr-dy-red-1: #dd4157;
$clr-dy-blue-1: #0066CC; //blue theme for Rcc

// 3rd party brands
$clr-ig:  #42739a;  // instagram
$clr-fb:  #3d5b97;  // facebook
$clr-twt: #5eb2e1;  // twitter
$clr-yt:  #cd332d;  // youtube
$clr-in:  #008cc9;  // linkedin
$clr-vi:  #00ADEF;  // vimeo
$clr-ppe:  #ffc33a; // PayPal Express

$clr-mage-error: #da2f47;
$clr-field-border: #919191;
$clr-field-focus-border: #333;
$clr-field-readonly-border: #e1e1e1;
$clr-field-disabled-border: #e1e1e1;
$color-off-white:#F5F5F5;

$clr-shade-off-green:#0D8722;
$clr-modal-overlay:rgba(0,0,0,0.6);
$clr-yellow:#FFCC00;
$clr-tuc-modal-overlay:rgba(151,151,151,0.5);
$clr-gray-border:#979797;
