.full-page-modal {
    background: white;
    overflow-y: scroll;

    .modal-inner-wrap {
        width: 100%;
        margin: 0;
        box-shadow: none;

        .modal-content {
            padding: 0;
        }
    }

    .modal-header {
        width: 100%;
        margin: 0 auto;
        background: white;
        border-bottom: 1px solid #ECECEC;
        padding: 26px 0;

        @include respond-min(768px) {
            padding-top: 45px;
            padding-bottom: 45px;
        }
    }

    .modal-header-inner {
        max-width: 1270px;
        margin: 0 auto;
    }

    .action-close {
        position: relative;
        float: right;
        right: -7px;

        @include respond-min(768px) {
            right: -9px;
        }

        &:before {
            content: url('../images/icons/modal-close.svg');
            line-height: inherit;
            font-size: inherit;
            width: 16px;
            height: 16px;

            @include respond-min(768px) {
                width: 22px;
                height: 22px;
            }
        }

        &:hover {
            opacity: .4;
        }

        &:focus {
            @include focus();
        }
    }
}

.full-page-modal--sticky-head {

    //this is a temporary fix for Edge
    #buy-now-pay-later {
        .rtl & {
          padding-top: 150px;
        }
    }

    .modal-header {
        position: sticky;
        left: 0;
        right: 0;
        top: 0;

    //temporary fix for edge
      .rtl & {
        position: fixed;
      }
    }
}
