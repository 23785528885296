.crumb {

}

.crumb__nav {

}

.crumb__list {
  @include list-reset;
  @include fs-responsive((g:11px, gt:13px));
  margin: -0.3em 0;

  li {
    @include list-item-reset;
    display: inline-block;
    margin: 0.3em 0;

    &:after {
      content: "";
      display: inline-block;
      margin: 0 1em;
      vertical-align: middle;
      border-left: 1px solid $clr-dy-grey-5;
      height: 1.6em;
      transform: rotate(28deg);
      position: relative;
      margin-top: -2px;
    }

    &:last-child:after {
      display: none;
    }
  }

  a {
    @include link-reverse;
    color: $clr-white;

    &:visited {
      color: $clr-white;
    }
  }

  a[aria-level="2"] {
    pointer-events: none;
  }

  span {

  }
}
