.unsubscribe__cta {
    width: 100%;

    @include respond-min($bp-mobile-wide-min){
        width: 60%;
    }
    @include respond-min($bp-tablet-min){
        width: 45%;
    }
}

.unsubscribe__form {
    padding-top: 28px;
    padding-bottom: 32px;

    @include respond-min($bp-tablet-min){
        padding-bottom: 40px;
    }
    @include respond-min($bp-tablet-wide-min){
        padding-top: 32px;
        padding-bottom: 48px;
    }
}

.unsubscribe__privacy {
    padding-top: 0;
    background-color: $clr-dy-grey-07;

    @include respond-min($bp-tablet-wide-min){
        padding-bottom: 48px;
    }
}

.unsubscribe__header, .unsubscribe__privacy {
    width: 100%;
    @include respond-min($bp-mobile-wide-min){
        width: 85%;
    }
}

.unsubscribe__header--container, .unsubscribe__success-container {
    padding-top: 32px;
    padding-bottom: 0;

    @include respond-min($bp-tablet-min){
        padding-top: 40px;
    }
    @include respond-min($bp-tablet-wide-min){
        padding-top: 48px;
    }
}

.unsubscribe__title, .unsubscribe__subtitle, .unsubscribe-privacy_title {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.unsubscribe__title, .unsubscribe__title__small{
    @include respond-min($bp-tablet-wide-min){
        width: 65%;
    }
}

.unsubscribe__subtitle {
    font-weight: 600;
}

.unsubscribe__main_text {
    padding-top: 0;
    margin-top: 0;

    @include respond-min($bp-tablet-wide-min){
        width: 65%;
    }
}

.unsubscribe-privacy_text {
    padding-bottom: 0;
    margin-top: 0.5em;
}

.unsubscribe__success-container {
    padding-bottom: 40px;
}


