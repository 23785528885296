body.rtl {
  .modal-popup {
    .modal-title {
      float: right;
    }

    .action-close {
      float: left;
      left: 0;
      right: 93%;
    }
  }
}
