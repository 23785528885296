// ------- AMASTY SPC: DYSON-CZ SPECIFIC -------

.dyson-cz {

  .am-checkout .fieldset .field.street {
    width: 100%;
    margin-left: 0;

    @include respond-min($bp-tablet-wide-min) {
      padding-bottom: 0;
      width: 48%;
      margin-left: 4%;
    }
  }

  //t's and c's
  .terms-link {
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .basket__checkout__payment {
    width: 250px;
    margin: 20px auto;

    @include respond-min($bp-tablet-min) {
      margin: 0 auto;
    }
  }

  .basket__checkout button {
    //margin-top: 30px;
  }

  .checkout-methods-items {
    flex-direction: column;
  }

  //checkout agreement
  .checkout-agreement {
    margin-bottom: 30px;
  }

  .cz-checkout-caveat {
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  .am-checkout .payment-method .checkout-agreements-block .action-show {
    text-decoration: none !important;
  }

  .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .checkout-agreement .action-show {
    text-decoration: none !important;
  }


    // SPC shipping address fields styling tweaks:
  .am-checkout {

    // Sorts out 'Address Line 1'
    fieldset.field.street.admin__control-fields.required {
      &:nth-child(3) {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          clear: left;
          margin-right:4%;
        }
      }
    }

    .fieldset .field {

      &[name="shippingAddress.firstname"], &[name="shippingAddress.telephone"], &[name="shippingAddress.city"], &[name="shippingAddress.company"],
      &[name="shippingAddress.custom_attributes.custom_field_2_shippingAddress"], &[name="billingAddresshomecreditbinder.custom_attributes.custom_field_2_billingAddresshomecreditbinder"]  {
        clear: both;
      }


      &[name="shippingAddress.city"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          margin-left: 0;
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.street.0"] .control {
        @include respond-min($bp-tablet-wide-min) {
          width: 100%;
        }
      }

      &[name="shippingAddress.telephone"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          margin-right: 0;
        }
      }

      &[name="shippingAddress.telephone"] .control {
        @include respond-min($bp-tablet-wide-min) {
          width: 100%;
        }
      }

      &[name="shippingAddress.region"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
        }
      }

      &[name="shippingAddress.company"] {
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
          margin-left: 0;
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.postcode"] {
        @include respond-min($bp-tablet-wide-min) {
          margin-right: 0;
          width: 48%;
        }
      }

      &[name="shippingAddress.custom_attributes.custom_field_1_shippingAddress"],
      &[name="shippingAddress.custom_attributes.custom_field_2_shippingAddress"],
      &[name="shippingAddress.vat_id"]{
        @include respond-min($bp-tablet-wide-min) {
          width: 48%;
        }
      }
    }


    // SPC billing address fields styling tweaks

    .billing-address-form {

      // Sorts out 'Address Line 1'/'Cím 1'
      fieldset.field.street.admin__control-fields.required {
        @include respond-min($bp-tablet-min) {
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.street.0"] {
        @include respond-min($bp-tablet-wide-min) {
          margin-right: 0;
          width: 100%;
          margin-left: 4%;
        }
      }

      &[name="shippingAddress.street.0"] .control {
        @include respond-min($bp-tablet-wide-min) {
          width: 100%;
        }
      }

      .checkout-billing-address {
        fieldset.fieldset[data-bind="visible: !isAddressDetailsVisible()"] .actions-toolbar {
          @include respond-min($bp-tablet-min) {
            width: 100%;
          }
        }

        fieldset.fieldset[data-bind="visible: !isAddressDetailsVisible()"] .actions-toolbar .action-cancel {
          @include respond-min($bp-tablet-min) {
            float: none;
            margin-left: 0;
          }
        }
      }
    }

    .billing-address-form fieldset.field.street.admin__control-fields,
    .billing-address-form fieldset.field.street.admin__control-fields.required {
      margin-left: 0;
      margin-right: 0;
    }

    .checkout-billing-address {
      .fieldset.address{
        .field[name="billingAddresshomecreditbinder.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        .field[name="billingAddresshomecreditbinder.firstname"],
        .field[name="billingAddresshomecreditbinder.city"],
        .field[name="billingAddresshomecreditbinder.postcode"],
        .field[name="billingAddresshomecreditbinder.lastname"],
        .field[name="billingAddresshomecreditbinder.company"],
        .field[name="billingAddresshomecreditbinder.custom_attributes.custom_field_1_billingAddresshomecreditbinder"],
        .field[name="billingAddresshomecreditbinder.custom_attributes.custom_field_2_billingAddresshomecreditbinder"],
        .field[name="billingAddressagmobindercardall.firstname"],
        .field[name="billingAddressagmobindercardall.lastname"],
        .field[name="billingAddressagmobindercardall.telephone"],
        .field[name="billingAddresshomecreditbinder.vat_id"],
        .field[name="billingAddressagmobindercardall.city"],
        .field[name="billingAddressagmobindercardall.postcode"],
        .field[name="billingAddressagmobindercardall.company"],
        .field[name="billingAddressagmobindercardall.vat_id"],
        .field[name="billingAddressagmobinderbankall.firstname"],
        .field[name="billingAddressagmobinderbankall.city"],
        .field[name="billingAddressagmobinderbankall.company"],
        .field[name="billingAddressagmobinderbankall.vat_id"],
        .field[name="billingAddressprepayment.firstname"],
        .field[name="billingAddressprepayment.city"],
        .field[name="billingAddressprepayment.company"],
        .field[name="billingAddressprepayment.vat_id"]{
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        .field[name="billingAddresshomecreditbinder.telephone"],
        .field[name="billingAddressagmobinderbankall.telephone"],
        .field[name="billingAddressprepayment.telephone"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        .field[name="billingAddressagmobindercardall.lastname"],
        .field[name="billingAddressagmobindercardall.custom_attributes.custom_field_1_billingAddressagmobindercardall"],
        .field[name="billingAddressagmobinderbankall.lastname"],
        .field[name="billingAddressprepayment.lastname"],
        .field[name="billingAddressagmobinderbankall.postcode"],
        .field[name="billingAddressprepayment.postcode"],
        .field[name="billingAddressagmobinderbankall.custom_attributes.custom_field_1_billingAddressagmobinderbankall"],
        .field[name="billingAddressprepayment.custom_attributes.custom_field_1_billingAddressprepayment"]{
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-left: 4%;
          }
        }

        .field[name="billingAddressagmobindercardall.street.0"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-left: 8%;
          }
        }

        .field[name="billingAddressagmobindercardall.postcode"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-left: 4%;
          }
        }


        .field[name="billingAddresshomecreditbinder.city"] {
          @include respond-min($bp-tablet-wide-min) {
            clear: both;
            margin-right: 4%;
          }
        }

        .field[name="billingAddressagmobindercardall.city"],
        .field[name="billingAddressagmobinderbankall.city"],
        .field[name="billingAddressprepayment.city"]{
          @include respond-min($bp-tablet-wide-min) {
            clear: both;
          }
        }

        .field[name="billingAddresshomecreditbinder.company"],
        .field[name="billingAddress.custom_attributes.company_id_sk"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-right: 4%;
          }
        }
      }
    }
  }

  .control__note {
    color: $clr-dy-grey-7;
    margin-top: 10px;
  }

  .checkout-agreement {
    font-family: $f-fallback;
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }

    input:before {
      border: 2px solid #ebebeb;
      background-color: #fff;
    }
  }

  //temporarily taking out sortedpro from the order summary
  .shipping-details-sortedpro {
    display: none;
  }

  //label[for="agreement_cashondelivery_2"], label[for="agreement_cashondelivery_3"], label[for="agreement_paypal_express_2"],
  //label[for="agreement_paypal_express_3"],  label[for="agreement_prepayment_2"], label[for="agreement_prepayment_3"],
  //label[for="agreement_agmobinderbankall_2"],  label[for="agreement_agmobinderbankall_3"],
  //label[for="agreement_agmobindercardall_2"],  label[for="agreement_agmobindercardall_3"] {
  //  display: none !important;
  //}


  .am-checkout .checkout-block__payment-tabs__header .payment-method-title label, .am-checkout .checkout-block__tabs__header .payment-method-title label {
    @include respond-min($bp-tablet-min) {
      min-height: 100px;
    }

    @include respond-min($bp-tablet-wide-min) {
      min-height: 110px;
    }

    @include respond-min($bp-desktop-wide-min) {
      min-height: 80px;
    }
  }

  .payment-method-title:nth-of-type(5) {
    @include respond-min($bp-tablet-min) {
      clear:both;
    }
  }

  #payment_form_paypal_express .payment-method-note {
    margin-top: 30px;
    margin-bottom: 35px;
  }

  .checkout-agreements {
    margin-top: 68px;
  }

  .am-checkout .payment-method .choice label[for="agreement_cashondelivery_1"], .am-checkout .payment-method .checkout-agreement label[for="agreement_cashondelivery_1"] {
    margin-top: 123px;
  }


  .homecredit__checkout__container {

  }

  .homecredit__info {

  }

  .am-checkout .payment-method .choice input[type="checkbox"], .am-checkout .payment-method .checkout-agreement input[type="checkbox"] {
    left: 63px;
  }

}
