// ------- TWO PAGE CHECKOUT: DYSON-PL SPECIFIC -------

.dyson-pl {

    div.field[name="shippingAddress.street.0"] label,
    div.field[name="shippingAddress.street.1"] label {
        display: none!important;
    }

    div.field[name="shippingAddress.street.0"] {
        margin-bottom: 0!important;
    }

    div.field[name="shippingAddress.street.1"] {
        margin-top: 0!important;
        margin-bottom: 0!important;
    }
    
    div.field[name="shippingAddress.street.2"] {
        display: none;
    }

    // To override Adyen module styles.css
    .payment-method-content .field input, .payment-method-content .field select {
        max-width: 500px;
    }

    .fieldset {
        .field {
            &[name="billingAddressadyen_cc.street.0"],
            &[name="billingAddressadyen_hpp.street.0"] {
                margin-bottom: 0!important;

                label.label {
                    display: none;
                }
            }

            &[name="billingAddressadyen_cc.street.1"],
            &[name="billingAddressadyen_hpp.street.1"] {
                margin-top: 0!important;
                margin-bottom: 0!important;

                label.label {
                    display: none;
                }
            }
        }
    }

    .checkout__row .opc-wrapper .opc .form#adyen-cc-form,
    .checkout__row .opc-wrapper .opc .form#payment_form_adyen_hpp_dotpay {
        border: 0;
        margin-top: 7%;
    }

    .billing-address-details {
        margin-bottom: 5%;
    }

    // Remove extra asterisk on t&cs
    .checkout__row .opc-wrapper .opc .form .field:not(.field--checkboxes).required label:after,
    .checkout__row .opc-wrapper .opc .form .field:not(.field--checkboxes)._required label:after {
        content: '';
    }

    // Tweak Adyen error message position
    div[data-role="checkout-messages"] .message.message-error.error {
        @include z-index(heaven);
        position: absolute;
        bottom: 62%;
        left: 8%;
        width: 84%;
        padding: 15px;
        margin-top: 0;

        @include respond-min($bp-mobile-wide-min) {
            bottom: 67%;
        }

        @include respond-min($bp-tablet-min) {
            bottom: 69%;
            width: 85%;
        }

        @include respond-min($bp-tablet-wide-min) {
            bottom: 49%;
        }

        @include respond-min($bp-desktop-wide-min) {
            bottom: 57%;
        }

        @include respond-min(1530px) {
            bottom: 69%;
            max-width: 1000px;
            margin-left: 10%;
        }
    }
}


