body.rtl {

  .checkout-block__header__indicator {
    margin-right: inherit;
    margin-left: 0.5em;
  }

  .checkout-block__summary__items__count {
    float: left;
  }

  .checkout-block__summary__items__title {
    margin-right: 50px;
  }

  .checkout-block__summary__items__list__product {
    .product-item-details {
      .product-item-quantity {
        .product-item-quantity__value {
          float: left;
        }
      }
    }
  }

  .checkout-block__summary__totals {
    .totals {
      &.grand {
        th, td {
          &.amount {
            float: left;
          }
        }
      }
    }
  }

  .am-checkout {
    .fieldset {
      .field {
        float: right;

        &[name="shippingAddress.firstname"],
        &[name="billingAddresscheckmo.firstname"],
        &[name="billingAddresscashondelivery.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-right: inherit;
            margin-left: 4%;
          }
        }

        &[name="shippingAddress.city"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-right: inherit;
            margin-left: 2%;
          }
        }

        &.field--checkboxes {
          label {
            margin-right: inherit;
          }
        }
      }
    }

    .payment-method {
      .checkout-agreement {
        label {
          margin-right: inherit;
        }
      }
    }
  }

  .checkout-block__header {
    .icon {
      margin-left: inherit;
      margin-right: auto;

      @include respond-min($bp-tablet-wide-min) {
        margin-right: auto;
        margin-left: 2.5em;
      }
    }

    .checkout-block--complete & {
      cursor: pointer;
      border-bottom: 0;

      .icon {
        display: inherit;
      }
    }
  }

  .checkout-block__tabs__header {
    .payment-method-title {
      &:nth-child(odd) {
        margin-right: inherit;
      }
    }
  }

  .checkout-block__tabs__content {
    .choice {
      label {
        margin-right: inherit;
        margin-left: 1rem;
        padding-left: inherit;
        padding-right: 48px;

        &:before,
        &:after {
          left: inherit;
          right: 0;
        }
      }
    }
  }

  .checkout-block__tabs__content {
    .payment-method-content {
      .actions-toolbar {
        .primary {
          .action-cancel {
            float: left;
          }
        }
      }
    }
  }

}
