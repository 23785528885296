.message {
    padding: 1.85em;
    margin-top: 1.5em;
    &.error {
        background: $clr-dy-grey-2-hover;
    }
    &.success {
        color: #06c;
        border: 1px solid #06c;
    }
}

$action-padding_em: 0.5em;
$action-size_px: 16px;
$content-padding_px: 25px;

.message.cookie {
    background: $clr-dy-grey-2;
    margin-top: 0;
    position: relative;
    padding: 1.5em 0;

    .content {
        @extend .layout;
        position: relative;
        padding-left: 1.9em;
        padding-right: (($content-padding_px / $font-size__base) * 1em) + ($action-padding_em * 2) + (($action-size_px / $font-size__base) * 1em); // Ensure there's enough padding for the close action
    }

    .actions {
        position: absolute;
        display: block;
        right: 20px;
        top: -4px;

        @include respond-min($bp-desktop-min) {
            top: 0;
            right: 0;
        }

        .action {
            background: transparent;
            border: 0;
            display: block;
            padding: $action-padding_em;

            span {
                font-size: 0;
                display: block;

                &:after {
                    content: url(../images/icons/modal-close.svg);
                    width: $action-size_px;
                    height: $action-size_px;
                    font-size: $action-size_px;
                    line-height: $action-size_px;
                    color: #8f8f8f;
                    display: block;
                }
            }
        }
    }

    .header-notification__text--language {
      + .actions {
        top: 50%;
        margin-top: -14px;

        @include respond-min($bp-desktop-min) {
          margin-top: -16px;
        }
      }
    }

    p {
        @include fs-static(14);
        margin: 0.75em 0;

        a {
            font-weight: bold;
            text-decoration: underline;
        }

        &:first-of-type {
            @include fs-static(16);
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .header-notification__text--language {
      padding: .5em 0;

      @include respond-min($bp-desktop-min) {
        padding: .75em 0;
      }

      span, a {
        @include fs-static(16);
        line-height: 1.5;
        color: $clr-dy-grey-7;

        @include respond-min($bp-desktop-min) {
          @include fs-static(18);
        }
      }

      span {
        display: block;

        @include respond-min($bp-tablet-min) {
          display: inline-block;
        }
      }

      a {
        font-weight: normal;
        text-decoration: underline;
      }
   }
}

.dyson-nz {
  .payment-method-content {
    position: relative;
  }
}
