// -------------
// _basket.scss relied on overriding default styles, Magenot 2.2.1 broke that really bad
// New _leap-cart-scss module is more of a rewrite and tries to avoid default styles and markup found in the cart.
// -------------

.leap-cart {
  &:not(:last-child) {
    margin-bottom: 1em;
    @include respond-min($bp-tablet-min) {
      margin-bottom: 1.5em;
    }
  }
}

.leap-cart__row {
  border: 1px solid $clr-dy-grey-2;
  background: $clr-dy-grey-05;
    .leap-cart__row-header {
        @include respond-max($bp-mobile-wide-min) {
            [class*="g-col"] {
                padding-left: 0;
            }
        }
    }
}

.leap-cart__img {
  text-align: center;
  @include respond-min($bp-phablet-min) {
    border-right: 1px solid $clr-dy-grey-2;
  }

  img {
    max-width: 120px;
    width: 100%;
  }

  /*
    1. Cancel out padding-bottom hack put on by Magento in:-
    vendor/magento/module-catalog/view/base/web/template/product/list/columns/image_with_borders.html
    For some reason the padding bottom hack was being incorrectly applied when the product image was the placeholder.
  */
  .product-image-wrapper {
    padding: 0 !important; /* 1 */
  }
}
.leap-cart__row-header {
  border-bottom: 1px solid $clr-dy-grey-2;
  margin: 1em 1em 1em 0;
  padding-bottom: 1em;
}

.leap-cart__row-footer {
  margin: 1em 1em 1em 0;
}
.soft-bundling__main-item {
  .leap-cart__row-header {
    border-bottom: 1px solid $clr-dy-grey-2;
    margin: 0 0 1em 0;
    padding-bottom: 1em;
    @include respond-max($bp-phablet-wide-min) {
      border-bottom: 0;
    }
    .trade-up-item-tools__accordion-heading {
      .trade-up-item-tools__drop-down {
        padding-top: 5px;
        display: block;
        .trade-up-item-tools__drop-down-text {
          text-decoration: underline;
          @include respond-max($bp-phablet-wide-min) {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .trade-up-item-tools__chevron {
          width: 15px;
          height: 22px;
          margin-left: 5px;
          vertical-align: bottom;
        }
        &[aria-expanded=true] .trade-up-item-tools__chevron {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
    h2.soft-bundling__checkout-name {
      border-bottom: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .leap-cart__row-footer {
    margin: 0;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .witb__info {
      order: 2;
      .trade-up-item-tools__accordion-content {
        padding: 24px 24px 0;
        background: transparent;
        border-bottom: 0;
        border-top: 1px solid #ebebeb;
        margin-top: 24px;
        @include respond-max($bp-phablet-wide-min) {
          padding: 16px 0px 0;
          margin-top: 16px;
        }
        ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          padding-left: 0;
          margin: 0;
          .trade-up-item-tools__single {
            border-bottom: 1px solid #ebebeb;
            padding: 24px 0;
            width: calc(50% - 16px);
            display: flex;
            flex-direction: row;
            margin: 0;
            position: relative;
            overflow: unset;
            @include respond-max($bp-phablet-wide-min) {
              padding: 16px 0;
              width: 100%;
            }

            &:first-child, &:nth-child(2) {
              padding-top: 0;
              @include respond-max($bp-phablet-wide-min) {
                padding-top: 16px;
              }
            }
            &::marker {
              content: none;
            }
            &:nth-child(odd) {
              margin-right: 12px;
              @include respond-max($bp-phablet-wide-min) {
                margin-right: 0;
              }
            }
            &:nth-child(2n) {
              margin-left: 12px;
              @include respond-max($bp-phablet-wide-min) {
                margin-left: 0;
              }
            }
            &:last-of-type {
              @include respond-max($bp-phablet-wide-min) {
                padding-bottom: 0;
                border-bottom: 0;
              }
            }
            .trade-up-item-tools__block {
              width: calc(100% - 60px);
              margin-right: 16px;
            }
            .trade-up-item-tools__image {
              max-width: 44px;
              max-height: 44px;
              min-width: 44px;
              min-height: 44px;
              border: 0;
              position: relative;
              .machines__machine-icon {
                width: 44px;
                height: 44px;
                border: 1px solid #919191;
              }
            }
          }
          .trade-up-item-tools__block--web-name {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
            font-weight: 500;
            color: #333;
          }
          .trade-up-item-tools__block-description {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #555;
            margin: 0;
          }
        }
      }
    }
    .price-container {
      position: absolute;
      top: 0;
      right: 0;
      @include respond-max($bp-phablet-wide-min) {
        width: 100%;
        right: auto;
        padding-right: 20px;
      }
      @include respond-min($bp-phablet-wide-min) {
        top: 0 !important; // override style inline
      }
      .leap-cart__price {
        .soft-bundling__checkout-price {
          border-bottom: 0;
          padding: 0;
          > span:first-of-type {
            display: none;
          }
          .price, span {
            font-size: 1rem;
            color: #666;
            font-weight: 500;
            font-family: 'Futura Latin';
            @include respond-max($bp-phablet-wide-min) {
              font-size: .8125rem;
            }
          }
          @include respond-max($bp-phablet-wide-min) {
            font-size: 16px;
          }
        }
      }
    }
    .g-wrap.qty-picker {
      margin-top: 0 !important; // override style inline by js
      @include respond-max($bp-phablet-wide-min) {
        display: flex;
        align-items: center;
        margin-top: 20px !important;
        border-top: 1px solid #ebebeb;
        padding-top: 8px;
        .picker {
          padding-left: 0;
          width: 70%;
        }
        .remove {
          padding-left: 0;
          width: 30%;
        }
      }
      .remove {
        @include respond-max($bp-phablet-wide-min) {
          .action-delete span {
            font-size: 0.75rem;
          }
        }

      }
    }
    .leap-cart__quantity {
      .basket__items__quantity {
        display: flex;
        align-items: center;
        .label {
          margin-top: 0;
        }
        .update-cart__buttons {
          display: inline;
          margin-left: 8px;
          padding: 0;
        }
      }
    }
  }
}


.leap-cart__product-name {
  @include fs-responsive((g:20,gt:24,gtw:28));
  font-weight: 400;
  margin-bottom: 0.5em;

  @include respond-min($bp-tablet-wide-min) {
    margin-bottom: 0;
  }
  .urgency-messaging-cart {
    font-size: 1rem;
    margin-top: 10px;
    .urgency-icon-basket {
      vertical-align: text-bottom;
    }
  }
}

.leap-cart__price {
  @include respond-min($bp-mobile-wide-min) {
    position: relative;
    top: 0.5em;
  }
  @include respond-min($bp-tablet-min) {
    top: 0.35em;
  }
}
