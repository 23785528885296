.notify {

  .form__item--checkbox {
    label {
      background-color: transparent;
      padding-top: 5px;
    }
  }

  .form span > a {
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.product_notify_success {
  height: auto;
  min-height: 150px;
}
