.dyson-vn {

    .am-checkout .fieldset .field {
        @include respond-min($bp-tablet-min) {
            width: 48%;
        }

        &[name="shippingAddress.lastname"], &[name="shippingAddress.city"], &[name="shippingAddress.country_id"] {
            @include respond-min($bp-tablet-min) {
                margin-left: 4%;
            }

            @include respond-min($bp-tablet-wide-min) {
                margin-left: 0;
            }

        }

        &[name="shippingAddress.street.0"] {
            @include respond-min($bp-tablet-min) {
                width: 100%;
                margin-left: 9%;
            }
        }

        &[name="shippingAddress.country_id"], &[name="billingAddressonepay_domestic.country_id"] {
            display: none;
        }

        &[name="shippingAddress.custom_attributes.ward"], &[name="shippingAddress.custom_attributes.province"] {
            @include respond-min($bp-tablet-min) {
                clear: both;
            }
        }

        &[name="shippingAddress.telephone"] .control, &[name="shippingAddress.street.0"] .control {
            @include respond-min($bp-tablet-wide-min) {
                width: 100%;
            }
        }

        &[name="shippingAddress.city"], &[name="shippingAddress.country_id"] {
            @include respond-min($bp-tablet-wide-min) {
                margin-left: 4%;
                margin-right: 0;
            }
        }

        &[name="billingAddressonepay_domestic.firstname"] {
            @include respond-min($bp-tablet-min) {
                margin-right: 4%;
            }
        }

        &[name="billingAddressonepay_domestic.street.0"] {
            @include respond-min($bp-tablet-min) {
                width: 100%;
                margin-left: 9%;
            }
        }

        &[name="billingAddressonepay_domestic.city"], &[name="billingAddressonepay_domestic.country_id"], &[name="billingAddressonepay_international.lastname"], &[name="billingAddressonepay_international.city"], &[name="billingAddressonepay_international.custom_attributes.ward"],
        &[name="billingAddressonepay_instalments.lastname"], &[name="billingAddressonepay_instalments.city"], &[name="billingAddressonepay_instalments.custom_attributes.ward"], &[name="billingAddressbanktransfer.lastname"], &[name="billingAddressbanktransfer.city"], &[name="billingAddresscheckmo.city"] {
            @include respond-min($bp-tablet-min) {
                margin-left: 4%;
            }
        }

        &[name="shippingAddress.telephone"],
        &[name="billingAddressonepay_domestic.telephone"],
        &[name="billingAddressonepay_international.telephone"],
        &[name="billingAddressonepay_international.custom_attributes.province"],
        &[name="billingAddressonepay_instalments.telephone"],
        &[name="billingAddressonepay_instalments.custom_attributes.province"],
        &[name="billingAddressonepay_domestic.custom_attributes.province"],
        &[name="billingAddressonepay_domestic.custom_attributes.ward"],
        &[name="billingAddressonepay_international.country_id"],
        &[name="billingAddressonepay_instalments.country_id"] {
            clear: both;
        }
    }


    .form-login {
        @include respond-min($bp-tablet-min) {
            width: 48%;
        }

        @include respond-min($bp-tablet-wide-min) {
            width: 100%;
        }
    }

    .field.field--checkboxes.newsletter-signup-component, .billing-address-same-as-shipping-block.field.choice, .checkout-agreement.field.choice.required {
        width: 100%;
        margin-bottom: 0;
    }

    .payment-method-billing-address {
        @include respond-min($bp-phablet-wide-min) {
            margin-top: 28px;
        }
        @include respond-max($bp-phablet-wide-min) {
            margin-top: 28px;
        }
        @include respond-min($bp-tablet-wide-min) {
            margin-top: 24px;
        }
    }

    //overriding OnePay's styles
    .banks-list .payment-description {
        display: block;
        margin: 10px 0;
    }

    .dl-dropdown-district, .field-district {
        @include respond-min($bp-tablet-min) {
            margin-left: 4%
        }
    }

    // Stop checkbox text overlapping at mobile
    /*.billing-address-same-as-shipping-block {
        margin-bottom: 3em;

        @include respond-min($bp-tablet-min) {
            margin-bottom: 1.5em;
        }
    }*/

    div.newsletter-signup-component {
        margin-bottom: 2.5em;

        @include respond-min($bp-tablet-min) {
            margin-bottom: 1.5em;
        }
    }

}
//6463
.dyson-vn {
    .delivery_proposition_wrapper {
        &.hide-desktop {
            .delivery-proposition {
                @include respond-max($bp-phablet-wide-min) {
                    //max 640 Mobile
                    padding: 0px 0px !important;
                }
            }
        }
    }
}
.dyson-vn {
    .cart-notice {
        @include respond-min($bp-phablet-wide-min) {
            //min 640 tab
            margin: 0 0 40px 0 !important;
        }
        @include respond-max($bp-phablet-wide-min) {
            //max 640 mobile
            margin: 32px 0 32px 0 !important;
        }
        @include respond-min($bp-tablet-wide-min) {
            //1024 desktop
            margin: 0 0 48px 0 !important;
            width: 100%;
        }
    }
    &.dyson-empty-cart {
        .cart-notice {
            @include respond-min($bp-phablet-wide-min) {//min 640 tab
                margin: 40px auto 20px auto !important;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                margin: 32px auto 16px auto !important;
            }
            @include respond-min($bp-tablet-wide-min) {//1024 desktop
                margin: 48px auto 24px auto !important;
            }
        }
    }

    //ROW-5333 Checkout Billing Address Title Label
    h5.delivery-address-label {
        font-weight: 300;
        color: #333;
        font-family: $f-futura;
        @include respond-min($bp-phablet-wide-min) { //min 640 tab
            margin-bottom: 20px;
            font-size: 22px;
            line-height: 28px;
            padding-top: 20px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 28px;
            padding-top: 16px;
        }
        @include respond-min($bp-tablet-wide-min) {// 1024 desktop
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 32px;
            padding-top: 24px;
        }
    }
    //end Row-5333
}

.dyson-vn {

    .am-checkout .fieldset .field {

        &[name="shippingAddress.telephone"] {
            .field-error {
                bottom: -17px;
            }
        }

        &[name="shippingAddress.city"] {
            @include respond-min($bp-tablet-min) {
                margin-left:0;
                clear:left;
                margin-right: 4%;
            }

            @include respond-min($bp-tablet-wide-min) {
                margin-left: 0;
            }

        }

        &[name="shippingAddress.custom_attributes.province"]{
            @include respond-min($bp-tablet-min) {
                clear:none;
            }
        }
        &.dl-dropdown-district {
            @include respond-min($bp-tablet-min) {
                margin-left:0;
            }
        }

        &.shippingTitleLabel{
            margin-bottom: 0;
            clear: left;
            width:100%;
            label {
                display:none;
            }
            h5 {
                margin:0;
            }
        }

        &.field[name="shippingAddress.custom_attributes.ward"]{
            clear: none;
        }

        &.field[name="shippingAddress.custom_attributes.district"]{
            margin-right: 4%;

        }

        &.modified_address {
            margin:0 0 24px 0;
            @include respond-max($bp-tablet-wide-min) {
                margin:-8px 0 20px 0;

            }
            @include respond-max($bp-tablet-min) {
                margin:-12px 0 16px 0;

            }
            h5 {
                color:$color-gray20;
                font-weight: $font-weight__regular;
                font-family: $f-futura;
                line-height: 32px;
                font-size:24px;

                @include respond-max($bp-tablet-wide-min) {
                    line-height: 28px;
                    font-size:22px;
                }
                @include respond-max($bp-tablet-min) {
                    font-size:20px;
                }
            }
        }
    }
}

.dyson-vn {
    .am-checkout {
        .fieldset {
            .field[name="billingAddressonepay_international.custom_attributes.ward"],
            .field[name="billingAddressonepay_instalments.custom_attributes.ward"],
            .field[name="billingAddressbanktransfer.custom_attributes.ward"],
            .field[name="billingAddresscheckmo.custom_attributes.ward"] {
                margin-left: 0%;
                clear: both;
            }
        }
        div.field-error {
            margin-top: 8px;
            font-weight: 300;
            font-size: 14px !important;
            letter-spacing: 0;
            line-height: 20px;
        }
    }
}
