body.rtl {
  .checkout__row {
    .opc-progress-bar {
      @include min-screen($bp-checkout-wide) {
        padding-right: inherit;
        padding-left: 350px;
      }
    }

    ol.minicart-items {
      .product-item {
        .product {
          .product-image-container {
            margin-right: auto;
            margin-left: 8px;
          }

          .product-item-details {
            .details-qty {
              .value {
                float: left;
                padding-right: 0;
                padding-left: 40px;
              }
            }
          }
        }
      }
    }

    #shipping-new-address-form,
    .checkout-billing-address fieldset {
      .field {
        float: right;

        @include respond-min($bp-tablet-min) {
          &:nth-child(1) {
            margin-right: auto;
            margin-left: 4%;
          }

          &:nth-child(3) {
            margin-right: auto;
            margin-left: 4%;
          }

          &:nth-child(5) {
            margin-right: auto;
            margin-left: 4%;
          }

          &:nth-child(8) {
            margin-right: auto;
            margin-left: 4%;
          }

          &:nth-child(10) {
            margin-right: auto;
            margin-left: 4%;
          }
        }
      }
    }

    .opc-sidebar {
      margin-left: auto;
      margin-right: 30px;
    }

    .opc-block-summary {
      .table-totals {
        th,td {
          text-align: right;
        }
      }
    }

    .opc-block-shipping-information {
      .action {
        margin-left: auto;
        margin-right: 8px;
      }
    }

    .opc-wrapper .opc .form.payments#co-payment-form .payment-method .actions-toolbar {
      text-align: left;
    }
  }

  // Country specific

  &.dyson-il {
    #shipping-new-address-form {
      .field.street .field {
        margin-right: 0!important;
      }

      div.field[name="shippingAddress.telephone"] {
        @include respond-min($bp-tablet-min) {
          margin-right: 0;
        }
      }
    }
  }

  &.dyson-ae {
    #shipping-new-address-form {
      .field.street .field {
        margin-right: 0!important;
      }
    }

    .checkout__row {
      .opc-wrapper .opc .form {
        [name="shippingAddress.street.0"] {
          margin-right: 0!important;
        }
      }
    }
  }
}
