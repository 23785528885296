/*
    We are using Magento's Modal Widget
    http://devdocs.magento.com/guides/v2.0/javascript-dev-guide/widgets/widget_modal.html

    This file just overrides some of the defaults.
*/

.modal-popup {
    .modal-inner-wrap {
        opacity: 0;
        transition: opacity .25s ease-in-out !important;
        width: 95%;
        margin-top: 25px;

        @include min-screen(755px) {
            width: 75%;
            margin-top: 80px;
        }
    }

    &._show {
        .modal-inner-wrap {
            opacity: 1;
        }
    }

    &.modal-small {
      .modal-inner-wrap {
        @include min-screen(755px) {
          // Don't change width
          width: 27%;
        }
      }
    }

    .modal-content {
        padding-bottom: 25px;
    }

    .modal-content,
    .modal-footer,
    .modal-header {
        padding-left: 45px;
        padding-right: 45px;
    }

    .modal-header {
        padding-top: 25px;
        padding-bottom: 20px;
        z-index: 9999;
    }

    .modal-title {
        float: left;
        @include fs-responsive((g:28px, gt:32px, gtw:40px));
        width: 80%;

        @include respond-min(370px) {
            width: auto;
        }
    }
}

// force scrollbars to stop jerk of removing them when the modal popup appears
html {
    overflow-y: scroll;
}
