body.rtl {
  .sub-nav__item {
    @include respond-min($bp-tablet-min) {
      &:nth-child(odd) {
        padding-right: 0;
        padding-left: 10px;
      }
    }

    @include respond-min($bp-tablet-wide-min) {
      &:nth-child(odd) {
        padding-left: 30px;
      }
    }
  }
}
