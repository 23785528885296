$rcc-breakpoints: (g:0, gt:$bp-tablet-min, gp:$bp-phablet-min, gtw:$bp-tablet-wide-min, gd:$bp-desktop-min); // mimic grid breakpoints

//for each breakpoint:
@each $bp-code, $bp in $rcc-breakpoints {
  //RCC color theme helpers

  // LIGHT THEME
  .rcc__clr-theme--light--#{$bp-code}{

    @include respond-min($bp) {
      h1, h2, h3, h4, h5, h6 {
        font-family: $f-futura;
        color: $clr-dy-grey-7;
        margin: 10px 0;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl &{
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }

      }
      p, li, em, strong, small {
        color: $clr-dy-grey-6;
        font-family: $f-futura;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }

      span.roundal--solid {
        background: #333333;
        margin-right: 10px;
      }

      svg.icon {
        fill: $clr-white;
      }
      .cta-icon {
        background-color: $clr-dy-grey-7;
        fill: $clr-white;
      }
      button {
        background: $clr-dy-grey-7;
        color: $clr-white;
      }

      .rcc__content__value-stacks__feature {
        border-bottom: 1px solid #333;
        p {
          color: #333;
        }
      }
    }
  }

  // MEDIUM THEME
  .rcc__clr-theme--medium--#{$bp-code} {
    @include respond-min($bp) {

      h1, h2, h3, h4, h5, h6 {
        font-family: $f-futura;
        color: $clr-dy-grey-7;
        margin: 10px 0;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }
      p, li, small {
        font-family: $f-futura;
        color: $clr-dy-grey-7;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }
      span.roundal--solid {
        background: $clr-dy-grey-7;
        margin-right: 10px;
      }
      svg.icon {
        fill: $clr-white;
      }
      .cta-icon {
        background-color: $clr-dy-grey-7;
        fill: $clr-white;
      }
      button {
        background: $clr-dy-grey-7;
        color: $clr-white;
      }

      .rcc__content__value-stacks__feature {
        border-bottom: 1px solid #333;
        p {
          color: #333;
        }
      }

    }
  }

  // DARK THEME
  .rcc__clr-theme--dark--#{$bp-code} {
    @include respond-min($bp) {

      h1, h2, h3, h4, h5, h6 {
        font-family: $f-futura;
        color: $clr-white;
        margin: 10px 0;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }
      p, li, small {
        font-family: $f-futura;
        color: $clr-white;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }

      span {
        color: $clr-white;
      }

      span.roundal--solid {
        background: $clr-white;
        margin-right: 10px;
      }

      svg.icon {
        fill: $clr-dy-grey-7;
      }
      .cta-icon {
        background-color: $clr-white;
        fill: $clr-dy-grey-7;
      }
      button {
        background: $clr-white;
        color: $clr-dy-grey-7;
      }

      .rcc__content__value-stacks__feature {
        border-bottom: 1px solid #fff;
        p {
          color: #fff;
        }
      }

      .button.rcc__cta--button-link,
      .button:link.rcc__cta--button-link:link,
      .button.rcc__cta--button-link--50pc,
      .button:visited.rcc__cta--button-link:visited,
      .button:active.rcc__cta--button-link:active {
        background: $clr-white !important;
        color: $clr-dy-grey-7 !important;

        span {
          color: $clr-dy-grey-7 !important;
        }
      }

      .button--tertiary.rcc__cta--button-link,
      .button--tertiary:link.rcc__cta--button-link:link,
      .button--tertiary.rcc__cta--button-link--50pc,
      .button--tertiary:visited.rcc__cta--button-link:visited,
      .button--tertiary:active.rcc__cta--button-link:active {
        background: $clr-dy-green-0 !important;
        color: $clr-dy-grey-7 !important;

        span {
          color: $clr-dy-grey-7 !important;
        }
      }
    }
  }

  .rcc__clr-theme--black--#{$bp-code} {
    // BLACK THEME
    @include respond-min($bp) {

      h1, h2, h3, h4, h5, h6 {
        font-family: $f-futura;
        color: $clr-white;
        margin: 10px 0;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }
      p, li, small {
        font-family: $f-futura;
        color: $clr-dy-grey-5;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }
      span{
        color: $clr-white;
      }

      span.roundal--solid {
        background: $clr-white;
        margin-right: 10px;
      }

      svg.icon {
        fill: $clr-dy-grey-7;
      }

      .cta-icon {
        background-color: $clr-white;
        fill: $clr-dy-grey-7;
      }
      button {
        background: $clr-white;
        color: $clr-dy-grey-7;
      }

      .rcc__content__value-stacks__feature {
        border-bottom: 1px solid #fff;
        p {
          color: #fff;
        }
      }
      .button.rcc__cta--button-link,
      .button.rcc__cta--button-link--50pc,
      .button:link.rcc__cta--button-link:link,
      .button:visited.rcc__cta--button-link:visited,
      .button:active.rcc__cta--button-link:active {
        background: $clr-white !important;
        color: $clr-dy-grey-7 !important;

        span {
          color: $clr-dy-grey-7 !important;
        }
      }
    }
  }

  .rcc__clr-theme--blue--#{$bp-code} {
    // BLUE THEME
    @include respond-min($bp) {

      h1, h2, h3, h4, h5, h6 {
        font-family: $f-futura;
        color: $clr-dy-blue-1;
        margin: 10px 0;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }
      p, li, small {
        font-family: $f-futura;
        color: $clr-dy-grey-7;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }
      }

      span.roundal--solid {
        background: $clr-dy-grey-7;
        margin-right: 10px;
      }

      svg.icon {
        fill: $clr-white;
      }

      .cta-icon {
        background-color: $clr-dy-grey-7;
        fill: $clr-white;
      }
      button {
        background: $clr-dy-grey-7;
        color: $clr-white;
      }
      .button {
        background: $clr-dy-grey-7 !important;
        color: $clr-white !important;
      }

      .rcc__content__value-stacks__feature {
        border-bottom: 1px solid #333;
        p {
          color: #333;
        }
      }
    }
  }
}

$widthStart: 0;
$widthEnd: 12;

@each $bp-code, $bp in $rcc-breakpoints {

  //RCC Text width helpers
  @for $i from $widthStart through $widthEnd {
    .rcc__text-width--#{$i}--#{$bp-code} {
      @include respond-min($bp) {
        width: 8.333% * $i;

        .rcc__content__value-stacks {
          $column: 100%;
          @if $i > 0 {
            $column: 100% / $i;
          }

          width: $column * 11;

          @include respond-min($bp-tablet-min) {
            width: $column * 4;
          }
        }
      }
    }
  }

  //RCC Content offset classes
  .rcc__text-offset--0--#{$bp-code} { @include respond-min($bp) { position: relative; left: 0; right: auto; } }
  .rcc__text-offset--1--#{$bp-code} { @include respond-min($bp) { position: relative; left: 8.333%; right: auto; } }
  .rcc__text-offset--2--#{$bp-code} { @include respond-min($bp) { position: relative; left: 16.666%; right: 0; margin: 0 auto; } }
  .rcc__text-offset--3--#{$bp-code} { @include respond-min($bp) { position: relative; left: 24.999%; right: auto; } }
  .rcc__text-offset--4--#{$bp-code} { @include respond-min($bp) { position: relative; left: 33.332%; right: auto ; } }
  .rcc__text-offset--5--#{$bp-code} { @include respond-min($bp) { position: relative; left: 8.333% * 5; right: auto; } }
  .rcc__text-offset--6--#{$bp-code} { @include respond-min($bp) { position: relative; left: 8.333% * 6; right: auto; } }
  .rcc__text-offset--7--#{$bp-code} { @include respond-min($bp) { position: relative; left: 8.333% * 7; right: auto; margin: 0 auto; } }
  .rcc__text-offset--8--#{$bp-code} { @include respond-min($bp) { position: relative; left: 8.333% * 8; right: 10%; } }
  .rcc__text-offset--9--#{$bp-code} { @include respond-min($bp) { position: relative; left: 8.333% * 9; right: 0; } }

  //RCC Text align Classes
  .rcc__text-align--left--#{$bp-code} { @include respond-min($bp) { text-align: left; } }
  .rcc__text-align--center--#{$bp-code} { @include respond-min($bp) { text-align: center; } }
  .rcc__text-align--right--#{$bp-code} { @include respond-min($bp) { text-align: right; } }

  //RCC Text position vertical helpers
  // .rcc__text-pos--above--#{$bp-code} { @include respond-min($bp) { position: absolute; top: 0; bottom: auto; transform: translateY(0); } }
  .rcc__text-pos--0--#{$bp-code} { @include respond-min($bp) { position: absolute; top: 0; bottom: auto; transform: translateY(0); } }
  .rcc__text-pos--5--#{$bp-code} { @include respond-min($bp) { position: absolute; top: 50%; bottom: auto; transform: translateY(-50%); } }
  .rcc__text-pos--10--#{$bp-code} { @include respond-min($bp) { position: absolute; top: auto; bottom: 0; transform: translate(0, 0); } }
  // .rcc__text-pos--below--#{$bp-code} { @include respond-min($bp) { position: absolute; top: 0; bottom: auto; transform: translateY(0); } }

  //RCC Motif positioning classes
  .rcc__motif-pos--in-line--#{$bp-code} { @include respond-min($bp) { display: inline-block; position: static; } }
  .rcc__motif-pos--top-left--#{$bp-code} { display: inline-block; position: static; @include respond-min($bp) { position: absolute; top: 0; bottom: auto; left: 0; right: auto; transform: translate(0, 0); } }
  .rcc__motif-pos--top-center--#{$bp-code} { display: inline-block; position: static; @include respond-min($bp) { position: absolute; top: 0; left: 50%; bottom: auto; transform: translate(-50%, 0); text-align: center; } }
  .rcc__motif-pos--top-right--#{$bp-code} { display: inline-block; position: static; @include respond-min($bp) { position: absolute; top: 0; right: 0; bottom: auto; transform: translateY(0); text-align: right; } }
  .rcc__motif-pos--bottom-left--#{$bp-code} { display: inline-block; position: static; @include respond-min($bp) { position: absolute; bottom: 0; top: auto; left: 0; transform: translateY(0); text-align: left; } }
  .rcc__motif-pos--bottom-center--#{$bp-code} { display: inline-block; position: static; @include respond-min($bp) { position: absolute; bottom: 0; left: 50%; top: auto; transform: translate(-50%, 0); text-align: center; } }
  .rcc__motif-pos--bottom-right--#{$bp-code} { display: inline-block; position: static; @include respond-min($bp) { position: absolute; bottom: 0; right: 0; left: auto; top: auto; transform: translate(0); } }

  //RCC play button positioning
  .rcc__play-btn-pos--0pc--#{$bp-code} { @include respond-min($bp) { top: 0; bottom: auto; transform: translate(-50%, 0); } }
  .rcc__play-btn-pos--50pc--#{$bp-code} { @include respond-min($bp) { top: 50%; bottom: auto; } }
  .rcc__play-btn-pos--100pc--#{$bp-code} { @include respond-min($bp) { bottom: 0; top: auto; transform: translate(-50%, 0); } }
}

//anomolies
.rcc__motif-pos--top-left--gt, .rcc__motif-pos--bottom-left--gt {
  @include respond-min($bp-tablet-min) {
    margin-left: 50px;
  }
  @include respond-min($bp-tablet-wide-min) {
    margin-left: 0;
  }
}

.rcc__motif-pos--top-right--gt, .rcc__motif-pos--bottom-right--gt {
  @include respond-min($bp-tablet-min) {
    margin-right: 50px;
  }
  @include respond-min($bp-tablet-wide-min) {
    margin-left: 0;
  }
}


.rcc__layout .rcc__image.rcc__motif-pos--bottom-left--gtw,
.rcc__layout .rcc__image.rcc__motif-pos--bottom-middle--gtw,
.rcc__layout .rcc__image.rcc__motif-pos--bottom-right--gtw,
.rcc__layout .rcc__image.rcc__motif-pos--top-left--gtw,
.rcc__layout .rcc__image.rcc__motif-pos--top-middle--gtw,
.rcc__layout .rcc__image.rcc__motif-pos--top-right--gtw {
  display: none;

  @include respond-min($bp-tablet-wide-min) {
    display: inline-block;
  }
}


.rcc__body__inner .rcc__image.rcc__motif-pos--bottom-left--gtw,
.rcc__body__inner .rcc__image.rcc__motif-pos--bottom-middle--gtw,
.rcc__body__inner .rcc__image.rcc__motif-pos--bottom-right--gtw,
.rcc__body__inner .rcc__image.rcc__motif-pos--top-left--gtw,
.rcc__body__inner .rcc__image.rcc__motif-pos--top-middle--gtw,
.rcc__body__inner .rcc__image.rcc__motif-pos--top-right--gtw {
  display: inline-block;

  @include respond-min($bp-tablet-wide-min) {
    display: none;
  }
}

// Title (line 1) Size
.rcc__title-size--heading_1 {
  font-family: $f-futura;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 12px;
  margin-top: 0;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    font-size: 36px;
    line-height: 44px;
  }

  @include respond-min($bp-tablet-wide-min) {
    font-size: 44px;
    line-height: 52px;
  }

}
.rcc__title-size--heading_2 {
  font-family: $f-futura;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 12px;
  margin-top: 0;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    font-size: 32px;
    line-height: 40px;
  }

  @include respond-min($bp-tablet-wide-min) {
    font-size: 36px;
    line-height: 44px;
  }

}
.rcc__title-size--heading_3 {
  font-family: $f-futura;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  margin-top: 0;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    font-size: 28px;
    line-height: 36px;
  }
  @include respond-min($bp-tablet-wide-min) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }
}
.rcc__title-size--heading_4 {
  font-family: $f-futura;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: 0;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    font-size: 24px;
    line-height: 32px;
  }
  @include respond-min($bp-tablet-wide-min) {
    font-size: 28px;
    line-height: 36px;
  }
}
.rcc__title-size--heading_5 {
  font-family: $f-futura;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: 0;
  font-weight: 400;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    font-size: 22px;
    line-height: 28px;
  }
  @include respond-min($bp-tablet-wide-min) {
    font-size: 24px;
    line-height: 32px;
  }
}
.rcc__title-size--heading_6 {
  font-family: $f-futura;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 0;
  font-weight: 500;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    font-size: 16px;
    line-height: 24px;
  }
  @include respond-min($bp-tablet-wide-min) {
    font-size: 18px;
    line-height: 28px;
  }
}
.rcc__title-size--large_paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  @include respond-min($bp-tablet-min) {
    font-size: 16px;
    line-height: 24px;
  }
  @include respond-min($bp-tablet-wide-min) {
    font-size: 18px;
    line-height: 28px;
  }
}
.rcc__title-size--paragraph {
  font-family: $f-futura;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 8px;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-wide-min) {
    font-size: 16px;
  }
}
.rcc__title-size--typography_body_fine {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 8px;

  @include respond-min($bp-tablet-wide-min) {
    font-size: 16px;
  }
}

//Divider line overriding style
.rcc--divider_line_top {
  border-top: 1px solid $clr-divider-line;
}
.rcc--divider_line_bottom {
  border-bottom: 1px solid $clr-divider-line;
}

//CTA classes
.rcc__cta--arrow-link {
  margin-top: 1em;
}

.rcc__cta--arrow-link--right {
  float: right
}

.rcc__cta--button-link {
  width: 100%;
  @include respond-min($bp-tablet-min) {
    width: auto;
  }
}

.rcc__cta--button-link--50pc {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  @include respond-min($bp-tablet-min) {
    width: auto;
    max-width: 46%;
    display: inline-block;
    margin-right: 10px;

      .dyson-ro & {
          max-width: 50%;
      }

  }
}

.rcc__icon-link {
  display: flex;
}

.rcc__roundal {
  flex-shrink: 0;

  body.rtl & {
    margin-left: 10px;
  }
}

.rcc__caveat {
  display: block;
}

//layout
.rich-content-component {
  position: relative;
  overflow: hidden;

  @include respond-min($bp-tablet-min) {
    //display: block;
  }

  video {
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    z-index: 0;
    background-size: cover;
    overflow: hidden;
    object-fit: cover;
  }

}

.rich-content-component--partials {
  display: block;

  @include respond-min($bp-tablet-min) {
    display: flex;
  }
}

.rcc__layout {
  @include layout;
  height: 100%;

  @include respond-min($bp-tablet-min) {
    position: relative;
    margin: 0 auto;
  }
}

.rcc__body__inner {
  position: relative;
  height: 100%;
}

.rcc__content {
  @include fs-responsive((g:16, gtw:20));
  //position: absolute;

  @include respond-min($bp-mobile-wide-min) {
    max-width: 100%;
  }

  @include respond-min($bp-tablet-min) {
    margin: 0;
  }
}

.rcc__body {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  .rtl & {
    top: 0;
    left: 0;
  }
}

.rcc__body--partial {
  @extend .rcc__body; //from above so it's ok to leave this extend in.

  @include respond-min($bp-tablet-min) {
    width: 50%;
  }
}

.rcc__media--partial {
  @include respond-min($bp-tablet-min) {
    width: 50%;
  }
}

.rcc__body--inline--g {
  position: relative;

  @include respond-min($bp-tablet-wide-min) {
    position: absolute;
  }
}

.rcc__body.rcc__body--inline--g .rcc__content {
  @include respond-min($bp-tablet-wide-min) {
    position: absolute;
  }
}

.rcc__media {
  min-height: 1%;
  //added for IE11 bug to remove extra spaces under image

  .roundal__icon .icon {
    fill: #fff;
    position: relative;
    top: 2px;
    left: 50%;
    width: 2.3em;
    height: 2.3em;
    cursor: pointer;
    transform: translate(-40%, 0);
  }

  .roundal--light {
    background: rgba(255, 255, 255, 0.2);
  }
}

.rcc__button {
  //@extend .hero__button;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  //display: flex;
  //flex-direction: column;
  width: 100%;

  &:hover {
    background: none;
    border: none;
  }
}

.rcc__button .rcc__body {
  display: flex;
  flex-direction: column;
}

.rcc__button--50pc {
  //@extend .hero__button;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;

  @include respond-min($bp-tablet-min) {
    width: 50%;
  }

  &:hover {
    background: none;
    border: none;
    padding: 0;
  }

  .g-col {
    padding: 0;
  }
}

.gol-col-1-2--100pc {
  @include respond-min(640px) {
    width: 100%
  }
  @include respond-min($bp-tablet-min) {
    width: 50% !important;
  }
}

.gpw-col, [class*="gpw-col"] {
  @include respond-min($bp-tablet-min) {
    width: 50% !important;
  }
}

.rcc__content--2.rcc__text-pos--below--g {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.rcc__body--50pc {
  position: relative;
  width: 100%;
  z-index: 1;

  @include respond-min($bp-tablet-wide-min) {
    width: 50%;
    height: 100%;
    display: inline-block;
    position: absolute;
  }
}

.rcc__body--50pc--right {
  position: relative;
  width: 100%;
  z-index: 1;

  @include respond-min($bp-tablet-min) {
    width: 50%;
    height: 100%;
    display: inline-block;
    position: absolute;
    right: 0;
    left: auto;
  }
}

.rcc__body--vert-bottom--50pc {
  @include respond-min ($bp-tablet-min) {
    z-index: 1;
    width: 100%;
    height: 50%;
    bottom: 0;
    display: block;
  }
}

.rcc__body--vertical--50pc {
  @include respond-min($bp-tablet-min) {
    z-index: 1;
    width: 100%;
    height: 50%;
    display: block;
  }
}

.rcc__body--vertical--50pc.rcc__body--inline--g.js-rcc__body-bg-override,
.rcc__body--vert-bottom--50pc.rcc__body--inline--g.js-rcc__body-bg-override {
  position: relative;

  @include respond-min($bp-tablet-min) {
    position: absolute;
  }
}

.rcc__content--prefix {
  margin: 0;
}

.rcc__body--inline--g,
.rcc__body--inline--g .hero__content,
.rcc__body--inline--g .rcc__content {
  //position: relative;

  //@include respond-min($bp-tablet-min) {
  //  position: absolute;
  //}
}

.rich-content-component {
  .row--minuscule {
    padding-top: 40px;
  }

  .row--minuscule--rcc--below {
    padding-top: 0;
  }
}

.row--minuscule--rcc--above {
  padding-top: 40px;
}

.row--minuscule--rcc {
  padding-bottom: 0;
  padding-top: 40px;
}

.rich-content-component .row--minuscule {
  padding-top: 40px;
}

.row--minuscule--rcc--below {
  padding-top: 0;
  padding-bottom: 40px;
}


.g-col.gpw-col-1-2--100pc.gpw-col-1-2--100pc--in-line-video {
  width: 100%;
}

.icon-link.rcc__icon-link {
  display: inline-flex;
}

.rcc__body--inline--g.rcc__body--mobile--below,
.rcc__body__inline--g.rcc__body--tablet--below,
.rcc__body__inline--g.rcc__body--desktop--below {
  position: relative;

}

.rcc__body--inline--g.rcc__body--desktop--top,
.rcc__body--inline--g.rcc__body--desktop--middle,
.rcc__body--inline--g.rcc__body--desktop--bottom {
  @include respond-min($bp-tablet-wide-min) {
    position: absolute;
  }
}

.rcc__body--inline--g.rcc__body--tablet--top,
.rcc__body--inline--g.rcc__body--tablet--middle,
.rcc__body--inline--g.rcc__body--tablet--bottom {
  @include respond-min($bp-tablet-min) {
    position: absolute;
  }
}

.rcc__body--inline--g.rcc__body--mobile--top,
.rcc__body--inline--g.rcc__body--mobile--middle,
.rcc__body--inline--g.rcc__body--mobile--bottom {
  position: absolute;
}

.row--minuscule--rcc--above--mobile {
  padding-top: 40px;
}

.row--minuscule--rcc--above--tablet {
  @include respond-min($bp-tablet-min) {
    padding-top: 40px;
  }
}

.row--minuscule--rcc--above--desktop {
  @include respond-min($bp-tablet-wide-min) {
    padding-top: 40px;
  }
}

.row--minuscule--rcc--below--mobile {
  padding-bottom: 40px;
  padding-top: 0;
}

.row--minuscule--rcc--below--tablet {
  @include respond-min($bp-tablet-min) {
    padding-bottom: 40px;
    padding-top: 0;
  }
}

.row--minuscule--rcc--below--desktop {
  @include respond-min($bp-tablet-wide-min) {
    padding-bottom: 40px;
    padding-top: 0;
  }
}

.row--minuscule--rcc--mobile {
  padding-top: 40px;
  padding-bottom: 40px;
}

.row--minuscule--rcc--tablet {
  @include respond-min($bp-tablet-min) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.row--minuscule--rcc--desktop {
  @include respond-min($bp-tablet-wide-min) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

// This will change depending on the rcc__text-width--12--g

.rcc__content__value-stacks {
  margin: 32px 0 6px;

  @include respond-min($bp-tablet-min) {
    margin: 40px 0 10px;
  }

  @include respond-min($bp-tablet-wide-min) {
    margin: 48px 0 14px;
  }

  @include respond-min($bp-desktop-min) {
    margin: 40px 0 14px;
  }
}

.rcc__content__value-stacks__feature {
  padding: 8px 0 8px;
  border-bottom: 1px solid;

  @include respond-min($bp-tablet-min) {
    padding: 12px 0 12px;
  }

  @include respond-min($bp-tablet-wide-min) {
    padding: 16px 0 16px;
  }
}

.rcc__content__value-stacks__feature--first {
  padding-top: 0;
}

.rcc__content__value-stacks__feature.rcc__content__value-stacks__feature--last {
  border-bottom: none;
  padding: 8px 0 0;
  @include respond-min($bp-tablet-min) {
    padding: 12px 0 0;
  }
  @include respond-min($bp-tablet-wide-min) {
    padding: 16px 0 0;
  }
}

.rcc__body--mobile--below {
  order: 1;
}

.rcc__body--tablet--above {
  @include respond-min($bp-tablet-min) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    //height: 15em;
    z-index: 1;
    order: 0;
  }

  @include respond-min($bp-tablet-wide-min) {
    position: absolute;
    height: 100%;
    max-height: 100%;
    top: auto;
    left: auto;
  }
}

.rcc__body--tablet--below {
  @include respond-min($bp-tablet-min) {
    order: 1;
  }
}
.rcc__body--desktop--below {
  @include respond-min($bp-tablet-wide-min) {
    order: 1;
    position: relative;
  }
}

.rcc__body--desktop--above {
  @include respond-min($bp-tablet-wide-min) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 15em;
    z-index: 1;
    max-height: 25em;
    order: 0;
  }
}

.row--minuscule--rcc--above--desktop {
  @include respond-min($bp-tablet-wide-min) {
    padding-top: 40px;
  }
}

.flexbox-for-above-below {
  display:flex;
  flex-direction: column;
}
// ROW-8180 Add-Hedgehog-Functionality-Design-Config-Start
.hedgehog {
  &.hedgehog-light-theme {
    background: #ffffff;
    .hedgehog_heading, .hedgehog_sub_heading {
      color: #333333;
    }
    .hedgehog-feature-action-block {
      border-top: 1px solid #EBEBEB;
      .hedgehog-action {
        color: #333333;
        .hedgehog-action-icon {
          fill: #333333;
        }
      }
    }
    .line {
      background: #333333;
      .HotspotPlugin_Hotspot {
        background: #ffffff;
        .Hotspot_ContentMessage {
          background: rgba(255,255,255,0.74);
          .hedgehog_spikes_label, .hedgehog_spikes_desp {
            color: #333333;
          }
          &:hover {
            background: rgba(235,235,235,0.74);
          }
        }
      }
      .HotspotPlugin_Hotspot.active {
        .Hotspot_ContentMessage {
          background: #333333;
          .hedgehog_spikes_label, .hedgehog_spikes_desp {
            color: #ffffff;
          }
        }
      }
    }
  }
  &.hedgehog-dark-theme {
    background: #000000;
    .hedgehog_heading, .hedgehog_sub_heading {
      color: #ffffff;
    }
    .hedgehog-feature-action-block {
      border-top: 1px solid #333333;
      .hedgehog-action {
        color: #EBEBEB;
        .hedgehog-action-icon {
          fill: #ffffff;
        }
      }
    }
    .line {
      background: #ffffff;
      .HotspotPlugin_Hotspot {
        background: #000000;
        .Hotspot_ContentMessage {
          background: rgba(0,0,0,0.54);
          .hedgehog_spikes_label, .hedgehog_spikes_desp {
            color: #ffffff;
          }
          &:hover {
            background: rgba(85,85,85,0.54);
          }
        }
      }
      .HotspotPlugin_Hotspot.active {
        .Hotspot_ContentMessage {
          background: #ffffff;
          .hedgehog_spikes_label, .hedgehog_spikes_desp {
            color: #333333;
          }
        }
      }
    }
  }
  .row--small {
    padding:48px 0;
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
      padding:40px 0;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
      padding:32px 0 14px;
    }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
      padding:48px 0;
    }
    .hedgehog_heading {
      font-size: 36px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 44px;
      margin: 0 0 12px;
      @include respond-min($bp-phablet-wide-min) {//min 640 tab
        font-size: 32px;
        line-height: 40px;
      }
      @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        font-size: 28px;
        line-height: 36px;
      }
      @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        font-size: 36px;
        line-height: 44px;
      }
    }
    .hedgehog_sub_heading {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 32px;
      margin: 0;
      @include respond-min($bp-phablet-wide-min) {//min 640 tab
        font-size: 24px;
        line-height: 32px;
      }
      @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        font-size: 20px;
        line-height: 28px;
      }
      @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        font-size: 24px;
        line-height: 32px;
      }
    }
    .hedgehog-image {
      border:0 !important;
      max-width: 731px;
      max-height: 731px;
      margin: 0 auto;
      text-align: center;
      overflow-x: hidden;
      @include respond-min($bp-phablet-wide-min) {//min 640 tab
        max-width: 721px;
        max-height: 721px;
      }
      @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        max-height: 320px;
        max-width: 320px;
      }
      @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        max-width: 731px;
        max-height: 731px;
      }
      & > span.HotspotPlugin_Overlay {
        height: 100% !important;
        z-index: -1;
      }
      img {
        width: 100%;
        height: 100%;
        @include respond-min($bp-phablet-wide-min) {//min 640 tab
          max-width: 721px;
          max-height: 721px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640 mobile
          max-height: 320px;
          max-width: 320px;
        }
        @include respond-min($bp-tablet-wide-min) {// 1024 desktop
          max-width: 731px;
          max-height: 731px;
        }
      }
    }
    .HotspotPlugin_Hotspot {
      // display: none;
      &.spot {
        z-index: 10;
        display: none !important;
      }
    }
    .line {
      display: none;
      .HotspotPlugin_Hotspot {
        top: 40px;
      }
    }
    .line {
      .HotspotPlugin_Hotspot {
        // padding: 8px;
        width: auto;
        white-space: nowrap;
        height: auto !important;
        .Hotspot_ContentMessage {
          padding: 8px;
        }
        .hedgehog_spikes_label {
          display: block;
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          margin-bottom: 0 !important;
          text-decoration: underline;
          border-radius: 0.25rem 0.25rem 0 0;
          @include respond-min($bp-phablet-wide-min) {//min 640 tab
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-min($bp-tablet-wide-min) {// 1024 desktop
            font-size: 16px;
            line-height: 24px;
          }
        }
        .hedgehog_spikes_desp {
          @extend .hedgehog_spikes_label;
          opacity: 0;
          width: 0;
          height: 0;
          border-radius: 0 0 0.25rem 0.25rem;
        }
      }
      .HotspotPlugin_Hotspot:has(>.Hotspot_ContentMessage_Unsaved) {
        display: none;
      }
    }
  }
  .rtb-overlay-container {
    .icon-info-set-section {
      padding-bottom: 0;
      @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-bottom: 0;
      }
      @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-bottom: 0;
      }
      @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-bottom: 0;
      }
      .rtb-overlay-header {
        .rtb-overlay-header-wrapper {
          h3 {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0;
            padding: 0 36px 0 0;
            @include respond-min(1024px) {
              font-size: 18px;
              line-height: 28px;
            }
            .rtl & {
              padding: 0 0 0 36px;
            }
            span {
              padding-left: 0;
            }
          }
          .rtb-overlay-close-button {
            right: 0;
            .rtl & {
              right: auto;
              left: 0;
            }
          }
        }
      }
      .rtb-overlay-content {
        p {
          font-size: 16px;
          color: #333333;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 24px;
          @include respond-min($bp-phablet-wide-min) {//min 640 tab
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-min($bp-tablet-wide-min) {// 1024 desktop
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .hedgehog-feature-action-block {
    display: none;
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
      display: flex;
      justify-content: space-between;
      margin: 14px 0 0;
    }
    .hedgehog-action {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0;
      background: transparent;
      border: 0;
      cursor: pointer;
      box-shadow: none;
      display: flex;
      align-items: center;
      &.prev-btn {
        text-align: left;
        padding: 8px 8px 8px 3px;
        margin: 0 auto 0 0;
        .hedgehog-action-icon {
          height: 14px;
          width: 14px;
          transform: rotate(90deg);
        }
        span {
          margin-left: 5px;
        }
      }
      &.next-btn {
        text-align: right;
        padding: 8px 3px 8px 8px;
        margin: 0 0 0 auto;
        .hedgehog-action-icon {
          height: 14px;
          width: 14px;
          transform: rotate(-90deg)
        }
        span {
          margin-right: 5px;
        }
      }
    }
    &.hedgehog-modal-action-block {
      display: flex;
      border-top: 0;
      justify-content: space-between;
      padding: 8px 0 16px;
      position: relative;
      z-index: 1;
      @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding: 8px 0 12px;
      }
      @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding: 8px 0;
      }
      @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding: 8px 0 16px;
      }
      .hedgehog-action {
        margin-top: 0;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        color: #333333 !important;
        &.prev-btn {
          text-align: left;
          padding: 8px;
          margin:0 auto 0 0;
          @include respond-min($bp-phablet-wide-min) {//min 640 tab
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-min($bp-tablet-wide-min) {// 1024 desktop
            font-size: 16px;
            line-height: 24px;
          }
          &.disablePrevFeatureBtn {
            // opacity: 0.5;
            // cursor: default;
            // pointer-events: none;
            display: none !important;
          }
          .hedgehog-action-icon {
            height: 18px;
            width: 18px;
            fill: #333333;
            @include respond-min($bp-phablet-wide-min) {//min 640 tab
              height: 14px;
              width: 14px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
              height: 14px;
              width: 14px;
            }
            @include respond-min($bp-tablet-wide-min) {// 1024 desktop
              height: 18px;
              width: 18px;
            }
            .hedgehog-action-icon {
              height: 18px;
              width: 18px;
            }
          }
          span {
            margin-left: 5px;
          }
        }
        &.next-btn {
          text-align: center;
          padding: 8px;
          margin: 0 0 0 auto;
          @include respond-min($bp-phablet-wide-min) {//min 640 tab
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            font-size: 14px;
            line-height: 20px;
          }
          @include respond-min($bp-tablet-wide-min) {// 1024 desktop
            font-size: 16px;
            line-height: 24px;
          }
          &.disableNextFeatureBtn {
            // opacity: 0.5;
            // cursor: default;
            // pointer-events: none;
            display: none !important;
          }
          .hedgehog-action-icon {
            height: 18px;
            width: 18px;
            fill:#333333;
            @include respond-min($bp-phablet-wide-min) {//min 640 tab
              height: 14px;
              width: 14px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
              height: 14px;
              width: 14px;
            }
            @include respond-min($bp-tablet-wide-min) {// 1024 desktop
              height: 18px;
              width: 18px;
            }
            .hedgehog-action-icon {
              height: 18px;
              width: 18px;
            }
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .rtb-overlay {
    z-index: 10000000;
  }
}
.bcFloat {
  left: auto !important;
  right: 0;
}
// for rtl market START
.rtl {
  .hedgehog-image {
    direction: ltr;
  }
  .row--small {
    .line {
      .HotspotPlugin_Hotspot {
        .hedgehog_spikes_label {
          text-align: right;
        }
      }
    }
  }
  .hedgehog {
    .hedgehog-feature-action-block {
      .prev-btn {
        margin:0 0 0 auto;
        .hedgehog-action-icon {
          transform: rotate(-90deg);
        }
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
      .next-btn {
        margin:0 auto 0 0;
        .hedgehog-action-icon {
          transform: rotate(90deg);
        }
        span {
          margin-left: 5px;
          margin-right: 0px;
        }
      }
      &.hedgehog-modal-action-block {
        .prev-btn {
          margin:0 0 0 auto;
          .hedgehog-action-icon {
            transform: rotate(-90deg);
          }
          span {
            margin-left: 0;
            margin-right: 5px;
          }
        }
        .next-btn {
          margin:0 auto 0 0;
          .hedgehog-action-icon {
            transform: rotate(90deg);
          }
          span {
            margin-left: 5px;
            margin-right: 0px;
          }
        }
      }
    }
  }
}
// for rtl market START
// ROW-8180 Add-Hedgehog-Functionality-Design-Config-End
//ROW-8778 START
.rcc__layout {
    .cms-home-badge {
        color:$clr-white;
        background-color: $clr-dy-blue-1;
        border-radius:2px;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        display: inline-flex;
        align-items: center;
        margin-bottom: 6px;
        justify-content: center;
        padding:0 8px;
        + .rcc__image {
            margin-top: 10px;
            display: flex;
        }
        @include respond-min($bp-tablet-min) { //min 768 tab
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 10px;
            + .rcc__image {
                margin-top: 10px;
                display: flex;
            }
        }
        @include respond-min($bp-tablet-wide-min) { // 1024 desktop
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 14px;
            padding:0 12px;
            + .rcc__image {
                margin-top: 10px;
                display: flex;
            }
        }
    }
}
//ROW-8778 END
//ROW-4034 Anchor Links - ROW-13052 - enhancement for anchor link - Start
.horizontal_links-wrapper {
    .horizontal_links_content {
        padding: 16px 0 8px;
        @include respond-min($bp-above-tablet-min) { //Desktop view from 1025
            padding: 16px 0 12px;
        }
        .horizontal_links-heading {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            color: $clr-dy-grey-7;
            @include respond-min($bp-tablet-wide-min) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .horizontal_links-slider {
        .disabled_horizontal-scroll {
            .sub-category-nav__block {
                margin: 0;
                .sub-category-nav {
                    overflow-x: hidden;
                }
            }
        }
        .sub-category-nav__block {
            margin: 0;
            padding: 16px 0;
            .sub-category-nav {
                justify-content: normal !important;
                .sub-category-nav__list {
                    margin: 0 16px 0 0;
                    .rtl & {
                        margin: 0 0 0 16px;
                    }
                    @include respond-min($bp-tablet-min) { //Tab view from 768
                        margin: 0 20px 0 0;
                        .rtl & {
                            margin: 0 0 0 20px;
                        }
                    }
                    @include respond-min($bp-above-tablet-min) { //Desktop view from 1025
                        margin: 0 24px 0 0;
                        .rtl & {
                            margin: 0 0 0 24px;
                        }
                    }
                    .sub-category-nav__link {
                        padding: 8px;
                        font-size: 14px;
                        max-width: unset;
                        min-width: unset;
                        font-weight: 300;
                        line-height: 20px;
                        text-align: center;
                        white-space: nowrap;
                        color: $clr-dy-grey-66;
                        border: 1px solid $clr-field-border;
                        @include respond-min($bp-tablet-min) { //Tab view from 768
                            padding: 10px;
                        }
                        @include respond-min($bp-above-tablet-min) { //Desktop view from 1025
                            padding: 12px;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        &:hover, &:focus, &:active {
                            color: $clr-dy-grey-7;
                            text-decoration: underline;
                            border-color: $clr-dy-grey-7;
                        }
                    }
                }
            }
        }
        .sub-category-nav-slider__prev {
            left: -25px;
            @include respond-min($bp-tablet-min) { //Tab view from 768
                left: -50px;
            }
            @include respond-min($bp-above-tablet-min) { //Tab view from 768
                left: 0;
            }
            .rtl & {
                left: auto;
                right: -25px;
                @include respond-min($bp-tablet-min) { //Tab view from 768
                    right: -50px;
                }
                @include respond-min($bp-above-tablet-min) { //Tab view from 768
                    right: 0;
                }
            }
            &:before {
                height: 100%;
            }
        }
        .sub-category-nav-slider__next {
            right: -25px;
            @include respond-min($bp-tablet-min) { //Tab view from 768
                right: -50px;
            }
            @include respond-min($bp-above-tablet-min) { //Tab view from 768
                right: 0;
            }
            .rtl & {
                left: -25px;
                right: auto;
                @include respond-min($bp-tablet-min) { //Tab view from 768
                    left: -50px;
                }
                @include respond-min($bp-above-tablet-min) { //Tab view from 768
                    left: 0;
                }
            }
            &:before {
                height: 100%;
            }
        }
    }
}
//ROW-4034 Anchor Links - ROW-13052 - enhancement for anchor link - End
//ROW-8746 Video play button for RCC component START
.image__cta{
    z-index: 1;
}
.video__play-btn {
    padding: 0;
    border: 0;
    margin: 24px 0 0;
    width: 100%;
    max-width: 366px;
    background: transparent !important;
    @include respond-max($bp-tablet-wide-min) { //Tab view
        margin: 20px 0 0;
        max-width: 330px;
    }
    @include respond-max($bp-tablet-min - 1) { //Mobile view
        margin: 16px 0 0;
        max-width: 278px;
    }
    &:hover, &:active, &:focus {
        border: 0;
        background: inherit;
        color: inherit;
    }
    .video__play_btn-holder {
        display: flex;
        .play_svg_icons {
            flex: 0 0 auto;
            min-width: 96px;
            min-height: 96px;
            padding: 12px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: $clr-dy-grey-7;
            color: $clr-white;
            @include respond-max($bp-tablet-wide-min) { //Tab view
                padding: 8px;
                min-width: 76px;
                min-height: 76px;
            }
            .video__play-icon {
                width: 24px;
                height: 24px;
                fill: $clr-white;
                margin-bottom: 8px;
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    width: 15px;
                    height: 20px;
                    margin-bottom: 3px;
                }
            }
            .video_play-text {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 2.29px;
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
                color: $clr-white;
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 2px;
                }
            }
        }
        .video__description-time {
            display: flex;
            flex-direction: column;
            padding: 12px;
            width: 100%;
            align-items: flex-start;
            justify-content: center;
            border: 1px solid $clr-dy-grey-2;
            background: $clr-white;
            opacity: .9;
            @include respond-max($bp-tablet-wide-min) { //Tab view
                padding: 8px;
            }
            .video--description {
                color: $clr-dy-grey-7;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                text-align: left;
                word-break: normal;
                .rtl & {
                    text-align: right;
                }
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .video--duration {
                @extend .video--description;
                color: $clr-dy-grey-7;
                font-weight: 300;
                .rtl & {
                    text-align: right;
                }
            }
        }
    }
    &.button-dark-theme { //dark theme css start
        .video__play_btn-holder {
            .play_svg_icons {
                color: $clr-dy-grey-7;
                background: $clr-dy-grey-2;
                border-color: $clr-dy-grey-2;
                .video__play-icon {
                    fill: $clr-dy-grey-7;
                }
                .video_play-text {
                    color: $clr-dy-grey-7;
                }
            }
        }
    } // Dark theme css end
}
//Round video play button CSS - Start
.round-shape-play-button {
    .custom__roundal-btn {
        border: 0;
        background: transparent !important;
        &:hover, &:active, &:focus {
            border: 0;
        }
        .roundal__icon {
            .icon {
                .rtl & {
                    transform: unset;
                    right: 0;
                    @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                        right: -10px;
                    }
                    @include respond-max($bp-tablet-min - 1) { //Mobile view
                        right: -8px;
                    }
                }
            }
        }
    }
}
//Round video play button CSS - End
//ROW-8746 Video play button for RCC component END
//ROW-12527 Navigation: Landing Page Quick Links for RCC component START
.sub-category-nav__wrapper {
    position: relative;
    background: $clr-white;
    .sub-category-nav__block {
        margin: 0 2rem;
        padding: 1.25rem 0 1.25rem 0;
        overflow-x: overlay;
        overflow-y: hidden;
        scrollbar-width: none;
        transition: all 0.25s ease 0s;-webkit-transition: all 0.25s ease 0s;-o-transition: all 0.25s ease 0s;-moz-transition: all 0.25s ease 0s;
        &::-webkit-scrollbar {
            width: 0;
        }
        .sub-category-nav, .sub-category-nav__link {
            display: flex;
        }
        .sub-category-nav { // this is ul
            padding: 0;
            margin: 0 auto;
            max-width: 100%;
            position: relative;
            justify-content: center;
            list-style: none;
            .sub-category-nav__list { // this is li
                margin: 0;
                position: relative;
            }
            .sub-category-nav__link { // this is anchor link
                text-align: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-flow: column;
                align-items: center;
                min-width: 7.75rem;
                max-width: 9.75rem;
                padding: 0 1.25rem;
                height: 100%;
                .sub-category-nav__img {
                    margin: 0 auto;
                    display: inline-block;
                    .sub-category__image { // this is img tag
                        display: inline-block;
                        object-fit: contain;
                        width: 3.625rem;
                        height: 3.375rem;
                        transition: all 0.25s ease 0s;-webkit-transition: all 0.25s ease 0s;-o-transition: all 0.25s ease 0s;-moz-transition: all 0.25s ease 0s;
                    }
                }
                .sub-category-nav__txt { // category heading here
                    font-size: .75rem;
                    font-weight: 300;
                    color: $clr-dy-grey-7;
                    text-align: center;
                    margin-top: 0.5rem;
                    line-height: 1rem;
                    max-height: 2rem;
                    overflow: hidden;
                    min-width: 5.25rem;
                    max-width: 7.25rem;
                    overflow-wrap: break-word;
                }
                .sub-category-nav-label__txt {
                    color: $clr-dy-blue-1;
                    font-size: 0.625rem;
                    line-height: 0.875rem;
                    font-weight: 300;
                    letter-spacing: 0;
                }
                &:hover .sub-category-nav__txt {
                    text-decoration: underline;
                }
                &:active .sub-category-nav__txt {
                    font-weight: 500;
                    text-decoration: underline;
                }
            }
        }
    }
}
.sub-category-nav-slider__prev, .sub-category-nav-slider__next {
    border: 0;
    opacity: 1;
    height: 100% !important;
    width: 2rem;
    cursor: pointer;
    padding: 0 0.5rem;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0 !important;
    margin: 0;
    z-index: 1;
    background: $clr-white;
    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        height: calc(100% - 24px);
        margin: auto 0;
        content: "";
        background: $clr-dy-grey-11;
        z-index: 1;
    }
    .icon--chevron {
        height: 0.884rem;
        width: 0.884rem;
        fill: $clr-dy-grey-66;
    }
    &:hover {
        background: $clr-dy-grey-05;
        &::before {
            background: $clr-field-border;
        }
        .icon--chevron {
            fill: $clr-dy-grey-10;
        }
    }
    &:active {
        background: $clr-dy-grey-12;
        &::before {
            background: $clr-field-border;
        }
        .icon--chevron {
            fill: $clr-dy-grey-10;
        }
    }
    &:disabled {
        display: none;
    }
}
.sub-category-nav-slider__prev {
    left: 0;
    .rtl & {
        left: auto;
        right: 0;
    }
    &::before {
        right: 0;
        .rtl & {
            right: auto;
            left: 0;
        }
    }
    .sub-category-nav-slider__left-btn {
        transform: rotate(-270deg);-webkit-transform: rotate(-270deg);-ms-transform: rotate(-270deg);-o-transform: rotate(-270deg);-moz-transform: rotate(-270deg);
        .rtl & {
            transform: rotate(-90deg);-webkit-transform: rotate(-90deg);-ms-transform: rotate(-90deg);-o-transform: rotate(-90deg);-moz-transform: rotate(-90deg);
        }
    }
}
.sub-category-nav-slider__next {
    right: 0;
    .rtl & {
        left: 0;
        right: auto;
    }
    &::before {
        left: 0;
        .rtl & {
            right: 0;
            left: auto;
        }
    }
    .sub-category-nav-slider__right-btn {
        transform: rotate(-90deg);-webkit-transform: rotate(-90deg);-ms-transform: rotate(-90deg);-o-transform: rotate(-90deg);-moz-transform: rotate(-90deg);
        .rtl & {
            transform: rotate(-270deg);-webkit-transform: rotate(-270deg);-ms-transform: rotate(-270deg);-o-transform: rotate(-270deg);-moz-transform: rotate(-270deg);
        }
    }
}
//ROW-12527 Navigation: Landing Page Quick Links for RCC component END
//ROW-17261 DCR Homepage Category Cards implementation - Start
.sub-category-nav__wrapper.dcr-category-nav__wrapper {
    @extend .layout;
    @include respond-max($bp-mobile-max) { //Mobile view
        padding: 0;
    }
    .sub-category-nav__block {
        margin: 0;
        padding: 40px 0;
        @include respond-max($bp-mobile-max) { //Mobile view
            padding: 32px 0;
        }
        .sub-category-nav { //this is ul
            justify-content: normal !important;
            .sub-category-nav__list { //this is li
                margin: 0 32px 0 0;
                .rtl & {
                    margin: 0 0 0 32px;
                }
                &:last-child {
                    margin: 0;
                }
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    margin: 0 16px 0 0;
                    .rtl & {
                        margin: 0 0 0 16px;
                    }
                }
                @include respond-max($bp-mobile-max) { //Mobile view
                    margin: 0;
                    padding: 0 16px 0 0;
                    .rtl & {
                        margin: 0 0 0 16px;
                    }
                    &:first-child {
                        padding: 0 16px 0 16px;
                    }
                }
                .sub-category-nav__link { //this is anchor link
                    padding: 0;
                    min-width: 199px;
                    max-width: 293px;
                    @include respond-max($bp-desktop-wide-min - 1) { //Below 1439
                        min-width: 197px;
                        max-width: 293px;
                    }
                    @include respond-max($bp-pdp-wide - 1) { //1025 to 1279 view
                        min-width: 158px;
                        max-width: 263px;
                    }
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        min-width: 120px;
                        max-width: 263px;
                    }
                    @include respond-max($bp-mobile-max) { //Mobile view
                        min-width: 115px;
                        max-width: 115px;
                    }
                    &:hover {
                        .sub-category-nav__txt {
                            text-decoration: none;
                        }
                    }
                    .sub-category-nav__img {
                        display: block;
                        width: 100%;
                        border-radius: 6px;
                        overflow: hidden;
                        .sub-category__image {
                            width: 100%;
                            height: auto;
                            @include respond-max($bp-mobile-max) { //Mobile view
                                width: 115px;
                                max-width: unset;
                                height: 164px;
                                object-fit: cover;
                            }
                        }
                    }
                    .sub-category-nav__txt {
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 500;
                        text-align: left;
                        min-width: 100%;
                        max-width: 100%;
                        max-height: unset;
                        width: 100% !important;
                        @include respond-max($bp-tablet-wide-min) { //Tab view
                            font-size: 16px;
                            line-height: 28px;
                        }
                        @include respond-max($bp-mobile-max) { //Tab view
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                    .sub-category-nav-label__txt {
                        display: none;
                    }
                }
                &:last-child { //li last child
                    .sub-category-nav__link { //this is anchor link
                        margin: 0;
                    }
                }
            }
        }
    }
    //Slider buttons for next & previous
    .sub-category-nav-slider__prev, .sub-category-nav-slider__next {
        width: 46px;
        height: 46px !important;
        border-radius: 50%;
        background: rgba(51, 51, 51, 0.5);
        @include respond-max($bp-tablet-wide-min) { //Tab view
            width: 32px;
            height: 32px !important;
            margin-top: 16px;
        }
        @include respond-max($bp-mobile-max) { //Mobile view
            margin-top: 8px;
        }
        &::before {
            content: none;
        }
        .icon--chevron {
            height: 23px;
            width: 23px;
            fill: $clr-white;
            display: block;
            margin: 0 auto;
            @include respond-max($bp-tablet-wide-min) { //Tab view
                height: 16px;
                width: 16px;
            }
        }
    }
    .sub-category-nav-slider__prev {
        left: 8px;
        @include respond-max($bp-desktop-wide-min - 1) { //1439 to 1280 view
            left: 23px;
        }
        @include respond-max($bp-pdp-wide - 1) { //Tab view
            left: 58px;
        }
        @include respond-max($bp-mobile-max) { //Mobile view
            left: 6px;
        }
    }
    .sub-category-nav-slider__next {
        right: 8px;
        @include respond-max($bp-desktop-wide-min - 1) { //1439 to 1280 view
            right: 28px;
        }
        @include respond-max($bp-pdp-wide - 1) { //Tab view
            right: 58px;
        }
        @include respond-max($bp-mobile-max) { //Mobile view
            right: 6px;
        }
    }
}
//ROW-17261 DCR Homepage Category Cards implementation - End

//row-12776
.page-products {
    .product__price-matched {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding:8px 16px;
        @include respond-min($bp-tablet-wide-min) {
            font-size: 16px;
            line-height: 24px;
            color: $clr-dy-blue-1;
        }
    }
    .hero-product-promotion-section {
        .product__price-matched {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
