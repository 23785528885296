/*
  Collapsibles
  =============================================
*/
.faqs {
  padding: 40px 0;
}




.faqs-collapsible {
  /*
    1. Reset button styles
    todo: Stop setting styles on <button>
  */

  p {

  }


  button[data-role="title"] {
    color: #000;
    font-family: $f-futura;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    padding: 30px 0px;
    border: none; /* 1 */
    background: none; /* 1 */
    border-bottom: 1px solid #ECECEC;
    text-align: left;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    @include respond-min($bp-tablet-min) {
      // padding-left: 50px;
      // padding-right: 50px;
    }

    &:hover,
    &:focus {
      background: none; /* 1 */
      border: none; /* 1 */
      border-bottom: 1px solid #ECECEC;
      color: #000;
    }

    &.button-flex {
      display:flex;
      justify-content: space-between;
    }

    &:after {
      content: '';
      background-image: url('../images/icons/sprite.png');
      background-position: 0px -3967px;
      display: block;
      float: right;
      width: 24px;
      height: 29px;
    }
  }

  [aria-expanded=true] {
    &:after {
      transform: rotate(180deg);
    }
  }

  li {
    padding: 0;
    margin: 0;
  }

  a {
    @include fs-responsive((g:14px, gtw:16px));
    display: block;
    font-family: $f-futura;
    font-weight: 400;
    // padding: 20px 42px;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

  }
}

.faqs-dropdown-collapsible__content {
  padding-top: 20px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.faqs-collapsible__questions {
  width: 80%;
}

/*
 Accordion-ROW-2138
  =============================================
*/
.accordion-module-collapsible {
    background-color: #fff;
    .accordion__heading-text {
        margin: 0;
        padding: 24px 0;
        @include respond-max($bp-tablet-wide-min) {
            //max tab and mobile
            padding: 20px 0;
        }
        @include respond-max($bp-tablet-min) {
            padding: 16px 0;
        }
        button {
            font-family: $f-futura;
            color: #333333;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 28px;
            width: 100%;
            padding: 0;
            border: none;
            background-color:inherit;
            //border-bottom: 1px solid #ECECEC;
            text-align: left;
            @include respond-max($bp-tablet-wide-min) {
                //max tab and mobile
                font-size: 16px;
                line-height: 24px;

            }
            &:hover,
            &:focus {
                background: none; /* 1 */
                border: none; /* 1 */
                color: #000;
                border-bottom: 0;
            }
            &.button-flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.expanded {
                    .accordion-item-tools__chevron{
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }
            }
            .accordion-item-tools__chevron{
                width: 21px;
                height: 21px;
                fill:#333;
                @include respond-max($bp-tablet-wide-min) {
                    //max tab and mobile
                    width: 17px;
                    height: 17px;
                }
                @include respond-max($bp-tablet-min) {
                    //max mobile only
                    width: 14px;
                    height: 14px;
                }
            }
            .hgroup__content {
                margin: 0;
            }
        }
        [aria-expanded=true] {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    .accordion-module-dropdown-collapsible__content {
        padding:0;
        @include respond-max($bp-tablet-wide-min) {
            //max tab and mobile
            padding:0;
        }
        @include respond-max($bp-tablet-min) {
            //max mobile only
            padding:0;
        }
        .accordion-bottom__spacing{
            margin-bottom: 0;
        }
        .content__heading {
            font-family: $f-futura;
            color: #333333;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 28px;
            margin: 0;
            @include respond-max($bp-tablet-wide-min) {
                //max tab and mobile
                font-size: 16px;
                line-height: 24px;
            }
        }
        > p {
            color: #555555;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 24px;
            padding: 8px 0 16px 0;
            margin: 0;
            &:last-child{
                padding-bottom: 0;
            }
            @include respond-max($bp-tablet-wide-min) {
                //max tab and mobile
                font-size: 16px;
                line-height: 24px;
                padding: 8px 0 24px 0 ;
            }
        }
    }
}
.accordion-module-black {
    background-color: $clr-black;
    .accordion-module-collapsible {
        background-color: $clr-black;
    }
    .accordion__heading-text {
        button {
            span.hgroup__content {
                color: #fff;
            }

            .shape--chevron.accordion-item-tools__chevron {
                fill: #fff;
            }
        }
    }
    .accordion-module-dropdown-collapsible__content{
        .content__heading,> p{
            color: #fff;
        }
        .accordion-bottom__spacing {
            margin-bottom: 0;
        }
    }
}
.accordion-module-black .contact-us h2,.accordion-module-black .contact-us h1,.accordion-module-black .contact-us__call span,
.accordion-module-black .contact-us__email a,.accordion-module-black .contact-us__inner p,.accordion-module-black .basic__content h2,.accordion-module-black .basic__content h1, .accordion-module-black .basic__content p,
.accordion-module-black .basic__content h3{
    color: #fff;
}
/*
 Accordion-ROW-2138
  =============================================
*/
//ROW-16979 Accordion Module - UI visibility enhancement -Start
.accordion-module__dropdown {
    position: relative;
    .accordion-module-collapsible {
        border-width: 1px 0 1px;
        border-style: solid;
        border-color: $clr-dy-grey-2;
        margin-top: -1px;
        .accordion__heading-text {
            padding: 0;
            button.button-flex {
                padding: 16px 0;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: $clr-dy-grey-7;
                @include respond-min($bp-tablet-wide-min) { // 1024 Desktop View
                    padding: 24px 0;
                }
                span.hgroup__content {
                    padding: 0 12px 0 0;
                    .rtl & {
                        padding: 0 0 0 12px;
                    }
                }
                .rtl & {
                    text-align: right;
                }
            }
            button .accordion-item-tools__chevron {
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                align-self: flex-start;
                @include respond-min($bp-tablet-wide-min) { // 1024 Desktop View
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }
        .accordion-module-dropdown-collapsible__content {
            padding: 0 0 16px;
            @include respond-min($bp-tablet-wide-min) { // 1024 Desktop View
                padding: 0 0 24px;
            }
        }
        .layout, .rcc__layout {
            width: 100%;
            padding: 0;
        }
    }
    &.accordion-module-black {
        .accordion-module-collapsible {
            border-color: $clr-white;
            .accordion__heading-text {
                padding: 0;
                button.button-flex {
                    color: $clr-white;
                }
            }
        }
    }
}
//ROW-16979 Accordion Module - UI visibility enhancement -End
