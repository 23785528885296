/*
  HEADER
*/
header {
  &.page-header {
    @include z-index(content-top);
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    transition: transform 0.5s cubic-bezier(.68,.03,.26,1.02);

    // Fix for disappearing navs on RTL Edge (Edge 17, 18, Insider don't like position: sticky in combo with RTL)
    .rtl & {
      @supports (-ms-ime-align: auto) {
        position: relative;
      }
    }

    &.page-header--simple {
      position: inherit;
    }

    &.scroll-hide {
      transform: translate3d(0px, -64px, 0px);
    }
  }
}

// MASTHEAD

.header__masthead {
  @include clearfix;
  color: $clr-white;
  transition: transform 0.5s cubic-bezier(.68,.03,.26,1.02);

  .layout{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-header--simple & {
      justify-content: flex-start;
    }
  }
}

.header__show-nav {

}

.header__logo {
    height: 100%;
    position: relative;

    @include respond-min($bp-tablet-min) {
        left: 0;
        margin-right: $v-minuscule;
        width: 78px;
        flex: 0 0 78px;
    }

    @include respond-min($bp-desktop-min) {
        // margin-right: $v-small;
    }

    body.checkout-cart-index & {
        padding: 16px 0 15px;

        @include respond-min($bp-tablet-min) {
            padding: 15px 0 14px;
        }
        @include respond-min($bp-desktop-min) {
            padding: 18px 0;
        }
    }
}

.header__logo__inner {
    a{
        display: flex;
    }

    .icon {
        height: 1.5rem;
        width: 4rem;

        @include respond-min($bp-tablet-min) {
            height: 1.625rem;
            width: 4.3125rem;
        }

        @include respond-min($bp-desktop-min) {
            height: 1.75rem;
            width: 4.625rem;
        }
    }
}


// NAV TOGGLE

.header__toggle-nav {
  height: 3rem;
  width: 3rem;
  position: relative;
  left: -25px;
  padding: 0 1.5rem;
  background: none;
  border: none;
  text-align: left;

  &:hover,
  &:focus {
    background: none;
    border: none;
  }

  @include respond-min($bp-tablet-wide-min) {
    display: none;
  }
}

.header__hamburger {
  height: .125rem;
  width: 1rem;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 0;

  &:before,
  &:after {
    content: "";
    height: .125rem;
    width: 1rem;
    background-color: #fff;
    position: absolute;
  }

  &:before {
    top: -0.375rem;
  }

  &:after {
    top: .375rem;
  }
}

.header__toggle-nav {
  &[aria-expanded="true"] {
    .header__hamburger {
      transform: rotate(45deg);

      &:after {
        transform: rotate(90deg);
        top: 0;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}

// PRIMARY NAV

.header__nav {
  @include z-index(chrome-top);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $clr-black;
  height: 0;
  overflow: hidden;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: auto;
  transition: height 0.2s ease-in-out;
  top: 48px;

  &.hidden {
    display: none;

    @include respond-min($bp-tablet-wide-min) {
      display: block;
    }
  }

  &.open {
    height: calc(100vh - 48px);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }

  @include respond-min($bp-tablet-wide-min) {
    height: auto;
    overflow: visible;
    background: none;
    position: static;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

.header__nav__outer {
  padding: 10vh 0;

  @include respond-min($bp-tablet-wide-min) {
    padding: 0;
  }
}

.header__nav__inner {
  @include layout;

  @include respond-min($bp-tablet-wide-min) {
    @include layout-reset();
  }
}

.header__nav__list {
    @include list-reset;
    @include fs-responsive((g:18px, gtw:$fs-body));
    font-family: $f-futura;
    font-weight: 400;

    > li {
        @include list-item-reset;

        @include respond-min($bp-tablet-wide-min) {
            margin: 0 $v-minute; //0px for all sites
            display: inline-block;
        }

        @include respond-min($bp-desktop-min) {
            margin: 0 17px; //0px for all sites
        }
    }
}

.header__nav__list {
    > li {
        @include list-item-reset;

        @include respond-min($bp-tablet-wide-min) {
            margin: 0px;
            display: inline-block;
        }

        @include respond-min($bp-desktop-min) {
            margin: 0px;
        }
    }
}

// NAV ITEMS

.header__nav-item {
  > a {
    @include link-reset;
    display: inline-block;
    padding: 1.5rem 0;
    width: 100%;
    border-bottom: 1px solid $clr-dy-grey-5;
    color: $clr-white;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include respond-min($bp-tablet-wide-min) {
      padding: 1.3125em 1em;
      width: inherit;
      border-bottom: 0;
    }

    @include respond-min($bp-desktop-min) {
      padding: 1.3125em 1.5em;
    }

    &:hover, &.active {
      @include respond-min($bp-tablet-wide-min) {
        color: $clr-dy-grey-5;
      }
    }

    .icon--chevron{
      fill: $clr-white;
      width: 1rem;
      height: 1.25rem;
      float: right;
      position: relative;
      top: 2px;

      @include respond-min($bp-tablet-wide-min) {
        display: none;
      }
    }

    &[aria-expanded="true"] {
      color: $clr-dy-grey-5;

      @include respond-min($bp-tablet-wide-min) {
        color: $clr-white;
        background-color: $clr-dy-grey-9;
      }

      .icon--chevron{
        transform: rotateX(180deg);
      }
    }
  }
}

.dyson-in
  .header__nav-item {
    > a {

    @include respond-min($bp-tablet-wide-min) {
      padding: 1.3125em 0.9em;
      width: inherit;
      border-bottom: 0;
    }

  }
}


// SEARCH

.header__search {
  margin-left: auto;
  position: relative;
  padding: 17px 0;
  margin-right: $v-minuscule;

  @include respond-min($bp-tablet-min){
    margin-right: $v-minuscule;
  }

  @include respond-min($bp-desktop-min) {
    margin-right: $v-tiny;
  }

  .icon {
    fill: $clr-white;
    height: 21px;
    width: 21px;

    @include respond-min($bp-tablet-min) {
      float: right;
    }
  }
}

.header__search .icon {

  .rtl & {
    @include respond-min($bp-tablet-min) {
      left: -4px;
    }
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    @include respond-min($bp-tablet-min) {
      top: 6px;
    }
  }
}

.header__search__inner{
  position: relative;
  height: 21px;

  @include respond-min($bp-tablet-min) {
    height: auto;
    display: flex;
  }
}

.header__search__input {
  @include fs-static($fs-body);
  font-family: $f-futura;
  font-weight: 300;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  line-height: normal;
  margin-right: 2px;
  pointer-events: none;
  width: 240px;
  display: none;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    display: inline-block;
  }
}

.header__search__input--mobile {
  display: inline-block;
  @include fs-static($fs-body);
  font-family: $f-futura;
  font-weight: 300;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  line-height: normal;
  margin-right: 0;
  pointer-events: none;
  width: 60%;
  position: relative;
  top: -8px;

  @include respond-min($bp-tablet-min) {
    display: none;
  }
}

.header__search {
  margin-left: auto;
  position: relative;
  padding: 17px 0 14px 26px;
  margin-right: 0;

  @include respond-min($bp-tablet-min) {
    padding: 17px 26px 14px 26px;
  }

  .icon {
    fill: #fff;
    height: 21px;
    width: 21px;
    position: relative;
    top: 0;
  }
}

.header__search__input-open {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  height: 100%;
  top: 0;

  border: none; /* 1 */
  background: none; /* 1 */
  padding: 0; /* 1 */

  &:hover,
  &:focus {
    background: none; /* 1 */
    border: none; /* 1 */
  }
}

// BASKET

.header__basket {
  height: 24px;
  width: 24px;
  flex: 0 0 24px;

  .icon{
    fill: $clr-white;
    height: 24px;
    width: 24px;
  }
}

.basket-item{
  position: relative;

  &:after{
    content: '';
    position: absolute;
    width: .5rem;
    height: .5rem;
    border-radius: .375rem;
    background-color: $clr-dy-green-0;
    top: 4px; right: -4px;
  }
}


// CONTACT

.header__contact {
  border-left: 1px solid $clr-dy-grey-7;
  border-right: 1px solid $clr-dy-grey-7;

  .header__contact__link {
    display: flex;
    align-items: center;
    padding: 0.875em 1.25em;
    border-bottom: none;

    @include respond-min($bp-tablet-wide-min) {
      pointer-events: none;
      cursor: none;
      padding: 1.25em;
    }

    .icon {
      fill: $clr-white;
      height: 1.25em;
      width: 1.25em;

      @include respond-min($bp-tablet-wide-min) {
        height: 1.5em;
        width: 1.5em;
      }
    }

    .header__contact__link__text {
      line-height: 1.5em;
      color: $clr-white;
      font-family: $f-futura;
      font-weight: 500;
      margin-left: 0.75em;
      @include fs-responsive((g:14px, gtw:16px));

      .dyson-th.primary & {
        font-family: $f-futura-thai;
      }

      .dyson-hk.primary & {
        font-family: $f-futura-hant;
      }

      .dyson-ae.rtl &, .dyson-sa.rtl & {
        font-family: $f-futura-arabic;
      }

      .dyson-il.rtl & {
        font-family: $f-futura-hebrew;
      }

      .dyson-kr & {
        font-family: $f-noto-sans;
      }
    }

    .header__contact__link__details {
      line-height: 1.5em;
      color: $clr-dy-grey-6;
      font-family: $f-futura;
      margin-left: 0.75em;
      display: none;
      @include fs-responsive((g:14px, gtw:16px));

      .dyson-th.primary & {
        font-family: $f-futura-thai;
      }

      .dyson-hk.primary & {
        font-family: $f-futura-hant;
      }

      .dyson-ae.rtl &, .dyson-sa.rtl & {
        font-family: $f-futura-arabic;
      }

      .dyson-il.rtl & {
        font-family: $f-futura-hebrew;
      }

      .dyson-kr & {
        font-family: $f-noto-sans;
      }

      @include respond-min($bp-tablet-wide-min) {
        display: inherit;
      }
    }
  }
}

//custom header
.custom-header {
    padding: 18px 0;
    .widget.block {
        background-color: transparent;
    }
    .header__logo {
        display: inline-block;
    }
    * {
        color: $clr-dy-grey-5;
    }
    ul li a {
        color: $clr-white;
    }
    .page-layout-empty & {
        display:none;
    }
}
//ROW-6451-Utility-Menu - START
.utility-menu__wrapper {
    border-bottom: 1px solid $clr-dy-grey-7;
    .layout {
        padding-top: 12px;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header__logo {
            padding: 0;
        }
        .utility-menu-nav {
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                li {
                    padding: 0 12px;
                    margin: 0;
                    &:last-child {
                        padding-right: 0;
                    }
                    a {
                        color: $clr-white;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 300;
                        letter-spacing: 0;
                        display: inline-block;
                        &:hover, &:focus {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
.header__nav {
    .header__search {
        display: none;
    }
    .utility-menu--item-mobile {
        display: none;
    }
}
.utility-menu-active {
    @include respond-min($bp-tablet-wide-min) {
        display: none;
    }
}
.utility-menu__wrapper {
    @include respond-max($bp-tablet-wide-min - 1) {
        display: none;
    }
}
.crumb__list a {
    &:hover {
        text-decoration: underline;
    }
}
.utilitymenu--enabled {
    .header__masthead {
        @include respond-min($bp-tablet-wide-min) {
            position: relative;
        }
    }
    .header__nav {
        @include respond-max($bp-tablet-wide-min) {
            padding: 0;
        }
        .header__nav__list {
            .header__nav-item {
                & > a {
                    @include respond-max($bp-tablet-wide-min) {
                        font-size: 16px;
                        line-height: 24px;
                        padding: 1rem 2.75rem;
                        border-color: $clr-dy-grey-10;
                        font-weight: 300;
                    }
                    @include respond-max($bp-tablet-min - 1) {
                        padding: 1rem 1.3125rem;
                    }
                }
                .sub-nav {
                    @include respond-max($bp-tablet-wide-min) {
                        padding: 0 2.75rem;
                    }
                    @include respond-max($bp-tablet-min - 1) {
                        padding: 0 1.3125rem;
                    }

                    .sub-nav__link--category {
                        @include respond-max($bp-tablet-wide-min) {
                            padding: 1rem 0;
                            font-size: 16px;
                            line-height: 24px;
                            border-color: $clr-dy-grey-10;
                        }
                    }
                }
            }
            .mobile--utility-menu-spacing {
                @include respond-min($bp-tablet-min) {
                    display: none;
                }
                @include respond-max($bp-tablet-min - 1) {
                    margin-top: 16px;
                }
            }
            .utility-menu--item-mobile {
                padding: 0.75rem 2.75rem;
                @include respond-max($bp-tablet-wide-min) {
                    display: block;
                }
                @include respond-max($bp-tablet-min - 1) {
                    padding: 0.75rem 1.3125rem;
                }
                a {
                    font-size: 16px;
                    line-height: 24px;
                    color: $clr-dy-grey-5;
                    width: 100%;
                    display: inline-block;
                    font-weight: 300;
                    &:hover, &:focus, &:active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
//ROW-6451-Utility-Menu - END
body.rtl .utility-menu__wrapper .layout .utility-menu-nav ul li:last-child {
    @include  respond-min($bp-tablet-wide-min) {
        padding:0 12px 0 0px;
    }
}
//ROW-12523-Global-Quick-Links - START
.global__quick-links {
    //width: 100%; //ROW-14574 removed for new req
    display: block;
    flex: 1; //ROW-14574 added for global quick link new requirement
    flex: 1 0 auto;//ROW-14574 added for global quick link new requirement
    &.header__nav.hidden {
        @include respond-max($bp-tablet-wide-min + 1) { //ROW-14574 added 1px as per the new requirement
            display: none;
        }
    }
    ul.header__nav__list {
        display: flex;//ROW-14574 added for global quick link new requirement
        margin-left: -12px;
        >
        li {
            line-height: 1;
            transition:all .3s;
            padding: 20px 12px;
            display: flex;
            align-items: center;
            a {
                padding: 0;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 24px;
                outline: none;
                display: flex;
                align-items: center;
                &:focus-visible{
                    outline: 1px solid $clr-white;
                    border-radius: 2px;
                    outline-offset: 4px;
                    text-decoration: underline;
                }
                &:hover{
                    text-decoration: underline;
                    color: $clr-white;
                }
            }
            &:last-child {
                .rtl &{
                    padding-right: 12px;
                    padding-left: 12px;
                }
            }
            &:hover{
                transition:all .3s;
                background-color:$clr-dy-grey-10;
            }
            &.active{
                transition:all .3s;
                background-color:$clr-dy-grey-10;
            }
            &:hover .global__quick-links{
                display: block;
            }

            &:focus-visible{
                background-color: $clr-dy-grey-10;
                a{
                    outline: 1px solid $clr-white;
                    border-radius: 2px;
                    outline-offset: 4px;
                }
            }
            &:has(a:focus){
                background-color: $clr-dy-grey-10;
            }
        }
    }
}
.rtl{
    .global__quick-links{
        .header__nav__list{
            margin-right: -12px;
            li:last-child{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
//ROW-12523-Global-Quick-Links - END
//ROW-12520-Hamburger Menu - Start
.hamburger_menu {
    top: 0;
    left: -100%;
    opacity: 0;
    visibility: hidden;
    z-index: 1999;
    height: 100vh;
    position: fixed;
    min-width: 395px;
    max-width: 404px;
    background: $clr-black;
    transition: all 250ms ease-in-out 0s; -moz-transition: all 250ms ease-in-out 0s; -webkit-transition: all 250ms ease-in-out 0s; -ms-transition: all 250ms ease-in-out 0s; -o-transition: all 250ms ease-in-out 0s;
    &.open {
        left: 0;
        opacity: 1;
        visibility: visible;
    }
    @include respond-max($bp-tablet-wide-min - 1) { //Tab view
        top: 53px;
        min-width: 100%;
        max-width: 100%;
        position: absolute;
    }
    @include respond-max($bp-tablet-min - 1) { //Mobile view
        top: 51px;
    }
    .rtl & {
        left: auto;
        right: -100%;
        &.open {
            right: 0;
        }
    }
    .hamburger_logo {
        padding: 12px 24px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        position: relative;
        min-height: 50px;
        justify-content: space-between;
        .hamburger_logo_img {
            .logo_link {
                display: block;
                .icon--logo {
                    max-width: 55px;
                    height: 20.8px;
                    display: block;
                    margin: 0;
                }
            }
        }
        .close_hamburger_menu {
            position: absolute;
            top: 12px;
            right: 19px;
            .rtl & {
                left: 19px;
                right: auto;
            }
            .close_hamburger {
                width: 1.625rem;
                height: 1.625rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                border: 0;
                cursor: pointer;
                background: transparent;
                .close_hamburger_icon {
                    height: 1rem;
                    width: 1rem;
                    position: relative;
                }
            }
        }
        @include respond-max($bp-tablet-wide-min - 1) { //Tab view
            display: none;
        }
    }
    ul, li {
        padding: 0;
        margin: 0;
    }
    .hamburger_menu_nav {
        height: calc(100vh - 61px);
        overflow-y: auto;
        overflow-x: hidden;
        min-width: 420px;//25px extra for scrollbar
        max-width: 429px;//25px extra for scrollbar
        @include respond-max($bp-tablet-wide-min - 1) { //Tab view
            height: calc(100vh - 53px);
            min-width: 100%;
            max-width: 100%;
        }
        @include respond-max($bp-tablet-min - 1) { //Mobile view
            height: calc(100vh - 52px);
            min-width: 100%;
            max-width: 100%;
        }
    }
    .hamburger_menu_list {
        min-width: 395px;
        max-width: 404px;
        @include respond-max($bp-tablet-wide-min - 1) { //Tab view
            min-width: 100%;
            max-width: 100%;
        }
        @include respond-max($bp-tablet-min - 1) { //Mobile view
            min-width: 100%;
            max-width: 100%;
        }
        .hamburger_menu_item {
            .hamburger_menu_link {
                color: $clr-white;
                font-size: 1rem; //VQA point covered
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.5rem; //VQA point covered
                cursor: pointer;
                display: flex;
                padding: 16px 25px;
                align-items: center;
                justify-content: space-between;
                position: relative;
                @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                    font-size: 1.125rem; //VQA point covered
                    font-weight: 400; //VQA point covered
                    line-height: 1.5rem; //VQA point covered
                    padding: 20px 50px;
                }
                @include respond-max($bp-tablet-min - 1) { //Mobile view
                    padding: 20px 25px;
                }
                &::before {
                    position: absolute;
                    left: 24px;
                    right: 24px;
                    bottom: 0;
                    height: 1px;
                    background: $clr-dy-grey-5;
                    content: "";
                    @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                        left: 50px;
                        right: 50px;
                    }
                    @include respond-max($bp-tablet-min - 1) { //Mobile view
                        left: 25px;
                    }
                }
                span {
                    display: inline-block;
                    margin-right: 28px;
                    .rtl & {
                        margin-left: 28px;
                        margin-right:0;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                    &:active {
                        font-weight: 900;
                    }
                }
                .icon--chevron {
                    fill: $clr-white;
                    width: 1rem;
                    height: 1rem;
                    min-width: 1rem;
                    min-height: 1rem;
                }
                &[aria-expanded="true"] {
                    background: rgba(51,51,51,0.6);
                    .icon--chevron{
                        transform: rotateX(180deg);
                    }
                }
                &:hover, &:active, &:focus {
                    background: rgba(51,51,51,0.6);
                }
                &.redirect_link {
                    padding-right: 44px;
                    .rtl & {
                        padding-left: 44px;
                        margin-right:0;
                    }
                    .icon--chevron {
                        display: none;
                    }
                }
            }
            .hamburger_sub_menu {
                padding: 1rem 0;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s ease-in-out;
                @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                    padding: 0 0 2rem;
                }
                &.open {
                    max-height: 100%;
                }
                .hamburger_sub_list {
                    .hamburger_sub_item {
                        .hamburger_sub_link {
                            color: $clr-white;
                            font-size: 16px;
                            font-weight: 300;
                            letter-spacing: 0;
                            line-height: 24px;
                            cursor: pointer;
                            display: flex;
                            padding: 16px 32px;
                            span {
                                margin-right: 36px;
                                .rtl & {
                                    margin-left: 36px;
                                    margin-right: 0;
                                }
                                &:hover, &:focus {
                                    text-decoration: underline;
                                }
                                &:active {
                                    font-weight: 500;
                                }
                            }
                            @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                                font-size: 16px;
                                padding: 20px 43px;
                            }
                            @include respond-max($bp-tablet-min - 1) { //Mobile view
                                padding: 20px 21px;
                            }
                        }
                    }
                }
            }
        }
        // Utility nav lists
        .mobile--utility-menu-spacing {
            padding: 24px 0 0;
            @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                padding: 16px 0 0;
            }
        }
        .utility-menu--item-mobile {
            a {
                padding: 12px 24px;
                color: $clr-white;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 24px;
                display: flex;
                cursor: pointer;
                span {
                    margin-right: 44px;
                    .rtl & {
                        margin-right: 0;
                        margin-left: 44px;
                    }
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                    &:active {
                        font-weight: 500;
                    }
                }
                @include respond-max($bp-tablet-wide-min - 1) { //Tab view
                    padding: 12px 43px;
                }
                @include respond-max($bp-tablet-min - 1) { //Mobile view
                    padding: 20px 21px;
                }
            }
            &:last-child {
                margin-bottom: 48px;
            }
        }
    }
    &::before {
        position: fixed;
        width: 100%;
        height: 100vh;
        top:0;
        left: 0;
        background: rgba(0,0,0,0.6);
        content: "";
        z-index: -1;
        @include respond-max($bp-tablet-wide-min - 1) { //Tab view
            content: none;
        }
    }
}
.header__toggle-nav {
    &.hamburger_menu_enabled {
        display: block;
        @include respond-min($bp-above-tablet-min) { //Desktop
            height: 2rem;
            width: 0;
            position: relative;
            left: -8px;
            padding: 1rem 1rem 1rem 1rem;
            text-align: left;
            margin: 0 0.5rem 0 0;
            .rtl & {
                left: auto;
                right: -8px;
                margin: 0 0 0 0.5rem;
            }
            .header__hamburger {
                left: 0.5rem;
                .rtl & {
                    left: auto;
                    right: 0.5rem;
                }
            }
        }
    }
}
.header__toggle-nav.hover_menu_enabled.hamburger_menu_enabled {
    @include respond-min($bp-above-tablet-min) {
        display: none;
    }
}
.page-header.tabletModeEnabled{
    .header__toggle-nav.hover_menu_enabled.hamburger_menu_enabled {
        @include respond-min($bp-above-tablet-min) {
            display: block;
        }
    }
}
//Custom scroll bar code for hamburger menu
.hamburger_menu_nav {
    @include respond-min($bp-tablet-wide-min) { //Desktop
        &::-webkit-scrollbar {
            width: 15px;
            background: $clr-white;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $clr-dy-grey-5;
            border: 3px solid $clr-white;
            border-radius: 2rem;
        }
    }
}
//ROW-12520-Hamburger Menu - End
