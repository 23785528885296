.legal_attributes {
  @include respond-min($bp-mobile-wide-min) {
    dl {
      border: 1px solid $clr-dy-grey-2;
    }
    dt,
    dd {
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 0;
    }
    dd ~ dt,
    dd ~ dd {
      border-top: 1px solid $clr-dy-grey-2;
    }
    dt {
      float: left;
      width: 50%;
      padding-bottom: 0;
    }
    dd {
      margin-left: 50%;
      border-left: 1px solid $clr-dy-grey-2;
    }
    dd:after {
      content: "";
      display: block;
      clear: both;
    }
  }
}
