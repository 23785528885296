/*
  TYPOGRAPHY
*/

// For use in fs-responsive
$typography-breakpoints: (g:0, gt:$bp-tablet-min, gtw: $bp-tablet-wide-min, gd:$bp-desktop-min);

// Font sizes
// @TODO: Consider making all sizes static variables, then using them in the fs-responsive
$fs-body: 16px;
$fs-body-larger: 18px;
$fs-body-smaller: 14px;
$fs-small: 12px;

$fs-body-bp: (g:13px, gt:15px, gtw:$fs-body);
$fs-body-small-bp: (g:12px, gt:13px, gtw:13px);

$fs-hprimary-bp: (g:28px, gt:32px, gtw:40px);
$fs-h1-bp: (g:24px, gt:28px, gtw:34px);
$fs-h2-bp: (g:22px, gt:24px, gtw:28px);
$fs-h3-bp: (g:20px, gt:22px, gtw:24px);
$fs-h4-bp: (g:22px, gt:22px, gtw:22px);
$fs-h5-bp: (g:15px, gt:17px, gtw:20px);
$fs-h6-bp: $fs-body-bp;

$fs-lead-bp: (g:$fs-body, gt:18px, gtw:20px);

$fs-blockquote-bp: $fs-h1-bp;
$fs-blockquote-cite-bp: $fs-lead-bp;

// Line heights
$lh-base: 1.4;
$lh-condensed: 1.2;
$lh-tight: 1.1;
$lh-flat: 1;

$lh-heading: 1.3;

$lh-blockquote: $lh-heading;
$lh-blockquote-cite: $lh-base;

// MIXINS

// Rem font-size
@mixin fs-rem($size_in_px) {
  $size: stripUnit($size_in_px);
  font-size: #{$size}px;
  font-size: #{calcRem($size)}rem;
}

// Static font size
@mixin fs-static($size_in_px) {
  @include respond-min(0) {
    @include fs-rem($size_in_px);
  }
}

// Responsive font size
@mixin fs-responsive($fs-map, $fs-breakpoints: $typography-breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @include respond-min($fs-breakpoint) {
        @include fs-rem($fs-font-size);
      }
    }
  }
}

//////////////////////////////////

// HEADINGS

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: $lh-heading;
  color: $clr-dy-grey-7;
  margin: 1em 0;
  font-weight: 300;
  letter-spacing: -0.01em;

  &.h-inherit { color: inherit; }

  &.h-large-margin {
    margin: 1.5em 0;

    @include respond-min($bp-tablet-min) {
      margin: 2em 0;
    }
  }

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}

h1, h2, h3, h4, h5, h6 {

  // behaviour of headings when inside links
  a & {
    text-decoration: none;
  }

  a:hover &,
  a:focus &,
  a:active & {
    // text-decoration: underline;
  }
}

.hprimary { @include fs-responsive($fs-hprimary-bp); }
h1, .h1 { @include fs-responsive($fs-h1-bp); }
h2, .h2 { @include fs-responsive($fs-h2-bp); }
h3, .h3 { @include fs-responsive($fs-h3-bp); }
h4, .h4 { @include fs-responsive($fs-h4-bp); }
h5, .h5 { @include fs-responsive($fs-h5-bp); }
h6, .h6 { @include fs-responsive($fs-h6-bp); font-weight: bold; line-height: $lh-base; }

@mixin heading-reset() {
  margin: 0;
  color: inherit;
  line-height: $lh-base;
  font-weight: normal;
  @include fs-responsive($fs-body-bp);
}

// LINKS

a {
  color: $clr-dy-grey-7;
  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $clr-dy-grey-7;
  }

  &:visited {
    color: $clr-dy-grey-7;
  }
}

@mixin link-reverse() {
  text-decoration: none;

  &:hover, &:focus, &:active {
    // text-decoration: underline;
  }
}

@mixin link-reset() {
  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

@mixin button-reset() {
  background: transparent;
  border: 0;
  color: $clr-dy-grey-7;

  &:hover, &:focus, &:active {
    background: transparent;
    border: 0;
    text-decoration: none;
  }
}

// PARAGRAPHS

p {
  margin: 1em 0;

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }
}

.lead {
  @include fs-responsive($fs-lead-bp);
}

// LISTS

ol, ul {
  line-height: inherit;
}

li {

  ol, ul {

  }

  li {

  }
}

@mixin list-reset() {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin list-item-reset() {
  padding: 0;
  margin: 0;
}

// QUOTES

blockquote {
  text-align: center;
  font-style: normal;
  font-size: 100%;
  color: inherit;
  @include fs-responsive($fs-blockquote-bp);
  line-height: $lh-blockquote;
  margin: 1.2em 0;

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }

  p {
    margin: 0.8em 0;
  }

  cite {
    display: block;
    @include fs-responsive($fs-blockquote-cite-bp);
    line-height: $lh-blockquote-cite;
  }
}

@mixin blockquote-reset() {
  text-align: left;
  margin: 0;
  color: inherit;
  line-height: $lh-base;
  @include fs-responsive($fs-body-bp);

  cite {
    font-size: inherit;
    line-height: inherit;
  }
}

// HELPERS

small, .small {
  display: inline-block;
  @include fs-static($fs-small);
}

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

// Ensure the line-breaking happens correctly to ensure the meaning of the sentence doesn't change in KR.
* {
  word-break: keep-all;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

}
