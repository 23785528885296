body.rtl {
  .slick-slide {
    direction: rtl;
    float: right;
  }

  .slick-list {
    direction: rtl;
  }

  .slider__cart__icons {
    .slick-list {
      direction: ltr;
    }
  }

  .slider__control--prev {
    &:after {
      content: '›';
    }
  }

  .slider__control--next {
    &:after {
      content: '‹';
    }
  }
}
