.product__slideshow {

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: none;
  }

  .modal-inner-wrap {
    width: 100%;
    // padding-bottom: 20%;

    @include respond-min($bp-tablet-wide-min) {
      max-width: 50%;
    }

    @include respond-min($bp-desktop-wide-min) {
      max-width: 40%;
    }

    .modal-content {
      padding: 0;
    }
  }
}

// styles for product gallery modal
.product__slideshow__container {
  transform: none;

  h4 {
    padding-left: 20px;
    font-size: 14px;
    margin-top: -30px;
    font-weight: 500;
    width: 80%;

    @include respond-min($bp-tablet-min) {
      font-size: 16px;
    }
  }
}


.product__slideshow__container__gallery {
  overflow: hidden;
  -ms-overflow-style: none;
  transform: none;

  .slick-track {
    direction: rtl;
  }

  .slider__list {
    margin-left: 0;
    transform: none;
  }

  .slider__list__slide {
    position: relative;
    transform: none;

    &--video {
      position: relative;

      picture {
        @include z-index(content-mid);
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
      }

      .fluid-media {
        @include z-index(content-lower);
      }

      .image__cta {
        @include z-index(content-top);
      }

      button.image__cta--roundal {
        background: none;
        border: none;
      }
    }

    // video is playing
    &--video--active {
      picture {
        @include z-index(content-lower);
      }

      video {
        position: relative;
        @include z-index(content-mid);
      }

      .image__cta {
        display: none;
      }
    }

    &--video-js--active {
      .image__cta {
        display: none;
      }
    }
  }

  .slick-track {
    position: relative;
    top: 0;
    display: block;
  }

  .slick-dots {
    padding-left: 0;
    width: 100%;
    text-align: center;
    margin-top: 4px;

    li {
      display: inline-block;
      position: relative;
      margin: 0;

      &:after {
        content:'•';
        font-size: 20px;
        color: $clr-dy-grey-4;
        position: absolute;
        left: 50%;
        margin-left: -3px;
        top: 13%;
        z-index: -1;
      }

      &.slick-active:after {
        color: $clr-black;
      }

      button {
        background: none;
        border: none;
        color: transparent;
        font-size: 0;
        padding: 3px;
        margin: 17px;
      }
    }
  }
}
