body.rtl {
  .soft-option__item__card__inner {
    > span {
      left: inherit;
      right: 20px;
    }
  }

  .soft-bundling__selection--top {
    padding: 8px;
  }

  .soft-bundling__selection--top__text {
    margin-left: 0;
    margin-right: 20px;
  }

  .soft-bundling__selected__product-summary ul {
    padding-right: 0;
  }

  .soft-bundling__selected__product-summary__item {
    padding-right: 10px;
    padding-left: 0;
  }

  .modal-popup.soft-bundling__optional-warning {
    button.soft-bundling__optional-warning--button {
      margin-right: 0;
      margin-left: 29px;
    }
  }

  .soft-bundling__checkout--sub-total__subtotal {
    margin-right: 0;
    margin-left: 10%;

    @include respond-min($bp-tablet-min){
      margin-left: 20%;
    }
  }

  .soft-bundling__qty-input {
    margin-left: 0;

    p {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  .soft-bundling__checkout--list__price {
    text-align: left;
  }

  .soft-bundling__checkout--list {
    @include respond-min($bp-tablet-min) {
      span.price {
        float: left;
      }
    }
  }

  .soft-bundling__checkout--items-selected {
    p {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.soft-bundling__selected__button {
  display: none;

  &--show {
    display: inherit;
  }
}
