// ------- DYSON-SA SPECIFIC -------

.dyson-sa {

  &.rtl {

    #shipping-new-address-form {

      div.field[name="shippingAddress.street.0"] {
        @include respond-min($bp-tablet-min) {
          margin-left: 4%;
          margin-right: 0 !important;
        }
      }
    }

    .am-checkout {

      .fieldset {

        .field {

          &[name="shippingAddress.firstname"],
          &[name="shippingAddress.city"],
          &[name="shippingAddress.street.0"] {
            @include respond-min($bp-mobile-wide-min) {
              margin-left: 4%;
              margin-right: 0;
            }
          }
        }
      }

      .checkout-block__tabs__header {
        .payment-method-title {
          &:nth-child(odd) {
            margin-left: 0;
          }
        }
      }


    }
  }

  .am-checkout {

    .payment-method {

      .choice {
        padding: 0;
      }

      .billing-address-details {
        display: inherit;
      }
    }

    .fieldset {

      &#customer-email-fieldset {
        width: 100%;

        @include respond-min($bp-mobile-wide-min) {
          width: 48%;

          .field {
            width: 100%;
          }
        }
      }

      .field {

        &[name="shippingAddress.lastname"],
        &[name="shippingAddress.firstname"],
        &[name="shippingAddress.city"],
        &[name="shippingAddress.telephone"],
        &[name="shippingAddress.street.0"],
        &[name="shippingAddress.street.1"] {
          width: 100%;

          @include respond-min($bp-mobile-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.postcode"] {
          width: 100%;

          @include respond-min($bp-mobile-wide-min) {
            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.firstname"],
        &[name="shippingAddress.street.0"],
        &[name="shippingAddress.city"] {
          @include respond-min($bp-mobile-wide-min) {
            margin-right: 4%;
          }
        }

        &[name="shippingAddress.company"] {
          @include respond-min($bp-tablet-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.street.0"],
        &[name="shippingAddress.street.1"] {
          label.label {
            display: none;
          }
        }

        &.street {

          legend.label {
            display: inherit;
          }
        }
      }
    }
  }
}