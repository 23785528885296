.icon-info-set {
    .modal-inner-wrap {
        img {
          height: 4rem;
          width: 4rem;
        }

        .modal-content {
            padding-top: 2rem;
        }
    }

    .modal-header-inner {
        &:before {
            content: url('../images/logo-black.svg');
            display: inline-block;
            width: 4.5rem;

            @include respond-min(768px) {
                width: 100px;
            }
        }
    }
}

/*
    1. The reason the border line is on a pseudo element and also has a negative margin-top is because when the modal.js smooth scrolls to the anchor, on some viewports (larger ones) it does not scroll right up to the line. This is hacky but makes sure the smooth scroll always goes at least to it or past the border line.
*/
.icon-info-set-section {
    margin-bottom: 43px;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
        border-top: 1px solid #f3f3f3;
        position: absolute;
        top: -2px; /* 1 */
    }

    h2 {
        padding-top: 1.5rem;
        margin-top: -2px;
    }
}

.layout .icon-info-set-section:first-child {
    &:before {
        display: none;
    }
}
