// Custom style tweaks for TH post-V5 migration
.dyson-th {
    //un-hiding and styling tooltips
    .am-checkout {
        #customer-email-fieldset .field-tooltip,
        .field-tooltip-content,
        .field-tooltip.toggle {
            display: block !important;
            font-family: $f-futura;
            color: $clr-dy-grey-7;

            div {
                padding-top: .25rem;
            }
        }
    }

    //layout for shipping & billing address on spc
    .am-checkout .opc-wrapper .opc .fieldset {
        //hiding labels as legend is used instead
        .field.street {
            legend.label {
                display: none;
            }

            label {
                display: block;
                margin-bottom: 0.5em;
            }
        }

        .field[name="billingAddressP2c2pPayment.firstname"],
        .field[name="shippingAddress.company"], .field[name="billingAddressP2c2pPayment.company"],
        .field[name="shippingAddress.street.0"], .field[name="billingAddressP2c2pPayment.street.0"],
        .field[name="shippingAddress.city"], .field[name="billingAddressP2c2pPayment.city"],
        .field[name="billingAddressP2c2pPayment.telephone"] {
            .control {
                width: 100%;
            }

            @include respond-min($bp-tablet-wide-min) {
                width: 48%;
                margin-right: 4%;
            }
        }

        .field[name="billingAddressP2c2pPayment.lastname"],
        .field[name="shippingAddress.street.1"], .field[name="billingAddressP2c2pPayment.street.1"],
        .field[name="shippingAddress.postcode"], .field[name="billingAddressP2c2pPayment.postcode"] {
            .control {
                width: 100%;
            }


            @include respond-min($bp-tablet-wide-min) {
                width: 48%;
            }
        }

        .field[name="shippingAddress.postcode"], .field[name="billingAddressP2c2pPayment.postcode"] {
            .message.warning {
                display: none;
            }
        }
    }
}

// End of custom TH styles
