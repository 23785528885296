@media only screen and (min-width:1025px){
  .james-dyson-award__image--JDA-Logo{height:114px}
}

@media only screen and (max-width:1024px) and (min-width: 768px){
  .james-dyson-award__image--JDA-Logo{height:93px}
}

@media only screen and (max-width: 767px){
  .james-dyson-award__image--JDA-Logo{height:70px}
}