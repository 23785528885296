body.rtl {
  .stars--small {
    .stars__text {
      margin-left: 0;
      margin-right: 40px;
    }
  }

  .product-reviews-summary {
    .rating-summary {
      text-align: right;
    }
  }
}
