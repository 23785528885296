$roundal-size: 70px;
$roundal-size-sml: 28px;
$roundal-size-med: 90px;
$roundal-size-lrg: 130px;

.roundal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: $roundal-size;
  height: $roundal-size;
  border-radius: 50%;
  background: rgba($clr-black,0.6);
  color: $clr-white;
  overflow: hidden;
  text-align: left;

  &--large {
    @include respond-min($bp-tablet-wide-min) {
      width: $roundal-size-lrg;
      height: $roundal-size-lrg;
    }
  }

  &--medium{
    width: $roundal-size-med;
    height: $roundal-size-med;
  }

  &--small {
    width: $roundal-size-sml;
    height: $roundal-size-sml;
    @include respond-min($bp-tablet-min) {
      width: $roundal-size-sml * 1.45;
      height: $roundal-size-sml * 1.45;
    }
  }
    &--too--small {
        width: 21px;
        height: 21px;
        display: inline-block;
        position: relative;
        @include respond-min($bp-tablet-min) {
            width: $roundal-size-sml;
            height: $roundal-size-sml;
        }
        .roundal__icon {
            width: 15px;
            .icon {
                width: 12px;
                height: 12px;
                top: -11px!important;
                left: 5px!important;
                @include respond-min($bp-tablet-min) {
                    top: -10px;
                    left: 6px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
        ~ .icon-text {
            font-size: 16px;
            vertical-align: text-top;
            font-weight: 400;
            @include respond-min($bp-tablet-min) {
                font-size: 18px;
            }
        }
    }

  &--solid {
    background: $clr-dy-grey-7;
  }

  &--solid-light {
    background: $clr-white;

    svg.icon--logo {
      fill: $clr-dy-grey-7;
    }
  }
}

.roundal__icon {
  display: inline-block;
  width: 21px;

  .roundal--large & {
    @include respond-min($bp-tablet-wide-min) {
      width: 40px;
    }

    .icon {
      @include respond-min($bp-tablet-wide-min) {
        width: 2.75em;
        height: 2.75em;
        top: 7px;
        left: 3px;
      }
    }
  }

  .roundal--small & {
    width: 15px;

    @include respond-min($bp-tablet-min) {
      width: 25px;
    }

    .icon {
      width: 0.65em;
      height: 0.65em;
      top: 2px;
      left: 4px;

      @include respond-min($bp-tablet-min) {
        width: .8em;
        height: .8em;
      }
    }
  }

  .icon {
    fill: $clr-white;
    position: relative;
    top: 2px;
    left: -2px;
    width: 2.3em;
    height: 2.3em;
    cursor: pointer;

    @include respond-min($bp-tablet-wide-min) {
      left: -4px;

      .promos__item & {
        left: -5px;
      }
    }
  }

  .icon--dark {
    fill: $clr-black;
  }
}

// Fix - translation does not fit within the roundal so client asked to remove
.dyson-mx {
  .roundal__text {
    display: none;
  }
}

.roundal__text {

  // off on mobile
  display: none;

  // medium by default (stolen from large, @TODO needs redoing)
  @include respond-min($bp-tablet-wide-min) {
    text-align: center;
    text-transform: uppercase;
    position: relative;
    top: 7px;
    display: inline-block;
  }

  // always off
  .roundal--small &,
  .promos__item & {
    display: none;
  }

  // customise for large
  .roundal--large & {
    @include respond-min($bp-tablet-wide-min) {
      text-align: center;
      text-transform: uppercase;
      position: relative;
      top: 7px;
      display: inline-block;
    }
  }
}

.roundal--animated {
  .roundal__icon {

  }

  a:hover & {
    .roundal__icon {
      animation-name: roundal-animation;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
    }
  }
}

.roundal__play-button {
  background: $clr-white;
  border-radius: 50%;
  box-shadow: -3px -3px 54px 0px rgba(217,217,217,1);
   -webkit-box-shadow: -3px -3px 54px 0px rgba(217,217,217,1);
   -moz-box-shadow: -3px -3px 54px 0px rgba(217,217,217,1);

  @include respond-min($bp-tablet-min) {
    background: rgba(0, 0, 0, 0.6);
    width: 40px;
    height: 40px;
    top: 12px;
    position: absolute;
    left: -21px;
  }
}

.roundal__play-button__icon svg.icon.icon--play.icon--dark {
  @include respond-min($bp-tablet-min) {
    fill: $clr-white;
    height: 20px;
  }
}

@keyframes roundal-animation {
  50% {
    transform: translateX(150%);
  }
  50.1% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
