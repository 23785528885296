.sticky-promotion-banner .layout {
  padding-left: 0;
  padding-right: 0;
  max-width: 1000px;

  @include respond-min($bp-tablet-wide-min) {
    padding-left: 24px;
    max-width: 1024px;
  }

  @include respond-min(1280px) {
    padding-left: 27px;
    max-width: 1280px;
  }

  @include respond-min(1400px) {
    padding-left: 29px;
    max-width: 1025px;
  }
}

.message.global.cookie.sticky-promotion-banner__container {
  background: $clr-black;
  opacity: 0.95;
  color: $clr-white;
  width: 100%;
  bottom: 0;
  position: fixed;
  padding: 16px;
  //@include z-index(heaven);
  z-index: 30;
  font-size: 14px;
  line-height: 20px;
  max-width: 1280px;

  @include respond-min($bp-tablet-min) {
    padding: 20px;
  }

  @include respond-min($bp-tablet-wide-min) {
    width: 75%;
    padding: 24px 24px 24px 0;
  }
}

.message.cookie.sticky-promotion-banner__container p {
  margin-top: 0;
}

.message.global.cookie.sticky-promotion-banner__container .content {
  width: 100%;
  padding: 0;

  @include respond-min($bp-tablet-wide-min){
    width: 100%;
  }
}

.message.global.cookie.sticky-promotion-banner__container .actions {
  right: 0;

  .action {
    padding: 0;
  }
}

.sticky-promotion-banner--text-container {
  margin-left: 8px;

  @include respond-min($bp-tablet-min) {
    margin-left: 12px;
  }
}

.rtl .sticky-promotion-banner--text-container {
  margin-right: 8px;
  margin-left: 0;

  @include respond-min($bp-tablet-min) {
    margin-right: 12px;
  }
}

.sticky-promotion-banner__text {
  padding: 0;
  display: flex;

  @include respond-min($bp-desktop-min) {
    padding: 0;
  }
  span, a {
    @include fs-static(14);
    line-height: 20px;
    color: $clr-dy-grey-7;

    @include respond-min($bp-tablet-wide-min) {
      @include fs-static(16);
      line-height: 24px;
    }
  }
  span {
    display: block;

    @include respond-min($bp-tablet-min) {
      display: inline-block;
    }
  }
  a {
    font-weight: normal;
    text-decoration: underline;
  }
}

.sticky-promotion-banner__icon{
  height: 18px;
  width: 18px;
  flex-shrink: 0; //for IE11

  @include respond-min($bp-tablet-min) {
    height: 20px;
    width: 20px;
  }
  @include respond-min($bp-tablet-wide-min) {
    height: 24px;
    width: 24px;
  }
}

.sticky-promotion-banner__icon img {
  vertical-align: top;
}

.sticky-promotion__wysiwyg {
  margin-right: 48px;
  max-height: 60px;
  min-height: 20px;
  overflow: hidden;

  @include respond-min($bp-tablet-min) {
    padding-right: 60px;
    width: 100%;
  }
  @include respond-min($bp-tablet-wide-min) {
    padding-right: 26px;
    max-height: 70px;
  }
}

//this is to force a maximum of 3 lines of text - although users should make sure the content doesn't run over 3 lines
.sticky-promotion__wysiwyg span {
  overflow: hidden;
  max-height: 60px;

  @include respond-min($bp-tablet-wide-min) {
    max-height: 70px;
  }
}

.sticky-promotion__wysiwyg p span a span {
  display: inline;
}

.sticky-promotion__wysiwyg a:link,
.sticky-promotion__wysiwyg a:visited,
.sticky-promotion__wysiwyg a:active,
.message.cookie.sticky-promotion-banner__container p a:link,
.message.cookie.sticky-promotion-banner__container p a:visited,
.message.cookie.sticky-promotion-banner__container p a:active {
  text-decoration: none;
  border-bottom: 1px solid;
}

.sticky-promotion__wysiwyg a:hover,
.sticky-promotion__wysiwyg a:focus,
.message.cookie.sticky-promotion-banner__container p a:hover,
.message.cookie.sticky-promotion-banner__container p a:focus {
  text-decoration: none;
  border-bottom: 1px solid transparent;
}


.rtl .sticky-promotion__wysiwyg {
  margin-left: 32px;
  margin-right: 0;

  @include respond-min($bp-tablet-min) {
    margin-left: 40px;
  }
  @include respond-min($bp-tablet-wide-min) {
    margin-left: 48px;
  }
}

.action.allow.primary.sticky-promotion-banner__close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 10px;
  top: 15px;
  transition-timing-function: ease-in;
  transition: 0.2s;

  @include respond-min($bp-tablet-min) {
    margin-left: 40px;
    right: 15px;
  }
  @include respond-min($bp-tablet-wide-min) {
    margin-left: 48px;
    right: 19px;
    top: 16px;
  }
}

.rtl .action.allow.primary.sticky-promotion-banner__close {
  width: 16px;
  height: 16px;
  right: auto;
  left: 10px;
  top: 15px;

  margin-right: 5px;

  @include respond-min($bp-tablet-min){
    margin-left: 0;
    margin-right: 40px;
    left: 15px;

  }
  @include respond-min($bp-tablet-wide-min) {
    margin-left:0;
    margin-right: 48px;
    left: 19px;
    top: 15px;
  }
}

.action.allow.primary.sticky-promotion-banner__close svg {
  width: 16px;
  height: 16px;
  right: 6px;
  top: 5px;

  @include respond-min($bp-tablet-min) {
    width: 20px;
    height: 20px;
    top: 9px;
  }
  @include respond-min($bp-tablet-wide-min) {
    width: 24px;
    height: 24px;
    right: 5px;
  }
}

.rtl .action.allow.primary.sticky-promotion-banner__close svg {
  width: 16px;
  height: 16px;
  right: 15px;
  top: 0px;

  @include respond-min($bp-tablet-min) {
    width: 20px;
    height: 20px;
    top: 8px;
  }
  @include respond-min($bp-tablet-wide-min) {
    width: 24px;
    height: 24px;
    right: 5px;
    top: 9px;
  }
}

.rtl .sticky-promotion-banner .message.cookie .actions{
  left: 20px;
  top: 0px;
}

.message.cookie .sticky-promotion-banner--text-container p a {
  font-weight: normal;
}