.product {

}

.product__main {

  @include respond-min($bp-tablet-min) {
    @include clearfix;
  }
}

.product__logo {
  @include vspace($v-small,$v-minuscule,$dir:bottom);

  @include respond-min($bp-tablet-min) {
    // float: left;
    // width: 60%;

    .product--group & {
      // width: 40%;
      // max-width: 400px;
    }
  }
}
.button .button--cart__basket-icon--card {
    width: 24px;
    position: relative;
    left: -4px;
    top: 4px;
}
.product__image {
  text-align: center;
  @include vspace($v-tiny,0,$dir:bottom);

  @include respond-min($bp-tablet-min) {
    text-align: right;
    float: right;
    max-width: 40%;
    padding-left: $l-gutter;

    .product--group & {
      width: 60%;
      max-width: none;
      text-align: center;
    }
  }
}

.product__image__smallprint {
  text-align: right;
  @include vspace($v-tiny,$dir:top);
}

.product__info {

  @include respond-min($bp-tablet-min) {
    // float: left;
    // width: 60%;

    .product--group & {
      // width: 40%;
      // max-width: 400px;
    }
  }
}

.product__features {
  @include list-reset;
  @include fs-responsive((g:16, gtw:24));
  font-family: $f-futura;
  font-weight: 300;
  border-top: 1px solid $clr-dy-grey-3;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-min) {
    border-top: 0;
  }

  li {
    @include list-item-reset;
    @include vspace($v-minuscule);
    border-bottom: 1px solid $clr-dy-grey-3;
  }
}

.product__features__title {
  @include fs-responsive((g:12,gtw:14));
  color: $clr-dy-grey-7;
  font-family: $f-fallback;
  margin-bottom: 0.5em;
}

.product__features__feature {
  @include fs-responsive((g:16,gtw:24));

  .product__features__title + & {
    margin-top: 0.5em;
  }
}

.product__basket {
  //@include vspace($v-tiny,$v-medium,$v-medium,$dir:top);

  .hero--product-variant__inner--perfect & {
    padding: 0;
  }
}

.product__tag {
  @include vspace($v-minuscule,$dir:top);
}

.product__action {
  @include vspace($v-minuscule,$dir:top);
//  max-width: 280px;

  .product--group & {
    @include vspace($v-small,$v-medium,$dir:top);

    @include respond-min($bp-tablet-min) {
      max-width: none;
    }
  }
}

.product__action__add-to-cart {


  @include respond-min($bp-tablet-min) {
    width: 50%;
  }
  @include respond-min($bp-desktop-min) {
    width: 100%;
  }
}

.product__action__text {
  @include fs-static($fs-small);
  @include vspace($v-minuscule,$dir:top);
}

.product__smallprint {
  clear: both;
  @include fs-static($fs-small);
  @include vspace($v-tiny,$v-small,$v-minuscule,$dir:top);
}

.product__urgency-messaging {
  @include fs-responsive($fs-body-small-bp);
  margin-top: 20px;
  font-family: $f-futura;
  margin-bottom: 10px;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

}

.product__urgency-messaging__time-icon {
  width: 15px;
  vertical-align: sub;
}

.product__claim-section {
  width: 100%;

  @include respond-min($bp-tablet-min) {
    display: flex;
  }

  @include respond-min($bp-desktop-min) {
    width: 33.33%;
    float: right;
    order: 3;
    display: inline-block;
  }
}

.product__claims {
  margin: 50px 0px;
  display: inline-block;

  @include respond-min($bp-tablet-min) {
    width: 70%;
    margin-right: 30px;
  }

  @include respond-min($bp-desktop-min) {
    width: 100%;
  }

  ul {
    padding-left: 20px;
    color: #333;
  }

  a:link {
    border-bottom: 1px solid #333;
  }

  a:hover {
    border-bottom: none;
  }
}

.dyson-nz {
  .product__claims {
    margin: 0 0 50px;
  }
}

.product__claims__features {
  margin: 20px 0;

  &__feature {
    border-bottom: 1px solid $clr-black-15;

    p {
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0;

      @include respond-min ($bp-tablet-wide-min) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__feature--last {
    border-bottom: none;
  }
}



.product__demo-video {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 22px;
  padding: 0;
  vertical-align: top;

  @include respond-min($bp-tablet-min) {
    position: static;
    margin: 50px 10px 0 0;
    width: 25%;
    max-width: 25%;
  }

  @include respond-min($bp-desktop-min) {
    width: 45%;
    max-width: 45%;
    // margin-top: 50px;
  }

  p {
    display: none;
    @include respond-min($bp-tablet-min) {
      display: inline-block;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .product__demo-video__still {
    cursor: pointer;
    object-fit: cover;
    border: none;
    background: none;
    padding: 0;
  }

  .product__demo-video__still img {
    height: 0px;
    object-fit: cover;

    @include respond-min($bp-tablet-min) {
      height: 80px;
    }

    @include respond-min($bp-tablet-wide-min) {
      height: 110px;
    }
  }
}

.image__cta.image__cta--roundal.product__demo-video__play-button
  .roundal.roundal--medium.roundal__play-button {
    @include respond-min($bp-tablet-wide-min) {
      top: 27px;
    }
}

.product__demo-video__play-button {
  position: absolute;
  left: 10px;
  top: 10px;

  .roundal__icon svg.icon {
    left: 0px;

    @include respond-min($bp-tablet-min) {
      left: -5px;
    }

    @include respond-min($bp-desktop-min) {
      left: -6px;
    }
  }

  @include respond-min($bp-tablet-min) {
    left: 50%;
  }

  @include respond-min($bp-tablet-wide-min) {
    top: 27%;
  }
}

.hgroup__product--column1 {
  @include respond-min($bp-desktop-min) {
    width: 33.33%;
  }
}

h1.hgroup__motif__h1-cdn-img-replace {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100px;
  width: 285px;
  padding-top: 250px;
  overflow: hidden;
  // background-image: url(data-content);

  @include respond-min($bp-tablet-min) {
    width: 310px;
  }
}

.product__claim-section__gallery {
  // display: inline-block;
  // background-color: $clr-white;
  // border-radius: 50%;
  // position: absolute;
  // top: 0;
  // left: 0;
  display: none;

  @include respond-min($bp-tablet-min) {
    margin: 50px 10px 0 0;
    width: 25%;
    position: static;
    display: inline-block;
  }

  @include respond-min($bp-desktop-min) {
    width: 45%;
  }

  p {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .product__claim-section--gallery-image {
    background: none;
    border: none;
    padding: 0;
  }

}

.product__claim-section__gallery--images {
  display: none;

  @include respond-min($bp-tablet-min) {
    height: 80px;
    color: $clr-white;
    display: inline-block;
    img {
      height: 80px;
      object-fit: cover;
    }

    @include respond-min($bp-tablet-wide-min) {
      img {
        height: 110px;
      }
    }
  }

  @include respond-min($bp-desktop-min) {
    height: 108px; //temporary until I've built the gallery
  }

}

.product__claim-section--gallery-button {
  background: $clr-white;
  box-shadow: -3px -3px 54px 0px rgba(217,217,217,1);
   -webkit-box-shadow: -3px -3px 54px 0px rgba(217,217,217,1);
   -moz-box-shadow: -3px -3px 54px 0px rgba(217,217,217,1);
  position: absolute;
  top: 77px;
  height: 70px;
  width: 70px;
  background-color: white;
  border-radius: 50px;
  padding: 18px 10px 0 10px;

  img {
    width: 70%;
    margin: 7px 7px;
  }

  @include respond-min($bp-tablet-min) {
    display: none;
  }

}

.product__claim-section__gallery-image img {
  height: 107px
}

//Product variant
.catalog-product-view {
  #product_addtocart_form {
    .card__action .secondary-nav__add, .product__action{
      @include respond-min($bp-mobile-max) {//min 767 tab
        padding-top: 20px;
      }
      @include respond-max($bp-mobile-max) {//max 767 mobile
        padding-top: 0;
      }
      @include respond-min($bp-tablet-wide-min) {//1024 desktop
        padding-top: 20px;
      }
    }
  }
}
.choose-variant-wrapper{
  padding-top: 20px;
  @include respond-min($bp-phablet-wide-min) {//min 640 tab
    padding-bottom: 12px;
  }
  @include respond-max($bp-phablet-wide-min) {//max 640 mobile
    padding-bottom: 8px;
  }
  @include respond-min($bp-tablet-wide-min) {//1024 desktop
    padding-bottom: 16px;
  }
  .choose-text{
    font-family: $f-futura;
    font-size: 14px;
    color: $clr-dy-grey-7;
    font-weight: 500;
    line-height: 20px;
    display: block;
    .selected-option {
        font-weight: 300;
    }
  }
  .brand_bg {
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
        .rtl &{
          left: auto;
          right: -9999px;
        }
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 40px;
        padding-right: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer;
        display: inline-block;
        font-family: "Futura Latin", "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: $clr-dy-grey-7;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        .rtl & {
          padding-right: 40px;
          padding-left: 16px;
        }
    }
    [type="radio"]:checked + label{
      font-weight: 500;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        border: 2px solid #333;
        border-radius: 100%;
        background: #fff;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
        .rtl & {
          left: auto;
          right: 0;
          margin-right: 0;
          margin-left: 8px;
        }
        @include respond-max($bp-tablet-wide-min) {
          width: 24px;
          height: 24px;
        }
    }
    [type="radio"]:not(:checked) + label:before {
      border: 1px solid #919191;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 16px;
        height: 16px;
        background: #333;
        position: absolute;
        top: 16px;
        left: 8px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        .rtl & {
          left: auto;
          @include respond-min($bp-phablet-wide-min) {//min 640 tab
            right: 7px;
          }
          @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            right: 7px;
          }
          @include respond-min($bp-tablet-wide-min) {//1024 desktop
            right: 8px;
          }
        }
        @include respond-max($bp-tablet-wide-min) {
          width: 14px;
          height: 14px;
          top: 15px;
          left: 7px;
        }
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    .out-of-stock { //inactive
      [type="radio"]:not(:checked) + label {
        text-decoration: line-through;
        color: #555555;
      }
      [type="radio"]:not(:checked) + label:after{
        text-decoration: line-through;
        position: absolute;
        content: "";
        top: 23px;
        left: 0;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;
        transform: rotate(-38deg) !important;
        width: 29px;
        opacity: 1;
        height: 0;
        @include respond-max($bp-tablet-wide-min) {
          width: 25px;
          top: 20px;
        }
      }
    }
    .out-of-stock { //active
      [type="radio"]:checked + label {
        text-decoration: line-through;
        color: #555555;
      }
    }
    .active.out-of-stock { //active
      [type="radio"]:checked + label span.active-linethrough:after{
        text-decoration: line-through;
        position: absolute;
        content: "";
        top: 23px;
        right: 0px;
        left: 0px;
        border-top: 2px solid;
        border-color: inherit;
        transform: rotate(-38deg) !important;
        width: 31px;
        opacity: 1;
        height: 0;
        @include respond-max($bp-tablet-wide-min) {
          width: 28px;
          top: 21px;
        }
      }
    }
    //Color variant
    .color-swatch {
      display: flex;
      align-items: center;
      margin-left: -8px;
      .prodvariants{
        position: relative;
        padding: 8px;
        display: block;
        font-size: 0;
        img {
            border-radius: 100%;
            cursor: pointer;
            @include respond-min($bp-phablet-wide-min) {//min 640 tab
              width: 20px;
              height: 20px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
              width: 20px;
              height: 20px;
            }
            @include respond-min($bp-tablet-wide-min) {//1024 desktop
              width: 24px;
              height: 24px;
            }
        }
        &:not(last-child){
          margin-right: 4px;
        }
        &.active {
            img {
              border: 2px solid #333;
              padding: 1px;
              @include respond-min($bp-phablet-wide-min) {//min 640 tab
                width: 28px;
                height: 28px;
              }
              @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                width: 28px;
                height: 28px;
              }
              @include respond-min($bp-tablet-wide-min) {//1024 desktop
                width: 32px;
                height: 32px;
              }
            }
        }
        &.out-of-stock{
          cursor: default;
          img {
              opacity: 0.7;
              cursor: default;
          }
          &.active img {
            @include respond-min($bp-phablet-wide-min) {//min 640 tab
              width: 22px;
              height: 22px;
            }
            @include respond-max($bp-phablet-wide-min) {//max 640 mobile
              width: 22px;
              height: 22px;
            }
            @include respond-min($bp-tablet-wide-min) {//1024 desktop
              width: 28px;
              height: 28px;
            }
          }
          span.cross {
            background: linear-gradient(to top left, #fff calc(50% - 1px), black , #fff calc(50% + 1px) );
            border-radius: 100%;
            display: block;
            font-size: 0;
          }
          &.active {
            span.cross {
              border: 2px solid #333;
            }
            img {
                border: 0;
            }
          }
        }
      }
    }
  }
}

.card {
  .choose-variant-wrapper {
    padding: 0;
  }
}
body {
  &.catalog-category-view,
  &.catalog-product-view,
  &.cms-page-view {
    .contents {
      &.box__container {
        .g-col.gtw-col-2-3 {
          padding-left: 0;
        }
        .contents__body {
          .product__in-the-box__contents-list {
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
          }
        }
        .contents__main .contents__item {
          width: 100%;
          padding-top: 16px;
          padding-bottom: 0;
          .contents__item__text {
            .box_product_hero_img {
              width: 100%;
              img {
                max-height: 356px;
                margin: 0 auto;
              }
            }
          }
        }
        .whats-in-the-box__row {
          .whats-in-the-box__col .whats-in-the-box__tool--wrapper {
            margin: 0;
            text-decoration: none!important;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            height: auto!important;
            overflow: hidden;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            width: 100%;
            margin-top: 16px;
            padding-left: 0;
            &.selected {
              outline: none;
              .whats-in-the-box__tool-item {
                .whats-in-the-box__tool-main-container {
                  border: 3px solid #666;
                  .toolDesc {
                    width: 100%;
                    height: auto;
                    padding: 16px;
                    opacity: 1;
                    margin: 0;
                    min-height: 54px;
                  }
                  svg.shape.shape--medium {
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                  }
                }
              }
            }
            .whats-in-the-box__tool-item {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              .whats-in-the-box__tool-main-container {
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                border: 1px solid #ebebeb;
                margin-top: 0;
                min-height: 98px;
                cursor: pointer;
                padding: 0;
                outline: none;
                .whats-in-the-box__tool-top-row {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  width: 100%;
                  -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
                  .whats-in-the-box__tool-image-box {
                    height: 116px;
                    width: auto;
                    margin: 0;
                    max-width: 94px;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    .whats-in-the-box__tool-image {
                      height: 110px;
                      width: auto;
                      min-height: 110px;
                      min-width: auto;
                    }
                  }
                  .whats-in-the-box__tool-name-container {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    border-bottom: 0;
                    max-height: 116px;
                    height: 100%!important;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding: 48px 0;
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    -ms-flex-pack: justify;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    svg.shape.shape--medium {
                      width: 15px;
                      height: 15px;
                      margin-right: 16px;
                      margin-left: 16px;
                      fill: #999;
                      color: #999;
                    }
                  }
                }
                .toolDesc {
                  display: block;
                  height: 0;
                  padding: 0;
                  -webkit-transition: all .5s ease-out;
                  transition: all .5s ease-out;
                  opacity: 0;
                  margin-left: 16px;
                  font-size: 14px;
                  line-height: 20px;
                  color: #555;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ROW- 11411 UI Klarna payment PDP page
.catalog-product-view {
  .klarna-payment {
    padding-top: 8px;
  }

  .card {
    .card__inner {
      .klarna-payment {
        padding-bottom: 0;
      }
    }
  }
}

//ROW-11420
.catalog-product-view {
  .messages {
    .message {
      &.error {
        background-color: $clr-dy-grey-1;
        margin-top:0;
        padding:16px;
        @include respond-min($bp-tablet-min) {
          padding:20px;
        }
        @include respond-min($bp-tablet-wide-min) {
          padding:32px;
        }

        div {
          color:$clr-mage-error;
          font-size:14px;
          line-height: 20px;

          &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 13.3px;
            height: 13.3px;
            background: url('../images/icons/alert.png') no-repeat;
            background-size: contain;
            margin-bottom: 5px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

//ROW-12807
.catalog-product-view {
  .card__preorder {
    margin:8px 0 8px 0;
    .pre-order-message {
      color:$clr-dy-grey-7;
      line-height: 20px;
      font-size:14px;
      font-weight: 500;
      @include respond-min($bp-tablet-wide-min) {
        line-height: 24px;
        font-size:16px;
      }
    }
  }
}

//row-11289
.catalog-product-view {
  .hero--product-variant__old_imperfect_layout {
    .product__action {
      .button.pre-order-btn{
        @media screen and (min-width: $bp-tablet-wide-min) and (max-width:$bp-pdp-wide) {
          width:100%;
        }
      }
    }
  }
}

// ROW - 16107
.catalog-product-view {
  color: $clr-dy-grey-7;
  .hero--product-variant--perfect,
  .hero--product-variant {
    background: none;
  }
  .mobile-show-pdp {
    display: none;
  }
  .hero {
    .row--small {
      padding-top: 0;
    }
  }
  &.rtl {
    .hero {
      .product__demo-video__play-button {
        @include respond-max($bp-phablet-wide-min) {
          right: -24px;
        }
      }
      .image {
        .hgroup__motif__h1-cdn-img-replace {
          background-position: right;
        }
      }
    }
    .mobile-show-pdp .product-gallery__image--icon-container .product__claim-section--gallery-button {
      @include respond-max($bp-phablet-wide-min) {
        right: -5px;
        left: 0;
      }
    }
    .product__demo-video {
      .product__demo-video__play-button {
        .roundal__icon .icon {
          right: -8px;
          @include respond-max($bp-phablet-wide-min) {
            right: 0;
            left: 0;
          }
        }
      }
    }
  }
  .hero {
    .single-line-motif {
      .hgroup__motif__h1-cdn-img-replace,
      img {
        height: 24px;
        padding-top: 24px !important;
        @include respond-min($bp-tablet-min) { //min 768 tab
          height: 26px;
          padding-top: 26px !important;
        }
        @include respond-min($bp-tablet-wide-min) { // 1024 desktop
          height: 28px;
          padding-top: 28px !important;
        }
      }
    }
    .double-line-extra-motif {
      .hgroup__motif__h1-cdn-img-replace,
      img {
        height: 50px;
        padding-top: 50px !important;
        @include respond-min($bp-tablet-min) { //min 768 tab
          height: 53px;
          padding-top: 53px !important;
        }
        @include respond-min($bp-tablet-wide-min) { // 1024 desktop
          height: 57px;
          padding-top: 57px !important;
        }
      }
    }
    .double-line-motif {
      .hgroup__motif__h1-cdn-img-replace,
      img {
        height: 44px;
        padding-top: 44px !important;
        @include respond-min($bp-tablet-min) { //min 768 tab
          height: 48px;
          padding-top: 48px !important;
        }
        @include respond-min($bp-tablet-wide-min) { // 1024 desktop
          height: 52px;
          padding-top: 52px !important;
        }
      }
    }
  }
  .hero--product-variant {
    .mobile-show-pdp {
      .hgroup__image {
        padding-top: 48px;
        padding-bottom: 16px;
        @include respond-max($bp-mobile-max) {
          margin: 0;
          padding: 0;
        }
        .image img {
          padding: 0!important;
          margin: 0;
          max-width: 100%;
        }
      }
    }
  }
  @include respond-max($bp-mobile-max) {
    .mobile-show-pdp {
      display: block;
      padding-top: 32px;
      .reviews-form-link {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }
      .product-gallery__image--icon-container {
        position: relative;
        z-index: 10;
        display: inline-block;
        .product__demo-video {
          margin-top: 16px;
        }
        .product__claim-section--gallery-button {
          top: 74px;
          margin-top: 8px;
        }
      }
      .product-reviews-summary {
        margin-top: 16px;
        margin-bottom: 0;
        .dyson-bazaarvoice__reviews-link {
          font-size: 14px!important;
          font-weight: 300!important;
          line-height: 20px!important;
          color: $clr-dy-grey-66!important;
        }
        .reviews-actions,
        .bv_main_container {
          a,
          .bv_text {
            font-size: 14px;
            font-weight: 300!important;
            line-height: 20px;
            color: $clr-dy-grey-66!important;
          }
        }
      }
      .hgroup__image {
        margin: 0;
        .image {
          span {
            margin-bottom: 16px;
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            padding-top: 0;
            padding-bottom: 0;
          }
          h1.hgroup__motif__h1-cdn-img-replace {
            margin: 0;
          }
        }
      }
    }
    .desktop-show-pdp {
      .product__demo-video,
      .product__claim-section--gallery-button,
      .hgroup__image,
      .product-reviews-summary,
      .reviews-form-link {
        display: none;
      }
      .product__claims__features {
        margin: 8px 0;
      }
      .hero__pricing__sold-out {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $clr-dy-grey-66;
      }
    }
    .hero--product-variant {
      .desktop-show-pdp {
        .hero__media.hero__media--absolute--g {
          display: none;
        }
      }
      .mobile-show-pdp {
        .hero__media.hero__media--absolute--g {
          position: relative;
        }
      }
      .hgroup {
        .hgroup__title  {
          margin-bottom: 16px;
          padding-top: 16px;
        }
        .hgroup__prefix {
          font-size: 20px;
          font-weight: 300;
          line-height: 28px;
          color: $clr-dy-grey-7;
        }
      }
    }
    .hero--product-variant--perfect {
      .product__claims {
        margin-top: 0;
        border-top: 1px solid $clr-dy-grey-2;
        width: 100%;
        padding-top: 32px;
        margin-bottom: 0;
        .hgroup__prefix {
          font-size: 20px;
          font-weight: 300;
          line-height: 28px;
          color: $clr-dy-grey-7;
          padding-bottom: 8px;
        }
        a {
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          color: $clr-dy-grey-7;
        }
        .product__claims__features__feature p {
          font-weight: 300;
          color: $clr-dy-grey-7;
        }
      }
    }
    .choose-variant-wrapper {
      padding-bottom: 0;
      padding-top: 16px;
    }
    .product__price-matched {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      .emi {
        margin: 0!important;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        background-size: 20px!important;
      }
      font {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }
      br {
        display: none;
      }
    }
    .product__urgency-messaging {
      color: $clr-mage-error;
      font-size: 12px;
      font-weight: 300;
      line-height: 17px;
      margin-top: 16px;
      margin-bottom: 0;
      a {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }
    }
    .custom-ajax-form,
    .notify-me-form {
      .button {
        margin-top: 16px;
        margin-bottom: 32px;
      }
      .dyson-promotion-banner__container {
        margin: 0;
      }
    }
    .product__basket {
      .product__price {
        padding-top: 16px;
        .price-box {
          margin: 0;
        }
        .price__is {
          .price-wrapper {
            margin-left: 0;
            display: block;
          }
          .price {
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 36px;
          }
        }
        .price__save {
          font-size: 16px;
          font-weight: 300;
          line-height: 18px;
          .price {
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
          }
        }
      }
    }
    .contents.box__container {
      .layout {
        padding: 0;
      }
      .contents__title {
        padding: 0 25px;
      }
      .contents__body {
        .g-col {
          padding: 0;
          .box_prod_name {
            padding: 16px 0;
            width: 80%;
            margin: 0 auto;
          }
        }
      }
      .g-wrap {
        margin: 0;
      }
      .contents__body {
        .whats-in-the-box__row {
          .whats-in-the-box__col {
            width: 100%;
            padding: 0;
            .whats-in-the-box__tool--wrapper {
              margin-top: 0;
              width: 100%;
              &.selected {
                .whats-in-the-box__tool-item {
                  .whats-in-the-box__tool-main-container {
                    border-left: 0;
                    border-right: 0;
                    border-width: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include respond-max($bp-phablet-wide-min) {
    .mobile-show-pdp {
      .roundal,
      .product__claim-section--gallery-button {
        width: 40px;
        height: 40px;
        border: 1px solid $clr-dy-grey-2;
      }
      .product-gallery__image--icon-container {
        .product__demo-video {
          margin-top: 0;
          .product__demo-video__play-button {
            top: 0;
            padding-top: 16px;
            left: 3px;
            .roundal__icon .icon {
              height: 17px;
              width: 17px;
              left: 4px;
            }
          }
        }
        .product__claim-section--gallery-button {
          top: 38px;
          margin-top: 8px;
          padding: 9px 10px 0;
          left: 5px;
          .product__claim-section__gallery-icon {
            width: 100%;
            margin: 0;
            height: 14px;
            width: 22px;
          }
        }
      }
    }
  }
}

//row-12776
.catalog-product-view {
    .product__price-matched {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding:8px 0;
        @include respond-min($bp-tablet-min) {
            width: 50%;
        }

        @include respond-min($bp-tablet-wide-min) {
            font-size: 16px;
            line-height: 24px;
            color: $clr-dy-blue-1;
            width: 100%;
        }
    }
    .hero {
        .product__price-matched {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
