.stat-set {

}

.stat-set__list {
  @include vspace($v-tiny,$v-small,$v-small,$dir:top,$negative:true);
}

.stat-set__item {
  @include vspace($v-tiny,$v-small,$v-small,$type:margin,$dir:top);
  @include vspace($v-tiny,$v-tiny,$v-small,top);
  border-top: 1px solid $clr-dy-grey-6;
}
