/*
  LAYOUT
*/

// WIDTHS
$l-width-max: $site-max-width; // max site width
$l-width-reduced: 1100px;
$l-width-tight: 740px;
$l-width-girdle: 580px;

// PERCENT WIDTH
$l-width-1-3: 33.3334%;
$l-width-2-3: 66.6667%;

// GUTTERS
$l-gutter: 50px;
$l-gutter-small: 25px;

// HELPERS
@mixin layout($width:$l-width-max,$gutter-sml:$l-gutter-small,$gutter-lrg:$l-gutter,$include-gutter:false) {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  max-width: $width;
  padding-left: $gutter-sml;
  padding-right: $gutter-sml;

  @include respond-min($bp-tablet-min) {
    padding-left: $gutter-lrg;
    padding-right: $gutter-lrg;
  }

  @include respond-min($bp-tablet-wide-min) {
    width: 90%;
  }

  @include respond-min($bp-desktop-min) {
    padding-left: 15px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  @include respond-min($bp-desktop-wide-min) {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    max-width: 1270px;
  }

  @if ($include-gutter) {
    max-width: $width + (2 * $gutter-sml);
    padding-left: 0;
    padding-right: 0;

    @include respond-min($bp-tablet-min) {
      max-width: $width + (2 * $gutter-lrg);
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@mixin layout-reset() {
  max-width: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// CLASSES
.layout { @include layout; }
.layout--nogutter { padding-left: 0; padding-right: 0; }
.layout--full { max-width: none; width: 100%; }
.layout--reduced { max-width: $l-width-reduced; }
.layout--tight { max-width: $l-width-tight; }
.layout--full-height { height: 100%; }

.layout--include-gutter {
  @include layout($include-gutter:true);
}
