body.rtl {
  .store-selector-collapsible {
    [data-role="title"] {
      text-align: right;

      &:after {
        float: left;
      }
    }

    li {
      float: right;
    }
  }

  .store-selector-tabs__tab {
    [data-role="collapsible"] {
      padding: 0 0 0 20px;
    }
  }

  .country-flag {
    margin-left: 0.5em;
    margin-right: 0;
  }
}
