body.rtl {
  .leap-cart {
    .g-wrap {
      > div {
        float: right;
      }
    }
  }

  .leap-cart__img {
    @include respond-min($bp-phablet-min) {
      border-right: 0;
      border-left: 1px solid $clr-dy-grey-2;
    }
  }

  .leap-cart__row-header,
  .leap-cart__row-footer {
    margin: 1em 0 1em 1em;
  }
}
