.carousel__slider {
  overflow: hidden;
  -ms-overflow-style: none;
  transform: none;
}

.carousel__slider .slider__control-carousel--prev--Light,
.carousel__slider .slider__control-carousel--prev--Dark {
  left: 4px;
  display: block;
  font-size: 24px;
  line-height: 0;
  padding-bottom: 7px;
  padding-left: 0;

  @include respond-min($bp-tablet-min){
    left: 28px;
  }

  @include respond-min($bp-tablet-wide-min) {
    font-size: 30px;
    left: 16px;
  }
}

.carousel__slider .slider__control-carousel--next--Light,
.carousel__slider .slider__control-carousel--next--Dark {
  right: 4px;
  display: block;
  font-size: 24px;
  line-height: 0;
  padding-bottom: 7px;
  padding-left: 2px;
  padding-right: 0;

  @include respond-min($bp-tablet-min){
    right: 28px;
  }

  @include respond-min($bp-tablet-wide-min) {
    right: 16px;
    font-size: 30px;
  }

}

.carousel__slider  .slick-track {
    position: relative;
    top: 0;
    display: block;
    height: 100%;
}

.carousel__slider  .slider__list {
    margin-left: 0;
    transform: none;
}

.carousel__slider  .slider__list__slide {
  position: relative;
  transform: none;

  &--video {
    position: relative;

    picture {
      @include z-index(content-mid);
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
    }

    .fluid-media {
      @include z-index(content-lower);
    }

    .image__cta {
      @include z-index(content-top);
    }

    button.image__cta--roundal {
      background: none;
      border: none;
    }
  }

  // video is playing
  &--video--active {
    picture {
      @include z-index(content-lower);
    }

    video {
      position: relative;
      @include z-index(content-mid);
    }

    .image__cta {
      display: none;
    }
  }
}

.carousel__slider .slick-dots {
  @include z-index(content-mid);
  left: 50%;
  padding-left: 0;
  display: flex !important;
  align-items: center;
  bottom: 0;
  position: absolute;
  transform: translateX(-50%);
  margin: 28px 0;

  @include respond-min($bp-tablet-min) {
    margin: 36px 0;
  }

  @include respond-min($bp-tablet-wide-min) {
    margin: 44px 0;
  }

  li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin: 0 .375rem;
    padding: 0;
    cursor: pointer;
    opacity: .4;

    button {
      border: 0;
      display: block;
      height: 24px;
      width: 24px;
      line-height: 0;
      font-size: 0;
      padding: .25rem;
      cursor: pointer;
      border-radius: 50%;
      pointer-events: none;
      background: transparent;

      &:before{
        content: "";
        position: absolute;
        border-radius: 50%;
        height: .5rem;
        width: .5rem;
        top: 33%;
        left: 35%;
      }
    }

    &:hover, &:focus {
      opacity: 1;
    }

    &.slick-active {
      height: 16px;
      width: 16px;
      background: transparent;
      border-radius: 50%;

      button {
        background: transparent;
        height: 24px;
        width: 24px;
      }

      &:hover, &:focus {
        opacity: 1;
      }
    }
  }
}

.carousel__slider  .slider__control--carousel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: none;
  border: none;
  color: transparent;

  &:before {
    background: transparent;
    content: '';
    height: 32px;
    width: 32px;
    display:block;
    border-radius: 50%;
    opacity: .7;
    background-image: url(../images/icons/carousel-chevron.svg);
    background-size: 30px 30px;

    @include respond-min($bp-tablet-min) {
      height: 40px;
      width: 40px;
    }

    @include respond-min($bp-tablet-wide-min) {
      height: 48px;
      width: 48px;
    }
  }

  &:after {
    border: none;
    border-radius: 50%;
    padding: 0;
    content: '';
    height: 44px;
    width: 44px;
    vertical-align: middle;
    overflow: hidden;
    opacity: .7;
    display: block;
    top: 0;
    position: absolute;
    left: -6px;

    @include respond-min($bp-tablet-min) {
      height: 48px;
      width: 48px;
      top: 3px;
      left: -4px;
    }

    @include respond-min($bp-tablet-wide-min) {
      height: 56px;
      width: 56px;
      top: 3px;
      left: -4px;
    }
  }
  &.slick-disabled {
    display: none !important; // because slick thinks otherwise
  }
}

.carousel-slider .slider__control--carousel {
  transition: .25s;

  &:hover::before {
    border: 0;
    outline: 0;
    opacity: 1;
  }
}


.carousel-slider[data-arrows-theme="light"] {
  li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: .4;
    transition: .25s;

    button {
      border: 0;
      display: block;
      width: 24px;
      line-height: 0;
      font-size: 0;
      padding: .25rem;
      cursor: pointer;
      border-radius: 50%;
      pointer-events: none;
      background: transparent;

      &:before{
        content: "";
        position: absolute;
        background: $clr-dy-grey-7;
        border-radius: 50%;
        top: 33%;
        left: 35%;
        transition: .25s;
      }
    }

    &:hover, &:focus {
      opacity: 1;
      transition: .25s;
    }

    &.slick-active {
      height: 24px;
      width: 24px;
      background: transparent;
      border-radius: 50%;
      opacity: .7;
      transition: all .25s;

      button {
        background: $clr-dy-grey-7;
        height: 16px;
        width: 16px;
        transition: .25s;
      }

      &:hover, &:focus {
        opacity: 1;
        transition: .25s;
      }
    }
  }

  button {
    svg{
      transform: rotate(270deg);
      fill: $clr-white;
      position: absolute;
      top: 18px;
      height: 10px;
      width: 10px;
      left: 14px;
    }

    &:before {
      background: $clr-dy-grey-7;
      transition: .25s;
    }
  }

  button {
    svg.hero-carousel__icon--next {
      transform: rotate(270deg);
      fill: $clr-white;
      position: absolute;
      top: 14px;
      height: 16px;
      width: 16px;
      left: 11px;

      @include respond-min($bp-tablet-min) {
        left: 15px;
        top: 18px;
      }

      @include respond-min($bp-tablet-wide-min) {
        height: 20px;
        width: 20px;
        left: 17px;
        top: 20px;
      }
    }

    svg.hero-carousel__icon--prev {
      transform: rotate(90deg);
      fill: $clr-white;
      position: absolute;
      top: 14px;
      height: 16px;
      width: 16px;
      left: 7px;

      @include respond-min($bp-tablet-min) {
        left: 11px;
        top: 18px;
      }

      @include respond-min($bp-tablet-wide-min) {
        height: 20px;
        width: 20px;
        left: 12px;
        top: 20px;
      }
    }

    &:before {
      background: $clr-dy-grey-7;
      transition: .25s;
    }
  }
}

.carousel-slider[data-arrows-theme="dark"] {
  li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: .3;
    transition: .25s;

    button {
      border: 0;
      display: block;
      width: 24px;
      line-height: 0;
      font-size: 0;
      padding: .25rem;
      cursor: pointer;
      border-radius: 50%;
      pointer-events: none;
      background: transparent;

      &:before{
        content: "";
        position: absolute;
        background: $clr-white;
        border-radius: 50%;
        top: 33%;
        left: 35%;
        transition: .25s;
      }
    }

    &:hover, &:focus {
      opacity: 1;
      transition: .25s;
    }

    &.slick-active {
      height: 24px;
      width: 24px;
      background: transparent;
      border-radius: 50%;
      opacity: .6;
      transition: .25s;

      button {
        background: $clr-white;
        width: 16px;
        height: 16px;
        transition: .25s;
      }

      &:hover, &:focus {
        opacity: 1;
        transition: .25s;
      }
    }
  }


  button {
    svg.hero-carousel__icon--next {
      transform: rotate(270deg);
      fill: $clr-dy-grey-7;
      position: absolute;
      top: 14px;
      height: 16px;
      width: 16px;
      left: 11px;

      @include respond-min($bp-tablet-min) {
        left: 15px;
        top: 18px;
      }

      @include respond-min($bp-tablet-wide-min) {
        height: 20px;
        width: 20px;
        left: 17px;
        top: 20px;
      }
    }

    svg.hero-carousel__icon--prev {
      transform: rotate(90deg);
      fill: $clr-dy-grey-7;
      position: absolute;
      top: 14px;
      height: 16px;
      width: 16px;
      left: 7px;

      @include respond-min($bp-tablet-min) {
        left: 11px;
        top: 18px;
      }

      @include respond-min($bp-tablet-wide-min) {
        height: 20px;
        width: 20px;
        left: 12px;
        top: 20px;
      }
    }

    &:before {
      background: $clr-white;
      transition: .25s;
    }
  }
}
