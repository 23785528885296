/*
  GLOBAL BREAKPOINTS & MEDIA QUERY HELPERS
*/

// Global breakpoint helpers
$bp-mobile-min:         320px;
$bp-mobile-wide-min:    480px;
$bp-phablet-min:        520px;
$bp-phablet-wide-min:   640px;
$bp-mobile-max:         767px;
$bp-tablet-min:         768px;
$bp-tablet-wide-min:    1024px;
$bp-above-tablet-min:   1025px;
$bp-tablet-wide-max:    1199px;
$bp-desktop-min:        $site-max-width;
$bp-desktop-wide-min:   1440px;
$bp-desktop-super-wide-min: 1920px;
$bp-mobile-max-notify-modal:429px;

// Specific breakpoints, when you need a more granular breakpoint
$bp-checkout-wide: 960px;
$bp-pdp-wide: 1280px;

// Media query helper for min-width
// $width: pass in min width (with units) or $bp- variable
@mixin respond-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

// Media query helper for retina devices
// $width: pass in a min width (with units) to target specific size retina devices and up
// 0 is default for all devices, override as needed for only larger retina devices
@mixin if-retina-min($width:0) {
  @media
    only screen and (min-device-pixel-ratio: 2) and (min-width: $width),
    only screen and (min-resolution: 192dpi) and (min-width: $width),
    only screen and (min-resolution: 2dppx) and (min-width: $width) {
      @content;
  }
}

// Responsive visibility helper classes
$visibility-breakpoints: (g:0, gm:$bp-mobile-min, gmw: $bp-mobile-wide-min, gp: $bp-phablet-min, gpw: $bp-phablet-wide-min, gt:$bp-tablet-min, gtw:$bp-tablet-wide-min, gd:$bp-desktop-min, gdw:$bp-desktop-wide-min);

@each $visibility-code, $visibility-bp in $visibility-breakpoints {
  .#{$visibility-code}-hide {
    display: block;
    @include respond-min($visibility-bp) {
      display: none;
    }
  }
  .#{$visibility-code}-show {
    display: none;
    @include respond-min($visibility-bp) {
      display: block;
    }
  }
  .#{$visibility-code}-show-inline {
    display: none;
    @include respond-min($visibility-bp) {
      display: inline;
    }
  }
  .#{$visibility-code}-show-inline-block {
    display: none;
    @include respond-min($visibility-bp) {
      display: inline;
    }
  }
}
