body.rtl {
  .product__claim-section {
    @include respond-min($bp-desktop-min) {
      float: left;
    }
  }

  .product__claims {
    @include respond-min($bp-tablet-min) {
      margin-right: 0;
      margin-left: 30px;
    }

    ul {
      padding-left: 0;
      padding-right: 20px;
    }
  }

  .product__demo-video {
    left: 100%;
    
    @include respond-min($bp-tablet-min) {
      margin: 50px 0 0 10px;
    }
  }

  .product__claim-section__gallery {
    @include respond-min($bp-tablet-min) {
      margin: 50px 0 0 10px;
    }
  }

  .product__demo-video__play-button {
    left: inherit;
    right: -33px;

    @include respond-min($bp-tablet-min) {
      right: 50%;
      top: 50%;
      margin-top: -20px;
      margin-right: -20px;

      .roundal__play-button {
        top: auto;
        left: auto;

        .roundal__play-button__icon svg.icon.icon--play.icon--dark {
          width: 100%;
          right: 0;
        }
      }
    }
  }
}
