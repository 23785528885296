.footer {
}

.footer--grey-top {
  border-top: 40px solid $clr-dy-grey-6;
}

.footer__lang {
  min-height: 100%;
  margin: 0;
  font-family: $f-fallback;
  @include vspace($v-small,$v-small,$v-large,$dir:bottom);
  @include fs-responsive((g:14px, gt:15px, gtw:$fs-body-larger));

  @include respond-min($bp-desktop-min) {
    border-bottom: 1px solid $clr-dy-grey-7;
  }
}

.footer__lang__country {
  @include link-reset;
  @include fs-responsive((g:14px, gt:18px));
  color: $clr-white;

  &:visited {
    color: $clr-white;
  }

  .icon{
    display: inline-block;
    margin-right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: relative;
    top: 3px;
  }
}

.footer__lang__country__alt {
  display: block;
  padding-top: .5rem;

  span {
    @include fs-responsive((g:14px, gt:16px));
    border-bottom: 1px solid $clr-white;
    line-height: 1.5;
    padding-bottom: 2px;
  }
}

.footer__nav {
  min-height: 100%;
  border-bottom: 1px solid $clr-dy-grey-7;

  @include respond-min($bp-desktop-min) {
    border-bottom: 0;
  }
}

.footer__links {
  @include list-reset;
  min-height: 100%;
  @include vspace($v-small,$v-medium,$v-large,$dir:bottom);
  font-family: $f-futura;
  @include fs-responsive((g:14px, gt:15px, gtw:$fs-body-larger));

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-desktop-min) {
    border-bottom: 1px solid $clr-dy-grey-7;
  }


  li {
    @include list-item-reset;
    margin-top: 1em;
    line-height: 1.1;

    &:first-child { margin-top: 0; }
  }

  a {
    @include link-reset;
  }
}

.footer__links--1 {
  a{
    font-weight: 500;
    color: $clr-white;

    &:hover{
      color: $clr-dy-grey-5;
    }
  }
}

.footer__links--2 {
  a{
    font-weight: 400;
    color: $clr-dy-grey-5;

    &:hover{
      color: $clr-dy-grey-5;
    }
  }
}

.footer__links--yellow {
  color: $clr-dy-yellow-0;
}

.footer__legal {
  max-width: 440px;

  @include respond-min($bp-desktop-min) {
    max-width: none;
  }
}

.footer__copyright {
  margin: 0;
  padding-top: $v-minuscule;
  color: $clr-white;
}

.footer__smallprint {
  margin: 0;
  padding-top: $v-tiny;
}

ol.footer__bullets {
  list-style-type: none;
}
