/*
  ROW HELPERS
*/

.row { }

.row--border-top { border-top: 1px solid $clr-dy-grey-2; }
.row--border-top-dark { border-top: 1px solid $clr-dy-grey-7; }
.row--margin-top { margin-top: 2em; } // margin-helper

.row--full-height { height: 100%; }

.row--massive { @extend .vspace-massive; }
.row--huge { @extend .vspace-huge; }
.row--large { @extend .vspace-large; }
.row--medium { @extend .vspace-medium; }
.row--medium-smaller { @extend .vspace-medium-smaller; }
.row--small { @extend .vspace-small; }
.row--tiny { @extend .vspace-tiny; }
.row--minuscule { @extend .vspace-minuscule; }
.row--minute { @extend .vspace-minute; }
.row--zero { @extend .vspace-zero; }

.row--massive--top { @extend .vspace-massive-top; }
.row--huge--top { @extend .vspace-huge-top; }
.row--large--top { @extend .vspace-large-top; }
.row--medium--top { @extend .vspace-medium-top; }
.row--medium-smaller--top { @extend .vspace-medium-smaller-top; }
.row--small--top { @extend .vspace-small-top; }
.row--tiny--top { @extend .vspace-tiny-top; }
.row--minuscule--top { @extend .vspace-minuscule-top; }
.row--zero--top { @extend .vspace-zero-top; }

.row--massive--bottom { @extend .vspace-massive-bottom; }
.row--huge--bottom { @extend .vspace-huge-bottom; }
.row--large--bottom { @extend .vspace-large-bottom; }
.row--medium--bottom { @extend .vspace-medium-bottom; }
.row--medium-smaller--bottom { @extend .vspace-medium-smaller-bottom; }
.row--small--bottom { @extend .vspace-small-bottom; }
.row--tiny--bottom { @extend .vspace-tiny-bottom; }
.row--minuscule--bottom { @extend .vspace-minuscule-bottom; }
.row--zero--bottom { @extend .vspace-zero-bottom; }

// special caveat row
.row--caveat
{
  @extend .vspace-minuscule;
  padding-bottom: 12px;
}
