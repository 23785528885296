/*
  CUSTOM FONTS
  Font faces, weights, stacks and helpers
*/

// FONT GENERATOR
@mixin font-face-init($label,$font,$weight:normal,$style:normal,$path:"../fonts/") {
  @font-face {
    font-family: $label;
    // src: url('#{$path}#{$font}.eot'); // IE9 Compat Modes
    // src: url('#{$path}#{$font}.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    //      url('#{$path}#{$font}.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
    src: url('#{$path}#{$font}.woff2') format('woff2'),
         url('#{$path}#{$font}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

@font-face{
  font-family:"Futura Latin";
  font-weight: 300;
  src: url("../fonts/Futura-Latin/DysonFutura-Light.woff2") format("woff2"),
  url("../fonts/Futura-Latin/DysonFutura-Light.woff") format("woff"),
  url("../fonts/Futura-Latin/DysonFutura-Light.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Latin";
  font-weight: 400;
  src:url("../fonts/Futura-Latin/DysonFutura-Book.woff2") format("woff2"),
  url("../fonts/Futura-Latin/DysonFutura-Book.woff") format("woff"),
  url("../fonts/Futura-Latin/DysonFutura-Book.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Latin";
  font-weight: 500;
  src:url("../fonts/Futura-Latin/DysonFutura-Medium.woff2") format("woff2"),
  url("../fonts/Futura-Latin/DysonFutura-Medium.woff") format("woff"),
  url("../fonts/Futura-Latin/DysonFutura-Medium.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Latin";
  font-weight: 700;
  src:url("../fonts/Futura-Latin/DysonFutura-Heavy.woff2") format("woff2"),
  url("../fonts/Futura-Latin/DysonFutura-Heavy.woff") format("woff"),
  url("../fonts/Futura-Latin/DysonFutura-Heavy.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Thai";
  font-weight: 300;
  src: url("../fonts/Futura-Thai/DysonThai-Light.woff2") format("woff2"),
  url("../fonts/Futura-Thai/DysonThai-Light.woff") format("woff"),
  url("../fonts/Futura-Thai/DysonThai-Light.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Thai";
  font-weight: 400;
  src:url("../fonts/Futura-Thai/DysonThai-Book.woff2") format("woff2"),
  url("../fonts/Futura-Thai/DysonThai-Book.woff") format("woff"),
  url("../fonts/Futura-Thai/DysonThai-Book.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Thai";
  font-weight: 500;
  src:url("../fonts/Futura-Thai/DysonThai-Medium.woff2") format("woff2"),
  url("../fonts/Futura-Thai/DysonThai-Medium.woff") format("woff"),
  url("../fonts/Futura-Thai/DysonThai-Medium.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Thai";
  font-weight: 700;
  src:url("../fonts/Futura-Thai/DysonThai-Heavy.woff2") format("woff2"),
  url("../fonts/Futura-Thai/DysonThai-Heavy.woff") format("woff"),
  url("../fonts/Futura-Thai/DysonThai-Heavy.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Arabic";
  font-weight: 300;
  src: url("../fonts/Futura-Arabic/DysonArabic-Light.woff2") format("woff2"),
  url("../fonts/Futura-Arabic/DysonArabic-Light.woff") format("woff"),
  url("../fonts/Futura-Arabic/DysonArabic-Light.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Arabic";
  font-weight: 400;
  src:url("../fonts/Futura-Arabic/DysonArabic-Book.woff2") format("woff2"),
  url("../fonts/Futura-Arabic/DysonArabic-Book.woff") format("woff"),
  url("../fonts/Futura-Arabic/DysonArabic-Book.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Arabic";
  font-weight: 500;
  src:url("../fonts/Futura-Arabic/DysonArabic-Medium.woff2") format("woff2"),
  url("../fonts/Futura-Arabic/DysonArabic-Medium.woff") format("woff"),
  url("../fonts/Futura-Arabic/DysonArabic-Medium.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Arabic";
  font-weight: 700;
  src:url("../fonts/Futura-Arabic/DysonArabic-Heavy.woff2") format("woff2"),
  url("../fonts/Futura-Arabic/DysonArabic-Heavy.woff") format("woff"),
  url("../fonts/Futura-Arabic/DysonArabic-Heavy.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hebrew";
  font-weight: 300;
  src: url("../fonts/Futura-Hebrew/DysonHebrew-Light.woff2") format("woff2"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Light.woff") format("woff"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Light.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hebrew";
  font-weight: 400;
  src:url("../fonts/Futura-Hebrew/DysonHebrew-Book.woff2") format("woff2"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Book.woff") format("woff"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Book.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hebrew";
  font-weight: 500;
  src:url("../fonts/Futura-Hebrew/DysonHebrew-Medium.woff2") format("woff2"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Medium.woff") format("woff"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Medium.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hebrew";
  font-weight: 700;
  src:url("../fonts/Futura-Hebrew/DysonHebrew-Heavy.woff2") format("woff2"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Heavy.woff") format("woff"),
  url("../fonts/Futura-Hebrew/DysonHebrew-Heavy.ttf") format("truetype");
}

@font-face{
  font-family:"Noto Sans";
  font-weight: 300;
  src: url("../fonts/Noto-sans/NotoSansKR-Light.otf") format("otf");
}

@font-face{
  font-family:"Noto Sans";
  font-weight: 400;
  src:url("../fonts/Noto-sans/NotoSansKR-Regular.otf") format("otf");
}

@font-face{
  font-family:"Noto Sans";
  font-weight: 500;
  src:url("../fonts/Noto-sans/NotoSansKR-Medium.otf") format("otf");
}

@font-face{
  font-family:"Noto Sans";
  font-weight: 700;
  src:url("../fonts/Noto-sans/NotoSansKR-Bold.otf") format("otf");
}

@font-face{
  font-family:"FoundryGridnik";
  font-weight: 500;
  src:url("../fonts/FoundryGridnik-Medium.woff") format("woff");
}

@font-face{
  font-family:"Futura Hant";
  font-weight: 300;
  src:url("../fonts/Futura-Hant/DysonCHT-Light.woff2") format("woff2"),
  url("../fonts/Futura-Hant/DysonCHT-Light.woff") format("woff"),
  url("../fonts/Futura-Hant/DysonCHT-Light.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hant";
  font-weight: 400;
  src:url("../fonts/Futura-Hant/DysonCHT-Book.woff2") format("woff2"),
  url("../fonts/Futura-Hant/DysonCHT-Book.woff") format("woff"),
  url("../fonts/Futura-Hant/DysonCHT-Book.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hant";
  font-weight: 500;
  src:url("../fonts/Futura-Hant/DysonCHT-Medium.woff2") format("woff2"),
  url("../fonts/Futura-Hant/DysonCHT-Medium.woff") format("woff"),
  url("../fonts/Futura-Hant/DysonCHT-Medium.ttf") format("truetype");
}

@font-face{
  font-family:"Futura Hant";
  font-weight: 700;
  src:url("../fonts/Futura-Hant/DysonCHT-Heavy.woff2") format("woff2"),
  url("../fonts/Futura-Hant/DysonCHT-Heavy.woff") format("woff"),
  url("../fonts/Futura-Hant/DysonCHT-Heavy.ttf") format("truetype");
}

// FONT STACKS
$f-fallback: "Helvetica Neue", Helvetica, Arial, sans-serif;
$f-futura: 'Futura Latin', $f-fallback;
$f-futura-thai: 'Futura Thai', $f-fallback;
$f-futura-arabic: 'Futura Arabic', $f-fallback;
$f-futura-hebrew: 'Futura Hebrew', $f-fallback;
$f-noto-sans: 'Noto Sans', Helvetica, Arial, sans-seif;
$f-foundrygridnik: 'FoundryGridnik', courier, $f-fallback;
$f-futura-hant: 'Futura Hant', $f-fallback;

// FONT WEIGHTS
// $f-light:  300;
// $f-book:   400;
// $f-medium: 500;
// $f-heavy: 700;

