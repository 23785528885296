/*
    The street fields (address 1, address 2) are treated differently on the MX site.
    On the MX site the street fields actual labels for each input is used.
    On other leap sites the labels are hidden and the legend is used as the label.
*/

body:not(.dyson-mx) {
    .checkout .opc-wrapper .field {
        &.required,
        &._required {
            &.street {
                label:after {
                    display: none;
                }

                legend:after {
                    content: '*';
                    // display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

    body:not(.dyson-mx) {
        .field.street {
            .field.additional {
                .label {
                    @extend .abs-visually-hidden;
                }
            }
        }
    }
}

.dyson-mx {
    .checkout .opc-wrapper .street {
        .field {
            margin-top: 0 !important;
        }

        legend.label {
           display: none !important;
        }
    }
}


/*

    Also hidden on the KR site

 */
.dyson-kr {
    .checkout .opc-wrapper .street {
        .field {
            margin-top: 0 !important;
        }

        legend.label {
           display: none !important;
        }
    }


    .totals.shipping .value {
        display:none;
    }

}

.dyson-tr {

        legend.label {
            //margin-bottom: -30px !important;

            &:after {
                content: "" !important;
            }

        }
}
