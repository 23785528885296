$fs-rating-data: (g:55px, gt:65px, gtw:100px);
$fs-rating-value: (g:95px, gt:105px, gtw:155px);

.rating {

}

.rating__title {
  @extend .h4;
  margin: 0;
  @include vspace($v-minute,$v-tiny,$v-tiny,$dir:bottom);
  @include fs-static(28); //was 20

  .rating--small & {
    @include vspace($v-minute,$dir:bottom);
  }
}

.rating__data {
  color: $clr-black;
  @include fs-responsive($fs-rating-data);
  line-height: 1;
  margin-bottom: 5px;

  .rating--small & {
    @include fs-static(32px);
  }
}

.rating__data__value {

  @include fs-responsive($fs-rating-value);

  .rating--small & {
    font-size: inherit;
  }
}

.rating__text {
  margin: 0;
  @include vspace($v-minuscule,$v-small,$v-small,$dir:top);

  .rating--small & {
    @include vspace($v-minute,$dir:top);
  }
}
