// ------- DYSON-PL SPECIFIC -------

.dyson-pl {
  .am-checkout {

    .checkout-block__summary__items__count {
      float:left;
    }

    .checkout-block__summary__items__list {
      padding-top: 20px;
    }

      .payment-method .actions-toolbar .primary .action-update
      {
          padding: 19px 16px;
      }


  // To override Adyen module styles.css
  .payment-method-content .field input,
  .payment-method-content .field select {
      max-width: 500px;
  }

    .fieldset {
      .field {
        &[name="shippingAddress.company"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.dont_activate_guarantee"] {
          margin-top: 1em;

          @include respond-min($bp-tablet-wide-min) {
            margin-top: 3em;
          }

          .form__item {
            padding-top: 20px;
          }
        }

        &[name="shippingAddress.street.0"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }

          label.label {
            display: none;
          }
        }

        &[name="shippingAddress.street.1"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }

          label.label {
            display: none;
          }
        }

          &[name="shippingAddress.street.2"] {
              display: none;
          }

        &[name="shippingAddress.city"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
              width: 48%;
              margin-right: 4%;
            }

          .control {
            width: 100%;
          }

        }

        &[name="shippingAddress.postcode"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }

          label.label {
            &:after {
              margin: 0 0 0 3px;
              font-size: 1em;
            }
          }
        }

        &[name="shippingAddress.country_id"],
        &[name="billingAddresscheckmo.country_id"],
        &[name="billingAddressadyen_hpp.country_id"],
        &[name="billingAddressadyen_cc.country_id"],
        &[name="billingAddressadyen_hpp.country_id"] {
          display: inherit;
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.telephone"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="billingAddresscheckmo.telephone"],
        &[name="billingAddressadyen_hpp.telephone"],
        &[name="billingAddressadyen_cc.telephone"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.vat_id"],
        &[name="billingAddressadyen_hpp.vat_id"],
        &[name="billingAddresscheckmo.vat_id"],
        &[name="billingAddressadyen_cc.vat_id"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.terms_and_conditions_accepted"] {
          .label,
          .form__item--inline {
            display: inline-block;

            a {
              display: inline-block;
              margin-left: 1em;
            }

            @include respond-min($bp-tablet-wide-min) {
              display: flex;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.privacy_policy_accepted"] {
          .label,
          .form__item--inline {
            display: inline-block;

            @include respond-min($bp-tablet-wide-min) {
              display: flex;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.newsletter_signup"] {
          .form__item--inline {
            display: inline-block;

            @include respond-min($bp-tablet-wide-min) {
              display: flex;
            }
          }

          .label {
            display: inline-block;
            height: auto;

            &:last-of-type {
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }

        &[name="billingAddressadyen_hpp.firstname"],
          &[name="billingAddressadyen_cc.firstname"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_hpp.lastname"],
        &[name="billingAddressadyen_cc.lastname"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_hpp.company"],
        &[name="billingAddresscheckmo.company"],
        &[name="billingAddressadyen_cc.company"] {
          width: 100%;

          .control {
            width: 100%;

            @include respond-min($bp-tablet-wide-min) {
              width: 48%;
            }
          }
        }

        &[name="billingAddressadyen_hpp.street.0"],
        &[name="billingAddresscheckmo.street.0"],
        &[name="billingAddressadyen_cc.street.0"] {
          width: 100%;

          label.label {
            display: none;
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_hpp.street.1"],
        &[name="billingAddresscheckmo.street.1"],
        &[name="billingAddressadyen_cc.street.1"] {
          width: 100%;

          label.label {
            display: none;
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_hpp.city"],
        &[name="billingAddresscheckmo.city"],
        &[name="billingAddressadyen_cc.city"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_hpp.postcode"],
        &[name="billingAddresscheckmo.postcode"],
        &[name="billingAddressadyen_cc.postcode"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &.field--checkboxes {
          padding: 1em 0;

          .form__item {
            margin-bottom: 0;

            label.label {
              margin-bottom: 0;
            }

            p.form__label {
              color: $clr-dy-grey-7;
              @include fs-static(14);
            }
          }

          .custom_checkout_field--divider_line_top {
            border-top: 1px solid $clr-divider-line;
          }

          &.field--delivery-to-someone-else {
            padding-top: 0;
            padding-bottom: 3em;
          }
        }

        &.street {
          legend.label {
            display: inherit;
          }
        }
      }
    }

    .payment-method-cc-form {
      .ccard .type .select,
      .ccard .name .input-text {
        width: 100%;
      }

      .field-tooltip.toggle {
        display: none;
      }
    }

      // Remove extra asterisk in tcs checkbox nb content: none removes the arrow and makes the checkbox unclickable
      .checkout-agreements {
          .field.required .label:after,
          .field._required .label:after {
              content: '' !important;
          }
      }

      // Add space under the 'edit' button so Dotpay 'select your bank' is clearer
      div.payment-method-billing-address {
          margin-bottom: 5%;
      }
  }

  // TODO - NOT THIS! MUST BE A BETTER WAY
  .loading-mask[data-role="loader"] {
    display: none!important;
  }

  .loading-mask--show[data-role="loader"] {
    display: inherit !important;
  }

  fieldset.fieldset[data-bind="visible: !isAddressDetailsVisible()"] .actions-toolbar {

    @include respond-min($bp-tablet-wide-min) {
      width: 50% !important;
      float: right;
    }
  }

}
