.download-pdf {
    // background-color: yellow;
}

.download-pdf__container__content {
  border: 1px solid #ebebeb;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  a {
    max-width: 70px;
  }
}

.download-pdf__container__content__icon-link {

}

.download-pdf__container__content__icon {
  max-width: 65px;
  min-height: 85px;

  img {
    width: 89px;
    height: auto;
    position: relative;
    top: 0;
    right: -2px;

    @include respond-min($bp-tablet-min) {
      width: 40px;
      right: 0;
    }
  }
}

.download-size-copy {
  text-align: center;
}
