
.modal-inner-wrap {
  background: black;
  .modal-content {
    padding: 0 20px 20px;

    /* This is trick for fluid iframe width */
    /* https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php */
    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }
    .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
