.card-nav {
  overflow-x: hidden;
}

.card-nav__content {
  @extend .vspace-small-bottom;
}

.card-nav__title {

}

.card-nav__slider {

}
