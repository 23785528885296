/*
    Fix focus ring for buttons on firefox
*/
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px solid #4D90FE;
}

/*
    Turn off focus, this is set via JS
    Js then listens for the tab key, if it's pressed then it removes this class
*/
.no-focus-outline {
    a,
    button {
        &:focus {
            outline: none;
            // max-width: 100%;
        }
    }
}
