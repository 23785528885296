.countdown__row {
    width: 100%;
    background-color: $clr-dy-blue-1;
    color: $clr-white;
}

.countdown__content {
    margin: 0 auto;
    padding: 32px 0;
    display:flex;
    flex-direction: column;
    align-content: center;
    @media screen and (min-width: 768px){
        padding: 40px 0;
    }
    @media screen and (min-width: 1024px){
        padding: 48px 0;
    }
}

.countdown-elapsed {
    display: none;
}

.countdown__counter {
    display:flex;
    justify-content: center;
    margin-top: 16px;
    .rtl & {
        flex-direction: row-reverse;
    }
    @media screen and (min-width: 768px){
        margin-top: 20px;
    }
    @media screen and (min-width: 1024px){
        margin-top: 24px;
    }
}

.countdown__title{
    text-align: center;
    color: white;
    margin:0 10px 0 10px;
    @media screen and (min-width: 768px){
        margin: 0 20% 0 20%;
    }
    @media screen and (min-width: 1024px){
        margin: 0 25% 0 25%;
    }
}

.countdown__body{
    text-align: center;
    color: white;
    margin:10px 10px 0px 10px;
    @media screen and (min-width: 768px){
        margin: 10px 15% 0px 15%;
    }
    @media screen and (min-width: 1024px){
        margin: 10px 20% 0px 20%;
    }

}

.countdown__counter--tile{
    display: flex;
    flex-direction: column;
    align-content: center;
}

.countdown__counter--number {
    border: 1px solid $clr-white;
    padding: 6.5px 16.5px;
    margin: 0 4px;
    text-align: center;
    color:white;
    font-weight: bolder;
    font-size: 1.4rem;
    @media screen and (min-width: 768px){
        padding: 10.5px 24.5px;
        margin: 0 6px;
        font-size: 1.6rem;
    }
    @media screen and (min-width: 1024px){
        padding: 14px 25.5px;
        margin: 0 8px;
        font-size: 1.8rem;
    }
}

.countdown__counter--label {
    text-align: center;
    color: white;
    padding-top: 4px;
    font-weight:500;
    font-size: 0.9rem;
    @media screen and (min-width: 1024px){
        font-size: 1.1rem;
    }
}

.countdown__cta-button.button.rcc__cta-link-button-link{
    background-color:#EBEBEB;
    color: #0a0a0a;
    padding: 20px 30px;
    width: fit-content;
    font-weight: 500;
    font-size:1rem;
    margin: 16px auto 0 auto;
    @media screen and (min-width: 768px){
        margin: 20px auto 0 auto;
    }
    @media screen and (min-width: 1024px){
        margin: 24px auto 0 auto;
    }

}
