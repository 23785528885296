// Copied from _contents.scss and replaced 'contents' with 'parts'

.parts {

  .parts__body {
    @include vspace($v-tiny,$v-tiny,$v-small,$dir:top,$negative:true);
  }

  .parts__main {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .parts__list {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .parts__item {
    border-bottom: 1px solid $clr-dy-grey-3;
    @include vspace($v-tiny,$v-tiny,$v-small,$dir:top);
    @include vspace($v-minuscule,$v-minuscule,$v-tiny,$dir:bottom);
    @include fs-responsive((g:$fs-small, gtw:14px));
    min-height: 100%;
    width: 100%; // fix for flex mayhem

    .parts__main & {
      padding-bottom: $v-tiny;
    }
  }

  .parts__item__url {
    @include link-reverse;
    display: block;
  }

  img {
    position: relative;
    vertical-align: middle;
    text-align: center;
    min-height: 120px; // bigger images will break
    display: flex;
    justify-content: center;
    align-items: center;

    .parts__main & {
      min-height: 0;
      display: block;
    }
  }

  strong {
    display: block;
    padding-top: $v-minuscule;

    .parts__main & {
      padding-top: $v-tiny;
    }

    // safe for 3 lines text max
    // min-height: 70px;
    // @include respond-min($bp-tablet-min) { min-height: 80px; }

    //  &:before {
    //    content: "[o]";
    //    margin-right: 5px;
    //    display: inline-block;
    //  }
  }
}

.parts__genuine {
  background-color: $clr-dy-grey-05;
}