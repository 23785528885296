body.rtl {
  .roundal {
    text-align: right;
  }

  .promos__item {
    .roundal {
      .icon {
        @media screen and (min-width: 1024px) {
          right: -8px;
        }
      }

      .roundal__text {
        @media screen and (min-width: 1024px) {
          display: none;
        }
      }
    }
  }

  .roundal__icon {
    .roundal--large & {
      .icon {
        @include respond-min($bp-tablet-wide-min) {
          left: 0;
          right: 3px;
        }
      }
    }

    .roundal--small & {
      .icon {
        left: 0;
        right: 4px;
      }
    }

    .icon {
      left: 0;
      right: -4px;
    }
  }

  .icon-link {
    .roundal--animated {
      .roundal__icon {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &:hover {
      .roundal--animated {
        .roundal__icon {
          animation-name: roundal-animation-reverse;
        }
      }
    }
  }
}

@keyframes roundal-animation-reverse {
  50% {
    transform: translateX(-150%);
  }
  50.1% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
