.tab {
  background: $clr-dy-grey-1;
  border: 2px solid $clr-white;
  box-shadow: 0 0 0 2px $clr-dy-grey-1;
  margin: 2px 2px 20px;
}

.tab__url {
  display: block;
  @include link-reset;
}

.tab__inner {
  @include clearfix;
  padding: 25px;
}

.tab__image {
  float: right;
  margin-left: 15px;
  max-width: 80px;

  @include respond-min($bp-mobile-wide-min) {
    max-width: 110px;
  }
}

.tab__title {
  overflow: hidden;
}

.slick-current {
  .tab {
    &:hover,
    &.tab--selected {
      border: 2px solid $clr-dy-grey-7;
      box-shadow: 0 0 0 2px $clr-dy-grey-7;
    }

    &.tab--selected {
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 20px;
        border-color: $clr-dy-grey-7 transparent transparent transparent;
      }

      &:after {
        border-color: $clr-dy-grey-1 transparent transparent transparent;
        border-width: 14px 14px 0 14px;
      }
    }
  }
}
