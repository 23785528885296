.catalog-product-view,
.catalog-category-view {
    [data-ui-id="message-success"] {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin: 0;
        /* padding: 0; */
        left: 0;
        border: 0;
        background: $clr-dy-grey-7;
        color: $clr-dy-green-2;
        @include z-index('heaven');
        display: flex;
        align-items: center;

        > div {
            padding-left: 70px;
            @include fs-responsive((g:$fs-body-larger, gt:25px, gtw:30px));

            @include respond-min($bp-tablet-min) {
                padding-left: 100px;
            }
        }

        &:before {
            content: "";
            width: 37px;
            height: 37px;
            border: 2px solid #79b928;
            border-radius: 3.125rem;
            position: absolute;
            top: 16px;
            left: 36px;

            @include respond-min($bp-tablet-min) {
                top: 24px;
                left: 66px;
            }

            @include respond-min($bp-tablet-wide-min) {
                top: 30px;
            }
        }

        &:after {
            content: '';
            display: block;
            width: 7px;
            height: 12px;
            border: solid #79b928;
            border-width: 0 2px 2px 0;
            position: absolute;
            top: 34px;
            left: 52px;
            -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
            -ms-transform: translateY(-50%) rotate(45deg) scale(1);
            transform: translateY(-50%) rotate(45deg) scale(1);

            @include respond-min($bp-tablet-min) {
                left: 82px;
                top: 42px;
            }

            @include respond-min($bp-tablet-wide-min) {
                top: 48px;
            }
        }
    }
    .notify-me__form__error{
      border-color: #ed8380;
    }
}



// #email_address-error {
//   position: relative;
//   top: -4px;
// }

.notify-me__container__headerSub__content {

    div.mage-error,
    div.field-error {
        font-size: 14px;
        color: #dd4157;
        position: relative;
        left: -1px;
        bottom: -4px;

        @include respond-min($bp-desktop-min) {
            position: absolute;
            top: 27%;
            left: 40%;
            height: 30px;

            .dyson-cz & {
                top: 68%;
                left: 41%;
            }
        }
    }

    input.mage-error,
    select.mage-error,
    textarea.mage-error {
        color: $clr-black;
    }

    .button__headerSub--notify-me {
        background-color: #000000;
        height: 20%;
        width: 100%;
        margin-top: 20px;

        @include respond-min($bp-desktop-min) {
            width: 33.33%;
            margin-left: 10px;
            margin-top: 40px;
        }
    }
}

.notify-me__container--addToCart {

    div.mage-error,
    div.field-error {
        font-size: 14px;
        color: #dd4157;
        position: relative;
        top: -6px;
    }

    .dyson-vn & {
        @include respond-min($bp-tablet-wide-min) {
            min-width: 600px;
        }
    }
    body[class*=dyson-] & {
        @include respond-min($bp-tablet-wide-min) {
            min-width: 600px;
        }
    }

}


.notify-me__container {
    padding: 20px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 9;
    border: 1px solid lightgray;
    border-left: none;
    visibility: hidden;

    .hero__content & {
        min-width: 400px;
    }

    &.show {
        visibility: visible;
    }

    div.mage-error, div.field-error {
        top: -4px;
        position: relative;
    }

    @include respond-min($bp-tablet-min) {
        height: 404px;
    }

    input {
        margin-bottom: 20px;

        @include respond-min($bp-tablet-min) {
            margin-bottom: 10px;
        }
    }

    &--addToCart {
        padding: 20px;
        display: block;
        position: absolute;
        bottom: 0;
        background-color: white;
        width: 100%;
        z-index: 9;
        border: 1px solid lightgray;
        visibility: hidden;

        &.show {
            visibility: visible;
        }

        input {
            margin-bottom: 10px;
        }

        .dyson-cz & {
            width: 400px;
            bottom: -50px;
        }
    }

    &--headerSub {
        padding: 20px;
        display: block;
        position: absolute;
        top: 56px;
        background-color: white;
        width: 100%;
        z-index: 9;
        border: 1px solid lightgray;
        left: 0;
        visibility: hidden;

        @include respond-min($bp-tablet-min) {
            top: 64px;
        }

        &.show {
            visibility: visible;
        }

        input {
            margin-bottom: 10px;
            // width: 33.33%;
        }

        .button--notify-me:hover span {

            &:after {
                content: 'Close';
                height: 20px;
                width: 20px;
            }
        }
    }
}

.notify-me__container--headerSub-all {

    @include respond-min($bp-phablet-min) {
        max-width: 70%;
    }

    @include respond-min($bp-desktop-min) {
        max-width: 1100px;
    }
}

.layout--reduced.notify-me__container--headerSub-all {
    margin: 0 auto;
}

.notify-me__container__headerSub__content {

    @include respond-min($bp-desktop-min) {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 50px;
    }

    input, button {
        @include respond-min($bp-tablet-min) {
            margin-top: 40px;
        }
    }

    input {
        @include respond-min($bp-desktop-min) {
            width: 33.33%;
            margin-left: 58px;
        }
    }

    button.button--notify-me {
        @include respond-min($bp-tablet-min) {
            width: 26%;
            height: 50%;
        }
    }
}

a.button--notifyClose {
    &:hover {
        background-color: red;
    }
}

.notify-me__container__headerSub__content__copy {

    @include respond-min($bp-desktop-min) {
        width: 33.33%;
    }

}

.notify-me__container__title {
    font-size: 1.5rem;
    display: inline;
    color: $clr-dy-grey-6;
}

.notify-me__container__copy {
    width: 100%;
    margin: 20px 0;
    font-family: $f-fallback;
    color: $clr-dy-grey-6;

    @include fs-responsive((g:12px, gt:$fs-body-smaller));

    @include respond-min($bp-tablet-min) {
        margin-top: 10px;
    }
}

.notify-me__container__close {
    width: 45%;
    float: right;
    text-align: right;
}

.notify-me__container__copy--email {
    margin: 10px 0;
}


// product_stickynav was overlapping notifyme container at largest breakpoints on ppp
.dyson-th, .dyson-hu, .dyson-ro {
    .notify-me__container--addToCart {
        @include respond-min($bp-desktop-min) {
            width: 200%;
        }
    }
}


.notify-me__container__button {
    background-color: $clr-black;
    color: $clr-white;

    &:hover {
        background-color: $clr-black;
        color: $clr-white;
    }
}

.notify-me__container__button {
  background-color: $clr-black;
  color: $clr-white;

  .slider & {
    height: auto;
  }

  &:hover {
    background-color: $clr-black;
    color: $clr-white;
  }
}
