.catalog-product-view .product__price {
  .product__price-matched {
    br {
      display:block;
    }
  }
}
.card .button.button--tertiary {
  background: #79b928;
}
.site-wide-alert-banner-product-page {
  .product__action {
    .button--tertiary {
      background: #79b928;
      padding: 16px 12px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .hero--product-variant--perfect .hgroup__product--column1 {
    .g-wrap .gtw-col-3-4 {
      width: 75%;
    }
  }
}
@media screen and (min-width: 768px) {
  .site-wide-alert-banner-product-page {
    .product__action {
      button.button {
        padding: 20px 23px;
      }
    }
  }
}