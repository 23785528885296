.hero-product-promotion-section {
    background: #FBFBFB;
    @include respond-max($bp-phablet-wide-min) {
      padding: 12px 0 32px 0;
    }
    @include respond-min($bp-phablet-wide-min) {//min 640
      padding: 10px 0 40px 0;
    }
    @include respond-min($bp-tablet-wide-min) {
      padding: 18px 0 48px 0;//desktop | padding top should be 48 but 18 is given here as 30px margin top is given for hero-item
    }
    @include respond-min($bp-tablet-wide-min) {
      .column-two_column {//only for desktop
          .hero-cards {
              display: inline-block;
              .hero__item {
                width: 48%;
                float: left;
                  &:nth-child(even) {
                      margin-left: 30px;
                  }
              }
          }
        }
        .column-one_column{
              .hero__item {
                .hero__product{
                  .hero-product__image {
                      height: 100%;
                  }
                  .image--stretch {
                      width: 100%;
                      height: 100%;
                  }
                  img.img-reduced {
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                  }
              }
            }
        }
    }
    .hero__item{
      border: 1px solid #EBEBEB;
      background: #fff;
      margin-top: 30px;
      position: relative;//to position notify me box
      @include respond-min($bp-phablet-wide-min) {//min 640
        padding: 40px;
      }
      @include respond-max($bp-phablet-wide-min) {//max 640
        padding: 0;
      }
      @include respond-min($bp-tablet-wide-min) {//1024
        padding: 24px 20px 24px 24px;
      }
      .hero__product{
        .badge span{
            position: absolute;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 500;
            z-index: 1;
            @include respond-min($bp-phablet-wide-min) {//min 640
              margin-top: -55px;
            }
            @include respond-max($bp-phablet-wide-min) {// max 640
              margin-top: -12px;
              margin-left: 15px;
            }
            @include respond-min($bp-tablet-wide-min) {//1024
              margin-top: -38px;
            }
        }
        .product__inner{
          display: flex;
          @include respond-max($bp-phablet-wide-min) {//max 640px
            flex-flow: column;
            padding-top: 16px;
          }
          .product__inner__left {
            @include respond-min($bp-phablet-wide-min) {//min 640px
              width: 60%;
              margin-right: 24px;
            }
            @include respond-max($bp-phablet-wide-min) {//mobile min
              order: 2;
              margin-top: 16px;
            }
          }
          .product__inner__right {
            @include respond-min($bp-phablet-wide-min) {
              width: 40%;
            }
            @include respond-max($bp-phablet-wide-min) {//mobile min
              order: 1;
            }
          }
        }
        img.img-reduced {
          @include respond-max($bp-phablet-wide-min) {//mobile min
              width: 55%;
              margin: 0 auto;
          }
        }
        .product__header {
          @include respond-max($bp-phablet-wide-min) {
            padding: 0 16px;
          }
        }
        .product__description {
            margin-top: 8px;
            ul.product__perks {
              list-style: none;
              padding-inline-start: 0;
              margin: 0;
            }
            @include respond-max($bp-phablet-wide-min) {
              padding: 0 16px;
            }
        }
        .product__stars_summary{
          @include respond-max($bp-phablet-wide-min) {//mobile min
            margin: 16px 0;
            padding: 0 16px;
          }
          @include respond-min($bp-phablet-wide-min) {
            margin: 20px 0;
          }
          @include respond-min($bp-tablet-wide-min) {
              margin: 24px 0;
          }
        }
        .product__pricing{
          @include respond-max($bp-phablet-wide-min) {//max 640px; mobile
            margin-bottom: 16px;
            padding: 0 16px;
          }
          @include respond-min($bp-phablet-wide-min) {//min 640; tablet
            margin-bottom: 20px;
          }
          @include respond-min($bp-tablet-wide-min) { //min 1024px; desktop
            margin-bottom: 24px;
          }

        }
        .price__save {
            color: #0066CC;
        }
        .price__is {
          color: #0066CC;
          font-weight: 500;
          font-size: 32px;
        }
        .price-wrapper {
            margin-left: 0;
        }
        .product__installment-message{
          @include respond-max($bp-phablet-wide-min) {//max 640px; mobile
            margin-bottom: 16px;
            padding: 0 16px;
          }
          @include respond-min($bp-phablet-wide-min) {//min 640; tablet
            margin-bottom: 20px;
          }
          @include respond-min($bp-tablet-wide-min) { //min 1024px; desktop
            margin-bottom: 24px;
          }
          .card__installment{
            padding: 0;
          }
        }
        .hero-product__action, .card__action__items{
            display: flex; // if this does not work use flex
            @include respond-min($bp-phablet-wide-min) {//min 640
              //position: absolute;
              //bottom: 24px; // the value should be same as of box padding
            }
        }
        .card__action__items__item {
            width: auto;
        }
        .product__action__item, .card__action__items__item {
            .button--cart__basket-icon--card, .button--cart__basket-icon--smaller {
                display: none;
            }
            @include respond-max($bp-phablet-wide-min) {//mobile min
              width: 50%;
            }
            &:first-child {
              @include respond-min($bp-phablet-wide-min) {
                margin-right: 16px;
              }
            }
          }
      }
      .notify-me__container {
          height: auto;
          border-left: 0;
          border-right: 0;
          border-bottom: 0;
      }
    }
}
.rtl{
  .hero-product-promotion-section {
      .hero__item .hero__product .product__inner .product__inner__left {
        @include respond-min($bp-phablet-wide-min) {
          margin-right: inherit;
          margin-left: 24px;
        }
      }
      .hero__item {
        @include respond-min($bp-tablet-wide-min) {//1024
          padding: 24px; //overide the one added in ltr
        }
        .product__action__item, .card__action__items__item {
          &:first-child {
            @include respond-min($bp-phablet-wide-min) {
              margin-left: 16px;
              margin-right: 0; //overide the one added in ltr
            }
          }
        }
      }
    }
}
