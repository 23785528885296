// ------- DYSON-TR SPECIFIC -------

.dyson-tr {

  .am-checkout {

    div[data-role="checkout-messages"] .message.message-error.error {
      @include z-index(heaven);
      position: absolute;
      bottom: 60.5%;
      left: 8%;
      width: 84%;
      padding: 15px;
      margin-top: 0;

      @include respond-min($bp-tablet-min) {
        bottom: 56%;
        width: 50%;
        left: 33%;
      }

      @include respond-min($bp-tablet-wide-min) {
        bottom: 52%;
        width: 55%;
        left: 20%;
        padding: 20px;
      }

      @include respond-min($bp-desktop-min) {
        bottom: 58%;
        width: 33%;
        left: 27%;
      }
    }

    .checkout-billing-address .actions-toolbar .primary {
      display:flex;
    }

    .checkout-block__content {
      overflow: auto;
    }

    .checkout-block__tabs__header {
      display: block;
    }

    .checkout-block__content__subheading {
      margin: 0;
      padding-bottom: 10px;
    }

    .checkout-block__tabs__header__tax--business div.field[name="billingAddressadyen_cc.commercial_title"] {
      @include respond-min($bp-desktop-min) {
        margin-left: 0;
      }
    }


    #shipping-new-address-form div.field[name="shippingAddress.street.1"] {
      @include respond-min($bp-desktop-wide-min) {
        margin-top: 0;
      }
    }

    form#co-payment-form fieldset.fieldset div.field:nth-child(7) {
      @include respond-min(1280px) {
      }
    }

    .payment-method-billing-address fieldset.field.street.admin__control-fields.required div.field.additional[name="billingAddressadyen_cc.street.1"] {
      @include respond-min(1280px) {
        margin-top: 0;
        width: 100%;
      }
    }

    .payment-method-billing-address div.field[name="billingAddressadyen_cc.street.0"] label.label {
      margin-bottom: 0.5em;
    }

    .payment-method-billing-address div.field[name="billingAddressadyen_cc.region"] {
      @include respond-min($bp-tablet-min) {
        margin-top: 0;
      }
    }

    .payment-method-billing-address div.field[name="billingAddressadyen_cc.postcode"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
        margin-top: 0;
      }

      @include respond-min(1280px) {
        //margin-right: 0;
      }
    }

    .payment-method-billing-address fieldset.field.street.admin__control-fields.required div.field[name="billingAddressadyen_cc.street.0"] {
      @include respond-min($bp-desktop-min) {
        margin-right: 4%;
        margin-top: 0;
      }
    }

    .billing-address-form legend.label {
      margin-bottom: 20px;

      @include respond-min($bp-tablet-min) {
        margin-bottom: -20px;
      }
    }

    #shipping-new-address-form div.field[name="shippingAddress.country_id"] {
      display: block;
      margin-top: 0;

      @include respond-min($bp-tablet-wide-min) {
        padding-bottom: 2em;
      }
    }

    legend.label {
      margin-bottom: 0;

      @include respond-min($bp-tablet-min) {
        margin-bottom: -20px;
      }
    }

    form#co-payment-form fieldset.fieldset div.switch-field__business {
      left: 0;
    }

    .fieldset {
      .checkout-block__tabs__header__tax {
        display: block;
        width: 100%;

        @include respond-min($bp-tablet-min) {
          display: flex;
        }

        .checkout-block__tabs__header__tax--individual {
          display: flex;
          flex-direction: column;

          @include respond-min($bp-tablet-min) {
            margin-right: 4%;
            width: 48%;
          }

        }

        .checkout-block__tabs__header__tax--business {
          @include respond-min($bp-tablet-min) {
            width: 48%;
          }

          .field[name="billingAddressadyen_cc.tax_id_number"],
          .field[name="billingAddressadyen_cc.tax_office"] {
            width: 100%;
          }

          .field[name="billingAddressadyen_cc.invoice_radio"] {

            @include respond-min($bp-desktop-min) {
              padding-bottom: 3.2em;
            }

            @include respond-min($bp-desktop-wide-min) {
              padding-bottom: 1.3em;
            }
          }
        }
      }


      .field {
        &[name="shippingAddress.postcode"] {
          width: 100%;
          margin-right: 4%;
          margin-top: 0;
          clear: left;

          .control {
            //width: 48%;
          }

          .input-text {
            border-color: $clr-dy-grey-2;
          }

          &._error {
            .input-text {
              border-color: $clr-dy-red-1;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.firstname"] {
          label.label {
            &:after {
              content: '';
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.lastname"] {

          label.label {
            &:after {
              content: '';
            }
          }
        }

        &[name="shippingAddress.street.0"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.1"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }


        &[name="shippingAddress.street.2"] {

          @include respond-min($bp-tablet-wide-min) {
            width: 100%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.city"] {
          margin-top: 0;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-left: 4%;
            margin-right: 0;
          }
        }

        &[name="shippingAddress.region"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="shippingAddress.telephone"] {

          label.label {
            &:after {
              content: '*';
              display: inline-block;
              margin: 0 0 0 3px;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            clear:left;
            float:left;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.region_id"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100%;
            display: block;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.country_id"] {
          margin-top: 0;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            display: block;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.activate_guarantee"] {
          @include respond-min($bp-tablet-wide-min) {
            padding: 0 0 1em 0;
          }

          span {
            @include fs-responsive((g:12px, gt:14px, gtw:16px));
          }
        }

        &[name="shippingAddress.custom_attributes.opt_in"] {
          padding: 1em 0 17em 0;

          @include respond-min(375px) {
            padding-bottom: 10.5em;
          }

          @include respond-min(425px) {
            padding-bottom: 7.5em;
          }

          @include respond-min($bp-phablet-min) {
            padding-bottom: 3.5em;
          }

          @include respond-min($bp-tablet-min) {
            padding-bottom: 2.5em;
          }

          label.label {
            display: inline-block;
          }

          span {
            @include fs-responsive((g:12px, gt:14px, gtw:16px));
          }
          a {
            text-decoration: underline;
          }
        }

        // TR TAX NUMBER FIELDS //
        .checkout-block__tabs__header__tax--business {

          &[name="billingAddressadyen_cc.citizen_id"] {
            @include respond-min($bp-tablet-wide-min) {

            }
          }

          &[name="billingAddressadyen_cc.commercial_title"] {
            width: 100%;

            label.label {
              @include respond-min($bp-tablet-min) {
                margin-bottom: 1.5em;
              }

              @include respond-min(942px) {
                margin-bottom: .5em;
              }

              @include respond-min($bp-tablet-wide-min) {
                margin-bottom: 1.65em;
                margin-left: 0;
              }

              @include respond-min(1304px) {
                margin-bottom: .5em;
              }
            }
          }
        }

        &[name="billingAddressadyen_cc.tax_id_number"] {

          form#co-payment-form & {
            width: 100% !important;
          }
        }

        &[name="billingAddressadyen_cc.tax_office"] {
          @include respond-min($bp-desktop-min) {
            width: 100%;
          }
        }


        //  ADYEN RELATED - BILLING ADDRESS STYLES //

        &[name="billingAddressadyen_cc.prefix"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.lastname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.company"] {
          display: none;
        }

        &[name="billingAddressadyen_cc.street.0"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100%;
            margin-top: 0;
          }
        }

        &[name="billingAddressadyen_cc.street.1"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100%;
          }
        }

        &[name="billingAddressadyen_cc.street.2"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100% !important;
          }
        }

        &[name="billingAddressadyen_cc.city"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.postcode"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.country_id"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.telephone"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            clear: left;
            float: left;
          }
        }

        &.street {
          legend.label {
            display: inherit;
            padding-bottom: 3%;
          }
        }
      }
    }

    .adyen-checkout__card__exp-cvc {
      display: flex;
    }

    .control.complete:after {
      height: 36px;
      width: 36px;
      top: 15px;
      right: 15px;
    }
  }
}
