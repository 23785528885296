// ------- DYSON-NZ SPECIFIC -------

.dyson-nz {
  .form__item--inline {
    display: block;
  }

  .checkout-block__progress-button {
    padding-top: 0;
  }

  .field.field--checkboxes {
    label.label small{
      @include respond-min($bp-tablet-min) {
        font-size: 14px;
      }
    }
  }

  .form__item.form__item--inline.form__item--checkbox {
    margin: 2em 0;

    @include respond-min($bp-tablet-min) {
      margin: 1em 0;
    }

    label.label {
      font-size: 12px;

      @include respond-min($bp-tablet-min) {
        font-size: 14px;
      }

    }
  }

  .am-checkout {

    .fieldset {
      .field {
        &[name="shippingAddress.postcode"] {
          width: 100%;
          margin-right: 4%;


          label.label {
            &:after {
              content: '*';
              display: inline-block;
              margin: 0 0 0 3px;
            }
          }

          .input-text {
            border-color: $clr-dy-grey-2;
          }

          &._error {
            .input-text {
              border-color: $clr-dy-red-1;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &.field--checkboxes {
          padding: 0.5em 0;
        }

        &[name="shippingAddress.prefix"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            margin-right: 0;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.0"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.1"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.street.2"] {
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48.1%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.city"] {
          margin-right: 0;
          @include respond-min($bp-tablet-wide-min) {
            width: 47.9%;
          }
        }

        &[name="shippingAddress.region"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 100%;
          }
        }

        &[name="shippingAddress.telephone"] {

          label.label {
            &:after {
              content: '*';
              display: inline-block;
              margin: 0 0 0 3px;
            }
          }

          @include respond-min($bp-tablet-wide-min) {
            width: 48.1%;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.region_id"] {
          display: block;
          width: 100%;

          .control {
            width: 100%;
          }

        @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-left: 4%;
        }

        }

        &[name="shippingAddress.country_id"] {
          display: block;
          width: 100%;

          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            display: block;

            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.custom_attributes.dont_activate_guarantee"] {
          @include respond-min($bp-tablet-wide-min) {
            padding-bottom: 0;
          }
        }

        &[name="shippingAddress.custom_attributes.opt_in"] {
          label.label {
            display: inline-block;
          }
          a {
            text-decoration: underline;
          }
        }


        //  ADYEN RELATED - BILLING ADDRESS STYLES //

        &[name="billingAddressadyen_cc.prefix"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.firstname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.lastname"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.company"] {
          display: none;
        }

        &[name="billingAddressadyen_cc.street.0"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

        &[name="billingAddressadyen_cc.street.1"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.street.2"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.city"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.postcode"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
            margin-right: 4%;
          }
        }

      &[name="billingAddressadyen_cc.region_id"] {
          @include respond-min($bp-tablet-wide-min) {
              width: 48%;
              margin-left: 4%;
          }
      }

        &[name="billingAddressadyen_cc.country_id"] {
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }

        &[name="billingAddressadyen_cc.telephone"] {
            clear: both;
          @include respond-min($bp-tablet-wide-min) {
            width: 48%;
          }
        }
      }
    }

    .control.complete:after {
      height: 36px;
      width: 36px;
      top: 15px;
      right: 15px;
    }
  }

  .payment-method-content .field input, .payment-method-content .field select {
      max-width: 100% !important;
  }

    .checkout-agreement.field.choice.required label.label:after {
        content: '';
        margin: 0 0 0 0;
    }
}
