//dyson-form
.dyson-form.prospect-data-form {
    margin: 48px 0;
    @include respond-max($bp-tablet-wide-min) { //tablet
      margin: 40px 0;
    }
    @include respond-max($bp-phablet-wide-min) { //phone
      margin: 32px 0;
    }
}
.dyson-form {
  input[type="text"], input[type="email"], input[type="search"], input[type="tel"],
  input[type="url"], input[type="number"], input[type="password"], select[multiple], textarea, select {
    border: 1px solid #919191;
    color: #333333;
    &.mage-error {
      border-color: #ed8380;
      color: inherit;
    }
  }
  select{
    background: url(../images/icons/sprite.png);
    background-repeat: no-repeat;
    background-position: 0 -1942px;
    background-position-x: right;
    background-repeat: no-repeat;
  }
  input[type=checkbox] {
    opacity: 0;
  }
  input[type=checkbox]:focus + label:before, select:focus {
      border: 1px solid #333;
  }
  .body-small {
    font-size: 14px;
    color: #333;
    line-height:20px;
    font-family: $f-futura;
    &.lighter {
      color: #555;
    }
    &.medium {
      font-weight: 500;
    }
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    &.fieldset:not(:last-child) {
      border-bottom: 1px solid #EBEBEB;
    }
  }
  .fieldset:before {
    margin: 0 0 32px;
    content: attr(data-hasrequired);
    display: block;
    letter-spacing: normal;
    line-height:20px;
    color: #333;
    font-size: 14px;
    font-family: $f-futura;
    @include respond-max($bp-tablet-wide-min) { //till 1024
      margin: 0 0 28px;
    }
  }
  .field {
    margin-bottom: 32px;
    @include respond-max($bp-tablet-wide-min) { //tablet
        margin-bottom: 28px;
    }
    &.mb-24 {
      margin-bottom: 24px;
    }
    .label {
      margin-bottom: 8px;
      display: block;
      span{
        line-height:24px;
        color: #333;
        font-size: 16px;
        font-family: $f-futura;
        font-weight: 500;
        @include respond-max($bp-tablet-wide-min) { //tablet
          font-size: 14px;
        }
      }
    }
    &.prospect_consent .label {
      margin-bottom: 0px;
    }
    &.postcode .control input{
      width: 165px; //for desktop
      display: block;
    }
    &.interest_category .label {
      margin-bottom: 16px;
    }
    &.body-small label.label {
      font-size: 14px;
    }
  }
  .field.required:not(.single-choice)>.label span:after,
  .field.required:not(.single-choice)>.label div:after {
    content: '*';
    color: #333;
    margin: 0 0 0 2px;
  }
  .field.choice {
    input[type="checkbox"] {
      font-family: $f-fallback;
      display: flex;
      margin-right: 0.5rem;
      position: relative;
      z-index: 1;
      left: 9px;
      margin: 0;
      align-items: center;
      padding: 0;
      opacity: 0;
      top: 23px;

      &:last-child{
        margin-right: 0;
      }

      &:checked + label {
        &:after{
          opacity: 1;
        }
      }
      &.mage-error ~ label:before { //mark all checkboxes red if error appears (~ is all)
        border: 1px solid #dd4157;
      }
    }
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 32px;
      margin-right: 1rem;
      padding-left: 38px;
      position: relative;
      font-weight: normal;
      color: $clr-dy-grey-7;

      &:before,
      &:after {
        content: '';
        height: 32px;
        width: 32px;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .15s ease-in;
        box-sizing: border-box;
      }

      &:before{
        border: 1px solid #919191;
        background-color: $clr-white;
      }

      &:after{
        background-image: url('../images/icons/sprite.png');
        background-repeat: no-repeat;
        background-position: 0 -1856px;
        opacity: 0;
      }
    }
  }
  .prospect_consent label.label {
    height: auto;
    min-height: 32px;
  }
  .terms {
    color: #555555;
    margin-bottom: 32px;
    @include respond-max($bp-tablet-wide-min) { //tablet
        margin-bottom: 28px;
    }
    &>div>div {
      padding: 0px !important;
    }
    a {
      text-decoration: underline;
    }
  }
  button.action.submit.primary {
    width: 100%;
    background: #333;
    height: 64px;
    color: #fff;
    border: 0;
    font-weight: 500;
  }
  div.mage-error, div.field-error {
    margin-top: 8px;
  }
  div.mage-error:before, div.field-error:before {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}
.prospect-data-form form {
    width: 45%; //desktop
    @include respond-max($bp-tablet-wide-min) { //tablet
      width: 55%;
    }
    @include respond-max($bp-phablet-wide-min) { //phone
      width: 100%;
    }
}

//RTL support
.rtl{
  .dyson-form {
    .field.choice {
      input[type="checkbox"] {
        left: auto;
        right: 0;
        &:last-child{
          margin-right: 0;
        }
      }
      label {
        padding-right: 38px;
        padding-left: 0;
        margin-right: 0;
        &:before,
        &:after {
          left: auto;
          right: 0;
        }
      }
    }
    div.mage-error {
      font-family: "Futura Hebrew", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}
