body.rtl {
  .full-page-modal {
    .action-close {
      right: inherit;
      left: -7px;

      @include respond-min(768px) {
        left: -9px;
      }
    }
  }
}
