.site-wide-alert-banner, .site-wide-alert-banner-product-page{
  .alert-banner {
    @include respond-min($bp-phablet-wide-min) {//min 640
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include respond-min($bp-tablet-wide-min) {//1024
      padding: 10px;
    }
  }
  .content.layout {
    position: relative;
  }
  .sitewide-alert-read-more {
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    font-family: $f-futura;
    margin-top: -3px;
  }
  button {
    background: none;
    border: 0;
    padding: 0;
  }
  .sitewide-alert-content {
      margin-right: 32px; //leave 16px for image + 16px
      .text{
        p, h1, h2, h3, h4, h5, h6 {
          display: inline;
          margin:0;
        }
        display: inline;
        margin:0;
      }
  }
  .sitewide-alert-banner__text{
    p {
      margin: 0;
    }
    .sitewide-alert-content__text, .sitewide-alert-more-content__text {
      p, h1, h2, h3, h4, h5, h6, span, strong{
        font-size: 14px;
        line-height: 20px;
        font-family: $f-futura;
      }
      font-size: 14px;
      line-height: 20px;
      font-family: $f-futura;
      .button-text {
        p, h1, h2, h3, h4, h5, h6, span, strong{
          font-family: $f-futura;
          @include respond-min($bp-phablet-wide-min) {//min 640
            font-size: 16px;
            line-height: 26px;
          }
          @include respond-max($bp-phablet-wide-min) {//max 640
            font-size: 16px;
            line-height: 26px;
          }
          @include respond-min($bp-tablet-wide-min) {//1024
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .sitewide-alert-banner__icon {
      width: 28px;
      height: 28px;
      margin-right: 8px;
  }
  .cta{
    .sitewide-alert-cta-title {
      display: flex;
      margin-top: 8px;
      .button-text {
        @include respond-min($bp-phablet-wide-min) {//min 640
          font-size: 16px;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640
          font-size: 16px;
        }
        @include respond-min($bp-tablet-wide-min) {//1024
          font-size: 18px;
        }
        align-self: center;
        font-weight: 500;
        font-family: $f-futura;
      }
    }
  }

  .actions.close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 30px;
    top: -5px;
    transition-timing-function: ease-in;
    transition: 0.2s;
    z-index: 1;
    @include respond-min($bp-tablet-min) {
      right: 35px;
      top: 0;
    }
    @include respond-min($bp-tablet-wide-min) {
      right: 12px;
      top: -4px;
    }
  }
}

.rtl .actions.close {
  width: 16px;
  height: 16px;
  right: auto;
  left: 10px;
  top: 15px;
  margin-right: 5px;

  @include respond-min($bp-tablet-min){
    margin-left: 0;
    margin-right: 40px;
    left: 15px;

  }
  @include respond-min($bp-tablet-wide-min) {
    margin-left:0;
    margin-right: 48px;
    left: 19px;
    top: 15px;
  }
}

.sitewide-alert-banner__close svg {
  width: 16px;
  height: 16px;
  top: 8px;
}

.rtl .sitewide-alert-banner__close svg {
  width: 16px;
  height: 16px;
  top: 8px;

  @include respond-min($bp-tablet-min) {
    width: 20px;
    height: 20px;
  }
  @include respond-min($bp-tablet-wide-min) {
    width: 24px;
    height: 24px;
  }
}
