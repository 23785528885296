// ------- DYSON-KR SPECIFIC -------

.dyson-kr {

  // SPC styling
  .am-checkout {

    //checkboxes
    .fieldset .field.field--checkboxes {
      padding: 4.5em 0;
      margin: 0;
      @include respond-min($bp-mobile-wide-min) {
        padding: 2.5em 0;
      }
      @include respond-min($bp-tablet-min) {
        padding: 1em 0;
      }
    }

    
    .checkout-payment {
      margin-top: 0;
      margin-bottom: 0;
      visibility: hidden;
      padding: 0;
      height: 0;
      border-top: 1px solid #ebebeb;
    }

    .label--auto-reg {
      margin-top: 4em;

      @include respond-min($bp-mobile-wide-min) {
        margin-top: 2em;
      }

      @include respond-min($bp-tablet-min) {
        margin-top: 1em;
      }
    }

    #shipping-new-address-form .label--black {
        color: $clr-dy-grey-7;
        padding-left: 0;
    }

    .control.complete:after {
      height: 36px;
      width: 36px;
      top: 15px;
      right: 15px;
    }

    #shipping-new-address-form .no-checkbox:before {
      content: "";
      width: 0;
      height: 0;
      border: 0;
      background-color: none;
    }
    #shipping-new-address-form .no-checkbox:after {
      content: "";
      width: 0;
      height: 0;
    }
  }
}

// NEW KCP PLUGIN STYLING
.dyson-kr {

  //2-step & SPC styling
  .checkout-container {

    //email address
    #customer-email-fieldset .field {
      width: 100%;
      float: none;

      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
    }

    .fieldset .field {

      //checkboxes
      &.field--checkboxes {
        .label, .no-checkbox {
          @include fs-responsive((g:12px, gt:14px, gtw:16px));
          line-height: 1.4;
        }
      }

      //shipping address
      &[name="shippingAddress.addressLookupInput"] {
        width: 100%;
        float: none;

        @include respond-min($bp-tablet-min) {
          width: 48%;
        }
      }

      &[name="shippingAddress.firstname"] {
        width: 100%;

        @include respond-min($bp-tablet-min) {
          width: 48%;
          margin-right: 0;
        }
      }

      &[name="shippingAddress.lastname"],
      &[name="shippingAddress.postcode"],
      &[name="shippingAddress.city"] {
        width: 100%;

        @include respond-min($bp-tablet-min) {
          width: 48%;
          margin-right: 4%;
        }
      }

      &[name="shippingAddress.street.0"],
      &[name="shippingAddress.street.1"],
      &[name="shippingAddress.telephone"] {
        @include respond-min($bp-tablet-min) {
          .control {
            width: 100%;
          }
        }
      }

      &[name="shippingAddress.country_id"] {
        width: 100%;

        @include respond-min($bp-tablet-min) {
          width: 48%;
        }
      }


      //billing address
      &[name="billingAddresskcp_gateway.region"],
      &[name="billingAddresskcp_gateway.lastname"] {
        position: unset;
        width: 100%;

        @include respond-min($bp-tablet-min) {
          width: 48%;
          margin-right: 4%;
        }
      }

      &[name="billingAddresskcp_gateway.firstname"],
      &[name="billingAddresskcp_gateway.city"],
      &[name="billingAddresskcp_gateway.postcode"],
      &[name="billingAddresskcp_gateway.street.1"] {
        width: 100%;

        @include respond-min($bp-tablet-min) {
          width: 48%;
        }
      }

      &[name="billingAddresskcp_gateway.street.0"],
      &[name="billingAddress.billingAddressLookupButtonReset"],
      &[name="billingAddress.addressLookupInput"] {
        width: 100%;

        @include respond-min($bp-tablet-min) {
          width: 48%;
          margin-right: 4%;
        }
      }
    }
  }
}
