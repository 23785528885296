body.rtl {
  .contact-us {
      .icon {
          float: right;
          margin-left: 30px;
          margin-right: auto;
      }
   }

   .contact-us__email {
       .contact-us__inner {
           @include respond-min(890px) {
               border-right: 1px solid #dadada;
               border-left: 0;
               padding-right: 64px;
               padding-left: 0;
           }
       }
   }
}
