#pincode_sign_up {
  button.input--submit.button.button--wider {
    background-color: $clr-dy-green-0;
  }
}


.pincode-success {
  background: $clr-dy-green-0 !important;
}


.control.complete {

  position:relative;

  &:after {
    content: "";
    display: block;
    width: 45px;
    height: 45px;
    background: url("https://dyson-h.assetsadobe2.com/is/image/content/dam/dyson/india/in-home-demo/booking-form/BookingForm-SectionCompleted.png") no-repeat center;
    position: absolute;
    top: 10px;
    right: 7px;
    background-size: cover;
  }

}