// SEARCH PANEL

.search-panel {
  @include z-index(modal-top);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $clr-black;
  height: 0;
  overflow: hidden;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  transition: height 0.2s ease-in-out;

  &.open {
    height: 100vh;
    overflow: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .search-panel__inner{
      opacity: 1;
    }
  }

  .search-panel__input {
    font-family: $f-futura;
    @include fs-static(28);
    font-weight: 300;
    background: transparent;
    border: 0;
    height: auto;
    padding: 0;
    color: $clr-white;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    &::placeholder {
      color: $clr-dy-grey-7;
    }

    @include respond-min($bp-tablet-min) {
      @include fs-static(40);
    }

    @include respond-min($bp-tablet-wide-min) {
      @include fs-static(64);
    }

    &:hover, &:focus, &:active {
      box-shadow: none;
    }

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      display: block;
      background-color: $clr-white;
    }
  }
}

.search-panel__inner {
  opacity: 0;
  position: relative;
  padding-top: 68px;
  transition: opacity 0.4s ease-in-out;

  @include respond-min($bp-tablet-min) {
    padding-top: 114px;
  }

  @include respond-min($bp-tablet-wide-min) {
    padding-top: 234px;
  }
}

.search-panel__form {
  width: 100%;
  position: relative;
  padding-right: 60px;

  @include respond-min($bp-tablet-min) {
    top: 0;
  }

  .icon--clear{
    fill: $clr-dy-grey-6;
    height: 1.2rem;
    width: 1.2rem;

    @include respond-min($bp-tablet-wide-min) {
      height: 2rem;
      width: 2rem;
    }
  }

  .icon--search {
    fill: $clr-white;
    height: 2.25rem;
    width: 2.25rem;

    @include respond-min($bp-tablet-min) {
      height: 3rem;
      width: 3rem;
    }

    @include respond-min($bp-tablet-wide-min) {
      height: 4rem;
      width: 4rem;
    }
  }
}

.search-panel__underline {
  display: block;
  border-bottom: 1px solid $clr-white;
  padding-bottom: 0.5rem;
  position: relative;
  width: 100%;

  @include respond-min($bp-tablet-wide-min) {
    width: 80%;
  }
}

.search-panel__submit {
  position: absolute;
  top: 0.25rem;
  right: -60px;
  background: transparent;
  border: 0;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;

  &.disabled {
    .icon--search {
      fill: #333;
    }
  }

  @include respond-min($bp-tablet-min) {
    height: 3rem;
    width: 3rem;
    top: 0.5rem;
  }

  @include respond-min($bp-tablet-wide-min) {
    height: 4rem;
    width: 4rem;
    right: -4.5rem;
    top: 1rem;
  }

  &:hover, &:focus, &:active {
    border: 0;
    background: transparent;
  }
}

/*
  1. Reset button styles
  todo: Stop setting styles on <button>
*/
.search-panel__clear {
  position: absolute;
  right: 0;
  top: 0.75rem;
  display: none;

  border: none; /* 1 */
  background: none; /* 1 */
  padding: 0; /* 1 */

  @include respond-min($bp-tablet-min) {
    right: 1rem;
    top: 1.25rem;
  }

  @include respond-min($bp-tablet-wide-min) {
    right: 1.5rem;
    top: 2rem;
  }

  [data-input-state="input"] & {
    display: block;
  }

  &:hover,
  &:focus {
    background: none; /* 1 */
    border: none; /* 1 */
  }
}

/*
  1. Reset button styles
  todo: Stop setting styles on <button>
*/
.search-panel__close {
  position: absolute;
  left: 20px;
  top: 1rem;
  height: 2.5rem;
  width: 2.5rem;

  background: none; /* 1 */
  border: none; /* 1 */
  padding: 0; /* 1 */

  &:hover,
  &:focus {
    background: none; /* 1 */
    border: none; /* 1 */
  }

  .icon--close {
    position: relative;
    fill: $clr-white;
    height: 1rem;
    width: 1rem;

    @include respond-min($bp-tablet-wide-min) {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  @include respond-min($bp-tablet-min) {
    left: 40px;
  }

  @include respond-min($bp-tablet-wide-min) {
    right: 60px;
    top: 4rem;
    left: inherit;
  }
}

.search-panel__popular-search {

  &.layout {
    flex-direction: column;
    align-items: start;
    margin-top: 1.5rem;
  }

  * {
    margin: 0 0 .5rem 0;
    font-size: $fs-body;

    @include respond-min($bp-tablet-wide-min) {
      font-size: $fs-body-larger;
    }

  }

  &__title {
    color: $clr-dy-grey-5;
    font-weight: 500;
    line-height: 1.5;
    width: 100%;
  }

  &__terms {
    list-style: none;
    padding: 0;
    line-height: 2;
    width: 100%;

    li {
      margin-bottom: 1rem;
    }

    a {
      color: $clr-white;
    }

  }
}
