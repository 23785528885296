// ------- TWO PAGE CHECKOUT: DYSON-HU SPECIFIC -------

.dyson-hu {

  // SHIPPING ADDRESS
  // Styling tweaks for HU 2PC shipping address fields in 2 columns
  .checkout__row .opc-wrapper .opc #shipping-new-address-form {
    // Hide unneeded Street Address label
    .field.street {
      legend {
        display: none;
      }

      @include respond-min($bp-tablet-min) {
        width: 48%;
        // Sorts out 'Address Line 1'/'Cím 1'
        div[name="shippingAddress.street.0"] {
            margin-right: 4%;
            width: 100%;
        }
      }
    }

    div.field[name="shippingAddress.telephone"],
    div.field[name="shippingAddress.postcode"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
    }

    div.field[name="shippingAddress.city"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;

      }
    }

    div.field[name="shippingAddress.company"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin-right: 4%;
      }
    }

    //div.field[name="shippingAddress.fax"] {
    //  @include respond-min($bp-tablet-min) {
    //    width: 48%;
    //    margin-right: 4%;
    //  }
    //}

    div.field[name="shippingAddress.custom_attributes.tax_number"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 0;
        width: 48%;
      }
    }
  }

  // BILLING ADDRESS
  .payment-method-billing-address .billing-address-form {
    // Hide unneeded Street Address label
    fieldset.field.street {
      legend {
        display: none;
      }

      @include respond-min($bp-tablet-min) {
        width: 48%;
        div[name="billingAddressbigfishpaymentgateway_pmgw_paypal.street.0"],
        div[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.street.0"],
        div[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.street.0"] {
            width: 100%;
            margin-right: 4%;
        }
      }
    }
  }

  // HU 2PC billing address fields in 2 columns
  .checkout__row .opc-wrapper .opc .fieldset {
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.telephone"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.telephone"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_paypal.telephone"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
    }

    .field[name="billingAddressbigfishpaymentgateway_pmgw_paypal.city"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        //margin-top: 1%;
      }
    }

    .field[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.city"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.city"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin-right: 4%;
        //margin-top: 1%;
      }
    }

    .field[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.company"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.company"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_paypal.company"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
      }
    }

    .field[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.city"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.city"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.custom_attributes.tax_number"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.custom_attributes.tax_number"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_paypal.custom_attributes.tax_number"],
    .field[name="billingAddress.custom_attributes.tax_number"]{
      @include respond-min($bp-tablet-min) {
        margin-right: 0;
      }
    }

    //removes duplication of margin bottom on this field
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otparuhitel.street.0"],
    .field[name="billingAddressbigfishpaymentgateway_pmgw_otpsimple.street.0"]{
      margin-bottom: 0;
    }
  }

  // Billing address buttons
  .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .payment-method .actions-toolbar {
    margin-bottom: 2%;
  }
}