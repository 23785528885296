/*
  COLOUR HELPERS
  Useful for theming/forcing colouring on a module
*/

.clr-white-fill { background-color: $clr-white; }
.clr-black-fill { background-color: $clr-black; }
.clr-dy-grey-05-fill { background-color: $clr-dy-grey-05; }
.clr-dy-grey-07-fill { background-color: $clr-dy-grey-07; }
.clr-dy-grey-0-fill { background-color: $clr-dy-grey-0; }
.clr-dy-grey-1-fill { background-color: $clr-dy-grey-1; }
.clr-dy-grey-2-fill { background-color: $clr-dy-grey-2; }
.clr-dy-grey-3-fill { background-color: $clr-dy-grey-3; }
.clr-dy-grey-4-fill { background-color: $clr-dy-grey-4; }
.clr-dy-grey-5-fill { background-color: $clr-dy-grey-5; }
.clr-dy-grey-6-fill { background-color: $clr-dy-grey-6; }
.clr-dy-grey-7-fill { background-color: $clr-dy-grey-7; }
.clr-dy-green-0-fill { background-color: $clr-dy-green-0; }
.clr-dy-green-1-fill { background-color: $clr-dy-green-1; }
.clr-dy-blue-0-fill { background-color: $clr-dy-blue-0; }
.clr-dy-blue-1-fill { background-color: $clr-dy-blue-1; }
.clr-dy-yellow-0-fill { background-color: $clr-dy-yellow-0; }

// clr-cons text color helpers, just in case you force a color on text
.clr-white-text { color: $clr-white; }
.clr-black-text { color: $clr-black; }
.clr-dy-grey-0-text { color: $clr-dy-grey-0; }
.clr-dy-grey-1-text { color: $clr-dy-grey-1; }
.clr-dy-grey-2-text { color: $clr-dy-grey-2; }
.clr-dy-grey-3-text { color: $clr-dy-grey-3; }
.clr-dy-grey-4-text { color: $clr-dy-grey-4; }
.clr-dy-grey-5-text { color: $clr-dy-grey-5; }
.clr-dy-grey-6-text { color: $clr-dy-grey-6; }
.clr-dy-grey-7-text { color: $clr-dy-grey-7; }
.clr-dy-green-0-text { color: $clr-dy-green-0; }
.clr-dy-green-1-text { color: $clr-dy-green-1; }
.clr-dy-blue-0-text { color: $clr-dy-blue-0; }
.clr-dy-blue-1-text { color: $clr-dy-blue-1; }
.clr-dy-yellow-0-text { color: $clr-dy-yellow-0; }
.clr-dy-red-0-text { color: $clr-dy-red-1; }
