/* Dyson Order Module */
.dyson-order {
    color: $clr-dy-grey-7;
    margin-top: 2em;
    margin-bottom: 2em;

    @include respond-min($bp-tablet-min) {
        margin-top: 2.5em;
        margin-bottom: 2.5em;
    }

    @include respond-min($bp-tablet-wide-min) {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

.dyson-order__heading-size--heading_1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0.75em;
    margin-top: 0;

    @include respond-min($bp-tablet-min) {
        font-size: 2.25rem;
        line-height: 2.75rem;
    }

    @include respond-min($bp-tablet-wide-min) {
        font-size: 2.75rem;
        line-height: 3.25rem;
    }

}

.dyson-order__heading-size--heading_5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.5em;
    margin-top: 0;
    font-weight: 400;

    @include respond-min($bp-tablet-min) {
        font-size: 1.375rem;
        line-height: 1.75rem;
    }

    @include respond-min($bp-tablet-wide-min) {
        font-size: 1.5rem;
        line-height: 2rem;
    }

}

.dyson-order__div--large_paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.33rem;
    margin-bottom: 0.5em;

    @include respond-min($bp-tablet-wide-min) {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}

.dyson-order__block-content--margin-vertical {
    margin-top: 2em;
    margin-bottom: 2em;
}

.dyson-order__block-content--margin-vertical-2 {
    margin-top: 0.75em;
    margin-bottom: 2em;
}

.dyson-order__block-content--margin-top-2 {
    margin-top: 2em;
}

.dyson-order__block-content--padding {
    padding: 0.35em 0.625em 0.75em;
}

.dyson-order__block-content--center-text {
    text-align: center;

    @include respond-min($bp-tablet-min) {
        text-align: left;
    }
}

.dyson-order__actions-toolbar--margin-bottom-2 {
    margin-bottom: 2em;
}

.dyson-order__actions-toolbar--padding {
    padding: 0.35em 0.625em 0.75em;
}

.dyson-order__block--margin-top {
    margin-top: 3rem;

    .title {
        margin-bottom: 2em;
    }
}

.dyson-order__block--margin-top .block .title {
    margin-bottom: 1em;
}

.dyson-order__p-no-margin {
    margin: 0;
}

.dyson-order__p-horizontal-margin {
    margin-left: 1rem;
    margin-right: 1rem;
}

.dyson-order__p--mobile {
    @include respond-max($bp-tablet-min - 0.1) {
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.dyson-order__span--larger {
    font-size: 1.375rem;
}

.dyson-order__link {
    text-decoration: underline;
    text-underline-position: under;

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
        color: $clr-dy-blue-1;
    }
}

.dyson-order__box-content--grey-background {
    background-color: $clr-dy-grey-25;
}

.dyson-order__order-address-details--mobile-vertical-margin {
    margin-top: 1em;
    margin-bottom: 1em;

    @include respond-min($bp-tablet-min) {
        margin: inherit;
    }
}

.dyson-order--block__item-list__product {
    background: $clr-dy-grey-05;
    border: 1px solid $clr-dy-grey-2;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 1.5em 0;
    margin-bottom: 1.5em;
    position: relative;
    width: 100%;

    .product-image-container {
        width: 25%;
        padding: 0.25em;
    }

    .product-item-details {
        flex: 1;
        padding: 0 1em;

        .product-item-name {
            @include fs-static(20);
            word-wrap: break-word;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $clr-dy-grey-2;
        }

        .product-item-inner-info {
            padding-top: 0.3em;
            margin-top: 0.3em;
            color: $clr-dy-grey-7;

            @include respond-max($bp-tablet-min - 0.1) {
                display: -ms-flexbox;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

/* Form Styling */
.dyson-order__form--fieldset {
    border: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding: 0;

    input.mage-error,
    select.mage-error,
    textarea.mage-error {
        border-color: $clr-dy-red-1;
    }

    div.mage-error,
    div.field-error {
        margin-top: 8px;
        font-size: 1rem;
        color: $clr-dy-red-1;
    }

    div.mage-error::before,
    div.field-error::before {
        margin-right: 4px;
    }
}

.dyson-order__form--label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5em;
    min-height: 26px;

    @include respond-max($bp-tablet-min) {
        font-size: 0.875rem;
    }

    &.dyson-order__form--label-telephone {
        margin-bottom: 0;
    }

    &--returns {
        display: block;
        font-weight: bold;
        margin-bottom: 0.5em;
        min-height: 26px;
        margin-top: 30px;
    }
}

.dyson-order__form--ol {
    padding-left: 1em;
}

.dyson-order__form--ul {
    padding-left: 1em;
}

.dyson-order__form--info-box {
    margin-bottom: 0.5em;
    font-size: 0.875rem;

    @include respond-max($bp-tablet-min) {
        font-size: 0.75rem;
    }
}

.dyson-order__form--select {
    color: $clr-dy-grey-7;

    /* Keep caret as default styling */
    &.valid {
        border-color: $clr-dy-grey-2;
        background-image: url("../images/icons/sprite.png");
        background-position: 0 -1942px;
        background-position-x: right;
        background-repeat: no-repeat;
    }
}

.dyson-order__form--input-span-prepend {
    position: absolute;
    font-size: 1rem;
    padding-left: 0.875em;
    top: 4.65em;

    @include respond-max($bp-tablet-min) {
        top: 4.375em;
    }
}

.dyson-order__input-text-prepended {
    padding-left: 3rem !important;
}

.dyson-order__returns--form {
    margin-top: 2em;

    @include respond-min($bp-tablet-min) {
        float: initial;
    }
}

.dyson-order__success__container {
    @include respond-min($bp-tablet-wide-min) {
        width: 36%;
        margin-right: 14%;
    }
}

.dyson-order__success--next {
    margin: 1rem 1rem 3rem 1rem;
    padding-left: 10px;

    @include respond-min($bp-tablet-min) {
        width: 40%;
    }

    .title__content {
        font-size: 20px;
    }
}

.dyson-order__success--details {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span:nth-of-type(1) {
        text-align: left;
    }

    span:nth-of-type(2) {
        text-align: right;
    }
}

/* End Form Styling */

/*
 * Custom checkout success styling
 *
 * Keeping it KR specific for now
 */
.dyson-kr.checkout-onepage-success .checkout-success .actions-toolbar {
    @include respond-min($bp-tablet-min) {
        display: inline-block;
        margin-right: 0.5rem;
    }

    a {
        min-width: 230px;
    }

    &.dyson-order__checkout-actions-toolbar--margin-vertical {
        margin-top: 1rem;

        @include respond-max($bp-tablet-min) {
            margin-bottom: 1rem;
        }
    }
}

.dyson-order-checkout-success-content {
    margin-bottom: 30px;

    @include respond-min($bp-tablet-min) {
        padding-left: 30px;
        margin-bottom: 60px;
    }
}
/* End Custom checkout success styling */
