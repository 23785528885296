// ------ Basket Page Shipping Table ------

h2.shipping-table__title {
  @include fs-responsive((g:24,gtw:28));
  border-bottom: 1px solid $clr-dy-grey-2;
  padding: 1em 0 0.25em 0;
  margin-bottom: 0;
}

.shipping-table__info {
  width: 100%;
  display: flex;
  padding: 1.25em 0;
  border-bottom: 1px solid $clr-dy-grey-2;

  @include respond-min($bp-tablet-wide-min) {
    padding: 1.5em 0;
  }
}

.shipping-table__info__icon {
  width: 28px;
  height: 28px;

  @include respond-min($bp-tablet-wide-min) {
    width: 40px;
    height: 40px;
  }
}

.shipping-table__info__text {
  flex: 1;
  padding-left: 1em;

  @include respond-min($bp-tablet-wide-min) {
    padding-left: 1.5em;
  }
}

.shipping-table__info__text__title {
  @include fs-responsive((g:16,gtw:18));
  font-weight: 500;
  color: $clr-dy-grey-7;
  margin: 0 0 0.25em 0;
}

.shipping-table__info__text__caption {
  @include fs-responsive((g:14,gtw:16));
  color: #555;
  margin: 0;
  max-width: 75%;
}

.shipping-table__info__price {
  @include fs-responsive((g:16,gtw:18));
  margin: 0 0 auto;
  width: 12.5%;
  min-width: 140px;
  color: $clr-dy-grey-7;
  text-align: right;

  @include respond-min($bp-tablet-wide-min) {
    text-align: center;
  }
}


// ------ Shipping Choice Tabs ------

.shipping-tabs {
  margin-top: 3em;
  margin-bottom: 3em;
  position: relative;
  display: inline-block;
  width: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 2em; height: 1px;
    bottom: 0;
    background: $clr-dy-grey-2;
  }

  &:before {
    left: -2em;
  }

  &:after {
    right: -2em;
  }
}

.shipping-tabs__container {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}

.shipping-tabs__tab {
  width: 50%;
  padding: 0;

  &:first-of-type {
    .shipping-tabs__tab__inner {
      border-right: 0;
    }
  }

  &--active {
    pointer-events: none;

    .shipping-tabs__tab__inner {
      background: $clr-white;
      border-bottom-color: $clr-white;
    }
  }

  &:hover {
    .shipping-tabs__tab__inner {
      color: $clr-dy-grey-7-hover;
    }
  }
}

.shipping-tabs__tab__inner {
  @include fs-responsive((g:18px, gtw:20px));
  font-family: $f-futura;
  cursor: pointer;
  color: $clr-dy-grey-7;
  background: $clr-dy-grey-05;
  width: 100%;
  padding: 1.4em 1em;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $clr-dy-grey-2;
  transition: all ease-in 0.2s;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}


// ------ Delivery Summary Panel ------

.shipping__scheduled-container {
  .checkout-block__content__subheading {
    border-top: 1px solid $clr-dy-grey-2;
    padding-bottom: 0;
    margin: 0;
    padding-top: 3em;
  }
}

.shipping__scheduled-container__summary {
  @include fs-responsive((g:20px, gtw:24px));
  background-color: $clr-dy-grey-05;
  border: 1px solid $clr-dy-grey-0;
  padding: 1.5em;
  color: $clr-dy-grey-7;
  font-weight: 400;
  font-family: $f-futura;
  position: relative;
  margin-bottom: 3em;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}

.shipping__scheduled-container__summary__edit {
  @include fs-responsive((g:14px, gt:16px));
  position: absolute;
  top: 2em;
  right: 2em;
  color: $clr-dy-grey-7;
  text-decoration: underline;
}

.shipping__scheduled-container__summary__line {
  margin: 0;
}


// ------ Delivery Choice Panel ------

.shipping__scheduled-container__slots {
  padding-top: 3em;
  padding-bottom: 3em;
  margin-bottom: 3em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  white-space: normal;
  width: 100%;
}

input.shipping__scheduled-container__slots__option {
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
}

input.shipping__scheduled-container__slots__option:checked+label {
  box-shadow: inset 0 0 0 4px $clr-dy-grey-6;
}

.shipping__scheduled-container__slots__button {
  color: $clr-dy-grey-7;
  cursor: pointer;
  outline: 0;
  border: 0;
  align-items: center;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 0 1px $clr-dy-grey-2;
  background-color: $clr-dy-grey-05;
}

.shipping__scheduled-container__slots__button--day {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;
  padding: 1em 0.5em;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @include respond-min($bp-tablet-min) {
    width: 24%;
    margin-right: 1%;
    margin-bottom: 1%;

    &:nth-of-type(3n) {
      margin-right: 1%;
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }

  @include respond-min($bp-tablet-wide-min) {
    width: 19.2%;

    &:nth-of-type(4n) {
      margin-right: 1%;
    }

    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }
}

.shipping__scheduled-container__times {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  white-space: normal;
}

.shipping__scheduled-container__slots__button--time {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;
  padding: 2em 0.5em;
}

.shipping__scheduled-container__slots__button--hide {
  display: none;
}

.shipping__scheduled-container__slots__button__day {
  @include fs-responsive((g:14,gt:16));
  font-weight: 400;
}

.shipping__scheduled-container__slots__button__date {
  @include fs-responsive((g:28,gt:32,gtw:36));
  font-weight: 500;
  line-height: 1.2;

  @include respond-min($bp-tablet-wide-min) {
    line-height: 1.4;
  }
}

.shipping__scheduled-container__slots__button__month {
  @include fs-responsive((g:14,gt:16));
  font-weight: 400;
}

.shipping__scheduled-container__slots__button__cost {
  @include fs-responsive((g:16,gt:18));
  font-weight: 500;
  color: $clr-dy-green-2;

  .shipping__scheduled-container__slots__button--day & {
    @include fs-responsive((g:14,gt:16));
    margin-top: 0.25em;
  }
}


// ------ Standard Delivery Widget ------

.standard-delivery-widget {
  display: none;
  margin-bottom: 2em;

  @include respond-min($bp-tablet-wide-min) {
    margin-bottom: 3em;
  }
}

.standard-delivery-widget__method {
  display: flex;
  width: 100%;
}

.standard-delivery-widget__method__icon {
  width: 32px;
  height: 32px;

  @include respond-min($bp-tablet-wide-min) {
    width: 40px;
    height: 40px;
  }
}

.standard-delivery-widget__method__details {
  flex: 1;
  padding-left: 1em;

  @include respond-min($bp-tablet-wide-min) {
    padding-left: 1.5em;
  }
}

.standard-delivery-widget__method__details__title {
  @include fs-responsive((g:16,gtw:18));
  font-weight: 500;
  margin: 0 0 0.25em 0;
}

.standard-delivery-widget__method__details__delivery {
  @include fs-responsive((g:14,gtw:16));
  margin-top: 0;
}

.standard-delivery-widget__method__price {
  @include fs-responsive((g:16,gtw:18));
  font-weight: 500;
  color: $clr-dy-green-2;
  margin-left: auto;
}


// ------ Click and Collect section ------

.shipping__cac {
  width: 100%;
  display: none;
  margin-bottom: 2em;
}

.shipping__cac--show {
  display: inline-block;
}

.shipping__cac__container {
  width: 100%;
  display: inline-block;

  @include respond-min($bp-tablet-wide-min) {
    display: flex;
  }
}

.shipping__cac__map {
  height: 480px;
  margin-bottom: 2em;

  @include respond-min($bp-tablet-wide-min) {
    height: 510px;
    width: 48%;
    margin-right: 4%;
    margin-bottom: 0;
  }
}

.shipping__cac__list {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 510px;

  @include respond-min($bp-tablet-wide-min) {
    width: 48%;
  }
}

.shipping__cac__list__location {
  width: 100%;
  position: relative;
  background-color: $clr-dy-grey-05;
  box-shadow: inset 0 0 0 1px $clr-dy-grey-2;
  padding: 1.25em;
  margin-bottom: 0.75em;
  margin-top: 0;

  @include respond-min($bp-tablet-wide-min) {
    padding: 1.5em;
    margin-bottom: 1em;
  }
}

.shipping__cac__list__location--selected {
  box-shadow: inset 0 0 0 4px $clr-dy-grey-6;
}

.shipping__cac__list__location__top {
  margin-bottom: 1.25em;
  display: flex;
  align-items: center;

  @include respond-min($bp-tablet-wide-min) {
    margin-bottom: 1.5em;
  }

  input[type="checkbox"] {
    position: absolute;
    height: 2em;
    width: 2em;
    z-index: 1;
    left: 1.5em;
    margin: 0;
    padding: 0;
    opacity: 0;
    display: inline-block;
    font-family: $f-futura;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    &:checked ~ label {
      &:after{
        opacity: 1;
      }
    }
  }

  label {
    cursor: pointer;
    height: 32px;
    margin-right: 1rem;
    padding-left: 45px;
    position: relative;
    font-weight: 400;
    -moz-user-select: none;
    -webkit-user-select: none;
    font-family: $f-futura;
    color: $clr-dy-grey-7;
    display: flex;
    align-items: center;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    &:before,
    &:after {
      content: '';
      height: 32px;
      width: 32px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .15s ease-in;
      box-sizing: border-box;
    }

    &:before {
      border: 2px solid $clr-dy-grey-2;
      background-color: $clr-white;
    }

    &:after {
      background-image: url('../images/icons/sprite.png');
      background-repeat: no-repeat;
      background-position: 0 -1856px;
      opacity: 0;
    }
  }
}

.shipping__cac__list__location__top__count {
  @include fs-responsive((g:14,gtw:16));
  color: $clr-dy-grey-7;
  font-family: $f-futura;
  margin-left: auto;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}

.shipping__cac__list__location__name {
  margin-bottom: 1.25em;

  @include respond-min($bp-tablet-wide-min) {
    margin-bottom: 1.5em;
  }
}

.shipping__cac__list__location__name__title {
  @include fs-responsive((g:16,gtw:18));
  font-weight: 500;
  font-family: $f-futura;
  color: $clr-dy-grey-7;
  float: left;
  padding-right: 1em;
}

.shipping__cac__list__location__name__distance {
  @include fs-responsive((g:16,gtw:18));
  font-weight: 500;
  font-family: $f-futura;
  color: $clr-dy-grey-7;
  float: right;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}

.shipping__cac__list__location__address {
  margin-bottom: 1.25em;

  @include respond-min($bp-tablet-wide-min) {
    margin-bottom: 1.5em;
  }

  span {
    display: block;
    @include fs-responsive((g:14,gtw:16));
    color: $clr-dy-grey-66;
    font-family: $f-futura;
    font-weight: 400;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }
  }
}

.shipping__cac__list__location__details {
  p {
    display: block;
    @include fs-responsive((g:14,gtw:16));
    font-weight: 400;
    color: $clr-dy-grey-7;
    margin-bottom: 0;
    font-family: $f-futura;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }
  }
}

.shipping__cac__list__location__details__link {
  @include fs-responsive((g:14,gtw:16));
  font-weight: 400;
  color: $clr-dy-grey-7;
  margin-bottom: 0;
  text-decoration: underline;
  font-family: $f-futura;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.shipping__cac__list__location__overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background-color: $clr-dy-grey-05;
  box-shadow: inset 0 0 0 1px $clr-dy-grey-2;
  padding: 1.25em;

  @include respond-min($bp-tablet-wide-min) {
    padding: 1.5em;
  }

  .shipping__cac__list__location__overlay__list {
    margin: 0;
    padding-left: 20px;

    li {
      @include fs-responsive((g:14,gtw:16));
      color: $clr-dy-grey-7;
      font-family: $f-futura;
      list-style: disc;
      margin: 0;
      line-height: 1.5;

      .dyson-th.primary & {
        font-family: $f-futura-thai;
      }

      .dyson-hk.primary & {
        font-family: $f-futura-hant;
      }

      .dyson-ae.rtl &, .dyson-sa.rtl & {
        font-family: $f-futura-arabic;
      }

      .dyson-il.rtl & {
        font-family: $f-futura-hebrew;
      }

      .dyson-kr & {
        font-family: $f-noto-sans;
      }
    }
  }

  .shipping__cac__list__location--overlay & {
    display: inherit;
  }
}

.shipping__cac__list__location__overlay__title {
  @include fs-responsive((g:16,gtw:18));
  font-weight: 500;
  font-family: $f-futura;
  color: $clr-dy-grey-7;
  margin-bottom: 1.25em;
  padding-right: 1.5em;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }

  @include respond-min($bp-tablet-wide-min) {
    margin-bottom: 1.5em;
  }
}

.shipping__cac__list__location__overlay__close {
  position: absolute;
  // SVG with PNG fallback for IE11
  background: url("../images/icons/modal-close-blk.png");
  background-repeat: no-repeat;
  right: 1.25rem;
  top: 1.25rem;
  height: 16px;
  width: 16px;
  background-size: 16px 16px;

  @include respond-min($bp-tablet-wide-min) {
    right: 1.5rem;
    top: 1.5rem;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
  }
}


// ------ Other CSS ------

// Classes added via JS to simulate tab functionality

.form-shipping-address--scheduled {
  .shipping__panel--scheduleddelivery {
    display: block;

    &.shipping__scheduled-container__slots {
      display: inline-flex;
    }
  }

  .shipping__panel--clickandcollect {
    display: none;
  }

  .field--address-lookup {
    display: inline-block;
    width: 100%;
    padding-bottom: 1.5em;
  }
}

.form-shipping-address--clickandcollect {
  .shipping__panel--scheduleddelivery {
    display: none;
  }

  .shipping__panel--clickandcollect {
    display: block;
  }

  .field--address-lookup {
    display: inline-block;
    width: 100%;
  }

  .field--delivery-to-someone-else {
    display: inline-block;
    width: 100%;
  }
}

.form-shipping-address--clickandcollect {
  .field--delivery-to-someone-else,
  .field--toggle-fields-layout,
  .field--other {
    display: none!important;
  }
}

.field--address-lookup,
.field--delivery-to-someone-else {
  display: none;
  float: left;
}

// TODO - Temp class to be updated when a better way of handling errors is implemented

.messages--am-checkout {
  padding-top: 61px;

  @include respond-min($bp-tablet-wide-min) {
    padding-top: 0;
  }

  .message {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;

    .layout {
      font-weight: 500;
    }
  }
}

.checkout-action {
  text-decoration: underline;
  color: $clr-dy-grey-7;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.field--toggle-fields-layout {
  padding-top: 1em;

  .checkout-action--manual {
    display: inherit;
  }

  .checkout-action--lookup {
    display: none;
  }

  &--manual {
    .checkout-action--manual {
      display: none;
    }

    .checkout-action--lookup {
      display: inherit;
    }
  }
}

.checkout-error {
  display: inline-block;
  width: 100%;
  color: $clr-dy-red-1;
  padding-top: 1em;
}

.button--postcode-check {
  .form-shipping-address--clickandcollect & {
    display: inline-block!important;
  }
  .form-shipping-address--scheduled & {
    display: none!important;
  }
}

.field-error--postcode {
  display: none;

  .form-shipping-address--scheduled & {
    display: none!important;
  }
}

.form-shipping-address--clickandcollect {
  .button--postcode-check {
    min-width: 120px;
    height: 64px;
  }

  .field[name="shippingAddress.postcode"] {
    width: 100%!important;
    display: block!important;

    .control {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      .input-text {
        width: 48%;
        order: 1;
      }

      .button--postcode-check {
        order: 2;
      }

      .field-error {
        order: 3;
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .field[name="shippingAddress.custom_attributes.terms_and_conditions_accepted"],
  .field[name="shippingAddress.custom_attributes.privacy_policy_accepted"],
  .field[name="shippingAddress.custom_attributes.newsletter_signup"] {
    display: block!important;
  }
}


// ------ Specific Country CSS ------

.dyson-pl.checkout-sp {

  .am-checkout #customer-email-fieldset {
    width: 100%;

    @include respond-min($bp-tablet-wide-min) {
      float: left;
      width: 48%;
      margin-right: 4%;
    }

    .field {
      width: 100%;
    }
  }

  .checkout-block--billing-mandatory {
    .payment-method-content[data-tab-content="1"],
    .payment-method-content[data-tab-content="3"] {
      .checkout-billing-address {
        .billing-address-same-as-shipping-block {
          display: none!important;
        }

        .action-update {
          float: right;
        }

        .action-cancel {
          display: none!important;
        }

        fieldset.fieldset {
          display: block!important;
        }
      }
    }

    .payment-method-content[data-tab-content="2"] {
      .billing-address-details {
        display: none!important;
      }
    }
  }
}
