.spec {
  display: block;
  color: $clr-black;

  &--dark {
    color: $clr-white;
  }
}

.spec__label {
  display: block;
  margin-bottom: 1em;
  @include fs-responsive((g:$fs-small, gtw:14px));
}

.spec__data {
  display: block;
  font-style: normal;
  @include fs-responsive((g:26px, gtw:38px));
}

.spec__value {
  display: inline-block;
}

.spec__text {
  display: inline-block;
  @include fs-responsive((g:14px, gtw:20px));
  font-family: $f-futura;
  font-weight: 300;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}

.spec--big {
  .spec__text {
    @include fs-responsive((g:28,gtw:40));
  }
}
