/*
  TABLES
*/

table {
  width: 100%;
  border-collapse: collapse;
}


th, td {
  text-align: left;
  padding: 0.5em 1em;
  @include fs-static(16);
  font-family: $f-fallback;
  border: 1px solid $clr-dy-grey-4;
}

th {

}

td {

}
