/*
  CHECKOUT
  #### CHECK END OF STYLESHEET FOR COUNTRY SPECIFIC LAYOUTS
*/

@mixin field-asterisk {
  content: '*';
  display: inline-block;
  margin-left: 5px;

  .dyson-tr & {
    margin-left: -1px;
  }
}

.payment-methods {
    .payment-img {
        img {
            max-width: 370px;
            width: auto;
            padding: 2px;
        }
    }
}
.checkout-billing-address {
    fieldset.fieldset {
        display: flex;
        flex-wrap: wrap;
    }
}
.block.crosssell {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding: 0 20px;
    .products {
        margin: 30px 0;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .product-items {
        font-family: "Futura Latin", "Helvetica Neue", Helvetica, Arial, sans-serif;
        .product-item {
            width: calc(25% - 20px);
            display: inline-block;
            margin: 0 10px 20px;
            position: relative;
            padding-bottom: 80px;
            .card__title {
                padding: 0 10px;
            }
            .image--stretch {
                text-align: center;
                overflow: hidden;
                .product-image-photo {
                    max-height: 300px;
                    width: auto;
                    text-align: center;
                    display: inline-block;
                }
            }
            .reviews-form-link, .product-reviews-summary {
                padding: 0 10px;
            }
            .product-item-info {
                min-width: 200px;
                max-width: 100%;
                .product-item-name {
                    font-size: 1.5rem;
                    line-height: 1.3;
                    color: #333333;
                    margin: 1em 0;
                    font-weight: 300;
                    letter-spacing: -0.01em
                }

            }
            .button--cart__basket-icon--card {
                display: inline-block;
                position: relative;
                width: 20px;
                top: 3px;
                left: -2px;
                font-size: 1.125rem;
            }
            .button {
                padding: 15px 5px;
            }
            .product-item-link {
                font-weight: 500;
            }
            .product-item-details {
                .product-item-actions {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }
            }
            .notify-me__container--addToCart {
                min-width: 100%;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .block.crosssell {

        .product-items {
            .product-item {
                width: calc(50% - 20px);

            }
        }
    }
}
.checkout__row {
  .action-close,
  .authentication-wrapper,
  .opc-estimated-wrapper {
    display: none;
  }

  .checkout-container {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 0;
  }


  // PROGRESS BAR

  .opc-progress-bar {
    @include list-reset;
    width: 100%;
    text-align: center;
    margin: 0 0 40px;
    counter-reset: i;
    font-size: 0;
    @include min-screen($bp-checkout-wide) {
      padding-right: 350px;
    }
  }

  .opc-progress-bar-item {
    @include list-item-reset;
    margin: 0 0 10px;
    width: 240px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
  }

  .opc-progress-bar-item:before {
    background: $clr-dy-grey-2;
    top: 19px;
    transition: background 0.3s;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px;
  }
  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0;
  }
  .opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: $clr-dy-grey-7;
    @include fs-responsive((g:12px, gt:14px, gtw:15px));
    font-family: $f-futura;
    font-weight: 400;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

  }
  .opc-progress-bar-item > span:before,
  .opc-progress-bar-item > span:after {
    background: #e4e4e4;
    height: 38px;
    margin-left: -19px;
    transition: background 0.3s;
    width: 38px;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
  }
  .opc-progress-bar-item > span:after {
    background: #ffffff;
    height: 27px;
    margin-left: -14px;
    top: 5px;
    width: 27px;
    padding: 1px 0 0 1px;
    content: counter(i);
    counter-increment: i;
    color: $clr-dy-grey-7;
    font-weight: bold;
    @include fs-responsive((g:15px, gt:16px, gtw:18px));
  }
  .opc-progress-bar-item._complete {
    cursor: pointer;
  }
  .opc-progress-bar-item._complete:hover:before {
    background: #d7d7d7;
  }
  .opc-progress-bar-item._complete:hover > span:before {
    background: #d7d7d7;
  }
  .opc-progress-bar-item._complete > span {
    color: $clr-dy-grey-7;
  }
  .opc-progress-bar-item._complete > span:after {
  }
  .opc-progress-bar-item._active:before {
    background: $clr-dy-green-2;
  }
  .opc-progress-bar-item._active > span {
    color: $clr-dy-grey-7;
  }
  .opc-progress-bar-item._active > span:before {
    background: $clr-dy-green-2;
  }

  .opc-progress-bar-item._active > span:after {
    // content: '';
    // background-image: url('/images/icons/sprite.png');
    // background-repeat: no-repeat;
    // background-position: center -300px;
  }


  // CHECKOUT MAIN

  .opc-wrapper {
    // flex: 1 0 0%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 1px;
    // width: 100%;

    .opc {
      @include list-reset;

      li {
        @include list-item-reset;

        &.checkout-shipping-method {
          margin-top: 30px;

          .button {
            font-family: $f-futura; // fending off magento opc styles

            .dyson-th.primary & {
              font-family: $f-futura-thai;
            }

            .dyson-hk.primary & {
              font-family: $f-futura-hant;
            }

            .dyson-ae.rtl &, .dyson-sa.rtl & {
              font-family: $f-futura-arabic;
            }

            .dyson-il.rtl & {
              font-family: $f-futura-hebrew;
            }

            .dyson-kr & {
              font-family: $f-noto-sans;
            }

          }
        }
      }

      .step-title {
        @include fs-responsive((g:24px, gt:28px, gtw:32px));
        font-family: $f-futura;
        font-weight: 400;
        color: $clr-dy-grey-7;
        border: 1px solid $clr-dy-grey-2;
        padding: 25px 30px;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }

        .dyson-tr & {
          padding: 25px;
          margin-bottom: 20px;
        }

      }

      .form{
        border: 1px solid $clr-dy-grey-2;
        border-top: 0;
        padding: 0 30px 25px 30px;

        .dyson-tr & {
          display: flex;
          flex-direction: column;
        }

        &.form-login {
          border-bottom: 0;
          padding-top: 25px;

          .field {
            margin-bottom: 0;

            @include respond-min($bp-tablet-min) {
              width: 48.5%;
            }
          }
        }

        &.methods-shipping {
          padding-top: 25px;

          table {
            margin-bottom: 30px;

            th,td {
              padding: 12px 5px;
              @include fs-static(12);
              font-weight: 400;
              font-family: $f-fallback;
              line-height: 1.25rem;
              color: $clr-dy-grey-7;
              border: none;

              @include respond-min($bp-tablet-min) {
                font-size: $fs-body;
              }
            }

            th {
              border-bottom: 1px solid $clr-dy-grey-2;
              font-weight: 700;
            }
          }
        }

        &.payments {
          &:not(#co-payment-form) {
            padding-top: 25px;
            border-top: 1px solid $clr-dy-grey-2;
          }
          &#co-payment-form {
            padding:0;

            //.step-title {
            //  margin: 0 -1px;
            //}

            .payment-method {
              margin: 25px 30px;

              &:not(:last-child) {
                padding-bottom: 25px;
                border-bottom: 1px solid $clr-dy-grey-2;
              }

              @include respond-min($bp-tablet-wide-min) {
                margin: 35px 40px;

                &:not(:last-child) {
                  padding-bottom: 35px;
                }
              }

              .actions-toolbar {
                text-align: right;
              }

              .action.primary.checkout {
                @include button;
                @include button--wider;
                @include button--tertiary;
                font-family: $f-futura;

                .dyson-th.primary & {
                  font-family: $f-futura-thai;
                }

                .dyson-hk.primary & {
                  font-family: $f-futura-hant;
                }

                .dyson-ae.rtl &, .dyson-sa.rtl & {
                  font-family: $f-futura-arabic;
                }

                .dyson-il.rtl & {
                  font-family: $f-futura-hebrew;
                }

                .dyson-kr & {
                  font-family: $f-noto-sans;
                }

                span {
                  font-family: $f-futura;

                  .dyson-th.primary & {
                    font-family: $f-futura-thai;
                  }

                  .dyson-hk.primary & {
                    font-family: $f-futura-hant;
                  }

                  .dyson-ae.rtl &, .dyson-sa.rtl & {
                    font-family: $f-futura-arabic;
                  }

                  .dyson-il.rtl & {
                    font-family: $f-futura-hebrew;
                  }

                  .dyson-kr & {
                    font-family: $f-noto-sans;
                  }

                }
              }
            }

            #block-discount-heading {
              display: none;
            }

            .payment-method-title {

              .label {
                color: $clr-dy-grey-7;
                @extend .form__label;
                @include fs-responsive((g:20, gtw: 26));
                margin-bottom: 8px;
                display: inline;
                font-family: $f-futura;
                font-weight: 400;
                line-height: 1.3;

                .dyson-th.primary & {
                  font-family: $f-futura-thai;
                }

                .dyson-hk.primary & {
                  font-family: $f-futura-hant;
                }

                .dyson-ae.rtl &, .dyson-sa.rtl & {
                  font-family: $f-futura-arabic;
                }

                .dyson-il.rtl & {
                  font-family: $f-futura-hebrew;
                }

                .dyson-kr & {
                  font-family: $f-noto-sans;
                }

              }
            }

            .field-select-billing {
              display: none;
            }

            .checkout-agreement {
              margin-bottom: 1em;
              .action-show {
                @include button-reset;
                text-decoration: underline;
                padding: 0;

                .dyson-my span & {
                  font-size: 11px;

                  @include respond-min($bp-tablet-wide-min) {
                    font-size: 14px;
                  }
                }
              }

              .dyson-my & {
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;

                @include respond-min($bp-tablet-wide-min) {
                  white-space: normal;
                }
              }
            }

          }
        }

        .field-tooltip.toggle,
        .note {
          display: none!important;
        }

        // Copy our default form styles
        .field {
          @extend .form__item;
        }

        .field:not(.field--checkboxes) {
          &.required,
          &._required {
            label:after {
              @include field-asterisk;
            }
          }
        }


        .label {
          @extend .form__label;
          color: $clr-dy-grey-7;
          margin-bottom: 8px;
        }

        .payment-method-title {
          .radio {
            position: relative;
            top: -0.25em;
          }
        }

        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="tel"],
        input[type="url"],
        input[type="password"],
        select[multiple],
        textarea,
        select {
          @extend .form__input;
        }

        fieldset {
          border: 0;
          padding: 0;
          margin: 0;

          .dyson-tr & {
            @include respond-min($bp-desktop-min){
              position:relative;
            }
            // padding: 20px;

            .actions-toolbar button.action.primary.checkout {
              width: 100%;

              @include respond-min($bp-tablet-wide-min) {
                width: 50%
              }
            }

            .checkout-agreements-block button.action.primary.checkout {
              width: 100%;

              @include respond-min($bp-tablet-wide-min) {
                width: 50%
              }


            }

            div.field {

              @include respond-min($bp-desktop-min) {
                // width: 40%;
                // display: inline-block;
              }
            }
          }

        }
      }
    }

    #shipping-new-address-form,
    .checkout-billing-address fieldset {
      @include clearfix;

      .field {
        float: left;
        width: 100%;

        @include respond-min($bp-tablet-min) {

          &:nth-child(1) {
            width: 48%;
            margin-right: 4%;
          }

          &:nth-child(2) {
            width: 48%;
          }

          &:nth-child(3) {
            width: 48%;
            margin-right: 4%;
          }

          &.street {
            width: 100%;

            .field {
              margin-bottom: 1.5em;
              .dyson-hu & {
                margin-bottom: 0;
              }

              &:last-child {
                margin-top: 8px;
                .dyson-hu & {
                  margin-top: 0;
                }
              }
            }
          }

          &:nth-child(5) {
            width: 48%;
            margin-right: 4%;
          }

          &:nth-child(6) {
            //width: 48%;
          }

          &:nth-child(7) {
            width: 48%;
          }
          &:nth-child(8) {
            width: 48%;
            margin-right: 4%;
          }

          &:nth-child(9) {
            width: 48%;
          }

          &:nth-child(10) {
            width: 48%;
            margin-right: 4%;
          }

          &:nth-child(11) {
            width: 48%;
          }
        }
      }
    }

    #opc-shipping_method {
      .actions-toolbar {
        width: 100%;
        text-align: right;
        padding-top: 30px;
      }

      .button {
        width: 48%;
      }
    }
  }

  #co-payment-form {

    .legend {
      display: none;

      & + br {
        display: none;
      }
    }

    .payment-method-billing-address {
        color: $clr-dy-grey-7;

      .actions-toolbar {
        button {
          @include button;
          @include button--wider;
          font-family: $f-futura;

          .dyson-th.primary & {
            font-family: $f-futura-thai;
          }

          .dyson-hk.primary & {
            font-family: $f-futura-hant;
          }

          .dyson-ae.rtl &, .dyson-sa.rtl & {
            font-family: $f-futura-arabic;
          }

          .dyson-il.rtl & {
            font-family: $f-futura-hebrew;
          }

          .dyson-kr & {
            font-family: $f-noto-sans;
          }

          &.action-cancel {
            @include button--secondary;
          }
        }
      }

      .billing-address-details {
        color: $clr-dy-grey-7;
        a {
          color: $clr-dy-grey-7;
          margin-top: 1em;
          display: inline-block;
        }
      }
    }
  }


  // CHECKOUT SIDEBAR

  .opc-sidebar {
    width: 320px;
    margin-left: 30px;

    .modal-inner-wrap {
      background: none;
    }

    .title {
      @include fs-responsive((g:16px, gt:18px, gtw:20px));
      font-weight: 400;
      color: $clr-dy-grey-7;
    }

    .items-in-cart {
      .title {
        color: $clr-dy-grey-7;
        @include fs-responsive((g:14px, gt:16px));
        margin: 10px 0 20px 0;

        strong {
          font-weight: 400;
        }
      }
    }

    ol.minicart-items {
      @include list-reset;
      list-style: none;

      .product-item {
        margin: 0 0 20px 0;

        .product {
          display: flex;
          background-color: $clr-dy-grey-05;
          border: 1px solid $clr-dy-grey-2;
          padding: 15px;

          .product-image-container {
            width: 70px;
            margin-right: 8px;
          }

          .product-item-details {
            width: 100%;
            flex-grow: 1;

            .product-item-name {
              font-weight: 400;
              @include fs-responsive((g:16px, gt:18px, gtw:20px));
              color: $clr-dy-grey-7;
              font-family: $f-futura;
              border-bottom: 1px solid $clr-dy-grey-2;
              display: inline-block;
              width: 100%;
              padding-bottom: 15px;
              margin-bottom: 25px;

              .dyson-th.primary & {
                font-family: $f-futura-thai;
              }

              .dyson-hk.primary & {
                font-family: $f-futura-hant;
              }

              .dyson-ae.rtl &, .dyson-sa.rtl & {
                font-family: $f-futura-arabic;
              }

              .dyson-il.rtl & {
                font-family: $f-futura-hebrew;
              }

              .dyson-kr & {
                font-family: $f-noto-sans;
              }

            }

            .details-qty {
              color: $clr-dy-grey-7;
              @include fs-responsive((g:14px, gt:15px));

              .value {
                float: right;
                padding-right: 40px;
              }
            }

            .cart-price {
              .price {
                font-weight: 400;
                @include fs-responsive((g:15px, gt:16px, gtw:18px));
                color: $clr-dy-grey-7;
                font-family: $f-futura;

                .dyson-th.primary & {
                  font-family: $f-futura-thai;
                }

                .dyson-hk.primary & {
                  font-family: $f-futura-hant;
                }

                .dyson-ae.rtl &, .dyson-sa.rtl & {
                  font-family: $f-futura-arabic;
                }

                .dyson-il.rtl & {
                  font-family: $f-futura-hebrew;
                }

                .dyson-kr & {
                  font-family: $f-noto-sans;
                }

              }
            }
          }
        }
      }
    }

    .ship-to, .ship-via {
      .shipping-information-title {
        & > span {
          @include fs-responsive((g:16,gt:18,gtw:20));
          color: $clr-dy-grey-7;
        }
      }

      .shipping-information-content {
        margin-top: 1em;
        a {
          color: $clr-dy-grey-7;
          margin-top: 0.75em;
          display: inline-block;
        }
      }
    }
  }

  .minicart-items {
    display: block!important;
  }
}

.opc-block-summary {
  .table-caption {
    display: none;
  }

  .table-totals {
    margin-bottom: 40px;

    th,td {
      color: $clr-dy-grey-5;
      @include fs-responsive((g:14px, gt:15px));
      font-weight: 400;
      padding: 5px 0;
      border: none;
    }

    .sub {
      th,td {
        padding-top: 20px;
      }
    }

    .grand {
      th,td {
        font-family: $f-futura;
        font-weight: 400;
        color: $clr-dy-grey-7;
        border-top: 1px solid $clr-dy-grey-2;
        padding-top: 20px;
        line-height: 1;
        vertical-align: bottom;

        .dyson-th.primary & {
          font-family: $f-futura-thai;
        }

        .dyson-hk.primary & {
          font-family: $f-futura-hant;
        }

        .dyson-ae.rtl &, .dyson-sa.rtl & {
          font-family: $f-futura-arabic;
        }

        .dyson-il.rtl & {
          font-family: $f-futura-hebrew;
        }

        .dyson-kr & {
          font-family: $f-noto-sans;
        }

        strong {
          font-weight: 400;
        }
      }

      th {
        @include fs-static(20);
      }
      td {
        @include fs-static(28);
        strong {
          font-weight: 500;
        }
      }
    }

    // padding bottom on penultimate row
    .totals {
      &:nth-last-child(2) {
        th, td {
          padding-bottom: 1em;
        }
      }
    }
  }
}

.opc-block-shipping-information {
  margin-top: 40px;
  // display: none;

  .shipping-information-title {
    color: $clr-dy-grey-7;
    @include fs-responsive((g:14px, gt:15px));
    margin-bottom: 4px;
  }

  .shipping-information-content {
    color: $clr-dy-grey-7;
    @include fs-responsive((g:13px, gt:14px));
    margin-bottom: 20px;
  }

  .action {
    @include button-reset;
    padding: 0;
    color: $clr-dy-grey-7;
    font-family: $f-fallback;
    font-size: 12px;
    margin-left: 8px;
  }
}

.checkout__header {
  padding: 30px 0;
  border-bottom: 1px solid $clr-dy-grey-2;
}

.loading-mask {
  @include z-index(modal-bottom);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($clr-white, 0.7);

  .loader {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      margin-left: -77px;
      margin-top: -43px
    }

    p {
      display: none;
    }
  }

  // because we end up with loaders on top of loaders :(
  .checkout-shipping-method & {
    display: none;
  }
}

#registration {
  // display: none;
}

.checkout-success {
  margin: 30px 0;
  padding: 30px;
  background: $clr-dy-grey-0;

  @include respond-min($bp-tablet-min) {
    margin: 60px 0;
    padding: 30px;
  }

  h1 {
    @include fs-responsive((g:20px, gt:24px, gtw:28px));
    color: $clr-dy-grey-7;
    font-weight: 300;
    margin-bottom: 0.5em;
  }

  h2 {
    @include fs-responsive((g:16px, gt:18px, gtw:22px));
    margin-top: 0;
  }

  p {
    margin-bottom: 2em;
  }
}

// Hide ability to have different billing address for Cash On Delivery payment method
.billing-address-same-as-shipping-block {
  #billing-address-same-as-shipping-cashondelivery,
  [for="billing-address-same-as-shipping-cashondelivery"] {
    display: none;
  }
}

// Hide Shipping Methods section
#opc-shipping_method {
  margin: 0;
  padding-top: 50px;
  #checkout-shipping-method-load,
  .step-title {
    display: none !important;
  }
  #co-shipping-method-form {
    border: none;
    padding: 0;
    #shipping-method-buttons-container {
      padding: 0;
    }
  }
  .no-quotes-block {
    display: none;
  }
}


.control._with-tooltip {
  position: relative;

  label.input-label-overlay {
    color: $clr-dy-grey-7;
    position: absolute;
    top: 21px;
    left: 16px;

    &:after {
      display: none !important;
    }

  }

  .input-label-overlay + input[type="text"].input-text {
    padding-left: 48px;

  }

}

// #### IN SPECIFIC STYLES/OVERRIDES
// a lack of specific classes has made contextual styling tweaks hard so nth-child has been heavily
// used in base theme. Countering this by trying to style based on name="" attribute which offers customEntry
// degree of targetablility
.dyson-in {

  .street.field:not(.field--checkboxes) {
    &.required,
    &._required {
      label:after {
        content: '';
        display: inline-block;
        margin-left: 5px;
        margin-bottom: -20px;
      }
    }
  }

  div.field.additional._required
  label.label {
    display:none;
  }

  .street .field._required
  .label {
    display:none;
  }

  #shipping-new-address-form {

    div.field[name="shippingAddress.company"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        clear: left;
      }
    }
    fieldset.field.street,
    fieldset.field.street:nth-child(5) {
      width: 100%;
      margin-right: 0;
    }
    fieldset.field.street,
    fieldset.field.street:nth-child(8) {
      width: 100%;
      margin-right: 0;
    }
    .field.street .field:last-child[name="shippingAddress.street.1"] { // yeah we're going there!
      @include respond-min($bp-tablet-min) {
        margin-top: 0;
      }
    }
    div.field[name="shippingAddress.city"], div.field[name="shippingAddress.postcode"], div.field[name="shippingAddress.telephone"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
      }
    }
    div.field[name="shippingAddress.country_id"], div.field[name="shippingAddress.country_id"]:nth-child(10) {
      @include respond-min($bp-tablet-min) {
        margin-right: 0;
      }
    }
    div.field[name="shippingAddress.custom_attributes.pan_tax_number"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
    }
    div.field[name="shippingAddress.custom_attributes.newsletter_signup"], div.field[name="shippingAddress.custom_attributes.newsletter_signup"]:nth-child(3)  {
      @include respond-min($bp-tablet-min) {
        width: 100%;
      }
    }
  }

  //Hiding the 'edit' button next to Shipping Method
  div.ship-via {
    display: none;
  }


  .payment-method-billing-address{

    div.field[name="billingAddresspayusubvention.street.1"],
    div.field[name="billingAddresspayu.street.1"] {
      margin-top: 0 !important;
    }
    div.field[name="billingAddresspayusubvention.city"],
    div.field[name="billingAddresspayu.city"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
    }
    div.field[name="billingAddresspayusubvention.region"],
    div.field[name="billingAddresspayu.region"] {
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
      }
    }
    div.field[name="billingAddresspayusubvention.telephone"],
    div.field[name="billingAddresspayu.telephone"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
      }
    }
    div.field[name="billingAddresspayusubvention.postcode"],
    div.field[name="billingAddresspayu.postcode"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
    }
    div.field[name="billingAddresspayusubvention.country_id"],
    div.field[name="billingAddresspayu.country_id"] {
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
      }
    }
    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem  !important;
      }
    }
  }
}


// #### NZ SPECIFIC STYLES/OVERRIDES
// Modifications to clean the look of the checkout for NZ - hide unwanted label and align fields correctly
.dyson-nz {

  #adyen_cc {
    display: none;
  }

  .field.street.required
  legend.label {
    display:none;
  }

  .field.additional {
    margin-top: 0px !important;
  }

  ul.credit-card-types {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

  }

  div.field.additional
  label.label:after {
    content: '' !important;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: -20px;
  }

  div.field.additional._required
  label.label:after {
    content: '*' !important;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: -20px;
  }

  ul.credit-card-types {
    li {
      display: none;
    }
    &:after {
      background: url('../images/brand/accepted-payment-nz-without-afterpay.png') no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      width: 150px;
      height: 30px;
    }
  }

  .checkout-payment-method .payment-method-title label div.adyen-sprite.adyen_cc {
    display:none;

  }

  .field.type.required label.label span:after {
    display: none;
  }
}


// #### KR SPECIFIC STYLES/OVERRIDES
.dyson-kr {

  .checkout__row .opc-wrapper .opc li {
    list-style: none;
  }

  .input--loading {
    background-image: url(../images/loading.gif);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 90% center;
    background-position: 95% center;
    background-position-y: center;
  }

  #shipping-new-address-form {

    .label--black {
      color: $clr-dy-grey-7;
      margin-bottom: 25px;
      padding-left: 48px;
    }

    .label--auto-reg {
      margin-top: 75px;

      @include respond-min($bp-mobile-wide-min) {
        margin-top: 40px;
      }

      @include respond-min($bp-tablet-min) {
        margin-top: 20px;
      }
    }

    .no-checkbox:after {
      content: '';
      width: 0;
      height: 0;
    }

    .no-checkbox:before {
      content: '';
      width: 0;
      height: 0;
      border: 0;
      background-color: none;
    }

    fieldset.field.street,
    fieldset.field.street:nth-child(5) {
      width: 48%;
      margin-right: 0;
    }

    div.field[name="shippingAddress.region"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin-right: 4% !important;
      }
    }

    div.field[name="shippingAddress.city"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        margin-right: 0% !important;
      }
    }

    .field.street.required {
      width: 100%;
    }

    div.field[name="shippingAddress.street.0"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }

    }

    div.field[name="shippingAddress.street.1"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
      }

      @include respond-min($bp-tablet-wide-min) {
        margin-left: 0px !important;
      }
    }
    div.field[name="shippingAddress.postcode"] {
      display: none;
    }

    div.field[name="shippingAddress.shippingAddressLookupButtonReset"] {
      display: none;
    }

    div.field[name='shippingAddress.shippingAddressLookupButton'] span:first-of-type {
      border-bottom: 1px solid;
    }

    div.field[name='shippingAddress.shippingAddressLookupButton']:hover span:first-of-type {
      border-bottom: none;
      cursor: pointer;
    }

    div.field[name='shippingAddress.shippingAddressLookupButtonReset'] span:first-of-type {
      border-bottom: 1px solid;
    }

    div.field[name='shippingAddress.shippingAddressLookupButtonReset']:hover span:first-of-type {
      border-bottom: none;
      cursor: pointer;
    }

    div.field[name="shippingAddress.shippingAddressLookupInput"] {
      width: 100% !important;
    }

  }

  .checkout-billing-address {
      fieldset.fieldset {
          display: flex;
          flex-wrap: wrap;
      }

    div.field[name="billingAddresskcp.region"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin-right: 4% !important;
      }
    }

    div.field[name="billingAddresskcp.city"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        margin-right: 0% !important;
      }
    }

    .field.street.required {
      width: 100% !important;
    }

    div.field[name="billingAddresskcp.street.0"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
    }
    div.field[name="billingAddresskcp.street.1"] {
      display: none;
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
      }
    }

    div.field[name="billingAddresskcp.postcode"] {
      display: none;
    }

    div.field[name="billingAddress.billingAddressLookupButtonReset"] {
      display: none;
    }

    div.field[name='billingAddress.billingAddressLookupButtonReset'] span:first-of-type {
      border-bottom: 1px solid;
    }

    div.field[name='billingAddress.billingAddressLookupButtonReset']:hover span:first-of-type {
      border-bottom: none;
      cursor: pointer;
    }

    div.field[name='billingAddress.billingAddressLookupButton'] span:first-of-type {
      border-bottom: 1px solid;
    }

    div.field[name='billingAddress.billingAddressLookupButton']:hover span:first-of-type {
      cursor: pointer;
      border-bottom: none;
    }

    div.field[name="billingAddress.billingAddressLookupInput"] {
      width: 100% !important;
    }
  }
}

// #### TR SPECIFIC STYLES/OVERRIDES
.dyson-tr {
   #checkout-payment-method-load {
     @include respond-min ($bp-desktop-min) {
       margin-top: 55%;
     }
   }

  .payment-group .step-title {
    margin-top: 10%;

    @include respond-min($bp-tablet-min) {
      margin-top: 45%;
    }
  }

  label[for="radio-individual"],
  label[for="radio-business"] {
    font-size: 20px;
    font-family: $f-futura;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

  }

  .field[name="shippingAddress.postcode"] {

    @include respond-min($bp-tablet-min) {
      margin-right: 4%;
    }
  }

  .field[name="billingAddressadyen_cc.tax_id_number"],
  .field[name="billingAddressadyen_cc.tax_office"] {
    @include respond-min($bp-tablet-wide-min) {
      width: 100% !important;
      float: right !important;
      clear: right !important;
    }

    @include respond-min($bp-desktop-min) {
      width: 48% !important;
    }
  }

  .fieldset .field[name="billingAddressadyen_cc.invoice_radio"]:nth-of-type(1) {
    @include respond-min($bp-desktop-min) {
      float: left !important;
      clear: left !important;
      width: 48% !important;
      display:inline-block !important;
    }
  }

  .fieldset .field[name="billingAddressadyen_cc.invoice_radio"]:nth-of-type(3) {
    @include respond-min($bp-desktop-min) {
      position: absolute !important;
      left: 52% !important;
    }
  }

  .field[name="billingAddressadyen_cc.commercial_title"] {
    @include respond-min($bp-desktop-min) {
      width: 48% !important;
      float: right !important;
      display: inline-block !important;
      margin-left: 20px !important;
      clear: right !important;
    }
  }

  .field[name="billingAddressadyen_cc.citizen_id"] {
    @include respond-min($bp-desktop-min) {
      width: 48% !important;
      float: left !important;
      clear: left !important;
    }
  }

  #checkout-payment-method-load .adyen-cc-form.form {
    border: 0;
  }

  .adyen-checkout__card__exp-cvc {
    display: block;
  }

  .checkout__row .opc-wrapper .opc .form.payments#co-payment-form {
    padding: 20px;

    .payment-method {
      margin: 20px 0;
    }
  }


  .checkout__row .opc-wrapper #shipping-new-address-form {

    .field.street.admin__control-fields.required {
      legend.label {
        margin-bottom: -34px;

        @include respond-min($bp-tablet-min) {
          margin-bottom: -20px;
        }
      }
    }

    div.field[name="shippingAddress.telephone"] {
      clear: left;
      @include respond-min($bp-tablet-min) {
        // width: 48%;
      }

      @include respond-min(960px) {
        width: 100% ;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;

      }
    }

    div.field[name="shippingAddress.postcode"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
      }

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }
    }

    div.field[name="shippingAddress.city"] {

        @include respond-min($bp-tablet-min) {
          margin-right: 0;
          margin-left: 0;
          margin-top: 20px;
          width: 48%;
        }
        @include respond-min(960px) {
          width: 100%;
          margin-left: 0;
        }
        @include respond-min($bp-desktop-min) {
          width: 48%;
          margin-top: 20px;
        }

    }

    div.field.field--checkboxes {
      &[name="shippingAddress.custom_attributes.activate_guarantee"] {
        @include respond-min($bp-desktop-min) {
          width: 100%;
        }
      }
    }

    div.field[name="shippingAddress.region"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
        width: 48%;
        margin-top: 20px;
      }

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }
    }

    div.field[name="shippingAddress.country_id"] {
      @include respond-min($bp-tablet-min) {
        margin-top: 0;
        margin-right: 0;
      }
      @include respond-min($bp-desktop-min){
        width: 47%;
      }
    }

    div.field[name="shippingAddress.street.0"] {
      margin-top: 20px;
      width: 100%;

      @include respond-min($bp-tablet-min) {
        margin-top: 20px;
      }

      @include respond-min($bp-desktop-min) {
        width: 100%;
        margin-left: 0;
      }
    }

    div.field[name="shippingAddress.street.1"] {
      width: 100%;

      @include respond-min($bp-tablet-min) {
        margin-top: 0;
      }
      @include respond-min($bp-desktop-wide-min) {
        margin-top: 20px;
        margin-left: 0px;
      }
    }

    div.field[name="shippingAddress.street.2"] {
      margin: 0;

      label.label:after {
        content: '';
      }

      @include respond-min($bp-tablet-min) {
        width: 100%;
      }

      @include respond-min($bp-desktop-wide-min) {
        margin-top: 0;
        margin-left: 0;
      }

    }

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem ;
      }
    }

  }

  .payment-method-billing-address{

    div.field[name="billingAddressadyen_cc.postcode"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
      @include respond-min($bp-desktop-min) {
        margin-right: 4% !important;
        margin-top: 0 !important;
      }
    }

    div.field[name="billingAddressadyen_cc.city"] {
      @include respond-min($bp-tablet-min) {
        margin: 0;
        width: 48% !important;
      }

      @include respond-min($bp-desktop-min) {
        float: left;
        clear: left;
        margin-top: 30px;
      }
    }

    div.field[name="billingAddressadyen_cc.country_id"] {
      @include respond-min($bp-tablet-min) {
        margin: 0 !important;
      }

      @include respond-min(960px) {
        width: 100%;
        margin-top: 30px;
        margin-left: 0px;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }
    }

    div.field[name="billingAddresscheckmo.country_id"] {
      @include respond-min($bp-tablet-min){
        margin: 0 0 0 4px;
      }

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }
    }

    div.field[name="billingAddresscheckmo.telephone"] {
      @include respond-min($bp-tablet-min){
        float: left;
        clear: left;
      }

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }

    }

    fieldset.field.street.admin__control-fields.required {
      @include respond-min($bp-tablet-wide-min) {
        width: 100%;
      }

      div.field.additional {
        @include respond-min($bp-desktop-min) {
          //display: inline-block;
          //width: 48%;
          //margin-top: 20px;
        }
      }
    }

    div.field[name="billingAddressadyen_cc.street.0"] {
      @include respond-min($bp-tablet-min) {
        width: 100% !important;
      }

      label.label {
        margin-bottom: 30px;
      }
    }


    div.field[name="billingAddressadyen_cc.street.1"] {

      @include respond-min($bp-tablet-min) {
        margin-left: 0;
        margin-top: 0 !important;
        width: 100% !important;
      }

      @include respond-min($bp-desktop-min) {
        margin-left: 0;
      }
    }

    div.field[name="billingAddressadyen_cc.telephone"] {
     @include respond-min ($bp-tablet-min) {
       width: 48%;
       clear: left;
       float: left;
     }
    }

    div.field[name="billingAddressadyen_cc.street.2"] {
      margin-top: 0;
      margin-left: 0;
      width: 100% !important;
      margin-bottom: 0 !important;

      label.label:after {
        content: '';
      }

      @include respond-min($bp-desktop-min) {
        margin-top: 0;
      }

      @include respond-min($bp-desktop-wide-min) {
        margin-top: 20px;
      }
    }

    div.field[name="billingAddressadyen_cc.region"] {
      @include respond-min($bp-tablet-min){
        margin-left: 0;
        width: 48% !important;
        margin-top: 0;
        margin-right: 4%;
      }
      @include respond-min($bp-desktop-min) {

      }
    }

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem ;
      }
    }
  }


  button.action.action-update,
  button.action.action-cancel {
    width: 55%;

    div.field[name="shippingAddress.street.2"] {

      label.label:after {
        content: '';
      }

      margin-left: 0;

      @include respond-min($bp-desktop-min) {
        width: 100%;
      }

      @include respond-min($bp-desktop-wide-min) {
        margin-top: 30px;
        margin-left: 0px;
      }

    }

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem ;
      }
    }

  }

  .payment-method-billing-address{

    div.field[name="billingAddressadyen_cc.postcode"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin-right: 4% !important;
      }

      @include respond-min($bp-desktop-min) {
        margin-top: 30px;
      }

      @include respond-min($bp-desktop-wide-min) {
        width: 48%;
        margin-right: 0;
      }
    }

    div.field[name="billingAddressadyen_cc.city"] {
      @include respond-min($bp-tablet-min) {
        margin: 0;
      }

      @include respond-min($bp-desktop-min) {
        width: 48%;
        float: none;
        margin-top: 0;
        display: inline-block !important;
      }

      @include respond-min($bp-desktop-wide-min) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    div.field[name="billingAddressadyen_cc.country_id"] {
      @include respond-min($bp-tablet-min) {
        margin: 0 0 0 5px;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }

      @include respond-min($bp-desktop-wide-min) {
        display: inline-block;
        margin-right: 0 !important;
      }
    }

    div.field[name="billingAddresscheckmo.country_id"] {
      @include respond-min($bp-tablet-min){
        margin: 0 0 0 4px;
      }

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }
    }

    div.field[name="billingAddresscheckmo.telephone"] {
      @include respond-min($bp-tablet-min){
        float: left;
        clear: left;
      }

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min){
        width: 48%;
      }

    }

    fieldset.field.street.admin__control-fields.required {
      @include respond-min($bp-tablet-wide-min) {
        width: 100%;
      }

      div.field.additional {

      }
    }
    div.field[name="billingAddressadyen_cc.street.2"] {

      label.label:after {
        content: '';
      }

      margin-left: 0;

      @include respond-min(960px) {
        width: 100%;
      }

      @include respond-min($bp-desktop-min) {
        margin-top: 0;
      }

      @include respond-min(1280px) {
        width: 100%
      }
    }

    div.field[name="billingAddresscheckmo.postcode"] {
      @include respond-min($bp-desktop-min) {
        width: 48%;
        margin-right: 0;
        margin-top: 30px;
      }
    }

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem ;
      }
    }

  }


  button.action.action-update,
  button.action.action-cancel {
    width: 55%;

    @include respond-min($bp-tablet-min){
      width: 40%;
    }

    @include respond-min($bp-desktop-min) {
      width: 36%;
    }

    @include respond-min($bp-desktop-wide-min) {
      width: 25%;
    }
  }

  .adyen-cc-form {
    border: none;
    padding-left: 0;
    margin-top: 25px;
  }

  .checkout-payment-method .payment-method-title label div.adyen-sprite.adyen_cc {
    display:none;
  }

  ul.credit-card-types {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

  }

  ul.credit-card-types {
    li {
      display: none;
    }
    &:after {
      background: url('../images/brand/accepted-payment-tr.png') no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      width: 150px;
      height: 30px;
    }
  }

  .tr-payment-form {
    display: flex;

    #adyen_cc_cc_type_exp_div {
      margin-left: 0;
    }

    .field.no-label.month {
      width: 49.25%;
      display: inline-block;
    }

    .field.no-label.year {
      width: 49.25%;
      display: inline-block;
    }

    #creditCardHolderName {
      @include respond-min($bp-desktop-min){
        width: 48%;
      }

    }

    .actions-toolbar {
      margin-top: 20px;
    }

  }

  .payment-tr-adyen {

    #adyen-cc-form {
      border: 0;
    }

    .checkout__row .opc-wrapper .opc .form.payments#co-payment-form .payment-method:not(:last-child) {
      border-bottom: 0;
    }

  }

  .adyen-checkout__field{
    width: 100%;
  }
  //hiding the radio button for adyen
  #adyen_cc {
    display: none;
  }

  .dsc-preinformation,
  .dsc-form {
    border: 1px solid #ebebeb;
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 150px;

    @include respond-min($bp-tablet-wide-min) {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }

  div.payment-method p {
    margin-bottom: 0.25em;
  }

  .dsc-tcs-checkbox {
    font-size: 14px;
    margin: 10px 0;
  }

  .adyen-cc-form {
    border: none;
    padding-left: 0;
    margin-top: 25px;
  }

  .checkout-payment-method .payment-method-title label div.adyen-sprite.adyen_cc {
    display:none;
  }

  ul.credit-card-types {
    list-style-type: none;
    margin: 0 0 15px 0;
    padding: 0;
    display: flex;
  }

  ul.credit-card-types {
    margin-bottom: 24px;

    li {
      display: none;
    }
    &:after {
      background: url('../images/brand/accepted-payment-tr.png') no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      width: 150px;
      height: 30px;
    }
  }

  #adyen-cc-form {
    padding: 0;

    .adyen-checkout__label {
      .adyen-checkout__label__text {
        font-size: 16px;
        margin-bottom: 4px;
        color: $clr-dy-grey-7;
      }
    }

    .adyen-checkout__input {
      color: $clr-dy-grey-7;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      margin: 0;
      height: 4rem;
      border: 1px solid #ebebeb;
      border-radius: 0;
      padding: 1rem;
      width: 100%;
      background-color: #fff;
      -moz-appearance: none;
      -webkit-appearance: none;
    }

    .adyen-checkout__field {
      margin-bottom: 24px;
    }

    .adyen-checkout__card__cardNumber__input {
      img {
        margin-right: 20px;
      }
    }

    .adyen-checkout__card__exp-cvc {
      .adyen-checkout__field {
        width: 100%;
        margin-right: 0;

        @include respond-min($bp-tablet-min) {
          width: 48%;
          display: inline-block;
        }

        &:first-child {
          margin-right: 4%;
        }
      }
    }

    .adyen-checkout__input:active,
    .adyen-checkout__input--active,
    .adyen-checkout__input:focus {
      border: 1px solid #ebebeb;
      box-shadow: none;
    }
  }
}

// #### AE SPECIFIC STYLES/OVERRIDES
.dyson-ae {

  #shipping-new-address-form{

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem ;
      }
    }

    div.field[name="shippingAddress.street.0"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }
    div.field[name="shippingAddress.street.1"] {
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
        margin-top: 0px !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }

  }

  .checkout__row {
    .opc-wrapper .opc .form {
      [name="shippingAddress.street.1"] label,
      [name="shippingAddress.postcode"] label,
      [name="billingAddresscheckmo.street.1"] label,
      [name="billingAddresscheckmo.street.0"] label {
        &:after {
          content: ''!important;
          display: none!important;
        }
      }

      [name="shippingAddress.telephone"] {
        clear: both;
      }

      [name="billingAddresscheckmo.street.1"] {
        margin-top: 0!important;
      }
    }
  }
}

// #### IL SPECIFIC STYLES/OVERRIDES
.dyson-il {

  #shipping-new-address-form{

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem  !important;
      }
    }

    div.field[name="shippingAddress.street.0"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }
    div.field[name="shippingAddress.street.1"] {
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
        margin-top: 0px !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }
    div.field[name="shippingAddress.telephone"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
      }
    }
    div.field[name="shippingAddress.custom_attributes.identity_number"] {
      @include respond-min($bp-tablet-min) {
        width: 100%;
      }
    }
    div.field[name="shippingAddress.custom_attributes.bnzc_email_alerts"] {
      @include respond-min($bp-tablet-min) {
        width: 100% !important;
      }
    }

  }

  .checkout__row {
    .opc-wrapper .opc .form {
      [name="shippingAddress.postcode"] label,
      [name="shippingAddress.street.1"] label,
      [name="billingAddressfree.street.0"] label,
      [name="billingAddressfree.street.1"] label {
        &:after {
          content: ''!important;
          display: none!important;
        }
      }

      [name="shippingAddress.telephone"] {
        clear: both;
      }

      [name="billingAddressfree.street.1"] {
        margin-top: 0!important;
      }
    }
  }
}

// #### SA SPECIFIC STYLES/OVERRIDES
.dyson-sa {

  #shipping-new-address-form{

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem ;
      }
    }

    div.field[name="shippingAddress.street.0"] {
      label.label:after {
        content: '';
        display:none;
      }
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
    }
    div.field[name="shippingAddress.street.1"] {
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
        margin-top: 0px !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }

    div.field[name="shippingAddress.telephone"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
        clear: none;
      }
    }
  }

  .checkout__row {
    .opc-wrapper .opc .form {
      [name="shippingAddress.street.1"] label,
      [name="shippingAddress.postcode"] label,
      [name="billingAddresscheckmo.street.1"] label,
      [name="billingAddresscheckmo.street.0"] label {
        &:after {
          content: ''!important;
          display: none!important;
        }
      }

      [name="shippingAddress.telephone"] {
        clear: both;
      }

      [name="billingAddresscheckmo.street.1"] {
        margin-top: 0!important;
      }
    }
  }
}

// #### SG SPECIFIC STYLES/OVERRIDES
.dyson-sg {

  #worldpay_cc-form, #worldpay_cc-savedcard-form{
    border: 0;
  }

  #shipping-new-address-form {

    div.field[name="shippingAddress.custom_attributes.newsletter_signup"] {
      width:100% !important;

    }

    fieldset.field.street {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0.0rem  !important;
      }
    }

    div.field[name="shippingAddress.street.0"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4% !important;
        width: 48% !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }
    div.field[name="shippingAddress.street.1"] {
      @include respond-min($bp-tablet-min) {
        width: 48% !important;
        margin-top: 0px !important;
      }
      &._required {
        label:after {
          content: '';
          display:none;
        }
      }
    }
    div.field[name="shippingAddress.telephone"] {
      @include respond-min($bp-tablet-min) {
        margin-right: 4%;
      }
    }
  }

  .checkout__row {
    .opc-wrapper .opc .form {
      [name="shippingAddress.street.1"] label,
      [name="billingAddressfree.street.0"] label,
      [name="billingAddressfree.street.1"] label {
        &:after {
          content: ''!important;
          display: none!important;
        }
      }

      [name="shippingAddress.telephone"] {
        clear: both;
      }

      [name="billingAddressfree.street.1"] {
        margin-top: 0px !important;
      }
    }
  }

  .form__title {
    @include fs-responsive((g:16px, gt:18px, gtw:20px));
    font-weight: 400;
    color: $clr-dy-grey-7;
    padding-top: 25px;
  }

  .custom_checkout_field--divider_line_top {
    border-top: 1px solid $clr-divider-line;
  }

  .label {
    color: $clr-dy-grey-7;
  }
}

// Custom style tweaks for MY post-V5 migration
.dyson-my {

  // Customises shipping address field styling: removes asterisks from fields with optional validation; hides unncessary street address label

  #shipping-new-address-form {

    .field.street.admin__control-fields > legend {
      visibility: hidden;
      height: 0;
    }

    div.field[name="shippingAddress.street.1"] {
      margin-top: 0 !important;

      :after {
        visibility: hidden;
      }
    }

    div.field[name="shippingAddress.postcode"] {
      margin-right: 4% !important;
    }

    div.field[name="shippingAddress.region"] {
      @include respond-min($bp-tablet-min) {
        width: 48%;
      }
    }
  }

  // Customises billing address field styling
  #billing-new-address-form {

    .field.street.admin__control-fields > legend {
      visibility: hidden;
      height: 0;
    }

    div.field {
      &[name="billingAddressdfe_ipay88.street.1"] {
        @include respond-min($bp-tablet-min) {
          margin-top: 0;
        }

        :after {
          visibility: hidden;
          height: 0;
        }
      }

      &[name="billingAddressdfe_ipay88.region_id"] {
        @include respond-min($bp-tablet-min) {
          width: 48%;
        }
      }

      &[name="billingAddressdfe_ipay88.postcode"] {
        @include respond-min($bp-tablet-wide-min) {
          margin-right: 4%;
        }
      }
    }
  }

  // Styling tweaks for iPay88 section on 2 step checkout

  .payment-method.df-payment-method.dfe_ipay88 {

    max-width: 100em !important;

    .form {
      border: 0 !important;
      padding: 1rem 0 2rem 0 !important;
    }

    // Fixes MY iPay88 payment method image styling: hides overlapping text labels; shrinks payment method logos; stops column overlap in desktop view
    .df-options.df-level-1 {
      column-count: 1;
    }

    .df-options {
      div.df-choice {
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }

      span {
        visibility: hidden;
        height: 0;
      }
    }

    .df-option {
      @include respond-min($bp-phablet-min) {
      //@include respond-min($bp-tablet-wide-min) {
        display: inline-block;
        width: 48%;
      }
    }

    // Fixes button styles on address section
    .actions-toolbar {
      margin-bottom: 2%;

      .primary {
        margin: 1em 3em 0 0 !important;

        @include respond-min(375px) {
          margin: 1em 0.5em 0 0 !important;
        }

        @include respond-min($bp-mobile-min) {
          margin: 1em 1.5rem 0 0 !important;
        }

        button {
          @include respond-min($bp-tablet-wide-min) {
            padding: 17px 60px !important;
          }
        }
      }
    }

    .action-update {
      margin: 0 0 4% 0;
      padding: 17px 60px !important;

      @include respond-min($bp-tablet-wide-min) {
        margin: 0 1% 0 0;
      }

      @include respond-min($bp-desktop-wide-min) {
        margin: 0;
      }
    }

    .action-cancel {
      padding: 17px 62px !important;
    }

    .payment-method-content {

      .checkout-agreements-block {
        margin-top: 1.5em;
      }
    }

    h4.df-please-select-an-option {
      margin-top: 1rem;
      font-size: 1.2rem !important;

      @include respond-min($bp-tablet-wide-min) {
        font-size: 1.6rem !important;
      }
    }
  } // End of iPay88 specific styles
} // End of custom MY styles


// Custom style tweaks for TH post-V5 migration
.dyson-th {
  //un-hiding and styling tooltips
  .checkout__row .opc-wrapper .opc .form .field-tooltip.toggle {
    display: block!important;
    font-family: $f-futura;
    color: $clr-dy-grey-7;
    div {
      padding-top: .25rem;
    }
  }
  //layout for shipping & billing address on 2 step checkout
  .checkout__row .opc-wrapper .opc .fieldset, .checkout__row .opc-wrapper .opc #shipping-new-address-form {
    //hiding legend as label is used instead
    .field.street {
      legend {
        display: none;
      }
    }
    .field[name="shippingAddress.street.1"], .field[name="billingAddressP2c2pPayment.street.1"] {
      margin: 0 !important;
    }
    .field[name="shippingAddress.street.0"], .field[name="billingAddressP2c2pPayment.street.0"] {
      @include respond-min($bp-tablet-min) {
        margin-bottom: 0;
      }
    }
    .field[name="shippingAddress.postcode"], .field[name="billingAddressP2c2pPayment.postcode"] {
      .message.warning {
        display: none;
      }
      @include respond-min($bp-tablet-min) {
        width: 48%;
        margin: 0;
      }
    }
  }
} // End of custom TH styles


//Pre-Order css for all LEAP sites

.product-item {
  position: relative;
}

.preorder_note {
  position: absolute;
  font-size: 12px;
  left: 27%;
  bottom: 67px;
  background: #fbfbfb;
}


//dsc iframe on checkout
.dyson-tr .dsc-preinformation {
  html.chrome.no-focus-outline,
  html.firefox.no-focus-outline,
  html.ie.no-focus-outline {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
  iframe {
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
  }
}

//afterpay installment
.dyson-nz {
  //cart page
  .cart-summary .after-pay-installment-msg {
    padding: 24px 0 0;
    @include respond-max($bp-tablet-wide-min) { //max 1024px;
      padding: 20px 0 0;
    }
    @include respond-max($bp-phablet-wide-min) { //max 640px
      padding: 2px 0 12px;
    }
  }
  //checkout page
  .after-pay__checkout__container {
    font-family: $f-futura;
    .after-pay__checkout__container__image {
        margin-bottom: 24px;
        margin-top: 16px;
        max-width: 136px;
        @include respond-max($bp-tablet-wide-min) { //max 1024px;
          margin-bottom: 20px;
        }
        @include respond-max($bp-phablet-wide-min) {
          margin-top: 8px;
          margin-bottom: 8px;
          max-width: 87px;
        }
    }
    .after-pay__checkout__container__message {
         color: #555;
     }
    .after-pay__learn__more {
        text-decoration: underline;
        color: #333;
        cursor: pointer;
    }
    .after-pay__info {
        padding: 20px;
        background: #f3f3f3;
        margin-top: 24px;
        margin-bottom: 30px;
        color: #333;
        @include respond-min($bp-tablet-min) {
           width: 68.5%;
        }
        @include respond-max($bp-tablet-wide-min) { //max 1024px;
          margin-top: 20px;
          margin-top: 12px;
        }
        @include respond-max($bp-tablet-min) {
          margin-top: 16px;
          margin-bottom: 26px;
          padding: 1em;
        }
    }
  }
}
