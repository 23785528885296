
body.rtl {
  .form__label--inner {
    margin: 0 0 0 1rem;
  }

  .forms-inline > div {
    padding-right: 0rem;
    padding-left: 1rem;
  }
  .block__newsletter__container {
    label {
      &:before, &:after {
        left: 100px;

        @include respond-max($bp-tablet-min) {
          left: 95px;
        }
      }
    }
    .block__newsletter__container__content-flex__inner__text {
      margin-right: 0px;
      margin-left: 20px;
    }
    .marketing-preferences-icon-circle {
      margin-right: 0rem;
      margin-left: .5rem;
    }
  }

  // success page styling
  .hero.product-registration__success {
    .hero__media .image {
      @include respond-min($bp-tablet-wide-min) {
        margin-right: 50%;
      }
    }
  }

  .title-tick {
    &:after {
      @include respond-min($bp-tablet-min) {
        left: 0px;
        right: 205px;
      }
    }
    &:before {
      @include respond-min($bp-tablet-min) {
        left: 0px;
        right: 220px;
      }
    }
  }
}