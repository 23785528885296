/*
  GRID SETUP
  Uses proportional-grids.scss
*/

$grid-namespace: "g";
$grid-gutter: 20px;
$grid-gutter-large: 30px;

// Column class/fraction generator
// @@TIP: default g-col (mobile and up) can be defined as just g-col. Use 1-1 fraction when smaller
// breakpoints had columns and you need to go full width on a larger viewport
// e.g. .g-col .gt-col-1-2 .gtw-col-1-1
// if you start a new row and it's not a crazy edge case e.g. 19/20, finish it with all useful, usable fractions for future users
$grid-columns:  "0-1"  0,  // to reset offsets mainly
                "1-2"  1/2, // class, fraction
                "1-3"  1/3,  "2-3"  2/3,
                "1-4"  1/4,  "3-4"  3/4,
                "1-5"  1/5,  "2-5"  2/5,  "3-5"  3/5,  "4-5"  4/5,
                "1-6"  1/6,  "3-6"  3/6,  "5-6"  5/6,
                "1-8"  1/8,  "3-8"  3/8,  "5-8"  5/8,  "7-8"  7/8,
                "1-1"  1;

@include grids-init(); // default/mobile
@include grids-init("gm",$grid-gutter,$bp-mobile-min);
@include grids-init("gmw",$grid-gutter,$bp-mobile-wide-min);
@include grids-init("gp",$grid-gutter,$bp-phablet-min);
@include grids-init("gpw",$grid-gutter,$bp-phablet-wide-min);
@include grids-init("gt",$grid-gutter,$bp-tablet-min);
@include grids-init("gtw",$grid-gutter-large,$bp-tablet-wide-min);
@include grids-init("gd",$grid-gutter-large,$bp-desktop-min);
