/*
  FORMS
*/

// FORM ITEMS

.form{

}

.form__item{
  //display: block;
  margin-bottom: 1.5rem;
  position: relative;

  .dyson-kr .page-layout-checkout & {
    display: none;
  }
}

.form__item--inline{
  display: flex;
  align-items: center;
  // justify-content: space-between;
}

.form__label{
  display: block;
  font-size: $fs-body;
  font-family: $f-fallback;
  line-height: 1.25rem;
  color: $clr-dy-grey-7;
  background-color: $clr-white;
  margin-bottom: 1rem;

  .form__item--inline &{
    margin-bottom: 0;
    padding-right: 1rem;
  }

  .notify & {
    > span > a {
      text-decoration: underline;
    }
  }
}

.form__label--inner{
  position: absolute;
  top: 50%;
  pointer-events: none;
  transform: translate3d(0,-50%,0);
  transition: transform .1s ease-in;
  margin: 0 0 0 .5rem;
  padding: 0 .5rem;

  .form__item--entered &{
    transform: translate3d(0,-210%,0);
  }
}

// FIELDS

input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="number"],
input[type="password"],
select[multiple],
textarea,
select,
.form__input
 {
  color: $clr-dy-grey-7;
  font-family: $f-fallback;
  font-size: $fs-body;
  margin: 0;
  height: 4rem;
  border: 1px solid $clr-dy-grey-2;
  border-radius: 0;
  padding: 1rem;
  width: 100%;
  background-color: $clr-white;
  -moz-appearance: none;
  -webkit-appearance: none;

  // transition: .15s box-shadow ease-in;
  // background-clip: padding-box;

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {

  }

  &::placeholder {
    color: $clr-dy-grey-7;
  }

  .ios & {
    // @include fs-static(16);
  }
}

textarea {
  resize: vertical;
  height: inherit;
}

select[multiple] {
  background-image: none;
  option {

  }
}

// DROPDOWNS

select {
  position: relative;
  color: $clr-dy-grey-7;
  background-image: url('../images/icons/sprite.png');
  background-position: 0 -1942px;
  background-position-x: right;
  background-repeat: no-repeat;
}

select::-ms-expand {
  display: none;
}

// BOOLEANS (RADIO & CHECKBOX)

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  font-family: $f-fallback;
  margin-right: 0.5rem;

  &:last-child{
    margin-right: 0;
  }
}

.form__item--radio {
  text-align: left;
}

// LARGE CHECKBOX

.form__item--checkbox {
  input[type="checkbox"]{
    position: absolute;
    height: 2rem;
    width: 2rem;
    z-index: 1;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0;

    &:checked ~ .form__label {
      &:after{
        opacity: 1;
      }
    }
  }

  .form__label{
    cursor: pointer;
    margin-right: 1rem;
    padding-left: 48px;
    line-height: 1.5;
    position: relative;
    font-weight: normal;
    -moz-user-select: none;
    -webkit-user-select: none;

    &:before,
    &:after{
      content: '';
      height: 32px;
      width: 32px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all .15s ease-in;
      box-sizing: border-box;
    }

    &:before{
      border: 1px solid $clr-dy-grey-2;
      background-color: $clr-white;
    }

    &:after{
      background-image: url('../images/icons/sprite.png');
      background-repeat: no-repeat;
      background-position: 0 -1856px;
      opacity: 0;
    }
  }
}

// LABELS

label {

}

// FORM LAYOUT

form {

}

fieldset {

}

// FORM VALIDATION

.form__validation{
  @include fs-responsive((g:12px, gt:$fs-body-smaller));
  margin-bottom: 1rem;
  font-family: $f-fallback;
}

.form__item--validation{
  .form__validation{
    color: $clr-dy-red-1;

    &:before{
      content: '';
      float: left;
      margin-right: 0.5rem;
      background-image: url('../images/icons/sprite.png');
      background-position: 0 -440px;
      width: 24px;
      height: 24px;
      transform: scale(0.75) translateY(-4px);
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  select[multiple],
  textarea {
    border-color: $clr-dy-red-1;
  }
}

//Magento ui was forcing a background: inherit which was breaking this select styling
select.mage-error {
  background-image: url('../images/icons/sprite.png');
  background-position: 0 -1942px;
  background-position-x: right;
  background-repeat: no-repeat;
}



// Dyson design styles
input.mage-error,
input[aria-invalid="true"],
._error select {
  border: 1px solid #dd4157;
}

div.mage-error,
div.field-error {
  // margin-top: 15px;
  font-size: 14px;
  color: #dd4157;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    background: url('../images/icons/alert.png') no-repeat;
    background-size: contain;
    margin-bottom: 3px;
    margin-right: 8px;
  }
}

div.mage-error + .form__label--inner {
  top: -9px;
  transform: none;
}

.review-control-vote {
  display: flex;

  > div {
    order: 10;
    margin-top: 40px;
  }
}

.review-field-age,
.review-field-nickname {
  margin-bottom: 40px;
}

.review-field-age {
  margin-top: 40px;
  @include respond-min($bp-tablet-min) {
    margin-top: 0px;
  }
}

input.mage-error, select.mage-error, textarea.mage-error {
  // background: inherit;
  border-color: #ed8380;
  color: #000;
}

// .form__item--registered,
// .form__item--recommend {
//   display: flex;
// }

.form__item--recommend--radio,
.form__item--registered--radio {
  display: flex;

  > div {
    order: 3;
  }

  label {
    margin-right: 25px;
    font-size: 14px;
  }
}

.form__item--checkbox--mandatory {
  display: flex;

  > div {
    order: 1;
  }

}

// .form__item--radio  > div {
//   order: 1;
// }


//NZ only Adyen

.dyson-nz {

  .fields.group.group-2 {
    display: flex;
  }

  .payment-method-content form.form#adyen-cc-form {

    @include respond-min($bp-tablet-min) {
      width: 75%;
    }
    @include respond-min($bp-desktop-min) {
      width: 50%;
    }
  }

  .field.no-label.year, .field.no-label.month, input#adyen_cc_cc_cid.input-text.cvv {
    width: 50%;
  }

  .field.no-label.month {
    margin-right: 5px;
  }

  input#adyen_cc_cc_cid.input-text.cvv::-webkit-input-placeholder::before {
    content: "***";
  }

  .checkout-agreements {
    margin-top: 10px;
  }

  form#adyen-cc-form {
    border: none;
    padding: 0px;
  }

  label.label[for="adyen_cc_cc_type"] {
    &:after {
      display: none!important;
    }
  }
}

// Options Picker

.options-picker__list {
  cursor: pointer;
  background-color: $clr-dy-grey-05;
  border: 1px solid $clr-dy-grey-2;
  border-top: 0;
  padding: 0.5rem 0;
  max-height: 11.25rem;
  overflow: auto;
  margin-bottom: 1.375rem;
  max-height: 12.75rem;

  &.options-picker__list--show {
    display: inherit;
  }

  > div {
    font-family: $f-futura;
    color: $clr-dy-grey-7;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

    &.options-picker__list--focus {
      background-color: #ececec;
    }

    > b {
      color: $clr-dy-grey-7;
      font-weight: normal;
    }
  }

  mark {
    background: none;
    color: $clr-dy-grey-7;
  }
}

.dyson-kr {
  #address-lookup-error {
    margin-top: 0.5em;
  }

  div[name="shippingAddress.shippingAddressLookupButton"],
  div[name="shippingAddress.shippingAddressLookupButtonReset"]{
    display: block;
    width: 100%!important;
  }

  fieldset.field.street {
    margin-bottom: 0!important;
  }
}
// Hides 'honeypots' to catch spam bot form entries on Notify Me and Review forms - don't use visibility/opacity/display attributes as some bots know to avoid them
// input-field is hidden purely with css, text-field is hidden with dyson-honeypot.js, but css used as back up
.form__item__input-field, .form__item__text-field {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}
