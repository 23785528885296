.callout {
  background: $clr-dy-grey-05;
  border: 1px solid $clr-dy-grey-3;
  position: relative;
  padding: 25px 20px 30px;
  margin-bottom: 32px;

  @include respond-min($bp-tablet-min) {
    padding: 45px 30px 50px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 32px 32px 0 0;
    border-color: $clr-dy-grey-3 transparent transparent transparent;

    @include respond-min($bp-tablet-min) {
      left: 30px;
    }
  }

  &:after {
    border-color: $clr-dy-grey-05 transparent transparent transparent;
    border-width: 30px 30px 0 0;
    margin-left: 1px;
  }
}

.callout__stars {
  .reviews-actions {
    display: none;
  }
}

.callout__quote {
  @include blockquote-reset;
}

.callout__title {
  @include fs-responsive((g:20,gtw:28));
  margin-bottom: 0.35em;
  margin-top: 0.25em;
  font-family: $f-futura;
  font-weight: 400;
  color: $clr-dy-grey-7;

  .dyson-th.primary & {
    font-family: $f-futura-thai;
  }

  .dyson-hk.primary & {
    font-family: $f-futura-hant;
  }

  .dyson-ae.rtl &, .dyson-sa.rtl & {
    font-family: $f-futura-arabic;
  }

  .dyson-il.rtl & {
    font-family: $f-futura-hebrew;
  }

  .dyson-kr & {
    font-family: $f-noto-sans;
  }
}

.callout__name, cite.callout__name {
  @include fs-static(16);
  font-weight: 400;
}

.callout__text {
  margin-top: 1.5em;
}

.review__text {
  @include fs-responsive((g:14,gtw:16));
  font-weight: 400;
}

.review__text__read_more {
  @include fs-responsive((g:14,gtw:16));
  position: relative;
  top: 0.25em;
  font-weight: 400;
  a {
    text-decoration: underline;
  }
}

.review__text__more {
  display: none;
}
