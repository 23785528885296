// ------- DYSON-IL SPECIFIC -------

.dyson-il {

  &.rtl {

    .am-checkout {

      .fieldset {

        .field {

          &[name="shippingAddress.street.0"],
          &[name="shippingAddress.firstname"],
          &[name="shippingAddress.custom_attributes.mobile_number"] {

            @include respond-min($bp-mobile-wide-min) {
              margin-left: 4%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .am-checkout {

    .fieldset {

      &#customer-email-fieldset {
        width: 100%;

        @include respond-min($bp-mobile-wide-min) {
          width: 48%;

          .field {
            width: 100%;
          }
        }
      }

      .field {

        &[name="shippingAddress.street.0"],
        &[name="shippingAddress.street.1"] {
          label.label {
            display: none;
          }
        }

        &[name="shippingAddress.street.0"],
        &[name="shippingAddress.firstname"],
        &[name="shippingAddress.custom_attributes.mobile_number"] {
          @include respond-min($bp-mobile-wide-min) {
            margin-right: 4%;
          }
        }

        &[name="shippingAddress.postcode"] {
          width: 100%;

          @include respond-min($bp-mobile-wide-min) {
            .control {
              width: 100%;
            }
          }
        }

        &[name="shippingAddress.lastname"],
        &[name="shippingAddress.firstname"],
        &[name="shippingAddress.custom_attributes.mobile_number"],
        &[name="shippingAddress.company"],
        &[name="shippingAddress.city"],
        &[name="shippingAddress.street.0"],
        &[name="shippingAddress.street.1"] {
          width: 100%;

          @include respond-min($bp-mobile-wide-min) {
            width: 48%;

            .control {
              width: 100%;
            }
          }
        }

        &.street {

          legend.label {
            display: inherit;
          }
        }
      }
    }

    & #shipping-new-address-form {
      
      div.field[name="shippingAddress.custom_attributes.identity_number"] {
        @include respond-min($bp-tablet-min) {
          width: 48%;
        }
      }
    }
  }
}
