$fs-promo-h2: (g:24px, gtw:28px);
$fs-promo-p:  (g:16px, gtw:20px);

.promos {
    .row--small {
        padding-top: 0;
        @include respond-min($bp-phablet-wide-min) {//min 640 tab
            padding-bottom: 28px !important;
        }
        @include respond-max($bp-phablet-wide-min) {//max 640 mobile
            padding-bottom:24px !important;
        }
        @include respond-min($bp-tablet-wide-min) {// 1024 desktop
            padding-bottom:32px !important;
        }
    }
}

.promos__title{
    //padding-top: 1em;
    padding-top:32px;
    margin:0;
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 28px;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-top:24px;
    }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:32px;
    }
    //margin-bottom: 1em;
}

.promos_content_padding{
    padding-top:32px !important;
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 28px !important;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-top:24px !important;
    }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:32px !important;
    }
}

.promos__list ul li.gt-col-1-2:nth-child(-n+2) .promos__item.nocontentandtitle, .promos__list ul li.gt-col-1-3:nth-child(-n+3) .promos__item.nocontentandtitle,
.promos__list ul li.gt-col-1-4:nth-child(-n+4) .promos__item.nocontentandtitle, .promos__list ul li.gt-col-1-5:nth-child(-n+5) .promos__item.nocontentandtitle,
.promos__list ul li.gt-col-1-6:nth-child(-n+6) .promos__item.nocontentandtitle, .promos__list ul li.gt-col-1-6:nth-child(-n+6) .promos__item.nocontentandtitle,
.promos__list ul li.gt-col-1-7:nth-child(-n+7) .promos__item.nocontentandtitle, .promos__list ul li.gt-col-1-8:nth-child(-n+8) .promos__item.nocontentandtitle,
.promos__list ul li.gt-col-1-9:nth-child(-n+1) .promos__item.nocontentandtitle, .promos__list ul li.gt-col-1-10:nth-child(-n+1) .promos__item.nocontentandtitle,
.promos__list ul li.gt-col-1-11:nth-child(-n+1) .promos__item.nocontentandtitle, .promos__list ul li.gt-col-1-12:nth-child(-n+1) .promos__item.nocontentandtitle{
    //padding-top:32px !important;
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 28px !important;
    }
    /* @include respond-max($bp-phablet-wide-min) {//max 640 mobile
       padding-top:24px !important;
     }*/
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:32px !important;

    }
}

.promos__content {
    font-family: $f-fallback;
    padding-top: 8px;
    margin: 0;
}

.promos__list ul{
    li{
        &:first-child{
            .promos__item{


                @include respond-min($bp-phablet-wide-min) {//min 640 tab
                    padding-top: 28px;
                }
                @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                    padding-top:24px;
                }
                @include respond-min($bp-tablet-wide-min) {// 1024 desktop
                    padding-top:32px;
                }

            }
        }
    }

}

.promos_title_content {

    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 16px !important;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-top:30px !important;
    }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:16px !important;
    }
}

.promos__list ul{
    li{
        &:first-child{
            .promos_title_content{
                @include respond-max($bp-phablet-wide-min) {//max 640 mobile
                    padding-top:12px !important;
                }
            }
        }
    }
}

.promos__list ul li .promos__item {
    //padding-top:30px !important;
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 45px !important;
    }
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-top:30px !important;
    }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:45px !important;
    }
}

.promos__list ul li.gt-col-1-2:nth-child(-n+2) .promos__item.promos_title_content, .promos__list ul li.gt-col-1-3:nth-child(-n+3) .promos__item.promos_title_content,
.promos__list ul li.gt-col-1-4:nth-child(-n+4) .promos__item.promos_title_content, .promos__list ul li.gt-col-1-5:nth-child(-n+5) .promos__item.promos_title_content,
.promos__list ul li.gt-col-1-6:nth-child(-n+6) .promos__item.promos_title_content, .promos__list ul li.gt-col-1-6:nth-child(-n+6) .promos__item.promos_title_content,
.promos__list ul li.gt-col-1-7:nth-child(-n+7) .promos__item.promos_title_content, .promos__list ul li.gt-col-1-8:nth-child(-n+8) .promos__item.promos_title_content,
.promos__list ul li.gt-col-1-9:nth-child(-n+1) .promos__item.promos_title_content, .promos__list ul li.gt-col-1-10:nth-child(-n+1) .promos__item.promos_title_content,
.promos__list ul li.gt-col-1-11:nth-child(-n+1) .promos__item.promos_title_content, .promos__list ul li.gt-col-1-12:nth-child(-n+1) .promos__item.promos_title_content  {
    //padding-top:30px !important;
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 16px !important;
    }
    //@include respond-max($bp-phablet-wide-min) {//max 640 mobile
    //  padding-top:12px !important;
    //}
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:16px !important;
    }
}

.promos__list ul li.gp-col-1-2:nth-child(-n+2) .promos__item.promos_title_content, .promos__list ul li.gp-col-1-2:nth-child(-n+1) .promos__item.promos_title_content{
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 16px !important;
    }
    // @include respond-max($bp-phablet-wide-min) {//max 640 mobile
    //   padding-top:12px !important;
    // }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:16px !important;
    }
}

.promos__list ul li.gp-col-1-2:nth-child(-n+2) .promos__item.nocontentandtitle, .promos__list ul li.gp-col-1-2:nth-child(-n+1) .promos__item.nocontentandtitle{
    @include respond-min($bp-phablet-wide-min) {//min 640 tab
        padding-top: 28px !important;
    }
    // @include respond-max($bp-phablet-wide-min) {//max 640 mobile
    //   padding-top:24px !important;
    // }
    @include respond-min($bp-tablet-wide-min) {// 1024 desktop
        padding-top:32px !important;

    }
}
.promos__list ul li.gp-col-1-2:nth-child(-n+1) .promos__item.nocontentandtitle{
    @include respond-max($bp-phablet-wide-min) {//max 640 mobile
        padding-top:24px !important;
    }
}

.promos__item {
    //@include vspace($v-tiny,$v-small,$v-small,$dir:top);
    @include respond-min($bp-phablet-wide-min) { //min 640 tab
        padding-top: 28px;
    }
    @include respond-max($bp-phablet-wide-min) { //max 640 mobile
        padding-top: 30px;
    }
    @include respond-min($bp-tablet-wide-min) { // 1024 desktop
        padding-top: 32px;
    }

    button > img,
    > img {
        display: block;
    }

    h2 {
        @include fs-responsive((g:14, gtw:16));
        margin: 0.9em 0 0 0;
        font-family: $f-fallback;
        font-weight: 700;
        color: $clr-dy-grey-7;
        line-height: 1.4;
    }

    p {
        @include fs-responsive((g:14, gtw:16));
        margin: 0 0 0.9em 0;
        color: $clr-dy-grey-7;
    }

    p:last-child {
        margin: 0;
    }

    button {
        position: relative;
        background: none;
        border: 0;
        padding: 0;
    }

    &:after {
        content: '';
    }

    &:before {
        content: '';
    }
}
.promos__item__url {
  @include link-reset();
}
.promos__item__media {
    img {
        // border: 1px solid $clr-dy-grey-2;
        max-width: 100%;

        .dyson-gv.category-technology &,
        .dyson-gv.category-features &,
        .dyson-gv.category-indoor-air-pollution &,
        .dyson-decgv.category-technology &,
        .dyson-decgv.category-features &,
        .dyson-decgv.category-indoor-air-pollution &,
        .dyson-in.category-the-problem &,
        .dyson-in.category-features &,
        .dyson-in.category-technology &,
        .dyson-in.category-indoor-air-pollution &,
        .dyson-kr.category-technology &,
        .dyson-kr.category-features &,
        .dyson-kr.category-indoor-air-pollution &,
        .dyson-tr.category-features &,
        .dyson-tr.category-technology &,
        .dyson-tr.category-indoor-air-pollution &,
        .dyson-nz.category-features &,
        .dyson-nz.category-technology {
            border: none;
        }
    }

    .promos__item__media--no-border {
        img {
            border: none;
        }
    }

    .promos__content {
        @include fs-static(16);

        @include respond-min($bp-tablet-wide-min) {
            @include fs-static(20);
            margin-top: 0;
        }
    }

    .promos--big {
        h2 {
            @include fs-responsive((g:24, gtw:28));
            font-family: $f-futura;
            font-weight: 300;
            margin-bottom: 0.5em;

            .dyson-th.primary & {
                font-family: $f-futura-thai;
            }

            .dyson-hk.primary & {
                font-family: $f-futura-hant;
            }

            .dyson-ae.rtl &, .dyson-sa.rtl & {
                font-family: $f-futura-arabic;
            }

            .dyson-il.rtl & {
                font-family: $f-futura-hebrew;
            }

            .dyson-kr & {
                font-family: $f-noto-sans;
            }
        }

        p {
            @include fs-responsive((g:16, gtw:20));
            color: $clr-dy-grey-6;
        }
    }
}
// subtle overrides for product features
.promos--product-promo {
    .promos__title {
        @include fs-responsive((g:28, gtw:32px));
    }

    .promos__item {
        h2, p {
            color: $clr-dy-grey-6;
        }

        .image__cta--roundal {
            top: 44%; // optical alignment
        }
    }
}
.promos--reverse {
    background: $clr-black;

    img {
        margin: auto;
    }

    p, h2 {
        color: $clr-white;
        text-align: center;
    }
}
.promos--white {
    .image {
        width: 100%;
    }

    img {
        margin: auto;
    }

    p, h2 {
        text-align: center;
    }
}
.promo-banner {
    background: $clr-black;
    padding: 20px 0 20px 0;
    color: $clr-white;
    text-align: center;
}
.promos__item__title {
    .dyson-in.category-the-problem & {
        color: #fff;
    }
}

.promos__item__header {

    @include respond-min($bp-tablet-min) {
        min-height: 80px;
    }

    @include respond-min($bp-desktop-min) {
        min-height: 10px;
    }
}

.promo__item__header.h1 {
    margin-bottom: 0px;

    @include respond-min($bp-tablet-min) {
        min-height: 50px;
    }
}

.dyson-kr {

  &.category-which-airwrap-for-you
  .promos__item {
    padding-top: 10px;

    ul {
      list-style-type: disc;
      padding-left: 20px;
    }
  }
}

//ROW-8746 Video play button for Partial width component START
.image__cta.image__cta--roundal.image__cta--square {
    left: 16px;
    bottom: 16px;
    top: auto;
    transform: unset;
    @include respond-max($bp-tablet-wide-min) { //Tab view
        left: 12px;
        bottom: 12px;
    }
    @include respond-max($bp-tablet-min - 1) { //Mobile view
        left: 8px;
        bottom: 8px;
    }
    .rtl & {
        left: auto;
        right: 16px;
        @include respond-max($bp-tablet-wide-min) { //Tab view
            left: auto;
            right: 12px;
        }
        @include respond-max($bp-tablet-min - 1) { //Mobile view
            left: auto;
            right: 8px;
        }
    }
    .partial_video__play-icon {
        display: flex;
        align-items: center;
        padding: 12px 15px;
        background: $clr-dy-grey-2;
        @include respond-max($bp-tablet-wide-min) { //Tab view
            padding: 8px 10px;
        }
        .video__play-icon {
            width: 15px;
            height: 20px;
            display: inline-block;
            fill: $clr-dy-grey-7;
            @include respond-max($bp-tablet-wide-min) { //Tab view
                width: 12px;
                height: 16px;
            }
        }
        .video_play-text {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1.71px;
            line-height: 16px;
            padding: 0 0 0 14px;
            color: $clr-dy-grey-7;
            display: inline-block;
            text-transform: uppercase;
            .rtl & {
                padding: 0 14px 0 0;
            }
            @include respond-max($bp-tablet-wide-min) { //Tab view
                font-size: 14px;
                letter-spacing: 1.5px;
                line-height: 16px;
                padding: 0 0 0 10px;
            }
        }
    }
}
//ROW-8746 Video play button for Partial width component END
//ROW-4893 Partial Width Component Style & SEO tags - Start
.promos {
    .promos__list {
        ul li:nth-child(2) .promos__item { //cover disturbed view for 520 - 640
            @include respond-min($bp-phablet-min) {
                padding-top: 12px !important;
            }
        }
        .promos__item__title { //promo title heading
            color: $clr-dy-grey-7;
            font-weight: 500;
            letter-spacing: 0;
        }
        .promos__item__copy { //promo content
            color: $clr-dy-grey-7;
            font-weight: 300;
            letter-spacing: 0;
            margin-bottom: 0;
        }
        .hgroup__action { //button outer div
            padding: 0;
            display: flex;
            justify-content: unset;
            align-items: center;
            button.common_btn { //button tag css start
                padding: 0 1.5rem;
                font-size: 1.125rem;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 1.75rem;
                text-align: center;
                width: 100%;
                max-width: 158px;
                min-height: 64px;
                margin: 1rem 0.5rem 0 0;
                &:last-child {
                    margin-right: 0;
                }
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    min-height: 56px;
                    padding: 0 1.25rem;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin: 0.75rem 0 0;
                }
                @include  respond-max($bp-mobile-max) { //Mobile view
                    padding: 0 1rem;
                }
                .rtl & {
                    margin: 1rem 0 0 0.5rem;
                    &:last-child {
                        margin-left: 0;
                    }
                }
                &.cta-button-1.dark-rectangle, &.cta-button-2.dark-rectangle {
                    color: $clr-white;
                    background: $clr-dy-grey-7;
                }
                &.cta-button-1.white-rectangle, &.cta-button-2.white-rectangle {
                    color: $clr-dy-grey-7;
                    background: $clr-divider-line;
                }
                &.cta-button-1.green-rectangle, &.cta-button-2.green-rectangle {
                    color: $clr-black;
                    background: $clr-dy-green-0;
                }
                &.cta-button-1.fuchsia-rectangle, &.cta-button-2.fuchsia-rectangle {
                    color: $clr-white;
                    background: $clr-dy-grey-13;
                }
            } //button tag css end
            a.common_btn { //anchor tag link button css start
                display: flex;
                align-items: center;
                color: $clr-dy-grey-7;
                font-size: 1.75rem;
                font-weight: 300;
                letter-spacing: 0;
                margin: 1rem 0.5rem 0 0;
                line-height: 2.25rem;
                &:last-child {
                    margin-right: 0;
                }
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    font-size: 1.5rem;
                    line-height: 2rem;
                    margin: 0.75rem 0 0;
                }
                @include  respond-max($bp-mobile-max) { //Mobile view
                    font-size: 1.375rem;
                    line-height: 1.75rem;
                    margin: 0.75rem 0.5rem 0 0;
                }
                .rtl & {
                    margin: 1rem 0 0 0.5rem;
                    &:last-child {
                        margin-left: 0;
                    }
                }
                .roundal--small {
                    margin: 0 0.5rem 0 0;
                    top: 0;
                    min-width: 40.6px;
                    min-height: 40.6px;
                    .rtl & {
                        margin: 0 0 0 0.5rem;
                    }
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        width: 28px;
                        height: 28px;
                        min-width: 28px;
                        min-height: 28px;
                    }
                    .roundal__icon {
                        .icon {
                            top: 0;
                            left: 0.625rem;
                            @include respond-max($bp-tablet-wide-min) { //Tab view
                                top: -4px;
                                left: 5px;
                            }
                            @include respond-max($bp-mobile-max) { //Mobile view
                                top: -3px;
                                left: 0.50rem;
                            }
                            .rtl & {
                                right: 0.625rem;
                                left: auto;
                                top: 4px;
                                @include respond-max($bp-tablet-wide-min) { //Tab view
                                    right: 5px;
                                    left: auto;
                                    top: -1px;
                                }
                                @include respond-max($bp-mobile-max) { //Mobile view
                                    right: 0.50rem;
                                    left: auto;
                                }
                            }
                        }
                    }
                }
                &.white-arrow {
                    min-height: 64px;
                    color: $clr-white;
                    padding: 0 1.25rem;
                    background: $clr-black;
                    justify-content: center;
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        min-height: 56px;
                        padding: 0 1.25rem;
                    }
                    @include  respond-max($bp-mobile-max) { //Mobile view
                        padding: 0 1rem;
                    }
                    .roundal--solid {
                        background: $clr-white;
                        .roundal__icon .icon {
                            fill: $clr-black;
                        }
                    }
                }
            } //anchor tag link button css start
        }
        .gp-col-1-2 { //colum design started
            h2.promos__item__title { //promo title heading
                padding: 1.75rem 0 0.5rem;
                font-size: 1.125rem;
                line-height: 1.75rem;
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    font-size: 1rem;
                    line-height: 1.5rem;
                    padding: 1rem 0 0.5rem;
                }
            }
            .promos__item__copy.rcc__title-size--paragraph { //promo content
                font-size: 1rem;
                line-height: 1.5rem;
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                }
            }
            .hgroup__action {
                @include respond-max($bp-mobile-max) { //Mobile view
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: normal;
                    justify-content: center;
                }
                button.common_btn {
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        margin: 0.75rem 0.5rem 0 0;
                        max-width: 155px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    @include respond-max($bp-mobile-max) { //Mobile view
                        margin: 0.75rem 0 0 0;
                        max-width: 100%;
                    }
                }
                a.common_btn {
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        margin: 0.75rem 0.5rem 0 0;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    @include respond-max($bp-mobile-max) { //Mobile view
                        margin: 0.75rem 0 0 0;
                    }
                }
            }
        }
        .gp-col-1-2.gt-col-1-3 { //colum design started
            h2.promos__item__title { //promo title heading
                padding: 1rem 0 0.5rem;
            }
            .hgroup__action {
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: normal;
                    justify-content: center;
                }
                button.common_btn {
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        max-width: 100%;
                        margin-right: 0;
                    }
                    &:only-child {
                        max-width: 100%;
                    }
                }
                a.common_btn {
                    margin: 1rem 0.5rem 0 0;
                    &:last-child {
                        margin-right: 0;
                    }
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        margin: 0.75rem 0 0 0;
                    }
                }
            }
        }
        .gp-col-1-2.gt-col-1-4 { //colum design started
            h2.promos__item__title {
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    padding: 1rem 0 0.5rem;
                }
            }
            .hgroup__action {
                flex-wrap: wrap;
                flex-direction: column;
                align-items: normal;
                justify-content: center;
                button.common_btn {
                    max-width: 100%;
                    width: 100%;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    padding: 0 1.25rem;
                    min-height: 56px;
                    margin: 1rem 0 0;
                    &:nth-child(2) {
                        margin: 0.5rem 0 0;
                    }
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        margin: 0.75rem 0 0 0;
                        &:nth-child(2) {
                            margin: 0.75rem 0 0;
                        }
                    }
                    @include  respond-max($bp-mobile-max) { //Mobile view
                        padding: 0 1rem;
                    }
                }
                a.common_btn {
                    margin: 1rem 0 0;
                    &:nth-child(2) {
                        margin: 0.5rem 0 0;
                    }
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        margin: 0.75rem 0 0 0;
                        &:nth-child(2) {
                            margin: 0.75rem 0 0;
                        }
                    }
                }
            }
        }
    }
}
//ROW-4893 Partial Width Component Style & SEO tags - End
//ROW-4895 Partial Width - UI Configurations - Start
.promos {
    &.promo-theme-dark {
        background: $clr-black;
        .promos__title, .promos__item__title, .promos__item__copy, .promos__content, .promos__item__header, .promos__item__header h1.promos__item__header {
            color: $clr-white;
        }
        .promos__list .hgroup__action {
            a.common_btn { //anchor tag link button css start
                &.white-arrow {
                    min-height: unset;
                    padding: 0;
                    justify-content: unset;
                }
            }
        }
    }
    .promos__list {
        .promos-card-holder {
            .promos__item__media {
                .image {
                    display: block;
                    img {
                        width: 100%;
                    }
                }
                &.image-border {
                    .image {
                        border: 1px solid $clr-dy-grey-2;
                    }
                }
            }
        }
    }
    &.both-column-image-border {
        .promos__list {
            .promos-card-holder {
                .promos__item__media {
                    &.image-border {
                        .image {
                            border: 0;
                            border-bottom: 1px solid $clr-dy-grey-2;
                        }
                    }
                }
            }
        }
    }
    .promos__list {
        .gp-col-1-2, .gp-col-1-2.gt-col-1-3, .gp-col-1-2.gt-col-1-4 { //colum design started
            .promos-card-border {
                border: 1px solid $clr-dy-grey-2;
                .promo_card-description {
                    padding: 0 1.25rem 1rem;
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        padding: 0 0.625rem 1rem;
                    }
                    @include respond-max($bp-mobile-max) { //Mobile view
                        padding: 0 1rem 1rem;
                    }

                    &.motif-image-enable {
                        .promos__item__title {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
    .promos__list {
        .promo-motif {
            .promo-motif-image-holder {
                padding: 1.5rem 0 1rem;
                @include respond-max($bp-tablet-wide-min) { //Tab view
                    padding: 1.25rem 0 1rem;
                }
                @include respond-max($bp-mobile-max) { //Mobile view
                    padding: 1rem 0 0.5rem;
                }
                img.promo-motif-image {
                    max-height: 1.75rem;
                    width: auto;
                    @include respond-max($bp-tablet-wide-min) { //Tab view
                        max-height: 1.625rem;
                    }
                    @include respond-max($bp-mobile-max) { //Mobile view
                        max-height: 1.5rem;
                    }
                }
            }
            &.double-line-motif {
                .promo-motif-image-holder {
                    img.promo-motif-image {
                        max-height: 3.25rem;
                        @include respond-max($bp-tablet-wide-min) { //Tab view
                            max-height: 3rem;
                        }
                        @include respond-max($bp-mobile-max) { //Mobile view
                            max-height: 2.75rem;
                        }
                    }
                }
            }
        }
    }
}
.row--border-top {
    border-top: 1px solid $clr-dy-grey-2 !important;
}
.row--border-bottom {
    border-bottom: 1px solid $clr-dy-grey-2 !important;
}
.row--border-both {
    border-top: 1px solid $clr-dy-grey-2 !important;
    border-bottom: 1px solid $clr-dy-grey-2 !important;
}
//ROW-4895 Partial Width - UI Configurations - End
