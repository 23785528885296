/*
 * Custom checkout success styling
 *
 * Keeping it KR specific for now
 */
.dyson-kr.checkout-onepage-success .checkout-success .actions-toolbar {
    @include respond-min($bp-tablet-min) {
        display: inline-block;
        margin-right: 0.5rem;
    }

    a {
        min-width: 230px;
    }

    &.dyson-order__checkout-actions-toolbar--margin-vertical {
        margin-top: 1rem;

        @include respond-max($bp-tablet-min) {
            margin-bottom: 1rem;
        }
    }
}
