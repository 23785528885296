.customer-header {
  border-bottom: 1px solid $clr-divider-line;
  padding: 0 2em 2em;
  max-width: 1280px;
  margin: 0 auto 2em auto;

  @include respond-min($bp-tablet-min) {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    border-bottom: 0;
  }

  @include respond-min(1280px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include respond-min($bp-desktop-wide-min) {
    padding-left: 5px;
    padding-right: 5px;
  }

  p {
    @include respond-min($bp-tablet-min) {
      width: 60%;
    }
    @include respond-min($bp-tablet-wide-min) {
      width: 50%;
    }
  }
}

.prod-reg__title {
  margin-left: 50px;
}

.product-registration-form {
  @include respond-min($bp-tablet-wide-min) {
    border: 1px solid $clr-divider-line;
    padding: 30px;
  }
}

.hr.product-reg__hr {
  border-top: 1px solid $clr-divider-line;
}

.label.form__label--inner {
  background: $clr-white;
  left: 0;
  //top: 30%;
}

.link.text-link {
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.block__newsletter__container__content-flex__inner__text {
  ul li {
    font-family: $f-futura;

    .dyson-th.primary & {
      font-family: $f-futura-thai;
    }

    .dyson-hk.primary & {
      font-family: $f-futura-hant;
    }

    .dyson-ae.rtl &, .dyson-sa.rtl & {
      font-family: $f-futura-arabic;
    }

    .dyson-il.rtl & {
      font-family: $f-futura-hebrew;
    }

    .dyson-kr & {
      font-family: $f-noto-sans;
    }

  }
}

.block__newsletter__container {
  margin: 2.5em 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $clr-divider-line;
  border-bottom: 1px solid $clr-divider-line;

  @include respond-min($bp-tablet-min) {
    padding-bottom: 40px;
  }

  .block__newsletter__container__content-flex__inner {
    @include respond-min($bp-tablet-min) {
      display: flex;
    }

    &__text {
      margin-right: 20px;
    }

  }

  .block__newsletter__container__title {
    display:flex;
    margin-bottom: 2em;

    h2 {
      margin-top: 5px;

    }
  }

  .marketing-preferences-icon-circle {
    background: #424242;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.625rem;
    height: 2.625rem;
    margin-right: .5rem;
    border-radius: 50%;

    img {
      width: 60%;
    }
  }

  .field.choice.newsletter {
    border: 1px solid $clr-dy-grey-2;
    padding: 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    p {
      margin-bottom: 20px;
    }

    input[type="checkbox"] {
      display: inline-block;
      font-family: $f-fallback;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }

      &:checked ~ label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    margin-right: 1rem;
    padding-left: 13px;
    position: relative;
    font-weight: normal;
    color: $clr-dy-grey-7;

    &:before,
    &:after {
      content: '';
      height: 32px;
      width: 32px;
      position: absolute;
      top: 0;
      left: -30px;
      transition: all .15s ease-in;
      box-sizing: border-box;
    }

    &:before{
      border: 2px solid $clr-dy-grey-2;
      background-color: $clr-white;
    }

    &:after{
      background-image: url('../images/icons/sprite.png');
      background-repeat: no-repeat;
      background-position: 0 -1856px;
      opacity: 0;
    }
  }
}


.forms-intro {
  margin-bottom: 1em;
}

.forms-inline {
  display:flex;
  margin-bottom: 1em;
  flex-wrap: wrap;

  &.no-wrap {
    flex-wrap: nowrap;
  }
}



.forms-inline > div {
  padding-right: 1rem;
}
.machine-registration__forms-control {
  margin-bottom: 5px;

  &--small {
    width: 25%;
  }
  &--medium {
    width: 30%;
  }
  &--large {
    width: 45%;
  }
}

.forms-additional-info {
  text-align: right;
  margin-bottom: 2em;
}

.privacy-policy {
  margin-top: 1.5em;
}

.product-registration-form {

  .field.required.form__item.form__mobile.form__field-email {
    @include respond-min($bp-tablet-min) {
      width: 48%;
    }
  }
  .field.field-name-firstname.required.form__item.form__item--collapse.form__mobile.form__field-firstname,
  .field.field-name-lastname.required.form__item.form__item--collapse.form__mobile.form__field-surname {
    vertical-align: top;
    @include respond-min($bp-tablet-min) {
      width: 48%;
      display: inline-block;
    }
  }

  .field.field-name-lastname.required.form__item.form__item--collapse.form__mobile.form__field-surname {
    @include respond-min($bp-tablet-min) {
      margin-left: 3%;
    }
  }

  .product-registration__cta {
    @include respond-min($bp-tablet-min) {
      width: 48%;
      margin-left: 51%;
    }
  }
}

.serial-number-form,
.purchase-date-form {
  @include respond-min($bp-tablet-min) {
    width: 48%;
    display: inline-block;
  }
}

.purchase-date-form {
  @include respond-min($bp-tablet-min) {
    margin-left: 3%;
    vertical-align: top;
  }
}

.form__item {
  &--day,
  &--month {
    width: 30%;
    display:inline-block;
    margin-bottom: 5px;
    height: 100%;
  }
  &--year {
    width: 40%;
    display:inline-block;
    margin-bottom: 5px;
    height: 100%;
    padding-right: 0 !important;
  }
}

.title-tick {
  &:after {
    content: "";
    width: 37px;
    height: 37px;
    border: 2px solid $clr-dy-green-1;
    border-radius: 3.125rem;
    position: absolute;
    left: -50px;
    top: 1px;
  }
  &:before {
    content: "";
    display: block;
    width: 7px;
    height: 16px;
    border: solid $clr-dy-green-1;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 19px;
    left: -33px;
    -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
    transform: translateY(-50%) rotate(45deg) scale(1);
  }
}

//error messages
div[for="serial_number_part_3"].mage-error,
div[for="serial_number_part_2"].mage-error {
  //display: none !important;
}
div[for="serial_number_part_1"].mage-error {
  //width: 300px;
}

input[aria-describedby="serial_number-error"] {
  //border: 1px solid $clr-dy-red-1;
}

#date-error {
  width: 238px;
  margin-top: 20px;
}

.year-unregistered-user.input-text ~ #date-error {
  width: 100%;
}
//auto registration component on checkout
.auto_registration {

  &__main__text,
  &__checkbox__label {
    font-family: $f-futura;
    span {
      font-family: $f-futura;
    }
  }

  &__main {
    padding-bottom: 2rem;
  }

  &__main__title {
    margin: 0;
    font-weight: 400;
  }

  &__main__text {
    margin-top: .5rem;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: $clr-dy-grey-66;
  }
}
