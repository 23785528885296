body.rtl {
  .hero__media {
    text-align: right;
  }

  @each $bp-code, $bp in $hero-breakpoints {
    .hero__media--left--#{$bp-code} { @include respond-min($bp) { text-align: left; } }
    .hero__media--center--#{$bp-code} { @include respond-min($bp) { text-align: center; } }
    .hero__media--right--#{$bp-code} { @include respond-min($bp) { text-align: right; } }
  }

  .hero__content {
    text-align: right;
    left: auto;
    right: 0;

    blockquote {
      text-align: inherit;
    }
  }

  @each $bp-code, $bp in $hero-breakpoints {
    .hero__content--left--#{$bp-code} { @include respond-min($bp) { left: 0; right: auto; } }
    .hero__content--lefter--#{$bp-code} { @include respond-min($bp) { left: 10%; right: auto; } }
    .hero__content--center--#{$bp-code} { @include respond-min($bp) { left: 0; right: 0; margin: 0 auto; } }
    .hero__content--righter--#{$bp-code} { @include respond-min($bp) { left: auto; right: 10%; } }
    .hero__content--right--#{$bp-code} { @include respond-min($bp) { left: auto; right: 0; } }
  }

  @each $bp-code, $bp in $hero-breakpoints {
    .hero__content--text-left--#{$bp-code} { @include respond-min($bp) { text-align: left; } }
    .hero__content--text-center--#{$bp-code} { @include respond-min($bp) { text-align: center; } }
    .hero__content--text-right--#{$bp-code} { @include respond-min($bp) { text-align: right; } }
  }

  .hero--product-variant--perfect {
    .hgroup__main-image {
      @include respond-min($bp-tablet-min) {
        float: left;
      }
    }

    .product__action {
      .button {
        margin-right: 0;
        margin-left: 4px;
      }
    }
  }
}
