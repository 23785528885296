/*
  GLOBAL HELPERS
  Function, @mixin & %placeholder helpers
*/

// FUNCTIONS

// Unit stripper
@function stripUnit($num) {
  @return $num / ($num * 0 + 1);
}

// Convert pixels to rem, based on base font
@function calcRem($size,$base-font-size:16px) {
  $remSize: $size / stripUnit($base-font-size);
  @return #{$remSize};
}

// Percentage calculator based on containing width
@function percent($size,$container) {
  @return ($size / $container) * 100%;
}

// Check if variable is a map
@function is-map($var){
  @return type-of($var) == 'map';
}

// MIXINS & HELPER CLASSES

// Min-width media query helper
@mixin respond-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// Retina media query helper
@mixin if-retina-min($width:0) {
  @media // retina prefixes
    only screen and (-webkit-min-device-pixel-ratio: 2)       and (min-width: $width),
    only screen and (   min--moz-device-pixel-ratio: 2)       and (min-width: $width),
    only screen and (   -moz-min-device-pixel-ratio: 2)       and (min-width: $width),
    only screen and (     -o-min-device-pixel-ratio: 2/1)     and (min-width: $width),
    only screen and (        min-device-pixel-ratio: 2)       and (min-width: $width),
    only screen and (                min-resolution: 192dpi)  and (min-width: $width),
    only screen and (                min-resolution: 2dppx)   and (min-width: $width) {
      @content;
  }
}

// Accessible hide & focus & reset
@mixin accessible-hide($focus:false) {
  position: absolute;
  left: -10000px;
  top: auto;

  @if $focus { // Optionally show when receiving focus
    &:active,
    &:focus {
      @include accessible-hide-reset;
    }
  }
}

@mixin accessible-hide-reset {
  position: static;
}

// Float clearing
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// default mixin for pseudo elements such as complex blocks built using before or after
@mixin pseudo($width, $height) {
  content: "";
  display: block;
  width: $width;
  height: $height;
}

// Border setter
@mixin border($border-widths, $border-color, $border-style:solid) {
  border-width: $border-widths;
  border-style: $border-style;
  border-color: $border-color;
}

// Placeholder styling
@mixin placeholder {
  &.placeholder { @content; } // For use with polyfill e.g. https://github.com/mathiasbynens/jquery-placeholder
  &::-moz-placeholder { @content; }
  &:-moz-placeholder { @content; } // Older Firefox
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

// Keyframes helper
@mixin keyframes($name) {
  @at-root {
    @-webkit-keyframes #{$name} { @content; }
    @-moz-keyframes #{$name} { @content; }
    @-ms-keyframes #{$name} { @content; }
    @keyframes #{$name} { @content; }
  }
}

// Prefix helper (for experimental features)
@mixin prefix($property,$value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -o-#{$property}: $value;
  -ms-#{$property}: $value;
  $property: $value;
}

// Custom animation for clicked buttons
@keyframes clicked {
  50%{
    transform: scale(0.9);
  }
  100%{
    transform: scale(1);
  }
}

@mixin focus {
  outline: 1px solid #4D90FE;
  box-shadow: 0px 0px 4px 0px #4D90FE;
}
