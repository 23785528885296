body.rtl {
  .search-panel__form {
    padding-right: 0;
    padding-left: 60px;
  }

  .search-panel__submit {
    right: inherit;
    left: -60px;

    @include respond-min($bp-tablet-wide-min) {
      right: inherit;
      left: -4.5rem;
    }

    &:hover, &:focus, &:active {
      border: 0;
      background: transparent;
    }
  }

  .search-panel__clear {
    right: inherit;
    left: 0;

    @include respond-min($bp-tablet-min) {
      left: 1rem;
    }

    @include respond-min($bp-tablet-wide-min) {
      left: 1.5rem;
    }
  }

  .search-panel__close {
    left: inherit;
    right: 20px;

    @include respond-min($bp-tablet-min) {
      right: 40px;
    }

    @include respond-min($bp-tablet-wide-min) {
      right: inherit;
      left: 60px;
    }
  }

  .search-panel__popular-search {

    &__title, &__terms {
      text-align: right;
    }
    
  }
}


