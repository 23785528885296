/*
  IMAGE
*/

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

picture {

}

.image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width:100%;

  .hero__motif & {
    width: 100%;
  }

  img {
    display: block;
  }

  &--stretch {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &--cover {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; // @TODO: Will need JS background fix for IE (add image-src to wrapper as background-image url, background-size: cover and visibility of image to hidden)
    }
  }

  &--max-height {

    img {
      max-height: 100%;
    }
  }
}

.image__url {
  display: inline-block;
  position: relative;
  max-width: 100%;

  .image--stretch & { width: 100%; }

  .image--cover & {
    width: 100%;
    height: 100%;
  }
}

.image__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
}
